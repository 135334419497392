import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Images from '../../assets/images';
import {
    ComposedChart,
    Line,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Scatter,
    ResponsiveContainer,
    ReferenceLine,
    CartesianAxis,
} from 'recharts';

const DepotPowerCard = (props:any) => {
    return (
        <>
            <Card className="small_card" variant="outlined">
                <CardContent className="card_content">
                    <div className="section_title">
                        <Grid container>
                            <Grid item xs={12}>
                                <h3>Power</h3>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="section_content">
                        <ResponsiveContainer  width={'99%'} height={100}>
                            <ComposedChart
                                data={props.depotPower}
                                margin={{
                                    top: 3,
                                    right: 3,
                                    bottom: 3,
                                    left: 2,
                                }}
                            >
                                <CartesianGrid vertical={false} fill="#EEEEEE" stroke="#FFFFFF" strokeOpacity={1} fillOpacity={0.5} />
                                <XAxis hide />
                                <YAxis tickMargin={-3} dy={4} width={42} unit=' KW' tickCount={4} interval={0} axisLine={false} tickLine={false} style={{ fontSize: '8px', color: '#4D4D4F' }} />
                                <Area dataKey="actualLoad" fill="#DDDDDD" stroke="#666666" strokeOpacity={0.5} fillOpacity={0.5} />
                                <Line dataKey="peakLoad" stroke="#25556E" dot={false} strokeDasharray="2 1" />
                                <ReferenceLine x={0} stroke="#666666" strokeOpacity={0.5} alwaysShow={true} />
                                <ReferenceLine x={props && props.depotPower? props.depotPower.length-1 : 0} stroke="#666666" strokeOpacity={0.5} alwaysShow={true} />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                </CardContent>
            </Card>
        </>
    )
}

export default DepotPowerCard;
