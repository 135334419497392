import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import ApiEndPoint from 'services/apiUrl.json';
import { getMethod } from 'services/api';
import { pageDetails } from 'common/common';
import moment from 'moment';
import { DataGrid } from '@material-ui/data-grid';
import CommonDownloadCsv from 'components/commonDownloadCsv/commonDownloadCsv';
import CommonPagination from 'components/commonPagination/commonPagination'
import CommonChargeSessionDetails from 'components/commonChargeSessionDetails/commonChargeSessionDetails'
import { dateFormats } from 'common/commonDateFormats';

const EvseDepotChargeHistory = (props: any) => {
    const pageSize = pageDetails.pageSize;
    const [depotChargeHistory, setDepotChargeHistory] = useState<any>({});
    const [currentPage, setCurrentPage] = useState<any>(0);
    const [pageData, setPageData] = useState('');
    const [depotChargeSessionInfo, setDepotChargeSessionInfo] = useState<any>({});
    const [open, setOpen] = React.useState(false);
    const [depotDownloadCsv, setDepotDownloadCsv] = useState();
    const [scroll, setScroll] = React.useState('paper');

    const fileHeader =[
        {label: 'START CHARGE',key: 'startChargeTime'},
        {label: 'END CHARGE',key: 'endChargeTime'},
        {label: 'PORT',key: 'portInformation'},
        {label: 'DRIVER NAME',key: 'driverName'},
        {label: 'RFID',key: 'rfId'},
        {label: 'VEHICLE ID',key: 'vehicleID'},
        {label: 'ENERGY (kWh)',key: 'energyKWh'}
    ];

    const fileName= 'DepotChargeHistory.csv'

    const historyColumns = [
        {
            field: "e",
            headerName: "e",
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <div className="empty">
                        {cellValues.value}
                    </div>
                );
            }
        },
        {
            field: "startChargeTime",
            headerName: "START CHARGE",
            sortable: false,
            hasFocus: false,
            cellClassName: "bold_cell_text",
            width: 160,
            disableColumnMenu: true,
            
        },
        {
            field: 'endChargeTime',
            headerName: 'END CHARGE',
            sortable: false,
            cellClassName: "bold_cell_text",
            width: 200,
            disableColumnMenu: true,
            
        },
        {
            field: 'portInformation',
            headerName: 'PORT',
            sortable: false,
            minWidth: 110,
            flex: 0.1,
            disableColumnMenu: true
        },
        {
            field: 'driverName',
            headerName: 'DRIVER NAME',
            sortable: false,
            flex: 0.1,
            minWidth: 100,
            disableColumnMenu: true
        },
        {
            field: 'rfId',
            headerName: 'RFID',
            sortable: false,
            width: 100,
            disableColumnMenu: true
        },
        {
            field: 'vehicleID',
            headerName: 'VEHICLE ID',
            sortable: false,
            flex: 0.1,
            minWidth: 100,
            disableColumnMenu: true
        },
        {
            field: 'energyKWh',
            headerName: 'ENERGY (kWh)',
            sortable: false,
            flex: 0.1,
            disableColumnMenu: true
        },
        {
            field: "ee",
            headerName: "ee",
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <div className="empty">
                        {cellValues.value}
                    </div>
                );
            }
        },

    ];

    useEffect(() => {
        getDepotChargeHistory()
    }, [currentPage])
    useEffect(() => {
        if(depotChargeHistory?.totalchargeHistory >0){
        getDepotDownloadCsv();
    }
      }, [depotChargeHistory?.totalchargeHistory]);

    useEffect(() => {
        handlePageData()
    }, [depotChargeHistory])

    const getDepotChargeHistory = async () => {
        await getMethod(ApiEndPoint.depotInformation, props?.depotId + "/charge-history" + "?page=" + currentPage + "&size=" + pageSize).then((res) => {
            setDepotChargeHistory(res.data)
        }).catch((err) => {
            console.log("Error", err)
        })
    }

    const getDepotDownloadCsv = async () => {
        await getMethod(ApiEndPoint.depotInformation, props?.depotId + "/charge-history" + "?page=" + 0 + "&size=" + depotChargeHistory.totalchargeHistory).then((res) => {
            setDepotDownloadCsv(res.data);
          }).catch((err) => {
            console.log('Error', err);
          });
      };


    const depotHistoryDataSet = (value: any) => {
        let historyTableData = []
        if (value && value.chargeHistoryList) {
            historyTableData = value.chargeHistoryList.map((val: any) => ({ ...val, e: 'a', ee: 'aa', id: val.historyId,startChargeTime: moment(val.startChargeTime).format(dateFormats.dateTimeFormat),endChargeTime: moment(val.endChargeTime).format(dateFormats.dateTimeFormat), }))
        }
        return historyTableData
    }

    const handlePageData = () => {
        let totalPage = depotChargeHistory && depotChargeHistory.totalchargeHistory ? depotChargeHistory.totalchargeHistory : 0;
        if (totalPage > 0) {
            let setPageValue = (currentPage >= 1 ? (pageSize * currentPage) + 1 : 1) + ' - ' + ((pageSize * (currentPage + 1)) < totalPage ? (pageSize * (currentPage + 1)) : totalPage) + ' of '
            setPageData(setPageValue)
        }
    }


    const handleCurrentPage = (setPage: any) => {
        setCurrentPage(setPage)    
    }

    const handleClickOpen = (scrollType: any, chargeData: any) => () => {
        if (chargeData && chargeData.chargeHistoryList && chargeData?.chargeHistoryList?.length >= 1) {
            setOpen(true);
        }
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onDepotChargeHistoryClick = (e: any) => {
        if (e && e.row) {
            setDepotChargeSessionInfo(e.row);
        }
    }

    return (
        <>
            <div className="table_scroll">
                <div className="defult_table" style={{ width: '100%' }} onClick={handleClickOpen('paper', depotChargeHistory)}>
                    <DataGrid autoHeight className="table"
                        rowHeight={59}
                        headerHeight={30}
                        rows={depotHistoryDataSet(depotChargeHistory)}
                        columns={historyColumns}
                        pageSize={pageSize}
                        onRowClick={onDepotChargeHistoryClick}
                        disableSelectionOnClick />
                </div>
                {open &&
                <CommonChargeSessionDetails {...props}
                    open={open}
                    setHandleClose ={handleClose}
                    historyId={depotChargeSessionInfo?.historyId}
                    sessionInfo={depotChargeSessionInfo}
                />}
                <div className="table_footer">
                    <Grid container direction="row" alignItems="center" spacing={0}>
                        <Grid className="text_left" item xs={8}>
                            {depotChargeHistory && depotChargeHistory?.totalchargeHistory > pageSize ?
                               <CommonPagination 
                               currentPage = {currentPage}
                               totalCount = {depotChargeHistory?.totalchargeHistory}
                               callBackCurrentPage = {handleCurrentPage}
                            /> : <></>}
                                <CommonDownloadCsv {...props}
                                datas={depotHistoryDataSet(depotDownloadCsv)}
                                headers={fileHeader}
                                filename={fileName}
                                callBackDownloadCsv={getDepotDownloadCsv}/>
                                </Grid>
                        <Grid className="text_right" item xs={4}>
                            <div className="list_number">{pageData} {depotChargeHistory.totalchargeHistory ? (depotChargeHistory.totalchargeHistory + ' Charge Session') : ''}</div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}
export default EvseDepotChargeHistory;