import React, { useState, useEffect } from 'react';
import Badge from '@material-ui/core/Badge';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import "react-image-gallery/styles/css/image-gallery.css";
import Images from 'assets/images';
import {getMethod} from "services/api";
import ApiEndPoint from "services/apiUrl.json";
import './vehicleDetail.scss';
import VehicleDetailsChargeHistory from './vehicleDetailChargeHistory/vehicleDetailChargeHistory';
import VehicleDetailAlerts from './vehicleDetailAlerts/vehicleDetailAlerts';
import VehicleRoutes from "./vehicleRoutes/vehicleRoutes";
import VehicleTripHistory from "./vehicleTripHistory/vehicleTripHistory";
import VehicleDetailOverview from "./vehicleDetailOverview/vehicleDetailOverview";
import { defaultVariable,useStyles } from 'common/common';
import { showProgressBar, a11yProps } from 'common/commonMethod';
import VehicleAnalytics from "./vehicleAnalytics/vehicleAnalytics";
import VehicleServiceHistory from "./vehicleServiceHistory/vehicleServiceHistory";
import TabPanel from "common/TabPanel";


const VehicleDetail = (props: any) => {
  const [vehicleDetails, setVehicleDetails] = useState<any>();
  const [imageDetail, setImageDetail] = React.useState('');
  const defaultSelect =1;
  const [vehicleId] = useState(props.location.state.id);

  const [isVisible, setIsVisible] = useState(true);
  const [vehicleStatus, setVehicleStatus] = useState("");

  const listenToScrollVehicleDetail = () => {
    const winScroll = document.body.scrollTop ||
        document.documentElement.scrollTop;
    let heightToHideFromVehicleDetail = 50;
    if (winScroll > heightToHideFromVehicleDetail) {
      isVisible &&
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScrollVehicleDetail);
    return () =>
        window.removeEventListener("scroll", listenToScrollVehicleDetail);
  }, [])

  useEffect(() => {
    getVehicleDetails();
  }, [])

  const getVehicleDetails = async () => {
    await getMethod(ApiEndPoint.vehicleService, vehicleId).then((res) => {
      setVehicleDetails(res.data);
      if (res && res?.data && res?.data?.images?.length >= 1){
        setImageDetail(res?.data?.images[0]?.thumbnailPath);
        setVehicleStatus(defaultVariable.vehicleStatusColor[res.data.status as keyof typeof defaultVariable.vehicleStatusColor]);
      }
    }).catch((err) => {
      console.log("Error", err)
    })
  }

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
      <>
        {/*----Page Mid Section Start----*/}
        <main className={classes.content + 'main_mid_section p-b-0'}>
          <div className={isVisible ? 'mid_section_inside details_pages' : 'mid_section_inside details_pages scroll_effect'}>
            <div className="depot_details vehicle_location station_details">
              <div className="depot_detail_head position_fixed_head">
                <div className="depot_information">
                  {/*----EVSE Depot Map----*/}
                  <div className="depot_location_map">
                    <img className='img_res' src={imageDetail ? imageDetail : Images.vehicle_no_img} alt="Fleet Vehicle" />
                  </div>
                  {/*----EVSE Depot Information----*/}
                  <div className="depot_info">
                    <div className="depot_name">{(vehicleDetails)?(vehicleDetails.vehicleId):''}
                      <div className={`status text_center `+ vehicleStatus}>
                        <span className="status_text">{vehicleDetails?.status}</span></div>
                    </div>

                    {isVisible
                    &&
                    <div className="soc_value">
                      <div className="soc_col">
                        <span className="label">EST DISTANCE</span>
                        <span className="result">{(vehicleDetails)?vehicleDetails?.estMiles + ' miles':'-'} </span>
                      </div>
                      <div className="soc_col">
                        <span className="label">SOC</span>
                        <span className="result">
                            <div className="soc_level">
                            <div className="text_class">{(vehicleDetails)?vehicleDetails.soc+'%':'-'}</div>
                            <div className="stat_progress">
                              <div className={`bar ${showProgressBar(vehicleDetails?.soc? vehicleDetails.soc : 0) }`} style={{ "width": vehicleDetails?.soc > 0 ? (vehicleDetails.soc + "%") : 0  }}></div>
                            </div>
                          </div>
                            </span>
                      </div>
                    </div>
                    }
                  </div>
                </div>
              </div>

              <div className="detail_page_tab">
                <AppBar className="defult_tab position_fixed_nav" position="static" color="default">
                  <div className="tab_menu_select">
                    <FormControl className="tab_menu_select_control">
                      <Select labelId="demo-simple-select-label" id="demo-simple-select" value={defaultSelect}>
                        <MenuItem className="optionlist" value={1}>
                          Status: Operational</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <Tabs className="tab_nav"
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable force tabs example">
                    <Tab className="tab_nav_item" label="Vehicle Detail" {...a11yProps(0)} />
                    <Tab className="tab_nav_item" label="Route" {...a11yProps(1)} />
                    <Tab className="tab_nav_item" label="Analytics" {...a11yProps(2)} />
                    <Tab className="tab_nav_item" label="Trip History" {...a11yProps(3)} />
                    <Tab className="tab_nav_item" label="Charge History" {...a11yProps(4)} />
                    <Tab className="tab_nav_item" label="Service History" {...a11yProps(5)} />
                    <Tab className="tab_nav_item" label={<Badge className="alert_badge" badgeContent={4} color="primary">
                      Alerts &nbsp;  &nbsp; &nbsp;
                    </Badge>} {...a11yProps(6)} />
                  </Tabs>
                </AppBar>

                <TabPanel  value={value} index={0}>
                  {(vehicleDetails)? <VehicleDetailOverview vehicleDetail={vehicleDetails}/>:''}
                </TabPanel>

                <TabPanel className="" value={value} index={1}>
                  <VehicleRoutes currentVehicleId={vehicleId} />
                </TabPanel>

                <TabPanel  value={value} index={2}>
                  <VehicleAnalytics />
                </TabPanel>

                <TabPanel className="tab_detail" value={value} index={3}>
                  <VehicleTripHistory currentVehicleId={vehicleId}/>
                </TabPanel>

                <TabPanel className="tab_detail" value={value} index={4}>
                  <VehicleDetailsChargeHistory />
                </TabPanel>

                <TabPanel className="tab_detail" value={value} index={5}>
                  <VehicleServiceHistory/>
                </TabPanel>

                <TabPanel className="tab_detail" value={value} index={6}>
                  <VehicleDetailAlerts />
                </TabPanel>

              </div>
            </div>
          </div>
        </main>

        {/*----Page Mid Section End----*/}
      </>
  );
}
export default VehicleDetail;