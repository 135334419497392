export const showProgressBar = (value: any) => {
    let statusClass = '';
    if (value && value > 0 && value <= 20 ) {
      return 'battery_low'
    }
    if (value && value > 20 && value <= 80 ) {
        return 'battery_avg'
    }
    if (value && value > 80 && value <= 100 ) {
        return 'battery_full'
    }
    return statusClass;
  };

export  const distanceToMouse = (pt: any, mp: any) => {
    if (pt && mp) {
        // return distance between the marker and mouse pointer
        return Math.sqrt(
            (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y)
        );
    }
};

export function a11yProps(index: any) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}


export const getCountriesList = () =>{
  let countries: any = [];
  countries = [
    {
      "code": "IN",
      "name": "India",
      "currency": "INR",
      "symbol": "$"
    },
    {
      "code": "US",
      "name": "United States",
      "currency": "USD",
      "symbol": "$"
    }
  ]
return countries    
}

export const getStateList = () =>{
  let states: any = [];
  states = [
    {
      "code": "UE",
      "name": "US East"
    },
    {
      "code": "AZ",
      "name": "Arizona"
    }
  ]
return states    
}

export const getFleetList = () =>{
  let fleets: any = [];
	fleets = [{
		"id": 1,
		"fleet": "Southern California"
	}, {
		"id": 2,
		"fleet": "Oregon"
	}, {
		"id": 3,
		"fleet": "Arizona"
	}, {
		"id": 4,
		"fleet": "No California"
	}, {
		"id": 5,
		"fleet": "Washington"
	}]
return fleets    
}

export const getManufacturingYearList = () =>{
  let years: any = [];
  years = [2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010, 2009, 2008]
  return years    
}

export const getMakersList = () =>{
  let makers: any = [];
	makers = [{
		"id": 1,
		"make": "VOLVO"
	}, {
		"id": 2,
		"make": "Ford "
	}, {
		"id": 3,
		"make": "Audi"
	}]
return makers    
}

export const getModelList = () =>{
  let models: any = [];
	models = [{
		"id": 1,
		"model": "XC40"
	}, {
		"id": 2,
		"model": "500 "
	}, {
		"id": 3,
		"model": "Q6"
	}]
 return models    
}
