import React from 'react';
import Proptypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import './vehicleStatus.scss';

const VehicleStatus = (props:any) => {
    return (
        <>
            <ListItem> 
                <span>{props?.statusLabel}: {props?.statusValue}</span>
                <div style={{ width: props?.statusValue + '%'  }} className={`vehicle_state_length ${props.classType}`}></div>
            </ListItem>
        </>
    )
}

VehicleStatus.propTypes = {
    statusLabel: Proptypes.string,
    statusValue: Proptypes.number,
    
};
  
VehicleStatus.defaultProps = {
    statusLabel: '',
    statusValue: 0,
    classType: ''
};

export default VehicleStatus;