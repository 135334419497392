import React, { useState, useEffect,useRef } from 'react';
import Button from '@material-ui/core/Button';
import { CSVLink} from "react-csv";
import Proptypes from 'prop-types';

const CommonDownloadCsv = (props:any) => {
    
    return (
    <>
         <CSVLink className="csv_download"
          data={props?.datas} 
          headers={props?.headers}  
          filename={props?.filename}>
         <Button className="btn_white" onClick={props?.callBackDownloadCsv}>Download CSV</Button>
         </CSVLink>
      
    </>
  );
};

CommonDownloadCsv.propTypes = {
  data: Proptypes.any,
  headers: Proptypes.any,
  filename: Proptypes.string
  
};

CommonDownloadCsv.defaultProps = {
  data: [],
  headers: [],
  filename: ""
}; 
export default CommonDownloadCsv;