import React, { useState, useEffect } from 'react';
import Badge from '@material-ui/core/Badge';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useSelector } from 'react-redux'
import { get } from 'lodash';
import StatusButton from '../../../components/statusButton/statusButton';
import { useStyles } from '../../../common/common';
import Images from '../../../assets/images';
import VehicleGroupAlerts from './vehicleGroupAlerts/vehicleGroupAlerts';
import VehicleGroupChargeHistory from './vehicleGroupChargeHistory/vehicleGroupChargeHistory';
import VehicleGroupInformation from './vehicleGroupInformation/vehicleGroupInformation';
import VehicleGroupOverview from './vehicleGroupOverview/vehicleGroupOverview'
import VehicleLocation from "./vehicleLocation/vehicleLocation";
import VehicleGroupAnalytics from './vehicleGroupAnalytics/vehiclegGroupAnalytics'
import VehicleGroupRoutes from './vehicleGroupRoutes/vehicleGroupRoutes'
import TabPanel from "../../../common/TabPanel";
import './vehicleGroup.scss';


function a11yProps(index:any) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const VehicleGroup = (props:any) => {
  const VehicleDetailsData = useSelector((state:any) => { return get(state.vehicleData, ['vehDetails'], {}) })
  const [value, setValue] = React.useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const classes = useStyles();

  const listenToScroll = () => {
    let heightToHideFrom = 50;
    const winScroll = document.body.scrollTop ||
      document.documentElement.scrollTop;
    if (winScroll > heightToHideFrom) {
      isVisible &&      // to limit setting state only the first time         
        setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () =>
      window.removeEventListener("scroll", listenToScroll);
  }, [])

  const handleChange = (event:any, newValue:any) => {
    setValue(newValue);
  };

  return (
      <>
        <main className={classes.content}>
        <div className={isVisible ? "mid_section_inside details_pages" : 'mid_section_inside details_pages scroll_effect'}>
            <div className="depot_details station_details vehicle_detail">
              <div className="depot_detail_head position_fixed_head">

                <div className="depot_information">
                  <div className="depot_location_map">
                    <img className='img_res' src={VehicleDetailsData?.image} alt="Fleet Vehicle" />
                  </div>
                  <div className="depot_info">
                    <div className="depot_name">{VehicleDetailsData?.name}</div>
                    {isVisible
                      &&
                      <div className="port_details">
                        <span className="port">
                          <img src={Images.IC_AIRPORT_SHUTTLE} alt="airport shuttle" />
                          {VehicleDetailsData?.totalVehicles ? VehicleDetailsData.totalVehicles : 0} Total Vehicle</span>

                      </div>
                    }
                  </div>
                </div>
                <div className="stat_list text_right">
                  <StatusButton classType="parked" statusLabel="Parked" statusValue={VehicleDetailsData?.statusCount?.parked} />
                  <StatusButton classType="complete" statusLabel="Complete" statusValue={VehicleDetailsData?.statusCount?.complete} />
                  <StatusButton classType="charging" statusLabel="Charging" statusValue={VehicleDetailsData?.statusCount?.charging} />
                  <StatusButton classType="connected" statusLabel="Connected" statusValue={VehicleDetailsData?.statusCount?.connected} />
                  <StatusButton classType="transit" statusLabel="Transit" statusValue={VehicleDetailsData?.statusCount?.transit} />
                  <StatusButton classType="in-service" statusLabel="In Service" statusValue={VehicleDetailsData?.statusCount?.inService} />
                  <StatusButton classType="offline" statusLabel="Offline" statusValue={VehicleDetailsData?.statusCount?.offline} />
                </div>
              </div>
              <div className="detail_page_tab">
                <AppBar className="defult_tab position_fixed_nav" position="static" color="default">
                  <Tabs className="tab_nav"
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable force tabs example"
                  >
                    <Tab className="tab_nav_item" label="Overview" {...a11yProps(0)} />
                    <Tab className="tab_nav_item" label="Location" {...a11yProps(1)} />
                    <Tab className="tab_nav_item" label="Information" {...a11yProps(2)} />
                    <Tab className="tab_nav_item" label="Routes" {...a11yProps(3)} />
                    <Tab className="tab_nav_item" label="Charge History" {...a11yProps(4)} />
                    <Tab className="tab_nav_item" label="Analytics" {...a11yProps(5)} />
                    <Tab className="tab_nav_item" label={<Badge className="alert_badge" badgeContent={4} color="primary">
                      Alerts &nbsp;  &nbsp; &nbsp;
                    </Badge>} {...a11yProps(6)} />
                  </Tabs>
                </AppBar>
                <TabPanel className="tab_detail" value={value} index={0}>
                  <VehicleGroupOverview />
                </TabPanel>
                <TabPanel value={value} index={1}>
                 <VehicleLocation />
                </TabPanel>
                <TabPanel className="" value={value} index={2}>
                  <VehicleGroupInformation />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <VehicleGroupRoutes />
                </TabPanel>
                <TabPanel className="tab_detail" value={value} index={4}>
                    <VehicleGroupChargeHistory />
                </TabPanel>
                <TabPanel  value={value} index={5}>
                    <VehicleGroupAnalytics />
                </TabPanel>
                <TabPanel className="tab_detail" value={value} index={6}>
                    <VehicleGroupAlerts />                   
                </TabPanel>
              </div>
            </div>
          </div>
        </main>
    </>
  );
}
export default VehicleGroup;