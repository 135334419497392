import React from "react";
import Grid from '@material-ui/core/Grid';
import PropTypes from "prop-types";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


const DropDownList= (props:any) => {

    return (
        <div className="form_col">
        <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={0}>
          <Grid item xs={12}>
            <label>{props?.label} *</label>
            <Select labelId="demo-simple-select-label"  
            variant="outlined"
            defaultValue={1}>
            {props?.data && props?.data.map((value: any) => 
            (<MenuItem className="optionlist" key={value.id} value={value.value}>{value.zone}</MenuItem>))} 
            </Select>
          </Grid> 
        </Grid> 
      </div> 
    );
}

export default DropDownList;
