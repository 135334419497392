import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Images from '../../assets/images';
import Header from '../header/header';
import { sidebarData, commonRoutes} from '../../common/common';
import {useHistory} from "react-router-dom";
import Badge from '@material-ui/core/Badge';
import Icon from '@material-ui/core/Icon';
import './sidebarMenu.scss';

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: sidebarData.drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: sidebarData.drawerWidth,
    },

    drawerOpen: {
        width: sidebarData.drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },


    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(8) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
}));

const SidebarMenu = (props:any) => {

    const classes = useStyles();
    const history = useHistory();
    const depotRoute = commonRoutes.depotAllRoutes;
    const vehicleRoute = commonRoutes.vehicleAllRoutes;
    const driverRoute = commonRoutes.driverAllRoutes;
    const meesageRoutes = commonRoutes.messageAllRoutes;
    const [opend, setOpend] = React.useState(true);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    useEffect(() => {
        if(history?.location?.pathname){
            selectedRoute()
        }
        
    }, [history?.location?.pathname])

    const handleDrawerOpen = () => {
        setOpend(true);
    };

    const handleDrawerClose = () => {
        setOpend(false);
    };

    const selectedRoute = () => {
        if(history?.location?.pathname){
            if(depotRoute.filter(val => val == history?.location?.pathname).length >= 1){
                setSelectedIndex(0) 
            }else if ( vehicleRoute.filter(val => val == history?.location?.pathname).length >= 1){
                setSelectedIndex(1)
            }else if ( driverRoute.filter(val => val == history?.location?.pathname).length >= 1){
                setSelectedIndex(2)
            }else if ( meesageRoutes.filter(val => val == history?.location?.pathname).length >= 1){
                setSelectedIndex(3)
            }              
        }             
    }; 

    const navigateToRoutes = (val:any) => {
         if (val){
            history.push(val);
         }  
    }

    return (
        <div className={opend ? "drawer_open" : "drawer_close"}>
            <CssBaseline />
            <Header />

            <div className="left_sidenavbar">
                <Drawer
                    variant="permanent"

                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: opend,
                        [classes.drawerClose]: !opend,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: opend,
                            [classes.drawerClose]: !opend,
                        }),
                    }}
                    anchor="left">
                    <div className="sidbar_logo">
                        <a href="/">
                            <img src={Images.LOGO} alt="logo" />
                        </a>
                        <Icon className="sidebar_open" onClick={handleDrawerClose}>
                            <img src={Images.ic_sidebar_open} alt="sidbar_open" />
                        </Icon>

                        <Icon className="sidebar_close">
                            <img {...props} onClick={handleDrawerOpen}
                                edge="start"
                                
                                src={Images.ic_sidebar_close} alt="sidbar_close" />
                        </Icon>
                    </div>
                    <List className="menu_list">                   
                        <ListItem className={`menu_list_item ${selectedIndex === 0 ? 'active' : '' }`} button onClick={() => navigateToRoutes(commonRoutes.depotListRoute)}>
                            <ListItemIcon className="nav_icon">
                                <img className="img_ic" src={Images.IC_EVSE_DEPOT_NAV} alt="EVSE DEPOT" />
                                <img className="img_hover" src={Images.IC_EVSE_DEPOT_W_NAV} alt="EVSE DEPOT" />
                            </ListItemIcon>
                            <ListItemText primary="EVSE Depots" />
                        </ListItem>
                        <ListItem className={`menu_list_item ${selectedIndex === 1 ? 'active' : '' }`} button onClick={() => navigateToRoutes(commonRoutes.vehicleListRoute)}>
                            <ListItemIcon className="nav_icon">
                                <img className="img_ic" src={Images.IC_AIRPORT_SHUTTLE_NAV} alt="Vehicles" />
                                <img className="img_hover" src={Images.IC_AIRPORT_SHUTTLE_W_NAV} alt="Vehicles" />
                            </ListItemIcon>
                            <ListItemText primary="Vehicles" />
                        </ListItem>
                        <ListItem className={`menu_list_item ${selectedIndex === 2 ? 'active' : '' }`} button onClick={() => navigateToRoutes(commonRoutes.driversRoute)}>
                            <ListItemIcon className="nav_icon">
                                <img className="img_ic" src={Images.IC_DRIVER_NAV} alt="Drivers" />
                                <img className="img_hover" src={Images.IC_DRIVER_W_NAV} alt="Drivers" />
                            </ListItemIcon>
                            <ListItemText primary="Drivers" />
                        </ListItem>
                        <ListItem className={`menu_list_item ${selectedIndex === 3 ? 'active' : '' }`} button onClick={() => navigateToRoutes(commonRoutes.messageRoute)} >
                            <ListItemIcon className="nav_icon">
                                <img className="img_ic" src={Images.ic_sidebar_msg} alt="Messages" />
                                <img className="img_hover" src={Images.ic_sidebar_w_msg} alt="Messages" />
                            </ListItemIcon>
                           
                            <Badge className="msg_badge" badgeContent={13} color="primary">
                            <ListItemText primary="Messages"  />
                      </Badge> 
                        </ListItem>
                        <ListItem className="menu_list_item" button>
                            <ListItemIcon className="nav_icon">
                                <img className="img_ic" src={Images.IC_RFID_NAV} alt="RFID Cards" />
                                <img className="img_hover" src={Images.IC_RFID_W_NAV} alt="RFID Cards" />
                            </ListItemIcon>
                            <ListItemText primary="RFID Cards" />
                        </ListItem>
                        <ListItem className="menu_list_item" button>
                            <ListItemIcon className="nav_icon">
                                <img className="img_ic" src={Images.IC_ANALYTICS_NAV} alt="Analytics" />
                                <img className="img_hover" src={Images.IC_ANALYTICS_W_NAV} alt="Analytics" />
                            </ListItemIcon>
                            <ListItemText primary="Analytics" />
                        </ListItem>
                        <ListItem className="menu_list_item" button>
                            <ListItemIcon className="nav_icon">
                                <img className="img_ic" src={Images.IC_FLEET_SETTING_NAV} alt="Fleet Settings" />
                                <img className="img_hover" src={Images.IC_FLEET_SETTING_W_NAV} alt="Fleet Settings" />
                            </ListItemIcon>
                            <ListItemText primary="Fleet Settings" />
                        </ListItem>
                    </List>
                </Drawer>
            </div>
        </div>
    )
}

export default SidebarMenu