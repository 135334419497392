import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import moment from "moment";
import { dateFormats } from 'common/commonDateFormats';
import Proptypes, { any } from 'prop-types';
import axios from 'axios';

const CommonRecentAlerts = (props:any) => {

    const alertData = props?.alertInfo;
    const end = props?.alertCount;

  return (
    <>
          <div className="section_title">
              <Grid container>
                  <Grid item xs={12}>
                      <h3>Recent Alerts</h3>
                  </Grid>
              </Grid>
          </div>
          <div data-testid="list" className="recent_alert_list">
              <List>
                  {alertData && alertData.length >= 1 && alertData.slice(0, end).map((value: any, index: any) => (
                      <ListItem key={index}>
                          <span className="unread"></span>
                          <Grid justifyContent="space-between" container spacing={0}>
                              <Grid className="vehicle_number" item xs={4} >{value?.vehicleId}</Grid>
                              <Grid className="time_detail text_right" item xs={8} >{value?.alertTime ? moment(value?.alertTime).format(dateFormats.dateTimeFormat) : ''} </Grid>
                          </Grid>
                          <div className="recent_alert_msg">
                              {value?.alert}
                          </div>
                      </ListItem>
                  ))}
              </List>
          </div>
    </>
  );
};

CommonRecentAlerts.propTypes = {
    end: Proptypes.number,
    alertData: Proptypes.any
};
  
CommonRecentAlerts.defaultProps = {
    end: 5,
    alertData: []
};

export default CommonRecentAlerts;