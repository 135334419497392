import {fetchFleetReducer, fetchFleetDataReducer} from './fleetDashboard';
import fetchDepotReducer from './evseDepotList';
import { fetchVehicleReducer, fetchCurrentVehicleReducer, fetchCurrentVehicleDetailReducer }  from './vehicleList';
import { fetchCurrentStationReducer } from './stationList'

const rehydrated = (state = false, action: any) => {
    switch (action.type) {
        case 'persist/REHYDRATE':
            return true;
        default:
            return state;
    }
};

export default {
    rehydrated: rehydrated,
    currentFleetId: fetchFleetReducer,
    depotData:fetchDepotReducer,
    vehicleData: fetchVehicleReducer,
    currentVehicleId: fetchCurrentVehicleReducer,
    currentVehicleDetailId: fetchCurrentVehicleDetailReducer,
    currentStationId: fetchCurrentStationReducer,
    fleetData: fetchFleetDataReducer
};
