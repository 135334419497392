const Images = {
    //LOGO: "logo.png"
    //LOGO: "logo512.png",
    LOGO: require("./logo/logo.svg").default,
    IC_BUS: require("./icons/ic_bus.svg").default,
    IC_ALERT: require("./icons/ic_alert.svg").default,
    IC_MSG: require("./icons/ic_msg.svg").default,
    IC_HELP: require("./icons/ic_help.svg").default,
    IC_USER: require("./icons/ic_user.svg").default,
    IC_EVSE_GRAY: require("./icons/ic_evse_gray.svg").default,
    IC_AIRPORT_SHUTTLE: require("./icons/ic_airport_shuttle.svg").default,
    IC_ASSIGMENT_IND: require("./icons/ic_assignment_ind.svg").default,
    IC_EVSE_BLUE: require("./icons/ic_evse_blue.svg").default,
    IC_AIRPORT_SHUTTLE_BLUE: require("./icons/ic_airport_shuttle_blue.svg").default,
    IC_RFID: require("./icons/ic_rfid.svg").default,
    IC_ROUTE: require("./icons/ic_route.svg").default,
    IC_UTILITIES: require("./icons/ic_utilities.svg").default,
    IC_DRIVER: require("./icons/ic_driver.svg").default,
    IC_EVSE_DEPOT_NAV: require("./nav-bar/ic_evse_depot.svg").default,
    IC_AIRPORT_SHUTTLE_NAV: require("./nav-bar/ic_airport_shuttle.svg").default,
    IC_DRIVER_NAV: require("./nav-bar/ic_driver.svg").default,
    IC_RFID_NAV: require("./nav-bar/ic_rfid.svg").default,
    IC_ANALYTICS_NAV: require("./nav-bar/ic_analytics.svg").default,
    IC_FLEET_SETTING_NAV: require("./nav-bar/ic_fleet_setting.svg").default,
    IC_EVSE_DEPOT_W_NAV: require("./nav-bar/ic_evse_depot_w.svg").default,
    IC_VEHICLE: require("./icons/vehicle_current_location.svg").default,
    ic_map_cluster: require("./map/map_cluster_marker.svg").default,
    IC_AIRPORT_SHUTTLE_W_NAV: require("./nav-bar/ic_airport_shuttle_w.svg").default,
    IC_DRIVER_W_NAV: require("./nav-bar/ic_driver_w.svg").default,
    IC_RFID_W_NAV: require("./nav-bar/ic_rfid_w.svg").default,
    IC_ANALYTICS_W_NAV: require("./nav-bar/ic_analytics_w.svg").default,
    IC_FLEET_SETTING_W_NAV: require("./nav-bar/ic_fleet_setting_w.svg").default,
    MAP_IMG: require("./map/map_img.svg").default,
    LOADING_GRAPH: require("./graph/landing-graph.svg").default,
    ENERGY_GRAPH: require("./graph/energy_graph.png"),
    EVSE_DEPOT_GRAPH: require("./graph/evse_depot_graph.png"),
    DEPOT_LOCATION: require("./map/depot_location.png"),
    IC_PORT_12x14: require("./icons/ic_port_12x14.svg").default,
    IC_alert_12x14: require("./icons/ic_alert_12x14.svg").default,
    IC_SELECT_CARET: require("./icons/ic_select_caret.svg").default,
    ic_arrow_drop_down_24px: require("./icons/ic_arrow_drop_down_24px.svg").default,
    ic_first_page: require("./icons/ic_first_page.svg").default,
    ic_chevron_left: require("./icons/ic_chevron_left.svg").default,
    ic_chevron_right: require("./icons/ic_chevron_right.svg").default,
    ic_last_page: require("./icons/ic_last_page.svg").default,
    ic_edit: require("./icons/ic_edit.svg").default,
    ic_zone_map: require("./map/ic_zone_map.svg").default,
    ic_zone_map2: require("./map/ic_zone_map2.svg").default,
    ic_wifi: require("./icons/ic_wifi.svg").default,
    ic_local_parking: require("./icons/ic_local_parking.svg").default,
    ic_local_hotel: require("./icons/ic_local_hotel.svg").default,
    ic_dialog_graph: require("./graph/dialog_graph.svg").default,
    ic_zone_map_del: require("./map/zone_map_del.png"),
    ic_analytics_graph1: require("./graph/analytics_1.png"),
    ic_analytics_graph2: require("./graph/analytics_2.png"),
    ic_sidebar_open: require("./icons/ic_sidebar_open.svg").default,
    ic_sidebar_close: require("./icons/ic_sidebar_close.svg").default,
    ic_port_charger: require("./icons/ic_port_charger.svg").default,
    ic_fleet_vehicle: require("./icons/fleet_vehicle.png"),
    ic_fleet_map: require("./map/vehicle_map.png"),
    ic_map_route: require("./icons/ic_map_route.svg").default,
    ic_any_graph: require("./graph/fleet_info.png"),
    speed_meter: require("./graph/speed_meter.svg").default,
    MAP_MARKER: require("./icons/ic_map_marker.svg").default,
    ic_back_arrow: require("./icons/ic_arrow_back.svg").default,
    ic_gallery_img1: require("./image_gallery/gallery_img1.png"),
    ic_gallery_img2: require("./image_gallery/gallery_img2.png"),
    ic_gallery_img3: require("./image_gallery/gallery_img3.png"),
    ic_gallery_img4: require("./image_gallery/gallery_img4.png"),
    ic_gallery_img1_thum: require("./image_gallery/gallery_img1_thum.png"),
    ic_gallery_img2_thum: require("./image_gallery/gallery_img2_thum.png"),
    ic_gallery_img3_thum: require("./image_gallery/gallery_img3_thum.png"),
    ic_gallery_img4_thum: require("./image_gallery/gallery_img4_thum.png"),
    ic_setfee: require("./icons/set_fee.svg").default,
    ic_dots: require("./icons/dots_icon.svg").default,
    location_map: require("./map/location_map.svg").default,
    ic_edit_location_available: require("./map/ic_edit_location_available.svg").default,
    ic_edit_location_not_available: require("./map/ic_edit_location_not_available.svg").default,
    ic_edit_location_primary_depot : require("./map/ic_edit_location_primary_depot.svg").default,
    ic_driver_group : require("./icons/driver_group.svg").default,
    ic_driver_shift_icon : require("./icons/driver_shift_icon.svg").default,
    vehicle_img : require("./image_gallery/vehicle_img.png"),
    vehicle_location_map : require("./map/vehicle_location_map.png"),
    ic_directions : require("./icons/ic_directions.svg").default,
    ic_rout_map : require("./map/rout_map.png"),
    ic_ic_arrow_drop_up : require("./icons/ic_arrow_drop_up.svg").default,
    vehicle_img_sec : require("./icons/fleet_vehicle2.png"),
    driver_photo : require("./icons/driver_photo.png"),
    driver_location: require("./map/driver_location.png"),
    license_front: require("./icons/license_front.png"),
    license_back: require("./icons/license_back.png"),
    shift_driver_group: require("./icons/shift_driver_group.svg").default,
    trip_route: require("./map/trip_route.png"),
    ic_format_list_numbered: require("./icons/ic_format_list_numbered.svg").default,
    schedule_chart: require("./graph/schedule_chart.svg").default,
    ic_add_circle: require("./icons/ic_add_circle.svg").default,
    ic_remove_circle: require("./icons/ic_remove_circle.svg").default,
    schedule_chart_off_peak: require("./graph/schedule_chart_off_peak.svg").default,
    schedule_chartpeak: require("./graph/schedule_chartpeak.svg").default,
    schedule_chart_super_peak: require("./graph/schedule_chart_super_peak.svg").default,
    vehicle_map_marker: require("./icons/vehicle_current_location.svg").default,
    vehicle_blank_map_marker: require("./icons/vehicle_current_location_blank.svg").default,
    ic_arrow_drop_down: require("./icons/ic_arrow_drop_down.svg").default,
    ic_sidebar_msg: require("./icons/ic_chat_bubblepx.svg").default,
    ic_sidebar_w_msg: require("./icons/ic_chat_w_bubblepx.svg").default,
    hr_status: require("./graph/hr_status.svg").default,
    ic_transit: require("./icons/ic_transit.svg").default,
    ic_parking: require("./icons/ic_parking.svg").default,
    ic_charging: require("./icons/ic_charging.svg").default,
    ic_in_service: require("./icons/ic_in-service.svg").default,
    ic_complete: require("./icons/ic_complete.svg").default,
    ic_connected: require("./icons/ic_connected.svg").default,
    ic_offline: require("./icons/ic_offline.svg").default,
    charge_percent: require("./graph/charge_percent.png"),
    energy_gr: require("./graph/energy_gr.svg").default,
    energy_consumption: require("./graph/energy_consumption.svg").default,
    custom_views: require("./graph/custom_views.svg").default,
    route_map_one: require("./map/route_map-1.svg").default,
    route_map_two: require("./map/route_map-2.svg").default,
    route_map_three: require("./map/route_map-3.svg").default,
    route_map_four: require("./map/route_map-4.svg").default,
    route_map_five: require("./map/route_map-5.svg").default,
    route_map_six: require("./map/route_map-6.svg").default,
    route_map_seven: require("./map/route_map-7.svg").default,
    route_map_eight: require("./map/route_map-8.svg").default,
    ic_sites: require("./icons/ic-sites.svg").default,
    ic_hours: require("./icons/ic-hours.svg").default,
    ic_distance: require("./icons/ic-distance.svg").default,
    ic_energy: require("./icons/ic-energy.svg").default,
    evse_status: require("./graph/evse_status.svg").default,
    ic_watch_later: require("./icons/ic_watch_later.svg").default,
    ic_error: require("./icons/ic_error.svg").default,
    port_usage: require("./graph/port_usage.svg").default,
    power_consumption: require("./graph/power_consumption.svg").default,
    evse_health: require("./graph/evse_health.png"),
    charge_status_vehicle: require("./graph/charge_status_vehicle.png"),
    vehicle_no_img : require("./icons/vehicle_no_img.jpg"),
    blink_bot : require("./icons/blink_bot.svg").default,
    driver_pic_1 : require("./icons/dr_1.svg").default,
    driver_pic_2 : require("./icons/dr_2.svg").default,
    driver_pic_3 : require("./icons/dr_3.svg").default,
    driver_pic_4 : require("./icons/dr_4.svg").default,
    driver_pic_5 : require("./icons/dr_5.svg").default,
    driver_pic_6 : require("./icons/dr_6.svg").default,
    driver_pic_7 : require("./icons/dr_7.svg").default,
    driver_pic_8 : require("./icons/dr_8.svg").default,
    driver_pic_9 : require("./icons/dr_9.svg").default,
    driver_pic_10 : require("./icons/dr_10.svg").default,
    driver_pic_11 : require("./icons/dr_11.svg").default,
    driver_all : require("./icons/ms_all.svg").default,
    send_button : require("./icons/send_button.svg").default,
    ic_open_in_new : require("./icons/ic_open_in_new.svg").default,
    speed_meter2: require("./graph/veh_avg2.png"),
    speed_meter3: require("./graph/veh_avg3.png"),
    speed_meter4: require("./graph/veh_avg4.png"),
    speed_meter5: require("./graph/veh_avg5.png"),
    info_map_a: require("./map/info_map.png"),
    up_image: require("./icons/collections_black.svg").default,
    delete_icon: require("./icons/deleteicon.svg").default,
    drag_icon: require("./icons/drag_indicator.svg").default,
    cancel_icon: require("./icons/cancel_black.svg").default,
    cancelred_icon: require("./icons/cancel_red.svg").default,
    error_icon: require("./icons/error_black.svg").default,
    gallery_img_thum1: require("./icons/gallery_thum1.png"),
    gallery_img_thum2: require("./icons/gallery_thum2.png"),
    gallery_img_thum3: require("./icons/gallery_thum3.png"),
    gallery_img_thum4: require("./icons/gallery_thum4.png"),
    gallery_img_thum5: require("./icons/gallery_thum5.png"),
    gallery_img_thum6: require("./icons/gallery_thum6.png"),
    gallery_img_thum7: require("./icons/gallery_thum7.png"),
    vehicle_img_thum1: require("./image_gallery/vehicle_img.png"),
    
   
    
    }
export default Images