import React, { useState, useEffect, useRef } from "react";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from "@material-ui/core/TextField";
import Divider from '@material-ui/core/Divider';
import { ContactlessOutlined } from "@material-ui/icons";
import CommonAddressForm from "../../../../components/commonAddressForm/commonAddressForm";
import CommonContactForm from "../../../../components/commonContactForm/commonContactForm";
import SimpleReactValidator from 'simple-react-validator';

const EditVehiclesDetails = (props: any) => {
  const [intialValues, setIntialValues] = useState<any>({vehicleData:props.vehicleData,vehicleName:props.vehicleData?.vehDetails});
  const [formErrors, setFormErrors] = useState<any>();
  const [scroll, setScroll] = React.useState('paper');
  const [fleetContact, setFleetContact] = useState<any>();
  const [serviceContact, setServiceContact] = useState<any>();
  const [initialDetails, setInitialDetails] = useState<any>();
  const [addressDetails, setAddressDetails] = useState<any>();
  const simpleValidator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState<any>();

  useEffect(() =>{
    makeFleetContactData()
    makeServiceContactData()
    initialData()
    makeAddressData()
  },[])

  const makeAddressData = async () =>{
    let addressInfo =  {
      "StreetAddress1":props.vehicleData.OrganisationDetails?.evsedepotStreetAddress1 ? props.vehicleData.OrganisationDetails?.evsedepotStreetAddress1 : '',
      "StreetAddress2":props.vehicleData.OrganisationDetails?.evsedepotStreetAddress2 ? props.vehicleData.OrganisationDetails?.evsedepotStreetAddress2 : '',
      "City":props.vehicleData.OrganisationDetails?.evsedepotCity ? props.vehicleData.OrganisationDetails?.evsedepotCity : '',
      "State":props.vehicleData.OrganisationDetails?.evsedepotState ? props.vehicleData.OrganisationDetails?.evsedepotState : '',
      "lat": '34.0226102',
      "lon": '-118.2511792',
      "ZipCode":props.vehicleData.OrganisationDetails?.evsedepotZipCode ? props.vehicleData.OrganisationDetails?.evsedepotZipCode : '',
      "CountryCode":props.vehicleData.OrganisationDetails?.evsedepotCountryCode ? props.vehicleData.OrganisationDetails?.evsedepotCountryCode : '',
      "description":props.vehicleData.OrganisationDetails?.description ? props.vehicleData.OrganisationDetails?.description : '',
      "notes":props.vehicleData.OrganisationDetails?.description ? props.vehicleData.OrganisationDetails?.notes : '',
          } 
    setAddressDetails(addressInfo);
  }

  const makeFleetContactData = async () =>{
    let contactInfo =  {
      "firstName":props.vehicleData.contactDetails[0]?.firstName ? props.vehicleData.contactDetails[0].firstName : '',
      "telePhoneNumber":props.vehicleData.contactDetails[0]?.telePhoneNumber ? props.vehicleData.contactDetails[0].telePhoneNumber : '',
      "mobileNumber":props.vehicleData.contactDetails[0]?.mobileNumber ? props.vehicleData.contactDetails[0].mobileNumber : '',
      "fax":props.vehicleData.contactDetails[0]?.fax ? props.vehicleData.contactDetails[0].fax : '',
      "email":props.vehicleData.contactDetails[0]?.email ? props.vehicleData.contactDetails[0].email : '',
      "note":props.vehicleData.contactDetails[0]?.note ? props.vehicleData.contactDetails[0].note : '',
    } 
  setFleetContact(contactInfo);
  }

  const makeServiceContactData = async () =>{
    let serviceContactInfo =  {
      "firstName":props.vehicleData.contactDetails[0]?.firstName ? props.vehicleData.contactDetails[0].firstName : '',
      "telePhoneNumber":props.vehicleData.contactDetails[0]?.telePhoneNumber ? props.vehicleData.contactDetails[0].telePhoneNumber : '',
      "mobileNumber":props.vehicleData.contactDetails[0]?.mobileNumber ? props.vehicleData.contactDetails[0].mobileNumber : '',
      "fax":props.vehicleData.contactDetails[0]?.fax ? props.vehicleData.contactDetails[0].fax : '',
      "email":props.vehicleData.contactDetails[0]?.email ? props.vehicleData.contactDetails[0].email : '',
      "note":props.vehicleData.contactDetails[0]?.note ? props.vehicleData.contactDetails[0].note : '',
    } 
    setServiceContact(serviceContactInfo);
  }

  const initialData = async ()=>{
    let initialDta = {
      "name": props.vehicleData?.vehDetails?.name,
      "organizationName": props.vehicleData?.OrganisationDetails?.organizationName
    }
    setInitialDetails(initialDta);
  }

  const handleInitialChange = (e: any) => {
    let name = e.target.name;let value = e.target.value;
    setInitialDetails({ ...initialDetails, [name]:value});    
  };


const submitEditForm = () =>{

  if (simpleValidator.current.allValid()) {
    let data:any = [];
    data['basic'] = initialDetails;
    data['address'] = addressDetails;
    data['fleetContact'] = fleetContact;
    data['serviceContact'] = serviceContact;
    //final data submit to api
    alert('You submitted the form and stuff!');
  } else {
    simpleValidator.current.showMessages();
    // rerender to show messages for the first time
    // you can use the autoForceUpdate option to do this automatically`
    forceUpdate(1);

  }
}
  
  return (
    <>
    <form>
      <div className="dialog_title">
        <h4>Edit Fleet Details</h4>
        <div className="button_row mt-0 full_right">
          <Button
            className="btn_white text_btn_theme"
            onClick={props?.EditfleeDetailclose}
          >
            Cancel
          </Button>
          <Button onClick={submitEditForm}  className="btn_white blue_bg">Save Changes</Button>
        </div>
      </div>
      

      <DialogContent dividers={scroll === 'paper'} >
        <DialogContentText component="div" id="scroll-dialog-description">
          <div className="defult_form">
            <div className="section_group">
                  <h5>* Required information</h5>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <div className="form_col">
                        <label>Name *</label>
                        <TextField
                      variant="outlined"
                      className="error"
                      value={initialDetails?.name}
                      name="name"                      
                      onChange={handleInitialChange}
                      onBlur={()=>simpleValidator.current.showMessageFor('name')}
                      inputProps={{ maxLength: 140 }}
                      />
                      <span className="error_msg">{simpleValidator.current.message('name', initialDetails?.name, 'required')}</span>
                       </div> 
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form_col">
                        <label>Organization *</label>
                        <TextField
                      label=""
                      disabled
                      className="disable"
                      variant="outlined"
                      value={initialDetails?.organizationName}
                      name="organizationName"
                      onChange={handleInitialChange}
                      onBlur={()=>simpleValidator.current.showMessageFor('organizationName')}
                      />
                      <span className="error_msg">{simpleValidator.current.message('organizationName', initialDetails?.organizationName, 'required')}</span>
                       </div> 
                    </Grid>
                  </Grid>  
                  </div> 
            <Divider />
            <CommonAddressForm
             addressDetails = {addressDetails}
             setAddressDetails = {setAddressDetails}
             validator = {simpleValidator}
             formErrors = {formErrors}
             renderMap={false}
             />
            <Divider />
            <CommonContactForm 
              contact = {fleetContact}
              setContact = {setFleetContact}
              validator = {simpleValidator}
              formErrors = {formErrors}
              title = "Fleet Contact"
              />
            <Divider /> 
            
            <CommonContactForm 
              contact = {serviceContact}
              setContact = {setServiceContact}
              validator = {simpleValidator}
              formErrors = {formErrors}
              title = "Service Contact"
              />
          </div>
        </DialogContentText>
      </DialogContent>
      </form>
    </>
  );
};

export default EditVehiclesDetails;
