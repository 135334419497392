import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Images from 'assets/images';
import StatusButton from 'components/statusButton/statusButton';
import DepotHealthCard from 'components/depotHealthCard/depotHealthCard';
import DepotPowerCard from 'components/depotPowerCard/depotPowerCard';
import DepotEnergyStatsCard from 'components/depotEnergyStatsCard/depotEnergyStatsCard';
import { depotGoogleMap, mapDetails } from 'common/common'
import ApiEndPoint from 'services/apiUrl.json';
import { getMethod } from 'services/api';
import { useSelector, useDispatch} from 'react-redux'
import { fetchDepotDetails }  from 'redux/actions/evseDepotList'
import CommonGoogleMap from 'components/commonGoogleMap/commonGoogleMap';
import CommonComposedChart from 'components/commonComposedChart/commonComposedChart';
import './evseDepotList.scss';
import { useStyles } from 'common/common';
import CommonRecentAlerts from 'components/commonRecentAlerts/commonRecentAlerts';

const EvseDepotList = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [depotList, setDepotList] = useState<any>({});
  const [depotAlerts, setDepotAlerts] = useState<any>({});
  const [depotSpinnerOff, setDepotSpinnerOff] = useState<boolean>(true)
  const depotListValue = depotList && depotList.length >= 1 ? depotList[0] : [];
  const centerValue = depotListValue && depotListValue.evseDepotDetails ? depotListValue.evseDepotDetails[0] : null ;
  const currentFleetId = useSelector((state: any) => { return state.currentFleetId })
  const totalRecentAlerts = 10;

  useEffect(() => {
    getDepotListData()
    getAlertData()
  }, [currentFleetId])

  const getDepotListData = async () => {
    await getMethod(ApiEndPoint.fleetDepotList, currentFleetId + "/evse-depots").then((res) => { 
      setDepotSpinnerOff(false);
      setDepotList(res?.data)
    }).catch((err) => {
      setDepotSpinnerOff(false);
      console.log("Error", err)
    })
  }

  const getAlertData = async () => {
    await getMethod(ApiEndPoint.fleetDepotList, currentFleetId + "/evse-depots/alerts").then((res) => { 
      setDepotSpinnerOff(false);
      setDepotAlerts(res?.data)
    }).catch((err) => {
      setDepotSpinnerOff(false);
      console.log("Error", err)
    })
  }

  const navigateToDepotDetails = (value: any) => {
    if (value){
       dispatch(fetchDepotDetails(value));
       history.push('/evseDepotDetail', { depotId: value.evsedepotId })
    }
  }

  const markerClicked = (value: any) => { navigateToDepotDetails(value) };

  return (
    <>
          {/*----Page Mid Section Start----*/}
          <main className={classes.content}>
          {depotSpinnerOff ?  <CircularProgress className="inner_progress" /> :
            <div className="mid_section_inside evse_list">
              <Grid container spacing={2}>
                {/*----Page Mid Left Section Start----*/}
                <Grid className="mid_section_left" item xs={12} sm={12} md={8}>
                  <div className="section_title">
                    <Grid container>
                      <Grid item xs={8}>
                        <h2>EVSE Depots</h2>
                      </Grid>
                      <Grid className="title_right" item xs={4}>
                        <h2>{depotListValue.totalDepots} depots</h2>
                      </Grid>
                    </Grid>
                  </div>
                  {/*----EVSE Depot List Section Start----*/}
                  
                  <div className="depot_list">
                    <List>

                      {/*----EVSE Depot1 Start----*/}
                      {depotListValue && depotListValue.evseDepotDetails && depotListValue.evseDepotDetails.map((value: any) => (
                        <ListItem key={value.evsedepotId}>
                          <Card className="list_card" variant="outlined" onClick={() => navigateToDepotDetails(value)} >
                            <CardContent className="card_content" >
                              <div className="depot_details">
                                <Grid container>
                                  <Grid item xs={9} sm={10} md={9} lg={9}>
                                    <div className="depot_information">
                                      {/*----EVSE Depot Map----*/}
                                      <div className="depot_location_map" >
                                  
                                        <CommonGoogleMap 
                                         center = {{
                                          lat: value.lat,
                                          lon: value.lon }}
                                          zoom={depotGoogleMap.zoom}
                                          markers = {[{
                                          lat: value.lat,
                                          lon: value.lon }]}
                                        />
      
                                      </div>

                                      {/*----EVSE Depot Information----*/}
                                      <div className="depot_info">
                                        <div className="depot_name">{value.evsedepotName}</div>
                                        <div className="depot_addr">{value.streetAddress1 ? value.streetAddress1 : ''} {value.streetAddress2 ? ', ' + value.streetAddress2 : ''}<br />{value.city + ', ' + value.state + ' ' + value.zipCode + ', ' + value.country}</div>
                                        <div className="port_details">
                                          <span className="port">
                                            <img src={Images.IC_PORT_12x14} alt="Depot Port" />
                                            {value.evseports} Total Ports</span>
                                          <span className="alert">
                                            <img src={Images.IC_alert_12x14} alt="Depot Alert" />
                                            {value.alerts} Alerts</span>
                                        </div>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid className="depot_graph text_right" item xs={3} sm={2} md={3} lg={3}>
                                    
                                    <CommonComposedChart 
                                       data={value && value.evseDepotEnergyUsage? value.evseDepotEnergyUsage:[]} 
                                    />

                                  </Grid>
                                </Grid>

                                {/*----EVSE Depot Status----*/}
                                <div className="stat_list ">
                                  <StatusButton classType="available" statusLabel="Available" statusValue={value.availablePorts} />
                                  <StatusButton classType="connected" statusLabel="Connected" statusValue={value.connectedPorts} />
                                  <StatusButton classType="charging" statusLabel="Charging" statusValue={value.chargingPorts} />
                                  <StatusButton classType="complete" statusLabel="Complete" statusValue={value.completePorts} />
                                  <StatusButton classType="error" statusLabel="Error" statusValue={value.errorPorts} />
                                  <StatusButton classType="offline" statusLabel="Offline" statusValue={value.offlinePorts} />
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                        </ListItem>
                      ))}

                    </List>
                  </div>
                  {/*----EVSE Depot List Section End----*/}

                </Grid>
                {/*----Page Mid Left Section End----*/}

                {/*----Page Mid Right Section Start----*/}
                <Grid className="mid_section_right" item xs={12} sm={12} md={4}>

                  {/*---Map Locations---*/}
                  <div className="section_title">
                    <Grid container>
                      <Grid item xs={12}>
                        <h2>Locations</h2>
                      </Grid>
                    </Grid>
                  </div>
                  <Card variant="outlined" style={{ height: '200px', width: '100%' }} >
                    <div style={{ height: '100%', width: '100%' }}>
                      {centerValue?  
                        <CommonGoogleMap {...props}
                        markerClicked={markerClicked}
                         center = {{
                            lat: centerValue.lat,
                            lon: centerValue.lon }}
                         zoom={mapDetails.depotLocationMapZoom}
                         options={mapDetails.viewSatellite}
                         markers = {depotListValue?.evseDepotDetails}
                         fullscreenControl = {true}
                         scrollwheel = {true}
                         gestureHandling = "cooperative"
                         />: ''}
                    </div>
                    {/* <img className="img_res" src={Images.MAP_IMG} alt="Map Img" /> */}
                  </Card>

                  {/*---EVSE Depot Analytics---*/}
                  <div className="analytics">
                    <div className="section_title">
                      <Grid container>
                        <Grid item xs={12}>
                          <h2>EVSE Analytics</h2>
                        </Grid>
                      </Grid>
                    </div>
                    <DepotHealthCard
                      depotLabel="Depot Health"
                      chargingPorts={depotListValue.totalChargingPorts}
                      availablePorts={depotListValue.totalAvailablePorts}
                      offlinePorts={depotListValue.totalOfflinePorts} />
                    <DepotPowerCard depotPower={depotListValue.totalEnergyUsage}/>
                    <DepotEnergyStatsCard depotEnergyStats={depotListValue.energyStats}/>
                  </div>

                <Card className="small_card" variant="outlined">
                  <CardContent className="card_content">
                    
                    <CommonRecentAlerts {...props} 
                    alertInfo={depotAlerts?.alertsList} 
                    alertCount={totalRecentAlerts}
                    />
                    
                  </CardContent>
                </Card>

                </Grid>
                {/*----Page Mid Right Section End----*/}

              </Grid>

            </div>
            }
          </main>

          {/*----Page Mid Section End----*/}
    </>
  );
}

export default EvseDepotList