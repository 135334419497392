import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ApiEndPoint from 'services/apiUrl.json';
import { getMethod, putMethod } from 'services/api';
import {defaultVariable} from 'common/common';


const SetChargeFee = (props: any) => {
  const defaultValue = 'Default Pricing';
  const evsePortLevelDetails = props.metadata && props.metadata.evsePortLevels ? props.metadata.evsePortLevels  : [];
  const chargeRateSetting = props.metadata && props.metadata.pricingSpecs ? props.metadata.pricingSpecs  : [];
  const defaultRateId = props.metadata && props.metadata.pricingSpecs ? props.metadata.pricingSpecs.filter((val: any) => val?.name === defaultValue)[0]?.id : undefined;
  const currenciesType = props.metadata && props.metadata.currencies ? props.metadata.currencies  : [];
  const unitsType = props.metadata && props.metadata.billingUnits ? props.metadata.billingUnits  : [];
  const defaultCurrencyType = currenciesType && currenciesType.find((val: any) => val.name === defaultVariable.defaultCurrency) ? currenciesType.find((val: any) => val.name === defaultVariable.defaultCurrency) : {};
  const defaultUnitType  = unitsType && unitsType.find((val: any) => val.unit === defaultVariable.defaultUnit) ? unitsType.find((val: any) => val.unit === defaultVariable.defaultUnit) : {};
  const [tabValue, settabValue] = React.useState(0);
  const [rateSetting, setRateSetting] = React.useState<any>('');
  const [defaultCurrSymbol, setdefaultCurrSymbol] = React.useState(defaultVariable.defaultCurrencySymbol);
  const [memberType, setMemberType] = React.useState<any>([]);
  const [statusCode, setStatusCode] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  
  const intialDataSet = {
    globalTaxRate: 0,
    sessionTaxRate: 0,
    occupancyTaxRate: 0,
    usageTaxRate: 0,
    sessionCurrency: defaultCurrencyType,
    sessionFreeChargingTime: 0, //set 0 sec against the 0 min default value
    occupancyBillingIncrementTime: 300, // set 300 sec against 5 min default
    roamingFee: 0,
    occupancyMaxFee: 0,
    occupancyGracePeriod: 0, //set 0 sec against the 0 min default value
    usageBillingUnit: defaultUnitType,
    memberLevelFees : [],
    usageBillingIncrementTime : 300  // set 300 sec against 5 min default
  }
  const [intialValues, setIntialValues] = React.useState<any>(intialDataSet);

  useEffect(() => {
    getAppliedPriceData()
  }, [tabValue])

  const filterMemberType= (data: any) =>{
    if(data?.memberLevelFees.length > 0){
      const uniqueMemberType = [new Set(data.memberLevelFees.map((item: any) => item.member))].sort();
      setMemberType(uniqueMemberType);
    }
  }

  const findMemberLevelFees = (member: any, costSegment: any, chargeType: any) => {
    let feeValueSet = '';
    let feeValue: any = intialValues.memberLevelFees.find((d: any) => 
      d.member === member &&                                       
      d.costSegment === costSegment
    );
    if (chargeType === 'session') {
      return feeValue && feeValue.sessionServiceFee ? feeValue.sessionServiceFee : feeValueSet; 
    }else if (chargeType === 'usage'){
      return feeValue && feeValue.usageChargingFee ? feeValue.usageChargingFee : feeValueSet; 
    }else if (chargeType === 'occupancy'){
      return feeValue && feeValue.occupancyFee ? feeValue.occupancyFee : feeValueSet; 
    }  
    return feeValueSet; 
  }

  const handleMemberLevelFees = (member: any, costSegment: any, chargeType: any,e: any) => {
    if (chargeType === 'session') {
      let feeValue: any = intialValues.memberLevelFees.map((d: any) => 
        d.member === member &&                                       
        d.costSegment === costSegment ? {...d,sessionServiceFee : e.target.value } : d
      );
      setIntialValues({ ...intialValues, ['memberLevelFees']: feeValue });
    }else if (chargeType === 'usage') {
      let feeValue: any = intialValues.memberLevelFees.map((d: any) => 
        d.member === member &&                                       
        d.costSegment === costSegment ? {...d,usageChargingFee : e.target.value } : d
      );
      setIntialValues({ ...intialValues, ['memberLevelFees']: feeValue });
    }else if (chargeType === 'occupancy') {
      let feeValue: any = intialValues.memberLevelFees.map((d: any) => 
        d.member === member &&                                       
        d.costSegment === costSegment ? {...d,occupancyFee : e.target.value } : d
      );
      setIntialValues({ ...intialValues, ['memberLevelFees']: feeValue });
    }
  }

  const handleChange = (e: any) => {
    if (e.target.name === 'usageBillingUnit'){
      let findValue = unitsType.find((val: any) => val.id === e.target.value) 
      setIntialValues({ ...intialValues, [e.target.name]: findValue ? findValue : {} });
    }else if (e.target.name === 'sessionCurrency' ){
      let findValue = currenciesType.find((val: any) => val.id === e.target.value) 
      setdefaultCurrSymbol(findValue.symbol)
      setIntialValues({ ...intialValues, [e.target.name]: findValue ? findValue : {} });
    }else{
      setIntialValues({ ...intialValues, [e.target.name]: e.target.value });
    }
       
  };

  const handleChargetab = (event: any, newValue: any) => {  
    settabValue(newValue);
  };

  const handleRateSetting = (event: any) => { 
    setRateSetting(event.target.value);
    if(event?.target?.value !== 0){
      getChargeFeeData(event.target.value);
    }

  };

  const portLevelId = evsePortLevelDetails[tabValue].id;
  const portTypeId = evsePortLevelDetails[tabValue]?.portTypes[0]?.id;
  const getChargeFeeData = async (specId: any) => {
    await getMethod(ApiEndPoint.evsePricing, '/portLevel/' + portLevelId + '/portType/' + portTypeId + '/pricing-specs/' + specId).then((res) => {
      setIntialValues(res.data)
      filterMemberType(res.data);
    }).catch((err) => {
      console.log("Error", err)
    })
  }
  
  const getAppliedPriceData = async () => {
    await getMethod(ApiEndPoint.depotInformation,props?.depotId + '/portLevel/' + portLevelId + '/portType/' + portTypeId + '/pricing' ).then((res) => {
      if(res.status === 204){
        setRateSetting(defaultRateId)
        getChargeFeeData(defaultRateId);
        setStatusCode(false);
      }else{
        setStatusCode(true);
        setRateSetting(0);
        setIntialValues(res.data);
        filterMemberType(res.data);
      }

    }).catch((err) => {
      console.log("Error", err)
    })
  }

  const checkNumericValue = (event: any) => {
    if (!/[0-9.]/.test(event.key)) {
      event.preventDefault();
    }
  }

  const checkOnlyNumeric = (event: any) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  }

const submitChargeFee = () => {
  const {evseDepotId, evseDepotLevelFeeId, roamingFee, ...remainingObject} = intialValues;
  putMethod(ApiEndPoint.depotInformation,props?.depotId + '/portLevel/' + portLevelId + '/portType/' + portTypeId + '/pricing', remainingObject ).then((res) => {
    props.scheduleClose();
    console.log("success", res)
  }).catch((err) => {
    console.log("Error", err)
  })
}

  return (
    <>
        <div className="dialog_title">
              <h4>Set Charge Fee</h4>
              <div className="button_row mt-0 full_right">                 
                    <Button className="btn_white text_btn_theme" onClick={props?.scheduleClose}>Cancel</Button>
                    {memberType.length > 0 ?
                    <Button className="btn_white blue_bg" onClick={submitChargeFee}>Apply Charge Fee</Button>  
                    :
                    <Button className="btn_white blue_bg" disabled onClick={submitChargeFee}>Apply Charge Fee</Button>  
                    }               
                </div>            
            </div>
            <DialogContent dividers={scroll === 'paper'}>
              <DialogContentText component="div"
                id="scroll-dialog-description">
                <div className="charging_fee">
<div className="push_inside">
                <Grid container >
                    <Grid item xs={12}>
                      
                      <div className="list_form">

                        <div className="list_row">
                          <div className="label w_100per">
                         
                            <span className="sab_label top_text">Fields left blank will default to zero value</span>
                          </div>
                        
                        </div>
                    

                      </div>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12}>
                     
                      <div className="list_form charge_set_tab">

                        <div className="list_row">
                          <div className="label">
                          EVSE Station
                           
                          </div>
                          <div className="result">
                            <AppBar position="static" color="default">
                                <Tabs
                                    value={tabValue}
                                    onChange={handleChargetab}
                                    variant="scrollable"
                                    scrollButtons="off"
                                    aria-label="scrollable force tabs example"
                                >
                                  {evsePortLevelDetails && evsePortLevelDetails.map((value: any,index: any)=> (<Tab key={value.id} value={index}
                                     label={value.name}
                                  />))}
                                </Tabs>
                            </AppBar>
                          </div>
                        </div>
                    

                      </div>
                    </Grid>
                </Grid>



                <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <div className="list_form charge_rate_set">
                        <div className="list_row">
                          <div className="label">
                          Charge Template                           
                          </div>
                          <div className="result">
                          <FormControl className="form_control">
                              <Select variant="outlined" 
                                labelId="charge-rate-setting-label"
                                id="charge-rate-setting-select"
                                value={rateSetting}
                                name="rateSetting"
                                onChange={handleRateSetting}
                              >
                                 {statusCode? <MenuItem className="optionlist" value={0}>
                                  Applied Price
                                </MenuItem> : ''}
                                {chargeRateSetting && chargeRateSetting.map((value: any) => (<MenuItem className="optionlist" key={value.id} value={value.id}> 
                                {value.name}
                                </MenuItem>))}                                
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    </Grid>
                </Grid>
                </div>
                <div className="separator"></div>
                <div className="push_inside">
                <Grid container spacing={5}>
               
                    <Grid item xs={5}>
                    <h5>Global Fee</h5>

                      <div className="list_form">
                        <div className="list_row">
                          <div className="label">
                          Tax rate
                            <span className="sab_label">Applied to the final bill</span>
                          </div>
                          <div className="result">
                            <FormControl className="form_control">
                              <OutlinedInput className="input_text"
                                id="outlined-global-taxrate"
                                name="globalTaxRate"
                                inputProps={{ maxLength: 5}}
                                value={intialValues.globalTaxRate}
                                onKeyPress={(event) => checkNumericValue(event)}
                                onChange={handleChange}
                                startAdornment={<InputAdornment position="start">%</InputAdornment>}
                              />
                            </FormControl>
                          </div>
                        </div>                    
                      </div>
                    </Grid>
                </Grid>
                </div>
                <div className="separator"></div>
                <div className="push_inside">
              <Grid container spacing={5}>
             
                    <Grid  item xs={5}>
                    <h5>Session Fee</h5>
                      <div className="list_form">
                        <div className="list_row">
                          <div className="label">
                          Session currency
                            <span className="sab_label">Local charging currency</span>
                          </div>
                          <div className="result">
                            <FormControl className="form_control">
                              <Select variant="outlined" 
                               labelId="charge-session-curreny-label"
                               id="charge-session-curreny-select"
                               value={intialValues.sessionCurrency.id}
                               name="sessionCurrency"
                               onChange={handleChange}                              
                              >
                              {currenciesType && currenciesType.map((value: any) => (<MenuItem className="optionlist" key={value.id} value={value.id}> 
                                {value.name}
                                </MenuItem>))}    
                              </Select>
                            </FormControl>
                          </div>
                        </div>

                        <div className="list_row">
                          <div className="label">
                          Roaming fee
                            <span className="sab_label">Interoperable network fee</span>
                          </div>
                          <div className="result text_label">
                          <span>{defaultCurrSymbol}</span> {intialValues.roamingFee}
                          </div>
                        </div>

                        <div className="list_row">
                          <div className="label">
                          Free charging
                            <span className="sab_label">Free promotional incentive</span>
                          </div>
                          <div className="result">
                          <FormControl className="form_control">
                              <Select variant="outlined" 
                                labelId="charge-free-charging-label"
                                id="charge-free-charging-select"
                                value={intialValues.sessionFreeChargingTime}
                                name="sessionFreeChargingTime"
                                onChange={handleChange}                                 
                              >
                               {defaultVariable.freeChargingDetails && defaultVariable.freeChargingDetails.map((value: any) => (<MenuItem className="optionlist" key={value.id} value={value.value}> 
                                {value.name}
                                </MenuItem>))}                                
                              </Select>
                            </FormControl>
                          </div>
                        </div>

                  

                        <div className="list_row">
                          <div className="label">
                          Session tax rate
                            <span className="sab_label">Tax only applied to session fee</span>
                          </div>
                          <div className="result">
                            <FormControl className="form_control">
                              <OutlinedInput className="input_text"
                                id="outlined-session-taxrate"
                                name="sessionTaxRate"
                                value={intialValues.sessionTaxRate}
                                inputProps={{ maxLength: 5}}
                                onKeyPress={(event) => checkNumericValue(event)}
                                onChange={handleChange}
                                startAdornment={<InputAdornment position="start">%</InputAdornment>}
                              />
                            </FormControl>
                          </div>
                        </div>                    
                      </div>
                    </Grid>


                    <Grid className="member_fee" item xs={7}>                     
                      <div className="list_form">
                        <div className="list_row">
                          <div className="label">
                          Service fee
                            <span className="sab_label">Fee to start each charge session</span>
                          </div>
                         
                        </div>

                        <div className="billing_detail_table">
                        {memberType && memberType.length > 0 ? 
                          <div className="list_row">
                            <div className="label"></div>
                            <div className="result">
                              <Grid container>
                                <Grid>
                                  <div className="table_title">Standard</div>
                                </Grid>
                                <Grid >
                                  <div className="table_title">Off-Peak</div>
                                </Grid>
                                <Grid >
                                  <div className="table_title">Peak</div>
                                </Grid>
                                <Grid>
                                  <div className="table_title">Super-Peak</div>
                                </Grid>
                              </Grid>

                            </div>
                        </div> : <div></div>}


                          {memberType && memberType.map((memberVal: any) => (
                          <div className="list_row">
                            <div className="label">{memberVal}</div>
                            <div className="result">
                              <Grid container>
                                <Grid>
                                  <FormControl className="form_control">
                                    <OutlinedInput 
                                    className="input_text disable" 
                                    id="outlined-guest-standard"
                                    name="memberLevelFees"
                                    value={findMemberLevelFees(memberVal,'Standard', 'session')}
                                    placeholder = '0.00'
                                    inputProps={{ maxLength: 5}}
                                    onKeyPress={(event) => checkNumericValue(event)}
                                    onChange={ (e) => handleMemberLevelFees(memberVal,'Standard', 'session', e)  }
                                    startAdornment={<InputAdornment position="start">{defaultCurrSymbol}</InputAdornment>} />
                                  </FormControl>
                                </Grid>
                                <Grid >
                                  <FormControl className="form_control">
                                    <OutlinedInput className="input_text disable" 
                                    id="outlined-guest-offpeak"
                                    name="memberLevelFees"
                                    value={findMemberLevelFees(memberVal,'Off-Peak', 'session')} 
                                    placeholder = '0.00'
                                    inputProps={{ maxLength: 5}}
                                    onKeyPress={(event) => checkNumericValue(event)}
                                    onChange={ (e) => handleMemberLevelFees(memberVal,'Off-Peak', 'session', e)  }
                                    startAdornment={<InputAdornment position="start">{defaultCurrSymbol}</InputAdornment>} />
                                  </FormControl>
                                </Grid>
                                <Grid >
                                  <FormControl className="form_control">
                                    <OutlinedInput className="input_text disable" 
                                      id="outlined-guest-peak"
                                      name="memberLevelFees"
                                      value={findMemberLevelFees(memberVal,'Peak', 'session')} 
                                      placeholder = '0.00'
                                      inputProps={{ maxLength: 5}}
                                      onKeyPress={(event) => checkNumericValue(event)}
                                      onChange={ (e) => handleMemberLevelFees(memberVal,'Peak', 'session', e)  }
                                      startAdornment={<InputAdornment position="start">{defaultCurrSymbol}</InputAdornment>} />
                                  </FormControl>
                                </Grid>
                                <Grid>
                                  <FormControl className="form_control">
                                    <OutlinedInput className="input_text disable" 
                                      id="outlined-guest-superpeak"
                                      name="memberLevelFees"
                                      value={findMemberLevelFees(memberVal,'Super-Peak', 'session')} 
                                      placeholder = '0.00'
                                      inputProps={{ maxLength: 5}}
                                      onKeyPress={(event) => checkNumericValue(event)}
                                      onChange={ (e) => handleMemberLevelFees(memberVal,'Super-Peak', 'session', e)  }
                                      startAdornment={<InputAdornment position="start">{defaultCurrSymbol}</InputAdornment>} />
                                  </FormControl>
                                </Grid>
                              </Grid>

                            </div>
                          </div>
                          ))}
                        </div>


                      </div>
                    </Grid>


                



                  </Grid>
                  </div>
                  <div className="separator"></div>
                  <div className="push_inside">
                  <Grid container spacing={5}>
                  
                    <Grid  item xs={5}>
                    <h5>Usage Rate</h5>
                      <div className="list_form">
                        <div className="list_row">
                          <div className="label">
                          Billing unit
                            <span className="sab_label">Minutes to bill </span>
                          </div>
                          <div className="result">
                            <FormControl className="form_control">
                              <Select variant="outlined" 
                                labelId="charge-billing-unit-label"
                                id="charge-billing-unit-select"
                                value={intialValues.usageBillingUnit.id}
                                name="usageBillingUnit"
                                onChange={handleChange}                                   
                              >
                               {unitsType && unitsType.map((value: any) => (<MenuItem className="optionlist" key={value.id} value={value.id}> 
                                {value.unit}
                                </MenuItem>))}    
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        { intialValues?.usageBillingUnit?.unit === 'Time' ?
                        <div className="list_row">
                          <div className="label">
                          Billing increment
                            <span className="sab_label">Minutes to bill Usage</span>
                          </div>
                          <div className="result">
                            <FormControl className="form_control">
                              <Select variant="outlined"
                                labelId="usage-billing-increment-label"
                                id="usage-billing-increment-select"
                                value={intialValues.usageBillingIncrementTime}
                                name="usageBillingIncrementTime"
                                onChange={handleChange}
                              >
                                {defaultVariable.usageBillingIncrementDetails && defaultVariable.usageBillingIncrementDetails.map((value: any) => (<MenuItem className="optionlist" key={value.id} value={value.value}> 
                                {value.name}
                                </MenuItem>))}  
                              </Select>
                            </FormControl>
                          </div>
                        </div>: <div></div>
                        }
                        <div className="list_row">
                          <div className="label">
                          Tax rate
                            <span className="sab_label">Tax only applied to usage rate</span>
                          </div>
                          <div className="result">
                          <FormControl className="form_control">

                            <OutlinedInput className="input_text"
                                id="outlined-usage-taxrate"
                                name="usageTaxRate"
                                value={intialValues.usageTaxRate}
                                inputProps={{ maxLength: 5}}
                                onKeyPress={(event) => checkNumericValue(event)}
                                onChange={handleChange}
                                startAdornment={<InputAdornment position="start">%</InputAdornment>}
                            />
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    </Grid>


                    <Grid className="member_fee" item xs={7}>
                     
                      <div className="list_form">

                        <div className="list_row">
                          <div className="label">
                          User charging rates
                            <span className="sab_label">Rate to charge users based on their consumption</span>
                          </div>
                         
                        </div>

                        <div className="billing_detail_table">
                        {memberType && memberType.length > 0 ? 
                          <div className="list_row">
                            <div className="label"></div>
                            <div className="result">
                              <Grid container>
                                <Grid>
                                  <div className="table_title">Standard</div>
                                </Grid>
                                <Grid >
                                  <div className="table_title">Off-Peak</div>
                                </Grid>
                                <Grid >
                                  <div className="table_title">Peak</div>
                                </Grid>
                                <Grid>
                                  <div className="table_title">Super-Peak</div>
                                </Grid>
                              </Grid>

                            </div>
                          </div> : <div></div>}

                          {memberType && memberType.map((memberVal: any) => (
                          <div className="list_row">
                            <div className="label">{memberVal}</div>
                            <div className="result">
                              <Grid container>
                                <Grid>
                                  <FormControl className="form_control">
                                    <OutlinedInput 
                                    className="input_text disable" 
                                    id="outlined-userguest-standard"
                                    name="memberLevelFees"
                                    value={findMemberLevelFees(memberVal,'Standard', 'usage')}
                                    placeholder = '0.00'
                                    inputProps={{ maxLength: 5}}
                                    onKeyPress={(event) => checkNumericValue(event)}
                                    onChange={ (e) => handleMemberLevelFees(memberVal,'Standard', 'usage', e)  }
                                    startAdornment={<InputAdornment position="start">{defaultCurrSymbol}</InputAdornment>} />
                                  </FormControl>
                                </Grid>
                                <Grid >
                                  <FormControl className="form_control">
                                    <OutlinedInput className="input_text disable" 
                                    id="outlined-userguest-offpeak"
                                    name="memberLevelFees"
                                    value={findMemberLevelFees(memberVal,'Off-Peak', 'usage')} 
                                    placeholder = '0.00'
                                    inputProps={{ maxLength: 5}}
                                    onKeyPress={(event) => checkNumericValue(event)}
                                    onChange={ (e) => handleMemberLevelFees(memberVal,'Off-Peak', 'usage', e)  }
                                    startAdornment={<InputAdornment position="start">{defaultCurrSymbol}</InputAdornment>} />
                                  </FormControl>
                                </Grid>
                                <Grid >
                                  <FormControl className="form_control">
                                    <OutlinedInput className="input_text disable" 
                                      id="outlined-userguest-peak"
                                      name="memberLevelFees"
                                      value={findMemberLevelFees(memberVal,'Peak', 'usage')} 
                                      placeholder = '0.00'
                                      inputProps={{ maxLength: 5}}
                                      onKeyPress={(event) => checkNumericValue(event)}
                                      onChange={ (e) => handleMemberLevelFees(memberVal,'Peak', 'usage', e)  }
                                      startAdornment={<InputAdornment position="start">{defaultCurrSymbol}</InputAdornment>} />
                                  </FormControl>
                                </Grid>
                                <Grid>
                                  <FormControl className="form_control">
                                    <OutlinedInput className="input_text disable" 
                                      id="outlined-userguest-superpeak"
                                      name="memberLevelFees"
                                      value={findMemberLevelFees(memberVal,'Super-Peak', 'usage')} 
                                      placeholder = '0.00'
                                      inputProps={{ maxLength: 5}}
                                      onKeyPress={(event) => checkNumericValue(event)}
                                      onChange={ (e) => handleMemberLevelFees(memberVal,'Super-Peak', 'usage', e)  }
                                      startAdornment={<InputAdornment position="start">{defaultCurrSymbol}</InputAdornment>} />
                                  </FormControl>
                                </Grid>
                              </Grid>

                            </div>
                          </div>
                          ))}

                        </div>


                      </div>
                    </Grid>


                



                  </Grid>
                  </div>
                  <div className="separator"></div>
                  <div className="push_inside">
                  <Grid container spacing={5}>
                  
                    <Grid item xs={5}>
                    <h5>Occupancy Rate</h5>
                      <div className="list_form">
                        <div className="list_row">
                          <div className="label">
                          Billing increment
                            <span className="sab_label">Minutes to bill occupancy</span>
                          </div>
                          <div className="result">
                            <FormControl className="form_control">
                              <Select variant="outlined"
                                labelId="charge-billing-increment-label"
                                id="charge-billing-increment-select"
                                value={intialValues.occupancyBillingIncrementTime}
                                name="occupancyBillingIncrementTime"
                                onChange={handleChange}
                              >
                                {defaultVariable.billingIncrementDetails && defaultVariable.billingIncrementDetails.map((value: any) => (<MenuItem className="optionlist" key={value.id} value={value.value}> 
                                {value.name}
                                </MenuItem>))}  
                              </Select>
                            </FormControl>
                          </div>
                        </div>

                        <div className="list_row">
                          <div className="label">
                          Occupancy max fee 
                            <span className="sab_label">Maximum amount charged</span>
                          </div>
                          <div className="result dis_text_value">
                          <FormControl className="form_control">

                            <OutlinedInput className="input_text disable"
                              id="outlined-occupancy-maxfee"
                              name="occupancyMaxFee"
                              value={intialValues.occupancyMaxFee}
                              inputProps={{ maxLength: 5}}
                              onChange={handleChange}
                              onKeyPress={(event) => checkNumericValue(event)}
                              startAdornment={<InputAdornment position="start">{defaultCurrSymbol}</InputAdornment>}

                            />
                            </FormControl>
                          </div>
                        </div>

                        <div className="list_row">
                          <div className="label">
                          Grace period 
                            <span className="sab_label">Length of time without fee penalty</span>
                          </div>
                          <div className="result">
                          <FormControl className="form_control">

                          <Select variant="outlined"
                                labelId="outlined-occupancy-graceperiod"
                                id="occupancy-graceperiod-increment-select"
                                value={intialValues.occupancyGracePeriod}
                                name="occupancyGracePeriod"
                                onChange={handleChange}
                              >
                                {defaultVariable.gracePeriodDetails && defaultVariable.gracePeriodDetails.map((value: any) => (<MenuItem className="optionlist" key={value.id} value={value.value}> 
                                {value.name}
                                </MenuItem>))}  
                              </Select>
                            </FormControl>
                          </div>
                        </div>

                        <div className="list_row">
                          <div className="label">
                          Tax rate
                            <span className="sab_label">Tax only applied to occupancy rate</span>
                          </div>
                          <div className="result">
                          <FormControl className="form_control">
                            <OutlinedInput className="input_text"
                                id="outlined-occupancy-taxrate"
                                name="occupancyTaxRate"
                                value={intialValues.occupancyTaxRate}
                                inputProps={{ maxLength: 5}}
                                onKeyPress={(event) => checkNumericValue(event)}
                                onChange={handleChange}
                                startAdornment={<InputAdornment position="start">%</InputAdornment>}
                            />
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    </Grid>


                    <Grid className="member_fee" item xs={7}>
                     
                      <div className="list_form">

                        <div className="list_row">
                          <div className="label">
                          Occupancy rate
                            <span className="sab_label">Rate per billing unit</span>
                          </div>
                         
                        </div>

                        <div className="billing_detail_table">
                          {memberType && memberType.length > 0 ? 
                          <div className="list_row">
                            <div className="label"></div>
                            <div className="result">
                              <Grid container>
                                <Grid>
                                  <div className="table_title">Standard</div>
                                </Grid>
                                <Grid >
                                  <div className="table_title">Off-Peak</div>
                                </Grid>
                                <Grid >
                                  <div className="table_title">Peak</div>
                                </Grid>
                                <Grid>
                                  <div className="table_title">Super-Peak</div>
                                </Grid>
                              </Grid>

                            </div>
                          </div> : <div></div>}

                          {memberType && memberType.map((memberVal: any) => (
                          <div className="list_row">
                            <div className="label">{memberVal}</div>
                            <div className="result">
                              <Grid container>
                                <Grid>
                                  <FormControl className="form_control">
                                    <OutlinedInput 
                                    className="input_text disable" 
                                    id="outlined-occupancyguest-standard"
                                    name="memberLevelFees"
                                    value={findMemberLevelFees(memberVal,'Standard','occupancy')}
                                    placeholder = '0.00'
                                    inputProps={{ maxLength: 5}}
                                    onKeyPress={(event) => checkNumericValue(event)}
                                    onChange={ (e) => handleMemberLevelFees(memberVal,'Standard', 'occupancy', e)  }
                                    startAdornment={<InputAdornment position="start">{defaultCurrSymbol}</InputAdornment>} />
                                  </FormControl>
                                </Grid>
                                <Grid >
                                  <FormControl className="form_control">
                                    <OutlinedInput className="input_text disable" 
                                    id="outlined-occupancyguest-offpeak"
                                    name="memberLevelFees"
                                    value={findMemberLevelFees(memberVal,'Off-Peak','occupancy')} 
                                    placeholder = '0.00'
                                    inputProps={{ maxLength: 5}}
                                    onKeyPress={(event) => checkNumericValue(event)}
                                    onChange={ (e) => handleMemberLevelFees(memberVal,'Off-Peak', 'occupancy', e)  }
                                    startAdornment={<InputAdornment position="start">{defaultCurrSymbol}</InputAdornment>} />
                                  </FormControl>
                                </Grid>
                                <Grid >
                                  <FormControl className="form_control">
                                    <OutlinedInput className="input_text disable" 
                                      id="outlined-occupancyguest-peak"
                                      name="memberLevelFees"
                                      value={findMemberLevelFees(memberVal,'Peak','occupancy')} 
                                      placeholder = '0.00'
                                      inputProps={{ maxLength: 5}}
                                      onKeyPress={(event) => checkNumericValue(event)}
                                      onChange={ (e) => handleMemberLevelFees(memberVal,'Peak', 'occupancy', e)  }
                                      startAdornment={<InputAdornment position="start">{defaultCurrSymbol}</InputAdornment>} />
                                  </FormControl>
                                </Grid>
                                <Grid>
                                  <FormControl className="form_control">
                                    <OutlinedInput className="input_text disable" 
                                      id="outlined-occupancyguest-superpeak"
                                      name="memberLevelFees"
                                      value={findMemberLevelFees(memberVal,'Super-Peak','occupancy')} 
                                      placeholder = '0.00'
                                      inputProps={{ maxLength: 5}}
                                      onKeyPress={(event) => checkNumericValue(event)}
                                      onChange={ (e) => handleMemberLevelFees(memberVal,'Super-Peak', 'occupancy', e)  }
                                      startAdornment={<InputAdornment position="start">{defaultCurrSymbol}</InputAdornment>} />
                                  </FormControl>
                                </Grid>
                              </Grid>

                            </div>
                          </div>
                          ))}
                        </div>
                      </div>
                    </Grid>

                  </Grid>
                  </div>
                </div>

              </DialogContentText>
            </DialogContent>
    </>
  )
}

export default SetChargeFee;
