import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import FleetCard from 'components/fleetCard/fleetCard';
import FleetConfigureSetting from 'components/fleetConfigureSetting/fleetConfigureSetting';
import './fleetDashboard.scss';
import ApiEndPoint from 'services/apiUrl.json';
import { getMethod } from 'services/api'
import { useDispatch} from 'react-redux'
import { fetchFleetDataAction, fetchFleetAction }  from 'redux/actions/fleetDashboard'
const FleetDashboard = () => {
    const dispatch = useDispatch();
    const [fleetData, setFleetData] = useState<any>([]);
    const [spinnerOff, setSpinnerOff] = useState<boolean>(true)
    const companyId = 1;
    
    const getFleetData = async () => {
        await getMethod(ApiEndPoint.fleetList , companyId + "/fleets").then((res) => {
            setSpinnerOff(false);
            setFleetData(res.data)
            dispatch(fetchFleetDataAction(res?.data));
        }).catch((err) => {
            setSpinnerOff(false);
            console.log("Error", err)            
        })
    }
    useEffect(() => {
        dispatch(fetchFleetAction(0));
        getFleetData()
    }, [])


    return (
        <div>
            <Container className="main_container" maxWidth="xl">
                <div className="fleet_dashboard">
                    <Grid container spacing={2}>
                        <Grid  item xs={12} sm={12} md={9}>
                            <Typography variant="h5" className="dashboard_heading">Fleets</Typography>
                            {spinnerOff ? <CircularProgress /> :
                            <div className="fleet_card_list">
                                <Grid data-testid='fleetDataTest' container>                                   
                                    {fleetData && fleetData.map((value: any) => (<FleetCard
                                        key={value.fleetId}
                                        fleetId={value.fleetId}
                                        fleetName={value.fleetName}
                                        totalPorts={value.fleetPorts}
                                        totalVeciles={value.fleetVehicles}
                                        totalDrivers={value.fleetDrivers}
                                        redirectLink='/evseDepotList'
                                    />))} 
                                </Grid>
                            </div>}
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <Typography variant="h5" className="dashboard_heading">Configure</Typography>
                            <div className="fleet_edit_list">
                                <FleetConfigureSetting />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </div>
    )
}

export default FleetDashboard;
