import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'
import ApiEndPoint from '../../../../services/apiUrl.json';
import { getMethod } from '../../../../services/api';
import { pageDetails } from '../../../../common/common';
import moment from 'moment';
import { useSelector } from 'react-redux'
import { DataGrid } from '@material-ui/data-grid';
import { dateFormats } from '../../../../common/commonDateFormats'
import CommonChargeSessionDetails from '../../../../components/commonChargeSessionDetails/commonChargeSessionDetails'
import CommonPagination from '../../../../components/commonPagination/commonPagination';
import CommonDownloadCsv from '../../../../components/commonDownloadCsv/commonDownloadCsv';
import { styleDetails } from '../../../../common/common';

const VehicleGroupChargeHistory = (props:any) => {
    const currentVehicleId = useSelector((state:any) => { return state.currentVehicleId })
    const pageSize = pageDetails.pageSize;
    const [vehicleChargeHistory, setVehicleChargeHistory] = useState<any>({});
    const [currentPage, setCurrentPage] = useState(0);
    const [pageData, setPageData] = useState('');
    const [vehicleChargeSessionInfo, setVehicleChargeSessionInfo] = useState<any>({});
    const [vehicleGroupDownloadCsv, setVehicleGroupDownloadCsv] = useState();
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');

    const fileHeader =[
        {label: 'VEHICLE ID',key: 'vehicleID'},
        {label: 'LICENSE',key: 'license'},
        {label: 'DRIVER NAME',key: 'driverName'},
        {label: 'PORT',key: 'portInformation'},
        {label: 'START CHARGE',key: 'startChargeTime'},
        {label: 'END CHARGE',key: 'endChargeTime'},
        {label: 'TIME',key: 'time'},
        {label: 'ENERGY (kWh)',key: 'energyKWh'}
      ];
     
    const fileName= 'VehicleGroupChargeHistory.csv'
    
    const vehicleHistorycolumns = [

        {
            field: "e",
            headerName: "e",
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues:any) => {
                return (
                    <div className="empty">
                        {cellValues.value}
                    </div>
                );
            }
        },

        {
            field: 'vehicleID',
            headerName: 'VEHICLE ID',
            sortable: false,
            width: 130,
            className: "port",
            disableColumnMenu: true,
            renderCell: (cellValues:any) => {
                return (
                    <>
                        {cellValues.value ? cellValues.value : '-'}
                    </>
                );
            }
        },

        {
            field: 'license',
            headerName: 'LICENSE',
            sortable: false,
            width: 120,
            disableColumnMenu: true,
            renderCell: (cellValues:any) => {
                return (
                    <>
                        {cellValues.value ? cellValues.value : '-'}
                    </>
                );
            }
        },

        {
            field: 'driverName',
            headerName: 'DRIVER',
            sortable: false,
            flex: 0.1,
            minWidth: 140,
            disableColumnMenu: true,
            renderCell: (cellValues:any) => {
                return (
                    <>
                        {cellValues.value ? cellValues.value : '-'}
                    </>
                );
            }
        },

        {
            field: 'portInformation',
            headerName: 'PORT',
            sortable: false,
            width: 100,
            disableColumnMenu: true,
            renderCell: (cellValues:any) => {
                return (
                    <>
                        {cellValues.value ? cellValues.value : '-'}
                    </>
                );
            }
        },

        {
            field: "startChargeTime",
            headerName: "START",
            sortable: false,
            hasFocus: false,
            minWidth: 160,
            flex: 0.1,
            disableColumnMenu: true,
            
        },

        {
            field: 'endChargeTime',
            headerName: 'END',
            sortable: false,
            flex: 0.1,
            minWidth: 160,
            disableColumnMenu: true,
            
        },

        {
            field: 'time',
            headerName: 'TIME',
            sortable: false,
            width: 80,
            disableColumnMenu: true,
            renderCell: (cellValues:any) => {
                return (
                    <>
                        {cellValues.value ? cellValues.value : '-'}
                    </>
                );
            }
        },

        {
            field: 'energyKWh',
            headerName: 'ENERGY (kWh)',
            sortable: false,
            width: 105,
            disableColumnMenu: true,
            renderCell: (cellValues:any) => {
                return (
                    <>
                        {cellValues.value ? cellValues.value : '-'}
                    </>
                );
            }
        },

        {
            field: "ee",
            headerName: "ee",
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues:any) => {
                return (
                    <div className="empty">
                        {cellValues.value}
                    </div>
                );
            }
        },

    ];

    useEffect(() => {
        getVehicleChargeHistory()
    }, [currentPage])
    useEffect(() => {
        if(vehicleChargeHistory?.totalchargeHistory >0){
        getVehicleGroupDownloadCsv();
    }
      }, [vehicleChargeHistory?.totalchargeHistory]);
    useEffect(() => {
        handlePageData()
    }, [vehicleChargeHistory])

    const getVehicleChargeHistory = async () => {
        const page = currentPage;
        const size = pageSize;
        await getMethod(ApiEndPoint.vehicleGroups, currentVehicleId + "/charge-history" + "?page=" + page + "&size=" + size).then((res) => {
            setVehicleChargeHistory(res.data)
        }).catch((err) => {
            console.log("Error", err)
        })
    }

    const getVehicleGroupDownloadCsv = async () => {
        await getMethod(ApiEndPoint.vehicleGroups, currentVehicleId + "/charge-history" + "?page=" + 0 + "&size=" + vehicleChargeHistory.totalchargeHistory).then((res) => {
            setVehicleGroupDownloadCsv(res.data);
          }).catch((err) => {
            console.log('Error', err);
          });
      };

    const vechicleHistoryDataSet = (value:any) => {
        let historyTableData = []
        if (value && value.chargeHistoryList) {
            historyTableData = value.chargeHistoryList.map((val:any) => ({ ...val, e: 'a', ee: 'aa', id: val.historyId,startChargeTime: moment(val.startChargeTime).format(dateFormats.dateTimeFormat), endChargeTime: moment(val.endChargeTime).format(dateFormats.dateTimeFormat), }))
        }
        return historyTableData
    }

    const handlePageData = () => {
        let totalPage = vehicleChargeHistory && vehicleChargeHistory.totalchargeHistory ? vehicleChargeHistory.totalchargeHistory : 0;
        if (totalPage > 0) {
            let setPageValue = (currentPage >= 1 ? (pageSize * currentPage) + 1 : 1) + ' - ' + ((pageSize * (currentPage + 1)) < totalPage ? (pageSize * (currentPage + 1)) : totalPage) + ' of '
            setPageData(setPageValue)
        }
    }

    const handleClickOpen = (scrollType:any, chargeData:any) => () => {
        if(chargeData && chargeData.chargeHistoryList && chargeData?.chargeHistoryList?.length >= 1){
          setOpen(true);
        }
        setScroll(scrollType);
      };

    const handleClose = () => {
        setOpen(false);
      };

    const onVehicleChargeHistoryClick = (e:any) =>{
        if(e && e.row){
            setVehicleChargeSessionInfo(e.row);
        }     
    }

    const handleCurrentPage = (setPage:any) => {
        setCurrentPage(setPage)    
    }

    return (
        <>
            <div className="table_scroll">
                <div className="defult_table" style={styleDetails.styleWidth} onClick={handleClickOpen('paper',vehicleChargeHistory )}>
                    <DataGrid autoHeight className="table"
                        rowHeight={59}
                        headerHeight={30}
                        rows={vechicleHistoryDataSet(vehicleChargeHistory)}
                        columns={vehicleHistorycolumns}
                        pageSize={pageSize}
                        onRowClick={(e) => onVehicleChargeHistoryClick(e)}
                    />
                </div>
                {open &&
                <CommonChargeSessionDetails {...props}
                    open={open}
                    setHandleClose ={handleClose}
                    historyId={vehicleChargeSessionInfo.historyId}
                    sessionInfo={vehicleChargeSessionInfo}
                />}
                
                <div className="table_footer">
                    <Grid container direction="row" alignItems="center" spacing={0}>
                        <Grid className="text_left" item xs={8}>          
                            {vehicleChargeHistory && vehicleChargeHistory.totalchargeHistory > pageSize ?
                                <CommonPagination
                                    currentPage={currentPage}
                                    totalCount={vehicleChargeHistory?.totalchargeHistory}
                                    callBackCurrentPage={handleCurrentPage}
                                /> : <></>}
                                <CommonDownloadCsv {...props}
                                datas={vechicleHistoryDataSet(vehicleGroupDownloadCsv)}
                                headers={fileHeader}
                                filename={fileName}
                                callBackDownloadCsv={getVehicleGroupDownloadCsv}/>                        </Grid>
                        <Grid className="text_right" item xs={4}>
                            <div className="list_number">{pageData} {vehicleChargeHistory.totalchargeHistory ? (vehicleChargeHistory.totalchargeHistory + ' Charge Session') : ''}</div>
                        </Grid>
                    </Grid>
                </div>
            </div>

            
        </>
    );
}
export default VehicleGroupChargeHistory;