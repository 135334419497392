import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ApiEndPoint from 'services/apiUrl.json';
import { getMethod, putMethod } from 'services/api';
import moment from 'moment';
import Images from 'assets/images';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import EvseSchedulePriority from './evseSchedulePriority'
import { dateFormats } from 'common/commonDateFormats'

const DragAndDropCalendar = withDragAndDrop(Calendar as any)

const EvseSchedule = (props: any) => {
    const localizer = momentLocalizer(moment);
    const timFormat =dateFormats.timFormat;
    const [finalEventData, setFinalEventData] = useState([]);
    const [calenderData, setCalenderData] = useState<any>({});
    const [openSchedule, setScheduleOpen] = useState(false);
    const [scheduleScroll, setScheduleScroll] = useState('paper');
    const calendetFinalData = calenderData && calenderData.daySchedules ? calenderData.daySchedules : [];
    const formats = {
        dayFormat: "dddd",
        timeGutterFormat: "h A",
        eventTimeRangeFormat: (range: any) =>
            `${moment(range.start).format('h:mm A')} – ${moment(range.end).format('h:mm A')}`,
    };

    useEffect(() => {
        getCalenderData()
    }, [])

    useEffect(() => {
        startOfWeek(moment)
    }, [calenderData])

    const getCalenderData = async () => {
        await getMethod(ApiEndPoint.depotInformation, props?.depotId + "/schedule").then((res) => {
            setCalenderData(res.data)

        }).catch((err) => {
            console.log("Error", err)
        })
    }

    const calScheduleClickOpen = (scrollType: any) => () => {
        setScheduleOpen(true);
        setScheduleScroll(scrollType);
    };
    const calScheduleClose = () => {
        setScheduleOpen(false);
    };

    const eventStyleGetter = (event: any, start: any, end: any, isSelected: any) => {
        var style = {}
        switch (event.costSegment) {
            case 'Peak':
                return { style: { backgroundColor: 'rgba(255, 75, 156, 0.2)', color: '#666666', fontSize: '10px', fontWeight: '500', borderRadius: '5px', border: '1px solid #FF489C' } };
            case 'Off-Peak':
                return { style: { backgroundColor: 'rgba(0, 185, 255, 0.2)', color: '#666666', fontSize: '10px', fontWeight: '500', borderRadius: '5px', border: '1px solid #00B9FF' } };
            case 'Super-Peak':
                return { style: { backgroundColor: 'rgb(195, 101, 153, 0.2)', color: '#666666', fontSize: '10px', fontWeight: '500', borderRadius: '5px', border: '1px solid #C365FD' } };
            case 'Standard':
                return { style: { backgroundColor: 'rgb(175, 134, 34, 0.2)', color: '#666666', fontSize: '10px', fontWeight: '500', borderRadius: '5px', border: '1px solid #af8625' } };
            default:
                return { style: style };
        }
    }

    const startOfWeek = (moment: any) => {
        const eventData: any = []
        let currentDate = moment()
        let weekStart = currentDate.clone().startOf('week')
        let monday = weekStart.format('YYYY-MM-DD');
        let tuesday = moment(weekStart).add(1, 'd').format('YYYY-MM-DD');
        let wednesday = moment(weekStart).add(2, 'd').format('YYYY-MM-DD');
        let thursday = moment(weekStart).add(3, 'd').format('YYYY-MM-DD');
        let friday = moment(weekStart).add(4, 'd').format('YYYY-MM-DD');
        let saturday = moment(weekStart).add(5, 'd').format('YYYY-MM-DD');
        let sunday = moment(weekStart).add(6, 'd').format('YYYY-MM-DD');
        calendetFinalData.map((val: any) => {
            if (val.day === 'MONDAY') {
                val && val.schedule && val.schedule.map((schedueldata: any) => {
                    schedueldata?.costSegment?.toLowerCase() !== 'standard' && eventData.push({
                        title: '',
                        start: new Date(moment(monday + ' ' + schedueldata.startTime)),
                        end: new Date(moment(monday + ' ' + (schedueldata.endTime === "00:00" ? "23:59" : schedueldata.endTime))),
                        costSegment: schedueldata.costSegment,
                        costSegmentId: schedueldata.costSegmentId,
                        startDay: val.day,

                    })
                })
            } else if (val.day === 'TUESDAY') {
                val && val.schedule && val.schedule.map((schedueldata: any) => {
                    schedueldata?.costSegment?.toLowerCase() !== 'standard' && eventData.push({
                        title: '',
                        start: new Date(moment(tuesday + ' ' + schedueldata.startTime)),
                        end: new Date(moment(tuesday + ' ' + (schedueldata.endTime === "00:00" ? "23:59" : schedueldata.endTime))),
                        costSegment: schedueldata.costSegment,
                        costSegmentId: schedueldata.costSegmentId,
                        startDay: val.day,
                    })
                })
            }
            else if (val.day === 'WEDNESDAY') {
                val && val.schedule && val.schedule.map((schedueldata: any) => {
                    schedueldata?.costSegment?.toLowerCase() !== 'standard' && eventData.push({
                        title: '',
                        start: new Date(moment(wednesday + ' ' + schedueldata.startTime)),
                        end: new Date(moment(wednesday + ' ' + (schedueldata.endTime === "00:00" ? "23:59" : schedueldata.endTime))),
                        costSegment: schedueldata.costSegment,
                        costSegmentId: schedueldata.costSegmentId,
                        startDay: val.day,
                    })
                })
            }
            else if (val.day === 'THURSDAY') {
                val && val.schedule && val.schedule.map((schedueldata: any) => {
                    schedueldata?.costSegment?.toLowerCase() !== 'standard' && eventData.push({
                        title: '',
                        start: new Date(moment(thursday + ' ' + schedueldata.startTime)),
                        end: new Date(moment(thursday + ' ' + (schedueldata.endTime === "00:00" ? "23:59" : schedueldata.endTime))),
                        costSegment: schedueldata.costSegment,
                        costSegmentId: schedueldata.costSegmentId,
                        startDay: val.day,
                    })
                })
            }
            else if (val.day === 'FRIDAY') {
                val && val.schedule && val.schedule.map((schedueldata: any) => {
                    schedueldata?.costSegment?.toLowerCase() !== 'standard' && eventData.push({
                        title: '',
                        start: new Date(moment(friday + ' ' + schedueldata.startTime)),
                        end: new Date(moment(friday + ' ' + (schedueldata.endTime === "00:00" ? "23:59" : schedueldata.endTime))),
                        costSegment: schedueldata.costSegment,
                        costSegmentId: schedueldata.costSegmentId,
                        startDay: val.day,
                    })
                })
            }
            else if (val.day === 'SATURDAY') {
                val && val.schedule && val.schedule.map((schedueldata: any) => {
                    schedueldata?.costSegment?.toLowerCase() !== 'standard' && eventData.push({
                        title: '',
                        start: new Date(moment(saturday + ' ' + schedueldata.startTime)),
                        end: new Date(moment(saturday + ' ' + (schedueldata.endTime === "00:00" ? "23:59" : schedueldata.endTime))),
                        costSegment: schedueldata.costSegment,
                        costSegmentId: schedueldata.costSegmentId,
                        startDay: val.day,
                    })
                })
            }
            else if (val.day === 'SUNDAY') {
                val && val.schedule && val.schedule.map((schedueldata: any) => {
                    schedueldata?.costSegment?.toLowerCase() !== 'standard' && eventData.push({
                        title: '',
                        start: new Date(moment(sunday + ' ' + schedueldata.startTime)),
                        end: new Date(moment(sunday + ' ' + (schedueldata.endTime === "00:00" ? "23:59" : schedueldata.endTime))),
                        costSegment: schedueldata.costSegment,
                        costSegmentId: schedueldata.costSegmentId,
                        startDay: val.day,
                    })
                })
            }
        })
        setFinalEventData(eventData)
    }
    
    const resetScheduleData = (dayValue:any, sTime:any, eTime:any, segId:any, eType:any) => {
        let paramObj = {
            "day": dayValue,
            "startTime": moment(sTime, [timFormat]).format('HH:mm'),
            "endTime": moment(eTime, [timFormat]).format('HH:mm'),
            "costSegmentId": segId
        }
        putMethod(ApiEndPoint.depotInformation, props?.depotId + '/schedule', paramObj).then((res) => {
            if (res?.status === 200) {
                getCalenderData();
            }
            console.log("success", res)
        }).catch((err) => {
            console.log("Error", err)
        })

    }

    const resizeEvent = ({ event, start, end }:any) => {
        let filteredArray = finalEventData.filter((val: any) => (val != event))
        let checkEventMatched = filteredArray.filter((val: any) => (start > val?.start && start < val?.end) || (end > val?.start && end < val?.end) || ((start < val?.start && start < val?.end) && (end > val?.start && end > val?.end)))
        if (checkEventMatched?.length === 0) {
            const nextEvents:any  = finalEventData.map( (existingEvent:any) => {
                return existingEvent.start ===  event.start && existingEvent.end === event.end ?{ ...existingEvent, start, end }
                : existingEvent
              })
            setFinalEventData(nextEvents);
            resetScheduleData(event?.startDay, start, end,  event?.costSegmentId , 'edit')
        }
    };

    return (
        <>
            <EvseSchedulePriority openScheduleCall={openSchedule} closeScheduleCal={calScheduleClose} scrollScheduleCal={scheduleScroll} />
            <div className="information_section">
                <Grid container spacing={2}>
                    <Grid className="mid_section_left" item xs={12}>
                        <Card variant="outlined" className="basic_card cal_card_state">
                            <CardContent>
                                {/*---- Holiday Code Start 
                            <div className="title_row">
                              <div className="charge_fee_btn">
                              <div className="schedule_select">
                                <FormControl className="select_control">
                                    <Select labelId="demo-simple-select-label" id="demo-simple-select" >
                                        <MenuItem className="optionlist" value={1}>
                                        Schedule A</MenuItem>
                                </Select>
                                    </FormControl>
                                </div>
                              <Button className="outline_btn" onClick={scheduleOpen('paper')} variant="outlined"><img src={Images.ic_setfee} alt="Set Fee" />Set Fee</Button>
                              </div>
                              <div className="clearfix"></div>
                              </div>
                              <div className="charge_fee_cal">
                                <div className="cal_legend">
                                    <div className="legend">Standard Price</div>
                                    <div className="legend off_peak">Off-Peak</div>
                                    <div className="legend peak">Peak</div>
                                    <div className="legend super_peak">Super-Peak</div>
                                </div>
                              <div className="edit_holiday">
                                <Grid container spacing={6}>
                                  <Grid item lg={3} sm={6}>
                                    <h4>Set Schedule</h4>
                                  </Grid>
                                  <Grid item lg={5} sm={6}>
                                  <h4>Public Holidays</h4>
                                  <div className="holiday_list details">
                                  <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                      <h5>HOLIDAY</h5>
                                      <div className="row_list holiday">New Year’s Day</div>
                                      <div className="row_list holiday">Martin Luther King, Jr. Day</div>
                                      <div className="row_list holiday">President’s Day</div>
                                      <div className="row_list holiday">Juneteenth (observed)</div>
                                      <div className="row_list holiday">Juneteenth</div>
                                      <div className="row_list holiday">Independence Day</div>
                                      <div className="row_list holiday">Independence Day (observed)</div>
                                      <div className="row_list holiday">Labor Day</div>
                                      <div className="row_list holiday">Columbus Day</div>
                                      <div className="row_list holiday">Veterans Day</div>
                                      <div className="row_list holiday">Thanksgiving Day</div>
                                      <div className="row_list holiday">Christmas Day (observed)</div>
                                      <div className="row_list holiday">Christmas Day</div>
                                      <div className="row_list holiday">New Year’s Day</div>
                                    </Grid>
                                    <Grid item xs={3}>
                                    <h5>DATE</h5>
                                    <div className="row_list date">January 1</div>
                                    <div className="row_list date">January 18</div>
                                    <div className="row_list date">February 15</div>
                                    <div className="row_list date">May 31</div>
                                    <div className="row_list date">June 18</div>
                                    <div className="row_list date">June 19</div>
                                    <div className="row_list date">July 4</div>
                                    <div className="row_list date">July 5</div>
                                    <div className="row_list date">September 6</div>
                                    <div className="row_list date">October 11</div>
                                    <div className="row_list date">November 11</div>
                                    <div className="row_list date">November 25</div>
                                    <div className="row_list date">December 24</div>
                                    <div className="row_list date">December 25</div>
                                    </Grid>
                                    <Grid item xs={3}>
                                    <h5>SETTINGS</h5>
                                    <div className="row_list status_list">Deactivate</div>
                                    <div className="row_list status_list">Deactivate</div>
                                    <div className="row_list status_list">Deactivate</div>
                                    <div className="row_list status_list">Deactivate</div>
                                    <div className="row_list status_list">Activate</div>
                                    <div className="row_list status_list">Deactivate</div>
                                    <div className="row_list status_list">Deactivate</div>
                                    <div className="row_list status_list">Deactivate</div>
                                    <div className="row_list status_list">Activate</div>
                                    <div className="row_list status_list">Deactivate</div>
                                    <div className="row_list status_list">Deactivate</div>
                                    <div className="row_list status_list">Deactivate</div>
                                    <div className="row_list status_list">Deactivate</div>
                                    <div className="row_list status_list">Deactivate</div>
                                   
                                    </Grid>
                                   </Grid> 
                                  </div>
                                  </Grid>
                                  <Grid item lg={4} sm={12}>
                                  <h4>Custom Holidays</h4>
                                  <div className="holiday_list details">
                                  <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                      <h5>LABEL</h5>
                                      <div className="row_list label_input">
                                      <FormControl className="form_control">
                                    <OutlinedInput className="input_text" value="All Saints Day" id="outlined-adornment-amount" />
                                  </FormControl>
                                      </div>
                                      <div className="row_list label_input">
                                      <FormControl className="form_control">
                                    <OutlinedInput className="input_text" value="Founder’s day" id="outlined-adornment-amount" />
                                  </FormControl>
                                      </div>
                                      <div className="row_list label_input">
                                      <FormControl className="form_control">
                                    <OutlinedInput className="input_text" value="" id="outlined-adornment-amount" />
                                  </FormControl>
                                      </div>
                                      <div className="row_list label_input">
                                      <FormControl className="form_control">
                                    <OutlinedInput className="input_text" value="" id="outlined-adornment-amount" />
                                  </FormControl>
                                      </div>
                                      <div className="row_list label_input">
                                      <FormControl className="form_control">
                                    <OutlinedInput className="input_text" value="" id="outlined-adornment-amount" />
                                  </FormControl>
                                      </div>
                                      <div className="row_list label_input">
                                      <FormControl className="form_control">
                                    <OutlinedInput className="input_text" value="" id="outlined-adornment-amount" />
                                  </FormControl>
                                      </div>
                                      <div className="row_list label_input">
                                      <FormControl className="form_control">
                                    <OutlinedInput className="input_text" value="" id="outlined-adornment-amount" />
                                  </FormControl>
                                      </div>
                                      <div className="row_list label_input">
                                      <FormControl className="form_control">
                                    <OutlinedInput className="input_text" value="" id="outlined-adornment-amount" />
                                  </FormControl>
                                      </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                    <h5>DATE</h5>
                                   
                                    <div className="row_list label_input">
                                      <FormControl className="form_control">
                                    <OutlinedInput className="input_text date" value="09/15/21" id="outlined-adornment-amount" />
                                  </FormControl>
                                      </div>
                                      <div className="row_list label_input">
                                      <FormControl className="form_control">
                                    <OutlinedInput className="input_text date" value="09/15/21" id="outlined-adornment-amount" />
                                  </FormControl>
                                      </div>
                                      <div className="row_list label_input">
                                      <FormControl className="form_control">
                                    <OutlinedInput className="input_text date" value="" id="outlined-adornment-amount" />
                                  </FormControl>
                                      </div>
                                      <div className="row_list label_input">
                                      <FormControl className="form_control">
                                    <OutlinedInput className="input_text date" value="" id="outlined-adornment-amount" />
                                  </FormControl>
                                      </div>
                                      <div className="row_list label_input">
                                      <FormControl className="form_control">
                                    <OutlinedInput className="input_text date" value="" id="outlined-adornment-amount" />
                                  </FormControl>
                                      </div>
                                      <div className="row_list label_input">
                                      <FormControl className="form_control">
                                    <OutlinedInput className="input_text date" value="" id="outlined-adornment-amount" />
                                  </FormControl>
                                      </div>
                                      <div className="row_list label_input">
                                      <FormControl className="form_control">
                                    <OutlinedInput className="input_text date" value="" id="outlined-adornment-amount" />
                                  </FormControl>
                                      </div>
                                      <div className="row_list label_input">
                                      <FormControl className="form_control">
                                    <OutlinedInput className="input_text date" value="" id="outlined-adornment-amount" />
                                  </FormControl>
                                      </div>
                                    </Grid>
                                    <Grid className="setting_state" item xs={4}>
                                    <h5>SETTINGS</h5>
                                    <div className="row_list status_list">Deactivate</div>
                                    <div className="row_list status_list">Activate</div>
                                    <div className="row_list status_list">Create</div>
                                    <div className="row_list status_list">Create</div>
                                    <div className="row_list status_list">Create</div>
                                    <div className="row_list status_list">Create</div>
                                    <div className="row_list status_list">Create</div>
                                    <div className="row_list status_list">Create</div>
                                    </Grid>
                                   </Grid> 
                                  </div>
                                  </Grid>
                                </Grid>
                                </div>
                              </div>
                            
                            Holiday Code End---*/}
                                <div className="title_row">
                                    <div className="charge_fee_btn">
                                        <div className="schedule_select">
                                            <FormControl className="select_control">
                                                <Select labelId="demo-simple-select-label" id="demo-simple-select" >
                                                    <MenuItem className="optionlist" value={1}>
                                                        Schedule A</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <Button className="outline_btn" onClick={calScheduleClickOpen('paper')} variant="outlined"><img src={Images.ic_format_list_numbered} alt="Set Fee" />Set Schedule Priority</Button>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>


                                <div className="charge_fee_cal">
                                    <div className="cal_legend">
                                        <div className="legend">Standard</div>
                                        <div className="legend off_peak">Off-Peak</div>
                                        <div className="legend peak">Peak</div>
                                        <div className="legend super_peak">Super-Peak</div>
                                    </div>
                                    <DragAndDropCalendar
                                        resizable
                                        localizer={localizer}
                                        formats={formats}
                                        events={finalEventData}
                                        onEventResize={resizeEvent}
                                        startAccessor="start"
                                        endAccessor="end"
                                        defaultView={Views.WEEK}
                                        views={['week']}
                                        eventPropGetter={(eventStyleGetter)}
                                        style={{}}
                                        step={15}
                                        timeslots={4} 
                                    />
                                </div>

                            </CardContent>
                        </Card>
                        <div className="time_zone_info">Time zone: <span>UTC -8</span></div>

                    </Grid>

                </Grid>
            </div>

        </>
    );
}

export default EvseSchedule;