import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import ImageGallery from "react-image-gallery";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import React, { useState, useEffect } from 'react';
import Images from 'assets/images';
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";
import VehicleDetailMapMarker from "common/vehicleDetailMapMarker";
import  {getMapOptions} from "common/mapMethods";
import  {googleMapLanguage,googleMapRegion} from "common/mapConstants";
import environment from "environment";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import EditVehicleInformation from './editVehicleInformation';
import EditVehicleParkingInfo from 'pages/vehicleDetail/vehicleDetailOverview/editVehicleParkingInfo';

    function GoogleMapVehicleDetail(props: any){
        const { children,vehicleDetails, ...other } = props;

        const vehicleLatLong = {
            latitude: Number((vehicleDetails.latitude).toFixed(6)),
            longitude: Number((vehicleDetails.longitude).toFixed(6))
        }

        const defaultVehicleTripLatLong = { lat: vehicleLatLong.latitude, lng: vehicleLatLong.longitude };

        return  (
            (vehicleDetails && (vehicleDetails != null)) ? (<GoogleMapReact
                bootstrapURLKeys={{
                    key: environment.googleMapKey,
                    language: googleMapLanguage,
                    region: googleMapRegion
                }}
                defaultCenter={defaultVehicleTripLatLong}
                defaultZoom={15}
                options={getMapOptions}
            >
                {
                    <VehicleDetailMapMarker {...props} key={vehicleDetails.id} lat={vehicleLatLong.latitude}
                                            lng={vehicleLatLong.longitude} text={vehicleDetails.vehicleId}
                                            tooltip={vehicleDetails.vehicleId} $hover={vehicleDetails.vehicleId}
                                            soc={vehicleDetails.soc}
                    />
                }
            </GoogleMapReact>) : (<GoogleMapReact
                bootstrapURLKeys={{
                    key: environment.googleMapKey,
                    language: googleMapLanguage,
                    region: googleMapRegion
                }}
                defaultCenter={defaultVehicleTripLatLong}
                defaultZoom={12}
                yesIWantToUseGoogleMapApiInternals/>)


        )
    }

    GoogleMapVehicleDetail.propTypes = {
        children: PropTypes.node,
        vehicleDetails: PropTypes.any.isRequired
    };


const VehicleDetailOverview = (props: any) => {
   
    const [openParkingInfo, setParkingInfoOpen] = useState(false);
    const [scrollParkingInfo, setParkingInfoScroll] = useState('paper');

    const [openEditVehicleInfo, setEditVehicleInfoOpen] = useState(false);
    const [scrollEditVehicleInfo, setEditVehicleInfoScroll] = useState('paper');

    const { children, vehicleDetail , ...other } = props;
    const imageSlider = [
        {
            original: Images.vehicle_img,
            thumbnail: Images.vehicle_img,
        },
        {
            original: Images.vehicle_img,
            thumbnail: Images.vehicle_img,
        },


    ];

    const [imageSliderFromVehicle, setImageSliderFromVehicle] = React.useState(imageSlider);

    const parkinginfoopen = (scrollType: any) => () => {
        setParkingInfoOpen(true);
        setParkingInfoScroll(scrollType);
      };
      
      const parkingInfoClose = () => {
        setParkingInfoOpen(false);
      };

      const editVehicleInfoopen = (scrollType: any) => () => {
        setEditVehicleInfoOpen(true);
        setEditVehicleInfoScroll(scrollType);
      };
      
      const editVehicleInfoClose = () => {
        setEditVehicleInfoOpen(false);
      };

    useEffect(() => {
        if(vehicleDetail.images && vehicleDetail.images.length > 0){
            const imgSlider = vehicleDetail.images.map((val: { path: any; thumbnailPath: any; }) => ({ original: val.path, thumbnail: val.thumbnailPath}));
            setImageSliderFromVehicle(imgSlider);
        }

    },vehicleDetail.images);

    const selectedVehicleIndex = 0;

    const getParkingData = (selectedIndex: any, val: any) =>{
        if(vehicleDetail.parkingInfo && vehicleDetail.parkingInfo.length >= 1) {
            return vehicleDetail.parkingInfo[selectedIndex][val];
        } else {
            return '-';
        }
    }

    const [openVehiclePhoto, setVehiclePhotoOpen] = useState(false);
    const [scrollVehiclePhoto, setVehiclePhotoScroll] = useState('paper');

    const vehiclePhotoOpen = (scrollType: any) => () => {
        setVehiclePhotoOpen(true);
        setVehiclePhotoScroll(scrollType);
      };
      
      const vehiclePhotoClose = () => {
        setVehiclePhotoOpen(false);
      };


    return (
        <>

<div className="dialog_box">
          <Dialog className="dialog_container sm edit_detail_section dialog_container_center" {...props}
            open={openEditVehicleInfo}
            onClose={editVehicleInfoClose}
            scroll={scrollEditVehicleInfo}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          > {openEditVehicleInfo &&
            <EditVehicleInformation 
            vehicleInfo= {vehicleDetail}
            vehicleInfoClose={editVehicleInfoClose}
          /> }
          </Dialog>
        </div>
        
        <div className="dialog_box">
          <Dialog className="dialog_container edit_detail_section dialog_container_center" {...props}
            open={openParkingInfo}
            close={parkingInfoClose}
            scroll={scrollParkingInfo}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description">
              <EditVehicleParkingInfo {...props}
              open={openParkingInfo}
              onClose={parkingInfoClose}
              data={getParkingData}
              />
          </Dialog>
        </div>
        


        



        <div className="dialog_box">
                <Dialog className="dialog_container edit_photo_gallery  dialog_container_center" {...props}
                open={openVehiclePhoto}
                onClose={vehiclePhotoClose}
                scroll={scrollVehiclePhoto}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                >
                <div className="dialog_title">
                    <h4>Edit Vehicle Photos</h4>
                    <div className="button_row mt-0 full_right">                 
                        <Button className="btn_white text_btn_theme" onClick={vehiclePhotoClose}>Cancel</Button>
                    
                        <Button className="btn_white blue_bg">Save Changes</Button>  
                                    
                    </div>            
                </div>

                <DialogContent  dividers='paper' {...props}>
                    <DialogContentText
                    id="scroll-dialog-description">
                        <div className="gallery_photo_upload">
                        <Card>
                        <CardContent>
                            <div className="error">
                            <span>Only JPEG and PNG format accepted</span>
                            </div>
                            <div className="dragdrop">
                            <img src={Images.up_image} alt="upimg" className="up-icon" />
                            <label htmlFor="contained-button-file">
                                <Button variant="contained" color="primary" component="span">
                                Drag and drop or click here
                                <cite>Maximum photo upload size 6MB . Accepted JPEG and PNG</cite>
                                </Button>
                            </label>
                            <input accept="image/*" className="" id="icon-button-file" type="file" />
                            
                            </div>

                            <div className="progresbar">
                            <label>Port.jpeg<span>(4 MB)</span></label>
                            <div className='range'><div className='upper-range'></div></div>
                            <IconButton className="top_action_btn error-icn">
                                <img src={Images.error_icon} alt="del" className="error-img"/>
                            </IconButton>
                            <IconButton className="top_action_btn cancel">
                                <img src={Images.cancel_icon} alt="del" className="cancel-img"/>
                            </IconButton>
                            <IconButton className="top_action_btn cancel">
                                <img src={Images.cancelred_icon} alt="del" className="cancelred-img"/>
                            </IconButton>
                            </div>
                        </CardContent>
            
                        </Card>
                        <Grid container spacing={0}>
                        <Grid item xs={6}>
                            <div className="drag-text">Drag and drop thumbnail for ordering</div>
                        </Grid>

                        <Grid item xs={6}>
                            <div className="drag-text text-right">Maximum 10 photo can be upload</div>
                        </Grid>
                        </Grid>

                        <Grid container direction="row" justifyContent="space-between" spacing={0} alignItems="center">
            
                        <Grid item> 
                            <div className="items">
                            <img src={Images.vehicle_img_thum1} alt="del" className=""/> 
                            <IconButton className="top_action_btn del">
                            <img src={Images.delete_icon} alt="del" className="del-img"/>
                            </IconButton>
                            <IconButton className="top_action_btn drag">
                            <img src={Images.drag_icon} alt="drag"/>
                            </IconButton>
                        </div>
                        </Grid>

                        <Grid item >
                        <div className="items">
                        <img src={Images.vehicle_img_thum1} alt="del" className=""/> 
                        <IconButton className="top_action_btn del">
                        <img src={Images.delete_icon} alt="del" className="del-img"/>
                        </IconButton>
                        <IconButton className="top_action_btn drag">
                            <img src={Images.drag_icon} alt="drag"/>
                        </IconButton>
                        </div>
                        </Grid>

                        <Grid item>
                        <div className="items">
                        <img src={Images.vehicle_img_thum1} alt="del" className=""/> 
                        <IconButton className="top_action_btn del">
                        <img src={Images.delete_icon} alt="del" className="del-img"/>
                        </IconButton>
                        <IconButton className="top_action_btn drag">
                            <img src={Images.drag_icon} alt="drag"/>
                        </IconButton>
                        </div>
                        </Grid>

                        <Grid item>
                        <div className="items">
                        <img src={Images.vehicle_img_thum1} alt="del" className=""/> 
                        <IconButton className="top_action_btn del">
                        <img src={Images.delete_icon} alt="del" className="del-img"/>
                        </IconButton>
                        <IconButton className="top_action_btn drag">
                            <img src={Images.drag_icon} alt="drag"/>
                        </IconButton>
                        </div>
                        </Grid>

                        <Grid item>
                        <div className="items">
                        <img src={Images.vehicle_img_thum1} alt="del" className=""/> 
                        <IconButton className="top_action_btn del">
                        <img src={Images.delete_icon} alt="del" className="del-img"/>
                        </IconButton>
                        <IconButton className="top_action_btn drag">
                            <img src={Images.drag_icon} alt="drag"/>
                        </IconButton>
                        </div>
                        </Grid>
                         </Grid>
                        </div>
                
                    </DialogContentText>
                </DialogContent>
                </Dialog>
            </div>

        <div className="information_section">
            <Grid container spacing={2}>
                <Grid className="mid_section_left" item xs={12} sm={12} md={7} lg={8}>
                    <Card variant="outlined" className="basic_card">
                        <CardContent>
                            {/*----Title Start----*/}
                            <div className="title_row">
                                <h2>CURRENT LOCATION</h2>
                                <div className="clearfix"></div>
                            </div>
                            {/*----Title End----*/}
                            {/*----Information Start----*/}
                            <div className="info_detail">
                                <div className="vehicle_route_map">
                                    <div style={{ height: '100%', width: '100%' }}>
                                        {
                                            (vehicleDetail) ? <GoogleMapVehicleDetail vehicleDetails={vehicleDetail}/>:''
                                        }
                                    </div>
                                </div>
                            </div>
                            {/*----Information End----*/}
                        </CardContent>
                    </Card>

                    <Card variant="outlined" className="basic_card">
                        <CardContent>
                            {/*----Title Start----*/}
                            <div className="title_row">
                                <h2>PHOTOS</h2>
                                <div className="action_icon">
                                    <IconButton className="top_action_btn" onClick={vehiclePhotoOpen('paper')}>
                                        <img src={Images.ic_edit} alt="edit"/>
                                    </IconButton>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            {/*----Title End----*/}

                            {/*----Information Start----*/}
                            {(vehicleDetail && vehicleDetail.images && vehicleDetail.images.length > 0)?<div className="info_detail">
                                <div className="image_gallery">
                                    <ImageGallery
                                        items={imageSliderFromVehicle}
                                        showPlayButton={false}
                                        showBullets={true}
                                    />
                                </div>
                            </div>: <div><span className="label">No image found</span></div>}
                            {/*----Information End----*/}
                        </CardContent>
                    </Card>

                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={4}>
                    <Card variant="outlined" className="basic_card  port_card">
                        <CardContent>
                            {/*----Title Start----*/}
                            <div className="title_row">
                                <h2>CURRENT BATTERY STATUS</h2>
                                {(vehicleDetail?.status?.toLowerCase() === 'charging' || vehicleDetail?.status?.toLowerCase() === 'complete') ?
                                <div className="full_right">
                                    <Button className="btn_white small_btn blue_bg">Stop Charge</Button>
                                </div>
                                :
                                <div className="full_right">
                                    <Button className="btn_white small_btn blue_bg">Start Charge</Button>
                                </div>}
                                <div className="clearfix"></div>
                            </div>
                            {/*----Title End----*/}
                            {/*----Information Start----*/}
                            <div className="info_detail">
                                <div className="port_details vehicle_info_card tb_info_section">
                                    <div className="row">
                                        <span className="label">Estimate distance</span>
                                        <span className="result">{vehicleDetail?.estMiles + ' miles'} </span>
                                    </div>
                                    <div className="row">
                                        <span className="label">State of Charge</span>
                                        <span className="result"> {vehicleDetail?.soc+'%' }</span>
                                    </div>
                                    <div className="row">
                                        <span className="label">Estimate max range</span>
                                        <span className="result">{vehicleDetail.estMaxRange === null ? '-' : vehicleDetail.estMaxRange + ' miles' } </span>
                                    </div>
                                    <div className="row">
                                        <span className="label">Battery size</span>
                                        <span className="result">{ vehicleDetail?.batterySize +' kWh'} </span>
                                    </div>
                                    <div className="row mb-10">
                                        <span className="label mt-5">Charge limit</span>
                                        <span className="result">
                                <FormControl className="select_control">
                                  <Select labelId="demo-simple-select-label" id="demo-simple-select">
                                  <MenuItem className="optionlist" value={1}>
                                  {vehicleDetail.chargeLimit + '%'}</MenuItem>
                                  </Select>
                               </FormControl>
                                </span>
                                    </div>
                                    <div className="row mb-10">
                                        <span className="label mt-5">Charge priority</span>
                                        <span className="result">
                                <FormControl className="select_control">
                                  <Select labelId="demo-simple-select-label" id="demo-simple-select">
                                  <MenuItem className="optionlist" value={1}>
                                  Priority 3</MenuItem>
                                  </Select>
                               </FormControl>
                                </span>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    <Card variant="outlined" className="basic_card">
                        <CardContent>
                            {/*----Title Start----*/}
                            <div className="title_row">
                                <h2>VEHICLE INFORMATION</h2>
                                <div className="action_icon" onClick={editVehicleInfoopen('paper')}>
                                    <IconButton className="top_action_btn">
                                        <img src={Images.ic_edit} alt="edit"/>
                                    </IconButton>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            {/*----Title End----*/}
                            {/*----Information Start----*/}
                            <div className="info_detail">
                                <div className="port_details vehicle_info_card mt-16 tb_info_section">
                                <div className="row">
                                        <span className="label">Year</span>
                                        <span className="result">{(vehicleDetail.manufactureYear)?vehicleDetail.manufactureYear:'-'}</span>
                                    </div>
                                    <div className="row">
                                        <span className="label">Make</span>
                                        <span className="result">{vehicleDetail.make}</span>
                                    </div>
                                    <div className="row">
                                        <span className="label">Model</span>
                                        <span className="result">{vehicleDetail.model}</span>
                                    </div>
                                    <div className="row">
                                        <span className="label">VIN</span>
                                        <span className="result">{(vehicleDetail.vehicleVIN)?vehicleDetail.vehicleVIN:'-'}</span>
                                    </div>
                                    
                                    <div className="row">
                                        <span className="label">License plate ID</span>
                                        <span className="result">{vehicleDetail.licensePlateId}</span>
                                    </div>
                                    <div className="row">
                                        <span className="label">State registration</span>
                                        <span className="result">{vehicleDetail.registrationState}</span>
                                    </div>
                                    <div className="row">
                                        <span className="label">Country</span>
                                        <span className="result">{vehicleDetail.country}</span>
                                    </div>
                                    <div className="row">
                                        <span className="label">Registration expiration</span>
                                        <span className="result">{vehicleDetail.registrationExpiration}</span>
                                    </div>
                                    <div className="row">
                                        <span className="label">Fleet</span>
                                        <span className="result">{vehicleDetail.fleet}</span>
                                    </div>
                                    <div className="row">
                                        <span className="label">Vehicle ID</span>
                                        <span className="result">{vehicleDetail.vehicleId}</span>
                                    </div>
                                  


                                </div>
                            </div>


                        </CardContent>
                    </Card>
 
                    <Card variant="outlined" className="basic_card">
                        <CardContent>
                            {/*----Title Start----*/}
                            <div className="title_row">
                                <h2>PARKING INFO</h2>
                                <div className="action_icon" onClick={parkinginfoopen('paper')}>
                                    <IconButton className="top_action_btn">
                                        <img src={Images.ic_edit} alt="edit"/>
                                    </IconButton>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            {/*----Title End----*/}
                            {/*----Information Start----*/}
                            <div className="info_detail">

                                <div className="port_details vehicle_info_card mt-16 tb_info_section">
                                    <div className="row">
                                        <span className="label">Lot number</span>
                                        <span className="result">{getParkingData(selectedVehicleIndex,'lotNumber')}</span>
                                    </div>
                                    <div className="row">
                                        <span className="label">Zone</span>
                                        <span className="result">{getParkingData(selectedVehicleIndex,'zone')}</span>
                                    </div>
                                    <div className="row">
                                        <span className="label">Lane/Space ID</span>
                                        <span className="result">{getParkingData(selectedVehicleIndex,'laneSpaceId')}</span>
                                    </div>
                                    <div className="row">
                                        <span className="label">Address 1</span>
                                        <span className="result">{getParkingData(selectedVehicleIndex,'addressLine1')}</span>
                                    </div>
                                    <div className="row">
                                        <span className="label">Address 2</span>
                                        <span className="result">{getParkingData(selectedVehicleIndex,'addressLine2')}</span>
                                    </div>
                                    <div className="row">
                                        <span className="label">City</span>
                                        <span className="result">{getParkingData(selectedVehicleIndex,'city')}</span>
                                    </div>
                                    <div className="row">
                                        <span className="label">State or Province</span>
                                        <span className="result">{getParkingData(selectedVehicleIndex,'state')}</span>
                                    </div>
                                    <div className="row">
                                        <span className="label">Zip Code</span>
                                        <span className="result">{getParkingData(selectedVehicleIndex,'postalCode')}</span>
                                    </div>
                                    <div className="row">
                                        <span className="label">Country</span>
                                        <span className="result">{getParkingData(selectedVehicleIndex,'country')}</span>
                                    </div>
                                    <div className="row">
                                        <span className="label">Latitude</span>
                                        <span className="result">{getParkingData(selectedVehicleIndex,'latitude')}</span>
                                    </div>
                                    <div className="row">
                                        <span className="label">Longitude</span>
                                        <span className="result">{getParkingData(selectedVehicleIndex,'longitude')}</span>
                                    </div>
                                    <div className="row">
                                        <span className="label">Note</span>
                                        <span className="result">{getParkingData(selectedVehicleIndex,'note')} </span>
                                    </div>

                                </div>
                            </div>


                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
        </>
    )
}

VehicleDetailOverview.propTypes = {
    children: PropTypes.node,
    vehicleDetail: PropTypes.any.isRequired,
}

export default VehicleDetailOverview;