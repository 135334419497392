import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import { getMethod } from 'services/api';
import ApiEndPoint from 'services/apiUrl.json';
import { useSelector } from 'react-redux';
import StationDetailMapMarker from './depotStationDetailMapMarker';

function GoogleMapStationDetail(props:any) {
    const { children, stationMapDetails, ...other } = props;
    const stationLatLong = {
      latitude: Number((stationMapDetails?.latitude).toFixed(6)),
      longitude: Number((stationMapDetails?.longitude).toFixed(6)),
    };
    const defaultTripLatLong = {
      lat: stationLatLong.latitude,
      lng: stationLatLong.longitude,
    };
    const getStationDetailMapOptions = (maps:any) => {
      return {
        streetViewControl: false,
        scaleControl: true,
        mapTypeControl: true,
        mapTypeId: maps.MapTypeId.ROADMAP,
        mapTypeControlOptions: {
          style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: maps.ControlPosition.TOP_LEFT,
          mapTypeIds: [
            maps.MapTypeId.ROADMAP,
            maps.MapTypeId.SATELLITE,
            maps.MapTypeId.HYBRID,
          ],
        },
        zoomControl: true,
        clickableIcons: true,
      };
    };
    
    const distanceToMouse = (pt:any, mp:any) => {
      if (pt && mp) {
        return Math.sqrt(
          (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y)
        );
      }
    };
  
    return stationMapDetails && stationMapDetails != null ? (
      <GoogleMapReact {...props}
        bootstrapURLKeys={{
          key: 'AIzaSyCqFay6qqmew8KKgujKBlhSNzA7RAr4yqQ',
          language: 'en',
          region: 'US',
        }}
        defaultCenter={defaultTripLatLong}
        defaultZoom={15}
        options={getStationDetailMapOptions}
        distanceToMouse={distanceToMouse}>
        {
          <StationDetailMapMarker {...props}
            lat={stationLatLong.latitude}
            lng={stationLatLong.longitude}
            text={stationMapDetails.serialNumber}
            tooltip={stationMapDetails.serialNumber}
          />
        }
      </GoogleMapReact>
    ) : (
      <GoogleMapReact {...props}
        bootstrapURLKeys={{
          key: 'AIzaSyCqFay6qqmew8KKgujKBlhSNzA7RAr4yqQ',
          language: 'en',
          region: 'US',
        }}
        defaultCenter={defaultTripLatLong}
        defaultZoom={12}
        distanceToMouse={distanceToMouse}
        yesIWantToUseGoogleMapApiInternals
      />
    );
  }
  
  GoogleMapStationDetail.propTypes = {
    children: PropTypes.node,
    stationMapDetails: PropTypes.any.isRequired,
  };
  

const DepotStationDetailsLocation = (props:any) => { 
    const currentStationId = useSelector((state:any) => {return state.currentStationId;});
    const [stationDetails, setstationDetails] = useState<any>({});
    const [portDetail, setPortDetail] = useState({});
    
    
    useEffect(() => {
        getDepotStationData();
      }, []);
      
      const getDepotStationData = async () => {
        await getMethod(ApiEndPoint.evseStationInformation,currentStationId + '/station-details').then((res) => {
            setstationDetails(res.data);
            if (res && res?.data && res?.data?.ports?.length >= 1) {
              setPortDetail(res?.data?.ports[0]);
            }
          }).catch((err) => {
            console.log('Error', err);
          });
      };
      
      return (
      <>
      <div className="title_row">
          <h2>LOCATION</h2>
          <div className="clearfix"></div>
          </div>
          {/*----Title End----*/}
          {/*----Information Start----*/}
          <div data-testid="locationInfo" className="info_detail">
              <Grid container>
                  <Grid className="" item xs={12}>
                      <div className="tb_info_section mt-13">
                          <div className="row">
                              <span className="label">Address 1</span>
                              <span className="result">{stationDetails?.addressLine1 ? stationDetails.addressLine1 : '-'}</span>
                          </div>
                          <div className="row">
                              <span className="label">Address 2</span>
                              <span className="result">{stationDetails?.addressLine2 ? stationDetails.addressLine2 : '-'}</span>
                          </div>
                          <div className="row">
                              <span className="label">City</span>
                              <span data-testid="cityDataTest" className="result">{stationDetails?.city ? stationDetails.city : '-'}</span>
                          </div>
                          <div className="row">
                              <span className="label">State or Province</span>
                              <span className="result">{stationDetails?.state? stationDetails.state : '-'}</span>
                          </div>
                          <div className="row">
                              <span className="label">Zip Code</span>
                              <span className="result">{stationDetails?.postalCode ? stationDetails.postalCode : '-'}</span>
                          </div>
                          <div className="row">
                              <span className="label">Country</span>
                              <span className="result">{stationDetails?.name ? stationDetails.name : '-'}</span>
                          </div>
                          <div className="row">
                              <span className="label">Latitude</span>
                              <span className="result">{stationDetails?.longitude ? stationDetails.latitude : '-'}</span>
                          </div>
                          <div className="row">
                              <span className="label">Longitude</span>
                              <span className="result">{stationDetails?.longitude? stationDetails.longitude : '-'}</span>
                          </div>
                          <div className="info_detail">
                              <div className="station_route_map">
                                  <div style={{height: '100%',width: '100%',}}>
                                      {stationDetails.latitude ? (<GoogleMapStationDetail stationMapDetails={stationDetails}/>) : ('')}
                                  </div>
                              </div>
                         </div>
                         </div>
                    </Grid>
                </Grid>
            </div>
         </>
  );
};
export default DepotStationDetailsLocation;	