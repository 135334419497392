import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import './depotHealthCard.scss';

const DepotHealthCard = (props:any) => {

    const depotHealthCount = () => {
        let healthCount = (props?.chargingPorts ? props.chargingPorts : 0) + (props?.availablePorts ? props.availablePorts : 0 ) + (props?.offlinePorts ? props.offlinePorts : 0 )
        return healthCount
    }
    return (
        <>
            <Card className="small_card" variant="outlined">
                <CardContent className="card_content">
                    <div className="section_title">
                        <Grid container>
                            <Grid item xs={12}>
                                <h3>{props.depotLabel}</h3>
                            </Grid>
                        </Grid>
                    </div>


                    <div className="shift_group_row section_content">
                        <div className="depot_bar">
                            <span className="charging" style={{ width: "50%" }}>&nbsp;</span>
                            <span className="available" style={{ width: "40%" }}>&nbsp;</span>
                            <span className="offline" style={{ width:"calc(10% - 3px)" }}>&nbsp;</span>
                            
                        </div>
                        <div className="depot_state">
                            <Grid container justifyContent="space-between" alignItems="flex-start">
                                <Grid className="state">
                                    <span className="">&nbsp;</span>
                                    <div className="state_text">
                                    TOTAL
                                    </div>
                                    <div className="state_number">
                                    {depotHealthCount()}
                                    </div>
                                </Grid>
                                <Grid className="state">
                                    <span className="charging">&nbsp;</span>
                                    <div className="state_text">
                                    CHARGING
                                    </div>
                                    <div className="state_number">
                                    {props.chargingPorts}
                                    </div>
                                </Grid>
                             
                                <Grid className="state">
                                    <span className="available">&nbsp;</span>
                                    <div className="state_text">
                                    AVAILABLE
                                    </div>
                                    <div className="state_number">
                                    {props.availablePorts}
                                    </div>
                                </Grid>
                                <Grid className="state">
                                    <span className="offline">&nbsp;</span>
                                    <div className="state_text">
                                    OFFLINE
                                    </div>
                                    <div className="state_number">
                                    {props.offlinePorts}
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>


                
                </CardContent>
            </Card>
        </>
    )
}

export default DepotHealthCard;
