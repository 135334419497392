const fleetId = ''
const fleetData = <any>[];
export const fetchFleetReducer = (state = fleetId, action: any) => {
    
    switch(action.type) {
        case 'FETCH_FLEET_ACTION': {
            state = action.payload
            return state
        }   
        default: 
            return state;
    }
}

export const fetchFleetDataReducer = (state = fleetData, action: any) => {
    
    switch(action.type) {
        case 'FETCH_FLEET_DATA_ACTION': {
            state = action.payload
            return state
        }   
        default: 
            return state;
    }
}