import React from "react";
import Button from '@material-ui/core/Button';

const FormButton= (props:any) => {

    return (
                    <div className="button_row mt-0 full_right">
                        <Button className="btn_white text_btn_theme" onClick={props?.cancel}>Cancel</Button>
                        <Button className="btn_white blue_bg">Save Changes</Button>
                    </div>  
    );
}

export default FormButton;
