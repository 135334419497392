import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Images from "assets/images";
import CommonAutoSearchGoogleMap from "components/commonGoogleMap/commonAutoSearchGoogleMap";
import { depotGoogleMap, mapDetails } from 'common/common';

const CommonAddressForm = (props: any) => {
  const [count, setCount] = React.useState(0);
  const [descCount, setDescCount] = React.useState(0);
  const [markers, setMarkers] = React.useState<any>();
  const [stateList, setStateList] = useState<any>();
  const [countryList, setCountryList] = useState<any>();
  
  const markerClicked = ()=>{};

  useEffect(() =>{
    getStateData()
    getCountriesData()
  },[])

  const getStateData = async () =>{
    let tempData = {
      "states": [
        {
          "code": "UE",
          "name": "US East"
        },
        {
          "code": "CA",
          "name": "CA East"
        }
      ]
    }
    setStateList(tempData['states']);
  }

  const getCountriesData = async () =>{
    let tempData = {
      "countries": [
        {
          "code": "India",
          "name": "IN",
          "currency": "INR",
        "symbol": "$"
        },
        {
          "code": "USA",
          "name": "US",
          "currency": "USD",
        "symbol": "$"
        }
      ]
    }
    setCountryList(tempData['countries']);
  }

  const handleChange = (e: any) => {
  let name = e.target.name; let value = e.target.value;
  props.setAddressDetails({ ...props.addressDetails, [name]: value });
}; 

  return (
    <>
      <div className="section_group">
        <h3>Address</h3>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={4}
        >
          <Grid item xs={6}>
            <div className="form_col">
              <Grid
                className="single_line"
                container
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={0}
              >
                <Grid item xs={12}>
                  <label>Address Line 1 *</label>
                  <TextField
                    variant="outlined"
                    className="error"
                    name="StreetAddress1"
                    onChange={handleChange}
                    value={props.addressDetails?.StreetAddress1}
                    onBlur={()=>props.validator.current.showMessageFor('StreetAddress1')}
                    inputProps={{ maxLength: 200 }}
                  />
                  <span className="error_msg">{props.validator.current.message('StreetAddress1', props.addressDetails?.StreetAddress1, 'required')}</span>
                </Grid>
              </Grid>
            </div>
            <div className="form_col">
              <Grid
                className="single_line"
                container
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={0}
              >
                <Grid item xs={12}>
                  <label>Address Line 2</label>
                  <TextField
                    variant="outlined"
                    value={props.addressDetails?.StreetAddress2}
                    name="StreetAddress2"
                    onChange={handleChange}
                    inputProps={{ maxLength: 200 }}
                  />
                </Grid>
              </Grid>
            </div>
            <div className="form_col">
              <Grid
                className="single_line"
                container
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={0}
              >
                <Grid item xs={12}>
                  <label>City *</label>
                  <TextField
                    variant="outlined"
                    name="City"
                    onChange={handleChange}
                    className="error"
                    value={props.addressDetails?.City}
                    onBlur={()=>props.validator.current.showMessageFor('City')}
                  />
                  <span className="error_msg">{props.validator.current.message('City', props.addressDetails?.City, 'required')}</span>
                </Grid>
              </Grid>
            </div>
            <div className="form_col">
              <Grid
                className="single_line"
                container
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={0}
              >
                <Grid item xs={12}>
                  <label>State *</label>
                  <Select
                    labelId="demo-simple-select-label"
                    variant="outlined"
                    name="State"
                    value={props.addressDetails?.State}
                    onChange={handleChange}
                    defaultValue="UE"
                  >
                    {props.addressDetails?.State &&
                      stateList.map((value: any) => (
                        <MenuItem
                          className="defult_select_option"
                          key={value.code}
                          value={value.code}
                        >
                          {value.name}
                        </MenuItem>
                      ))}
                  </Select>
                  <span className="error_msg">{props.validator.current.message('State', props.addressDetails?.State, 'required')}</span>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={6}>
            { props.renderMap &&
            <div className="form_col">
              <Grid
                className="single_line"
                container
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={0}
              >
                <Grid item xs={12} >
                  <label>
                    Map View *
                    <span className="sub_label">
                      Place the pin on the precise location of the EVSE
                      stations. Drivers will use pin location to navigate to the
                      stations.
                    </span>
                  </label> 
                  <div className="map_section">
                  <div style={{ height: '100%', width: '100%' }}>
                      {props.addressDetails?.lat?  
                        <CommonAutoSearchGoogleMap {...props}
                         center = {{
                            lat: props.addressDetails.lat,
                            lon: props.addressDetails.lon }}
                         zoom={mapDetails.depotLocationMapZoom}
                         options={mapDetails.viewSatellite}
                         markerClicked={markerClicked}
                         markers = {[{
                          lat: props.addressDetails?.lat,
                          lon: props.addressDetails?.lon }]}
                          setIntialValues = {props?.setIntialValues} 
                          intialValues = {props?.intialValues} 
                         fullscreenControl = {true}
                         scrollwheel = {true}
                         gestureHandling = "cooperative"
                         setAddressDetails={props.setAddressDetails}
                         addressDetails={props.addressDetails}
                         />: ''}
                    </div>  
                  </div>
                </Grid>
              </Grid>
            </div>
            }
          </Grid>
        </Grid>

        <Grid
          className="dobule_line"
          container
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={4}
        >
          <Grid className="p_t_0" item xs={6}>
            <div className="form_col">
              <Grid
                className="single_line"
                container
                justifyContent="flex-start"
                alignItems="center"
                spacing={0}
              >
                <Grid item xs={12}>
                  <label>Zip Code *</label>
                  <TextField
                    variant="outlined"
                    value={props.addressDetails?.ZipCode}
                    name="ZipCode"
                    onChange={handleChange}
                    className="error"
                    onBlur={()=>props.validator.current.showMessageFor('ZipCode')}
                  />
                  <span className="error_msg">{props.validator.current.message('ZipCode', props.addressDetails?.ZipCode, 'required')}</span>
                </Grid>
              </Grid>
            </div>
            <div className="form_col">
              <Grid
                className="single_line"
                container
                justifyContent="flex-start"
                alignItems="center"
                spacing={0}
              >
                <Grid item xs={12}>
                  <label>Country *</label>
                  <Select
                    labelId="demo-simple-select-label"
                    variant="outlined"
                    name="CountryCode"
                    onChange={handleChange}
                    value={props.addressDetails?.CountryCode}
                    defaultValue="USA"
                  >
                    {countryList &&
                        countryList.map((value: any) => (
                        <MenuItem
                          className="defult_select_option"
                          key={value.code}
                          value={value.code}
                        >
                          {value.name}
                        </MenuItem>
                      ))}
                  </Select>
                  <span className="error_msg">{props.validator.current.message('CountryCode', props.addressDetails?.CountryCode, 'required')}</span>
                </Grid>
              </Grid>
            </div>
          </Grid>
          {props.renderMap &&
          <Grid className="p_t_0" item xs={6}>
            <div className="form_col">
              <Grid
                className="single_line"
                container
                justifyContent="flex-start"
                alignItems="center"
                spacing={0}
              >
                <Grid item xs={12}>
                  <label>Latitude *</label>
                  <TextField
                    variant="outlined"
                    value={props.addressDetails?.lat}
                    name="lat"
                    onChange={handleChange}
                    className="error"
                    onBlur={()=>props.validator.current.showMessageFor('lat')}
                  />
                  <span className="error_msg">{props.validator.current.message('lat', props.addressDetails?.lat, 'required')}</span>
                </Grid>
              </Grid>
            </div>
            <div className="form_col">
              <Grid
                className="single_line"
                justifyContent="flex-start"
                alignItems="center"
                spacing={0}
              >
                <Grid item xs={12}>
                  <label>Longitude *</label>
                  <TextField
                    variant="outlined"
                    value={props.addressDetails?.lon}
                    name="lon"
                    onChange={handleChange}
                    className="error"
                    onBlur={()=>props.validator.current.showMessageFor('lon')}
                  />
                  <span className="error_msg">{props.validator.current.message('lon', props.addressDetails?.lon, 'required')}</span>
                </Grid>
              </Grid>
            </div>
          </Grid>}
        </Grid>
      </div>
      <Divider />
      <div className="section_group">
        <div className="form_col">
          <Grid
            className="single_line textarea"
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0}
          >
            <Grid item xs={12}>
              <label>
                Description
              </label>
              <TextField
                multiline
                rows={4}
                helperText={descCount + " Characters (maximum 400 characters)"}
                value={props.addressDetails?.description}
                variant="outlined"
                name="description"
                className="error"
                inputProps={{ maxLength: 400 }}
                onChange={handleChange}
                onBlur={(e) => setDescCount(e.target.value.length)}
              />
            </Grid>
          </Grid>
        </div>
        <div className="form_col">
          <Grid
            className="single_line textarea"
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0}
          >
            <Grid item xs={12}>
              <label>
                Notes
              </label>
              <TextField
                multiline
                rows={4}
                helperText={count + " Characters (maximum 400 characters)"}
                value={props.addressDetails?.notes}
                variant="outlined"
                name="notes"
                className="error"
                inputProps={{ maxLength: 400 }}
                onChange={handleChange}
                onBlur={(e) => setCount(e.target.value.length)}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default CommonAddressForm;
