import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';

const CommonContactForm = (props: any) => {
  const handleChange = (e: any) => {
    let name = e.target.name; let value = e.target.value;
    props.setContact({ ...props.contact, [name]: value });
  };

  return (
    <>
      <div className="section_group">
        <h3>{props.title}</h3>
        <Grid className="dobule_line" container justifyContent="flex-start" alignItems="flex-start" spacing={4}>
          <Grid item xs={6}>
            <div className="form_col">
              <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}>
                <Grid item xs={12}>
                  <label>Name *</label>
                  <TextField
                    variant="outlined"
                    value={props.contact?.firstName}
                    name="firstName"
                    onChange={handleChange}
                    className="error"
                    onBlur={()=>props.validator.current.showMessageFor('firstName')}
                    inputProps={{ maxLength: 140 }}
                  />
                  <span className="error_msg">{props.validator.current.message('firstName', props.contact?.firstName, 'required')}</span>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
        <Grid className="dobule_line" container justifyContent="flex-start" alignItems="flex-start" spacing={3}>
          <Grid className="p_t_0" item xs={6}>
            <div className="form_col">
              <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}>
                <Grid item xs={12}>
                  <label>Telephone *</label>
                  <TextField
                    variant="outlined"
                    value={props.contact?.telePhoneNumber}
                    name="telePhoneNumber"
                    onChange={handleChange}
                    className="error"
                    onBlur={()=>props.validator.current.showMessageFor('telePhoneNumber')}
                    inputProps={{ maxLength: 15 }}
                  />
                  <span className="error_msg">{props.validator.current.message('telePhoneNumber', props.contact?.telePhoneNumber, 'required')}</span>
                </Grid>
              </Grid>
            </div>
            <div className="form_col">
              <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}>
                <Grid item xs={12}>
                  <label>Mobile

                  </label>
                  <TextField
                    variant="outlined"
                    value={props.contact?.mobileNumber}
                    name="mobileNumber"
                    onChange={handleChange}
                    className="error"
                    inputProps={{ maxLength: 15 }}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid className="p_t_0" item xs={6}>
            <div className="form_col">
              <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}>
                <Grid item xs={12}>
                  <label>Fax</label>
                  <TextField
                    variant="outlined"
                    value={props.contact?.fax}
                    name="fax"
                    onChange={handleChange}
                    className="error"
                    inputProps={{ maxLength: 15 }}
                  />
                </Grid>
              </Grid>
            </div>

            <div className="form_col">
              <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}>
                <Grid item xs={12}>
                  <label>Email *</label>
                  <TextField
                    variant="outlined"
                    value={props.contact?.email}
                    name="email"
                    onChange={handleChange}
                    className="error"
                    onBlur={()=>props.validator.current.showMessageFor('email')}
                    inputProps={{ maxLength: 120 }}
                  />
                  <span className="error_msg">{props.validator.current.message('email', props.contact?.email, 'required|email')}</span>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

        <div className="form_col">
          <Grid className="single_line textarea" container justifyContent="flex-start" alignItems="flex-start" spacing={0}>
            <Grid item xs={12}>
              <label>Note</label>
              <TextField
                multiline
                rows={4}
                helperText="33 Characters (maximum 400 characters)"
                value={props.contact?.note}
                variant="outlined"
                name="note"
                onChange={handleChange}
                className="error"
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default CommonContactForm;
