import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import React, { useState, useEffect } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import { getCountriesList, getStateList, getFleetList, getMakersList, getManufacturingYearList, getModelList } from 'common/commonMethod';
import moment from 'moment';
import MomentUtils from '@date-io/moment'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const EditVehicleInformation = (props: any) => {
  const [vehicleInfo, setVehicleInfo] = useState<any>(props?.vehicleInfo); 
  const [countryList, setCountryList] = useState<any>([]);
  const [stateList, setStateList] = useState<any>([]);
  const [fleetList, setFleetList] = useState<any>([]);
  const [yearList, setYearList] = useState<any>([]);
  const [modelList, setModelList] = useState<any>([]);
  const [makerList, setMakerList] = useState<any>([]);
  const [formErrors, setFormErrors] = useState<any>({});
  const [selectedDate, setSelectedDate] = React.useState<Date | null>( moment().toDate());

  useEffect(() => {
    setYearList(getManufacturingYearList)
    setModelList(getModelList)
    setMakerList(getMakersList)
    setCountryList(getCountriesList)
    setStateList(getStateList)
    setFleetList(getFleetList)
  }, [])

  const handleDateChange = (date:any) => {
    setSelectedDate(date);
  };
  
  const handleInfoChange = (e: any) => {
    setVehicleInfo({ ...vehicleInfo, [e.target.name]: e.target.value });
    handleValidation(e.target.name, e.target.value)
  };

  const handleRequired = (name:any, value: any) => {
    value = value.toString().replace(/\s+/g, "");
    if(value==null || value==undefined || value=='') {
      setFormErrors({ ...formErrors, [name]: "Required field" });
      return false;
  
    }
  
    return true;
  }

  const handleValidation = (name:any,value:any) =>{
    setFormErrors({ ...formErrors, [name]: "" });
    let result = true;
    switch(name) {
      case 'manufactureYear':
        result = handleRequired(name,value);
        break;     
      case 'make':
        result = handleRequired(name,value);
        break;     
      case 'model':
        result = handleRequired(name,value);
        break;    
      case 'vehicleVIN':
        result = handleRequired(name,value);
        break;  
      case 'licensePlateId':
        result = handleRequired(name,value);
        break;  
      case 'registrationState':
        result = handleRequired(name,value);
        break;  
      case 'country':
        result = handleRequired(name,value);
        break;  
      case 'fleet':
        result = handleRequired(name,value);
        break;          
                                                              
      default:
        break;
    }
      return result;  
  }
  

  return (
    <>
      <div className="dialog_title">
        <h4>Edit Vehicle Information</h4>
        <div className="button_row mt-0 full_right">
          <Button className="btn_white text_btn_theme" onClick={props?.vehicleInfoClose}>Cancel</Button>
          <Button className="btn_white blue_bg">Save Changes</Button>
        </div>

      </div>

      <DialogContent dividers='paper' {...props}>
        <DialogContentText component="div"
          id="scroll-dialog-description">
          <div className="defult_form">
            <div className="section_group">
              <h5>* Required information</h5>
              <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={4}>
                <Grid item xs={9}>
                  <div className={`form_col ${formErrors?.manufactureYear ? 'error' : ''}`}>
                    <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                      <Grid item xs={12}>
                        <label>Year *</label>
                        <Select labelId="demo-simple-select-label" variant="outlined" name="manufactureYear" onChange={handleInfoChange} defaultValue={' '} value={vehicleInfo.manufactureYear}>
                        <MenuItem className="defult_select_option" value={' '} >Select</MenuItem>
                        {yearList && yearList.map((value: any, index:any) => (
                          <MenuItem key={index} className="defult_select_option" value={value}>{value}</MenuItem>
                          ))} 
                        </Select>
                        <span className="error_msg">{formErrors?.manufactureYear}</span>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={`form_col ${formErrors?.make ? 'error' : ''}`}>
                    <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                      <Grid item xs={12}>
                        <label>Make *</label>
                        <Select labelId="demo-simple-select-label" variant="outlined" name="make" onChange={handleInfoChange} defaultValue={' '} value={vehicleInfo.make}>
                        <MenuItem className="defult_select_option" value={' '} >Select</MenuItem>
                        {makerList && makerList.map((value: any, index:any) => (
                          <MenuItem key={index} className="defult_select_option" value={value.make}>{value.make}</MenuItem>
                          ))} 
                        </Select>
                        <span className="error_msg">{formErrors?.make}</span>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={`form_col ${formErrors?.model ? 'error' : ''}`}>
                    <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                      <Grid item xs={12}>
                        <label>Model *</label>
                        <Select labelId="demo-simple-select-label" variant="outlined" name="model" onChange={handleInfoChange} defaultValue={' '} value={vehicleInfo.model}>
                        <MenuItem className="defult_select_option" value={' '} >Select</MenuItem>
                        {modelList && modelList.map((value: any, index:any) => (
                          <MenuItem  key={index} className="defult_select_option" value={value.model}>{value.model}</MenuItem>
                        ))}  
                        </Select>
                        <span className="error_msg">{formErrors?.model}</span>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={`form_col ${formErrors?.vehicleVIN ? 'error' : ''}`}>
                    <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                      <Grid item xs={12}>
                        <label>VIN *</label>
                        <TextField variant="outlined" value={vehicleInfo?.vehicleVIN} name="vehicleVIN" onChange={handleInfoChange}/>
                        <span className="error_msg">{formErrors?.vehicleVIN}</span>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={`form_col ${formErrors?.licensePlateId ? 'error' : ''}`}>
                    <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}>
                      <Grid item xs={12}>
                        <label>License plate ID *</label>
                        <TextField variant="outlined" value={vehicleInfo?.licensePlateId} name="licensePlateId" onChange={handleInfoChange}/>
                        <span className="error_msg">{formErrors?.licensePlateId}</span>
                      </Grid>
                    </Grid>
                  </div>

                  <div className={`form_col ${formErrors?.registrationState ? 'error' : ''}`}>
                    <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}>
                      <Grid item xs={12}>
                        <label>State registration *</label>
                        <Select labelId="demo-simple-select-label" variant="outlined" name="registrationState" onChange={handleInfoChange} defaultValue={' '} value={vehicleInfo.registrationState}>
                        <MenuItem className="defult_select_option" value={' '}>Select</MenuItem>
                        {stateList && stateList.map((value: any, index:any) => (
                          <MenuItem  key={index} className="defult_select_option" value={value.name}>{value.name}</MenuItem>
                        ))}
                        </Select>
                        <span className="error_msg">{formErrors?.registrationState}</span>
                      </Grid>
                    </Grid>
                  </div>

                  <div className={`form_col ${formErrors?.country ? 'error' : ''}`}>
                    <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}>
                      <Grid item xs={12}>
                        <label>Country *</label>
                        <Select labelId="demo-simple-select-label" variant="outlined" name="country" onChange={handleInfoChange} defaultValue={' '} value={vehicleInfo.country}>
                          <MenuItem className="defult_select_option" value={' '} >Select</MenuItem>
                          {countryList && countryList.map((value: any, index:any) => (
                            <MenuItem  key={index} className="defult_select_option" value={value.name}>{value.name}</MenuItem> 
                          ))}
                        </Select>
                          <span className="error_msg">{formErrors?.country}</span>
                      </Grid>
                    </Grid>
                  </div>

                  <div className="form_col date_cal">
                  <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}>
                      <Grid item xs={12}>
                        <label>Registration expiration *</label>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>                       
                        <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            format="MMMM-DD-YYYY"
                            value={selectedDate}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                          
                      </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>
                  </div>

                  <div className={`form_col ${formErrors?.fleet ? 'error' : ''}`}>
                    <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}>
                      <Grid item xs={12}>
                        <label>Fleet *</label>
                        <Select labelId="demo-simple-select-label" variant="outlined"  name="fleet" onChange={handleInfoChange} defaultValue={' '} value={vehicleInfo.fleet}>
                        <MenuItem className="defult_select_option" value={' '} >Select</MenuItem>
                        {fleetList && fleetList.map((value: any, index:any) => (
                          <MenuItem key={index} className="defult_select_option" value={value.fleet}>{value.fleet}</MenuItem>
                        ))}
                        </Select>
                        <span className="error_msg">{formErrors?.fleet}</span>
                      </Grid>
                    </Grid>
                  </div>

                  <div className="form_col">
                    <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}>
                      <Grid item xs={12}>
                        <label>Vehicle ID</label>
                        <TextField variant="outlined" value={vehicleInfo?.vehicleId} name="vehicleId" onChange={handleInfoChange} />
                      </Grid>
                    </Grid>
                  </div>

                </Grid>

              </Grid>


            </div>

          </div>
        </DialogContentText>
      </DialogContent>

    </>
  )
}
export default EditVehicleInformation;