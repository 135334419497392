import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Images from 'assets/images';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

const EditVehicleParkingInfo = (props:any) => { 
   
    const close = props?.onClose; 
    const parkingData = props?.data;
    const selectedVehicle = 0;
  
    return (
        <>
            <div className="dialog_title">
              <h4>Edit Parking Info</h4>
              <div className="button_row mt-0 full_right">                 
                    <Button className="btn_white text_btn_theme" onClick={close}>Cancel</Button>
                    <Button className="btn_white blue_bg">Save Changes</Button>              
                </div>  
             
            </div>

            <DialogContent dividers='paper' {...props}>
              <DialogContentText component="div"
                id="scroll-dialog-description">
                <div className="defult_form">
                  <div className="section_group">
                  <Grid className="dobule_line mt-0" container justifyContent="flex-start" alignItems="flex-start" spacing={4}>
                    <Grid  item xs={6}>
                      <div className="form_col">
                      <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}>
                        <Grid item xs={12}>
                        <label>Lot number</label>
                          <TextField variant="outlined" defaultValue={parkingData(selectedVehicle,'lotNumber')} />  
                        </Grid> 
                      </Grid> 
                      </div> 
                      <div className="form_col">
                        <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}> 
                          <Grid item xs={12}>
                          <label>Zone</label>
                          <TextField variant="outlined" defaultValue={parkingData(selectedVehicle,'zone')} />  
                          </Grid> 
                        </Grid> 
                      </div> 
                     </Grid>
                     <Grid item xs={6}>
                        <div className="form_col">
                        <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}>
                          <Grid item xs={12}>
                          <label>Lane/Space ID</label>
                            <TextField variant="outlined" defaultValue={parkingData(selectedVehicle,'laneSpaceId')} />  
                          </Grid> 
                        </Grid> 
                        </div>
                        <div className="form_col">
                        <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}>
                          <Grid item xs={12}>
                          <label>Floor</label>
                            <TextField variant="outlined" defaultValue="" />  
                          </Grid> 
                        </Grid> 
                        </div>
                     </Grid>
                  </Grid>  
                  </div> 
                  <Divider /> 
                  <div className="section_group">
                  <h3>Address</h3>
                  <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={4}>
                    <Grid item xs={6}>
                      <div className="form_col">
                        <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                          <Grid item xs={12}>
                          <label>Address Line 1</label>
                            <TextField  variant="outlined" defaultValue={parkingData(selectedVehicle,'addressLine1')} />
                            
                          </Grid> 
                        </Grid> 
                      </div>
                      <div className="form_col">
                          <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                             <Grid item xs={12}>
                            <label>Address Line 2</label>
                              <TextField variant="outlined" defaultValue={parkingData(selectedVehicle,'addressLine2')} />
                            </Grid> 
                          </Grid> 
                      </div>
                      <div className="form_col">
                      <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                        <Grid item xs={12}>
                        <label>City</label>
                          <TextField variant="outlined" defaultValue={parkingData(selectedVehicle,'city')} />  
                        </Grid> 
                      </Grid> 
                      </div> 
                  <div className="form_col">
                    <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                      <Grid item xs={12}>
                      <label>State or Province</label>
                      <Select labelId="demo-simple-select-label"  variant="outlined" >
                        <MenuItem className="defult_select_option" value={1}>{parkingData(selectedVehicle,'state')}</MenuItem>
                        <MenuItem className="defult_select_option" value={2}>{parkingData(selectedVehicle,'state')}</MenuItem>
                        <MenuItem className="defult_select_option" value={3}>{parkingData(selectedVehicle,'state')}</MenuItem>
                      </Select>
                       </Grid> 
                    </Grid> 
                  </div> 

                 </Grid>
                     <Grid item xs={6}>
                     <div className="form_col">
                      <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                        <Grid item xs={12}>
                        <label>Map View *
                              <span className="sub_label">Place the pin on the precise location of the EVSE stations. Drivers will use pin location to navigate to the stations.</span>
                            </label>
                          <div className="map_section">
                          <img className="img_res" src={Images.info_map_a} alt="Depot Port" />
                          </div>
                        </Grid> 
                      </Grid> 
                      </div>
                     </Grid>
                  </Grid>   

                  <Grid className="dobule_line" container justifyContent="flex-start" alignItems="flex-start" spacing={4}>
                    <Grid className="p_t_0" item xs={6}>
                      <div className="form_col">
                      <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}>
                        <Grid item xs={12}>
                        <label>Zip Code</label>
                          <TextField variant="outlined" defaultValue={parkingData(selectedVehicle,'postalCode')} />  
                        </Grid> 
                      </Grid> 
                      </div> 
                      <div className="form_col">
                        <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}> 
                          <Grid item xs={12}>
                          <label>Country</label>
                          <Select labelId="demo-simple-select-label"  variant="outlined" >
                            <MenuItem className="defult_select_option" value={1}>{parkingData(selectedVehicle,'country')}</MenuItem>
                            <MenuItem className="defult_select_option" value={2}>{parkingData(selectedVehicle,'country')}</MenuItem>
                            <MenuItem className="defult_select_option" value={3}>{parkingData(selectedVehicle,'country')}</MenuItem>
                          </Select>
                          </Grid> 
                        </Grid> 
                      </div> 
                     
                     </Grid>
                     <Grid className="p_t_0" item xs={6}>
                        <div className="form_col">
                        <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}>
                          <Grid item xs={12}>
                          <label>Latitude</label>
                            <TextField variant="outlined" defaultValue={parkingData(selectedVehicle,'latitude')} />  
                          </Grid> 
                        </Grid> 
                        </div>
                        <div className="form_col">
                        <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}>
                          <Grid item xs={12}>
                          <label>Longitude</label>
                            <TextField variant="outlined" defaultValue={parkingData(selectedVehicle,'longitude')} />  
                          </Grid> 
                        </Grid> 
                        </div>
                     </Grid>
                  </Grid> 


                  <Grid className="dobule_line" container justifyContent="flex-start" alignItems="flex-start" spacing={4}>
                    <Grid className="p_t_0" item xs={12}>
                  
                      <div className="form_col checkbox">
                      <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}> 
                          <Grid item xs={12}>
                          <label>Geofence</label>
                          <FormControlLabel  control={ 
                            <Checkbox name="checkedB" color="primary"/>
                            }
                            label="Activate geofence 10 yard radius"
                            />
                          </Grid>
                        </Grid>      
                      
                      </div>
                     </Grid>
                   
                  </Grid> 
                  </div>
                
                  <Divider /> 
                  <div className="section_group">      
                  <div className="form_col">
                    <Grid className="single_line textarea" container justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                      <Grid item xs={12}>
                      <label>Note
                      </label>
                        <TextField multiline rows={4} helperText="33 Characters (maximum 400 characters)" defaultValue={parkingData(selectedVehicle,'note')} variant="outlined" /> 
                      </Grid> 
                    </Grid> 
                  </div> 
                  </div>
                   </div>             
              </DialogContentText>
            </DialogContent>
          
        </>
    );
}


export default EditVehicleParkingInfo;