import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
//import { DataGrid } from '@mui/x-data-grid';
import { DataGrid } from '@material-ui/data-grid';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import {columns, columnsChargehistory, alertcolumns, rowsChargehistory, rows, alertrow} from 'data/driverInfoData'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Images from 'assets/images';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';

const drawerWidth = 200;
function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({

  search: {
    position: 'relative',
     '&:hover': {
     // backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '145px',
    background:'#fff',
    borderRadius:'5px'
  },
  searchIcon: {
    padding: '0px 10px',
    height: '100%',
    //width:'14px',
    fontSize:'14px',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color:'#999',
  },
  
  inputRoot: {
    color: '#999',
  },
  inputInput: {
    padding: theme.spacing(1.5, 1, 1.5, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
    transition: theme.transitions.create('width'),
    fontSize:'12px',
    width: '100px',
   
  },


  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },

  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },


  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(8) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },





  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));




const DriverInfo = (props: any) => {


  const [opend, setOpend] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpend(true);
  };

  const handleDrawerClose = () => {
    setOpend(false);
  };




  const [isVisible, setIsVisible] = useState(true);




  const listenToScroll = () => {
    let heightToHideFrom = 50;
    const winScroll = document.body.scrollTop ||
      document.documentElement.scrollTop;
    if (winScroll > heightToHideFrom) {
      isVisible &&      // to limit setting state only the first time         
        setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () =>
      window.removeEventListener("scroll", listenToScroll);
  }, [])



  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const [openEditDriverInfo, setEditDriverInfoOpen] = useState(false);
    const [scrollEditDriverInfo, setEditDriverInfoScroll] = useState('paper');

    const editDriverInfoOpen = (scrollType: any) => () => {
      setEditDriverInfoOpen(true);
      setEditDriverInfoScroll(scrollType);
    };
    
    const EditDriverInfoclose = () => {
      setEditDriverInfoOpen(false);
    };

  return (
      <>


<div className="dialog_box">
  <Dialog className="dialog_container sm edit_detail_section dialog_container_center" {...props}
            open={openEditDriverInfo}
            onClose={EditDriverInfoclose}
            scroll={scrollEditDriverInfo}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description">
            <div className="dialog_title">
              <h4>Edit Driver Information</h4>
              <div className="button_row mt-0 full_right">                 
                    <Button className="btn_white text_btn_theme" onClick={EditDriverInfoclose}>Cancel</Button>
                    <Button className="btn_white blue_bg">Save Changes</Button>              
                </div>  
              </div>

            <DialogContent dividers='paper' {...props}>
              <DialogContentText component="div" id="scroll-dialog-description">
                <div className="defult_form">
                 <div className="section_group">
                  <h5>* Required information</h5>
                    <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={4}>
                      <Grid item xs={9}>
                        <div className="form_col">
                          <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                            <Grid item xs={12}>
                            <label>Driver Name *</label>
                            <TextField variant="outlined" defaultValue="2020" /> 
                            </Grid> 
                          </Grid> 
                        </div>
                        <div className="form_col">
                            <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                              <Grid item xs={12}>
                              <label>Driver Manager *</label>
                              <TextField variant="outlined" defaultValue="Ford" /> 
                              </Grid> 
                            </Grid> 
                        </div>
                        <div className="form_col date_cal">
                        <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                          <Grid item xs={12}>
                          <label>Start Date *</label>
                          <TextField variant="outlined" defaultValue="November 12, 2022" /> 
                          </Grid> 
                        </Grid> 
                        
                      </div> 
                    </Grid> 
                  </Grid>
                  </div>
                  <Divider /> 
              <div className="section_group">
                  <h3>Contact</h3>
              <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={4}>
                <Grid item xs={9}>
                  <div className="form_col">
                    <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                      <Grid item xs={12}>
                      <label>Address line 1 *</label>
                      <TextField variant="outlined" defaultValue="7800 S Citrus Ave" /> 
                       </Grid> 
                    </Grid> 
                  </div> 
                  <div className="form_col">
                      <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}>
                        <Grid item xs={12}>
                        <label>Address line 2</label>
                          <TextField variant="outlined" defaultValue="" />  
                        </Grid> 
                      </Grid> 
                      </div> 

                      <div className="form_col">
                        <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}> 
                          <Grid item xs={12}>
                          <label>City *</label>
                          <TextField variant="outlined" defaultValue="Los Angeles" /> 
                         </Grid> 
                        </Grid> 
                      </div> 

                      <div className="form_col">
                        <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}> 
                          <Grid item xs={12}>
                          <label>State *</label>
                          <Select labelId="demo-simple-select-label"  variant="outlined" >
                            <MenuItem className="defult_select_option" value={1}>California</MenuItem>
                            <MenuItem className="defult_select_option" value={2}>California</MenuItem>
                            <MenuItem className="defult_select_option" value={3}>California</MenuItem>
                          </Select>
                          </Grid> 
                        </Grid> 
                      </div> 

                      <div className="form_col">
                        <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}> 
                          <Grid item xs={12}>
                          <label>Zip Code *</label>
                          <TextField variant="outlined" defaultValue="90043" /> 
                         </Grid> 
                        </Grid> 
                      </div> 

                      <div className="form_col">
                        <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}> 
                          <Grid item xs={12}>
                          <label>Country *</label>
                          <Select labelId="demo-simple-select-label"  variant="outlined" >
                            <MenuItem className="defult_select_option" value={1}>USA</MenuItem>
                            <MenuItem className="defult_select_option" value={2}>USA</MenuItem>
                            <MenuItem className="defult_select_option" value={3}>USA</MenuItem>
                          </Select>
                          </Grid> 
                        </Grid> 
                      </div> 

                      <div className="form_col">
                        <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}> 
                          <Grid item xs={12}>
                          <label>Telephone *</label>
                          <TextField variant="outlined" defaultValue="1(310)555-5555" /> 
                         </Grid> 
                        </Grid> 
                      </div> 

                      <div className="form_col">
                        <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}> 
                          <Grid item xs={12}>
                          <label>Email *</label>
                          <TextField variant="outlined" defaultValue="kim.mirron@email.com" /> 
                         </Grid> 
                        </Grid> 
                      </div> 

                 </Grid>
                 </Grid>

                 </div>
                 
                  </div>             
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>

        {/*----Page Mid Section Start----*/}
        <main className={classes.content}>
          <div className={isVisible ? '' : 'scroll_effect'}>
            <div className="depot_details station_details vehicle_detail">
              <div className="depot_detail_head position_fixed_head">

              <div className="depot_information">
                  {/*----EVSE Depot Map----*/}

                  <div className="depot_location_map">
                    <img className="img_res" src={Images.driver_photo} alt="Driver Picture" />
                  </div>

                  {/*----EVSE Depot Information----*/}
                  <div className="depot_info">
                    <div className="depot_name">Mckenna Declan
                    <div className="status text_center driver_onduty"><span className="status_text">On Duty</span></div>
                    </div>
                    <div className="depot_addr">DI8945</div>

                   {isVisible
                      &&
                      <div className="port_details">
                        <span className="alert">
                           
                        </span>
                        <span className="port">
                       
                        </span>
                        
                      
                      </div>
                    }
                  </div>
                </div>

              
          
              </div>




              <div className="detail_page_tab">
                <AppBar className="defult_tab position_fixed_nav" position="static" color="default">
                <Tabs className="tab_nav"
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable force tabs example"
                  >
                   
                    <Tab className="tab_nav_item" label="Driver Detail" {...a11yProps(0)} />
                    <Tab className="tab_nav_item" label="Trip History" {...a11yProps(1)} />
                    <Tab className="tab_nav_item" label="Charge History" {...a11yProps(2)} />
                    <Tab className="tab_nav_item" label={<Badge className="alert_badge" badgeContent={3} color="primary">
                      Alerts &nbsp;  &nbsp; &nbsp; 
                    </Badge>} {...a11yProps(3)} />

      
                  </Tabs>
                  <div className="tab_menu_select">
                  <Button className="btn_white light_gry"> <img className="mr-5 d-in-b" src={Images.ic_sidebar_msg} alt="Messages" />Message</Button>
                 
                  </div>
                </AppBar>
 
            

               


                <TabPanel className="" {...props} value={value} index={0}>
                <div className="information_section">
                    <Grid container spacing={2}>
                      <Grid className="mid_section_left" item xs={12} sm={12} md={8}>
                        <Card variant="outlined" className="basic_card">
                          <CardContent>
                            {/*----Title Start----*/}
                            <div className="title_row">
                              <h2>DRIVER INFORMATION</h2>
                              <div className="action_icon">
                                <IconButton className="top_action_btn" onClick={editDriverInfoOpen('paper')}>
                                  <img src={Images.ic_edit} alt="edit" />
                                </IconButton>
                              </div>
                              <div className="clearfix"></div>
                            </div>
                            {/*----Title End----*/}

                            {/*----Information Start----*/}
                            <div className="info_detail">
                              <Grid container >
                                <Grid className="" item xs={12} sm={6} md={6}>
                                  <div className="tb_info_section mt-13">
                                    <div className="label">DRIVER NAME</div>
                                    <div className="result">Mckenna Declan</div>
                                  </div>
                                  <div className="tb_info_section">
                                    <div className="label">DRIVER MANAGER</div>
                                    <div className="result">Robert, Jeremy</div>
                                  </div>
                                  <div className="tb_info_section">
                                    <div className="label">START DATE</div>
                                    <div className="result">Mar 13, 2021
                                    </div>
                                  </div>
                                 
                                </Grid>
                                <Grid className="" item xs={12} sm={6} md={6}>
                                  <div className="tb_info_section mt-13">
                                    <div className="label">CONTACT INFO</div>
                                    <div className="result">
                                      <span className="label">Address 1</span>
                                      <span className="result">3400 Beverly Blvd</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">Address 2</span>
                                      <span className="result">-</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">City</span>
                                      <span className="result">Los Angeles</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">State</span>
                                      <span className="result">CA</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">Zip Code</span>
                                      <span className="result">90041</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">Country</span>
                                      <span className="result">USA</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">Tel</span>
                                      <span className="result">+1 (414) 515-4555</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">Mobile</span>
                                      <span className="result">+1 (310) 555-5555</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">Email</span>
                                      <span className="result">makenna.declan@email.com</span>
                                    </div>
                                  </div>

                                
                                
                                </Grid>
                              </Grid>
                            </div>
                            {/*----Information End----*/}
                          </CardContent>
                        </Card>
                        <Card variant="outlined" className="basic_card">
                          <CardContent>
                            {/*----Title Start----*/}
                            <div className="title_row">
                              <h2>DRIVER LICENSE</h2>
                              <div className="action_icon">
                                <IconButton className="top_action_btn">
                                  <img src={Images.ic_edit} alt="edit" />
                                </IconButton>
                              </div>
                              <div className="clearfix"></div>
                            </div>
                            {/*----Title End----*/}

                            {/*----Information Start----*/}
                            <div className="info_detail licence_info">
                              <Grid container >
                                <Grid className="" item xs={12} sm={6} md={6}>
                                  <div className="tb_info_section mt-13">
                                    <div className="label">LICENSE INFORMATION</div>
                                    <div className="result">
                                      <span className="label">Last Name</span>
                                      <span className="result">Declan</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">First Name</span>
                                      <span className="result">Mckenna</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">Driver License</span>
                                      <span className="result">I1234561</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">Expire</span>
                                      <span className="result">08/31/2021</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">DOB</span>
                                      <span className="result">08/31/1977</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">Class</span>
                                      <span className="result">CM1</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">End</span>
                                      <span className="result">None</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">State</span>
                                      <span className="result">California</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">Sex</span>
                                      <span className="result">M</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">Height</span>
                                      <span className="result">5’-08”</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">Hair</span>
                                      <span className="result">BLK</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">Weight</span>
                                      <span className="result">150 lb</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">Eyes</span>
                                      <span className="result">BRN</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">Address</span>
                                      <span className="result">2570 24th Street
                                      <br />-
                                      <br />Sacramento
                                      <br />CA
                                      <br />95818
                                      </span>
                                    </div>
                                  </div>
                                
                                 
                                </Grid>
                                <Grid className="" item xs={12} sm={6} md={6}>
                                  <div className="tb_info_section mt-13">
                                    <div className="label">LICENSE IMAGE</div>
                                    <div className="result">
                                      <div className="license_img">
                                      <img className="img_res" src={Images.license_front} alt="License" />
                                      </div>
                                      <div className="license_img">
                                      <img className="img_res" src={Images.license_back} alt="License" />
                                      </div>
                                    </div>

                                  </div>

                                
                                
                                </Grid>
                              </Grid>
                            </div>
                            {/*----Information End----*/}
                          </CardContent>
                        </Card>
                        <Card variant="outlined" className="basic_card">
                          <CardContent>
                            {/*----Title Start----*/}
                            <div className="title_row">
                              <h2>CURRENT LOCATION</h2>
                              <div className="clearfix"></div>
                            </div>
                            {/*----Title End----*/}

                            {/*----Information Start----*/}
                            <div className="info_detail  mt-13">
                            <div className="map_section">
                                    <img className="img_res" src={Images.vehicle_location_map} alt="Map Img" />
                                    </div>
                            </div>
                            {/*----Information End----*/}
                          </CardContent>
                        </Card>


                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                      <Card variant="outlined" className="basic_card">
                          <CardContent>
                            {/*----Title Start----*/}
                            <div className="title_row">
                              <h2>DRIVER SPECIFICATION</h2>
                              <div className="action_icon">
                                <IconButton className="top_action_btn">
                                  <img src={Images.ic_edit} alt="edit" />
                                </IconButton>
                              </div>
                              <div className="clearfix"></div>
                            </div>
                            {/*----Title End----*/}
                            {/*----Information Start----*/}
                            <div className="info_detail">
                            
                            <div className="port_details vehicle_info_card mt-16 tb_info_section">
                              <div className="row">
                                <span className="label">Shift Info</span>
                                <span className="result">Morning</span>
                              </div>
                              <div className="row">
                                <span className="label">RFID</span>
                                <span className="result">4232</span>
                              </div>
                              <div className="row">
                                <span className="label">Assigned vehicle</span>
                                <span className="result">VEH2275</span>
                              </div>
                              <div className="row">
                                <span className="label">Fleet</span>
                                <span className="result">Ford 150</span>
                              </div>
                              <div className="row">
                                <span className="label">Driver Group</span>
                                <span className="result">Collect and Charge</span>
                              </div>
                              <div className="row">
                                <span className="label">Driver ID</span>
                                <span className="result">DI8945</span>
                              </div>
                             
                          
                                           
                           
                              </div>
                            </div>


                          </CardContent>
                        </Card>

                        <Card variant="outlined" className="basic_card">
                          <CardContent>
                            {/*----Title Start----*/}
                            <div className="title_row">
                              <h2>SCHEDULE</h2>
                             
                              <div className="clearfix"></div>
                            </div>
                            {/*----Title End----*/}
                            {/*----Information Start----*/}
                            <div className="info_detail">
                            <div className="driver_schedule">
                            <Grid className="driver_schedule_list" container spacing={0}>
                              <Grid item className="label" sm={4} xs={4} md={12} lg={4}>
                                Monday
                               </Grid>
                               <Grid item className="text_right" sm={4} xs={4} md={6} lg={4}>
                               <FormControl className="select_control">
                                  <Select labelId="demo-simple-select-label" id="demo-simple-select" >
                                  <MenuItem className="optionlist" value={1}>
                                  9:00 AM</MenuItem>
                                  </Select>
                               </FormControl>
                               </Grid>
                               <Grid item className="text_right" sm={4} xs={4} md={6} lg={4}>
                               <FormControl className="select_control">
                                  <Select labelId="demo-simple-select-label" id="demo-simple-select" >
                                  <MenuItem className="optionlist" value={1}>
                                  7:30 PM</MenuItem>
                                  </Select>
                               </FormControl>
                               </Grid>
                            </Grid>
                            <Grid className="driver_schedule_list" container spacing={0}>
                              <Grid item className="label" sm={4} xs={4} md={12} lg={4}>
                              Tuesday
                               </Grid>
                               <Grid item className="text_right" sm={4} xs={4} md={6} lg={4}>
                               <FormControl className="select_control">
                                  <Select labelId="demo-simple-select-label" id="demo-simple-select" >
                                  <MenuItem className="optionlist" value={1}>
                                  8:30 AM</MenuItem>
                                  </Select>
                               </FormControl>
                               </Grid>
                               <Grid item className="text_right" sm={4} xs={4} md={6} lg={4}>
                               <FormControl className="select_control">
                                  <Select labelId="demo-simple-select-label" id="demo-simple-select" >
                                  <MenuItem className="optionlist" value={1}>
                                  6:30 PM</MenuItem>
                                  </Select>
                               </FormControl>
                               </Grid>
                            </Grid>
                            <Grid className="driver_schedule_list" container spacing={0}>
                              <Grid item className="label" sm={4} xs={4} md={12} lg={4}>
                              Wednesday
                               </Grid>
                               <Grid item className="text_right" sm={4} xs={4} md={6} lg={4}>
                               <FormControl className="select_control">
                                  <Select labelId="demo-simple-select-label" id="demo-simple-select" >
                                  <MenuItem className="optionlist" value={1}>
                                  9:00 AM</MenuItem>
                                  </Select>
                               </FormControl>
                               </Grid>
                               <Grid item className="text_right" sm={4} xs={4} md={6} lg={4}>
                               <FormControl className="select_control">
                                  <Select labelId="demo-simple-select-label" id="demo-simple-select" >
                                  <MenuItem className="optionlist" value={1}>
                                  7:30 PM</MenuItem>
                                  </Select>
                               </FormControl>
                               </Grid>
                            </Grid>
                            <Grid className="driver_schedule_list" container spacing={0}>
                              <Grid item className="label" sm={4} xs={4} md={12} lg={4}>
                              Thursday
                               </Grid>
                               <Grid item className="text_right" sm={4} xs={4} md={6} lg={4}>
                               <FormControl className="select_control">
                                  <Select labelId="demo-simple-select-label" id="demo-simple-select" >
                                  <MenuItem className="optionlist" value={1}>
                                  9:00 AM</MenuItem>
                                  </Select>
                               </FormControl>
                               </Grid>
                               <Grid item className="text_right" sm={4} xs={4} md={6} lg={4}>
                               <FormControl className="select_control">
                                  <Select labelId="demo-simple-select-label" id="demo-simple-select" >
                                  <MenuItem className="optionlist" value={1}>
                                  7:30 PM</MenuItem>
                                  </Select>
                               </FormControl>
                               </Grid>
                            </Grid>
                            <Grid className="driver_schedule_list" container spacing={0}>
                              <Grid item className="label" sm={4} xs={4} md={12} lg={4}>
                              Friday
                               </Grid>
                               <Grid item className="text_right" sm={4} xs={4} md={6} lg={4}>
                               <FormControl className="select_control">
                                  <Select labelId="demo-simple-select-label" id="demo-simple-select" >
                                  <MenuItem className="optionlist" value={1}>
                                  9:00 AM</MenuItem>
                                  </Select>
                               </FormControl>
                               </Grid>
                               <Grid item className="text_right" sm={4} xs={4} md={6} lg={4}>
                               <FormControl className="select_control">
                                  <Select labelId="demo-simple-select-label" id="demo-simple-select" >
                                  <MenuItem className="optionlist" value={1}>
                                  7:30 PM</MenuItem>
                                  </Select>
                               </FormControl>
                               </Grid>
                            </Grid>
                            <Grid className="driver_schedule_list" container spacing={0}>
                              <Grid item className="label" sm={4} xs={4} md={12} lg={4}>
                              Saturday
                               </Grid>
                               <Grid item className="text_right" sm={4} xs={4} md={6} lg={4}>
                               <FormControl className="select_control">
                                  <Select labelId="demo-simple-select-label" id="demo-simple-select" >
                                  <MenuItem className="optionlist" value={1}>
                                  Start time</MenuItem>
                                  </Select>
                               </FormControl>
                               </Grid>
                               <Grid item className="text_right" sm={4} xs={4} md={6} lg={4}>
                               <FormControl className="select_control">
                                  <Select labelId="demo-simple-select-label" id="demo-simple-select" >
                                  <MenuItem className="optionlist" value={1}>
                                  Finish time</MenuItem>
                                  </Select>
                               </FormControl>
                               </Grid>
                            </Grid>
                            <Grid className="driver_schedule_list" container spacing={0}>
                              <Grid item className="label" sm={4} xs={4} md={12} lg={4}>
                              Sunday
                               </Grid>
                               <Grid item className="text_right" sm={4} xs={4} md={6} lg={4}>
                               <FormControl className="select_control">
                                  <Select labelId="demo-simple-select-label" id="demo-simple-select" >
                                  <MenuItem className="optionlist" value={1}>
                                  Start time</MenuItem>
                                  </Select>
                               </FormControl>
                               </Grid>
                               <Grid item className="text_right" sm={4} xs={4} md={6} lg={4}>
                               <FormControl className="select_control">
                                  <Select labelId="demo-simple-select-label" id="demo-simple-select" >
                                  <MenuItem className="optionlist" value={1}>
                                  Finish time</MenuItem>
                                  </Select>
                               </FormControl>
                               </Grid>
                            </Grid>   



                            </div>
                            </div>


                          </CardContent>
                        </Card>

                        <Card variant="outlined" className="basic_card">
                          <CardContent>
                            {/*----Title Start----*/}
                            <div className="title_row">
                              <h2>MANAGE ACCOUNT</h2>
                             
                              <div className="clearfix"></div>
                            </div>
                            {/*----Title End----*/}
                            {/*----Information Start----*/}
                            <div className="info_detail">
                            <div className="driver_schedule">
                      
                            <Grid className="driver_schedule_list" container spacing={0}>
                              <Grid item className="label" sm={4} md={12} lg={4}>
                              Account status
                               </Grid>
                               <Grid item className="text_right" sm={8} md={12} lg={8}>
                               <FormControl className="select_control">
                                  <Select labelId="demo-simple-select-label" id="demo-simple-select" >
                                  <MenuItem className="optionlist" value={1}>
                                  Active</MenuItem>
                                  </Select>
                               </FormControl>
                               </Grid>
             
                            </Grid>
                            <Grid className="driver_schedule_list" container spacing={0}>
                              <Grid item className="label" sm={4} md={12} lg={4}>
                              Notify driver
                               </Grid>
                               <Grid item className="text_right" sm={8} md={12} lg={8}>
                               <Button className="btn_white driver_btn light_gry">Send email notification</Button>
                               </Grid>
             
                            </Grid>
                            <Grid className="driver_schedule_list" container spacing={0}>
                              <Grid item className="label" sm={4} md={12} lg={4}>
                              Reset password
                               </Grid>
                               <Grid item className="text_right" sm={8} md={12} lg={8}>
                               <Button className="btn_white driver_btn light_gry">Reset driver password</Button>
                               </Grid>
             
                            </Grid>
                            <Grid className="driver_schedule_list mt-30" container spacing={0}>
                              <Grid item className="label" md={12} lg={4}>
                              Delete account
                               </Grid>
                               <Grid item className="text_right" md={12} lg={8}>
                               <Button className="btn_white driver_btn delet_acct">Permanently delete account</Button>
                               </Grid>
             
                            </Grid>

                            <Grid className="driver_schedule_list" container spacing={0}>
                              <Grid item className="error_msg" lg={12}>
                              Delete account will permanently remove driver profile
                               </Grid>
                                           
                            </Grid>


                            </div>
                            </div>


                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </div>

                </TabPanel>

              
              

                <TabPanel className="tab_detail" {...props} value={value} index={1}>
                <div className="table_scroll">
                    <div className="defult_table" style={{ width: '100%' }} >

                      <DataGrid autoHeight className="table"
                        rowHeight={59}
                        headerHeight={30}
                        rows={rowsChargehistory}
                        columns={columnsChargehistory}
                        pageSize={12}

                        disableSelectionOnClick />

                    </div>
                    <div className="table_footer">
                      <Grid container direction="row" alignItems="center" spacing={0}>
                        <Grid className="text_left" item xs={8}>
                          <div className="pagination">
                            <span><img src={Images.ic_first_page} alt="first page" /></span>
                            <span><img src={Images.ic_chevron_left} alt="back page" /></span>
                            <span>1</span>
                            <span><img src={Images.ic_chevron_right} alt="back page" /></span>

                          </div>
                          <Button className="btn_white">Download CSV</Button>
                        </Grid>
                        <Grid className="text_right" item xs={4}>
                          <div className="list_number">1-50 of 45,257 charge session</div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>

                </TabPanel>

                <TabPanel  className="tab_detail" {...props} value={value} index={2}>
                <div className="table_scroll">
                    <div className="defult_table" style={{ width: '100%' }} >

                      <DataGrid autoHeight className="table"
                        rowHeight={59}
                        headerHeight={30}
                        rows={rows}
                        columns={columns}
                        pageSize={12}

                        disableSelectionOnClick />

                    </div>
                    <div className="table_footer">
                      <Grid container direction="row" alignItems="center" spacing={0}>
                        <Grid className="text_left" item xs={8}>
                          <div className="pagination">
                            <span><img src={Images.ic_first_page} alt="first page" /></span>
                            <span><img src={Images.ic_chevron_left} alt="back page" /></span>
                            <span>1</span>
                            <span><img src={Images.ic_chevron_right} alt="back page" /></span>

                          </div>
                          <Button className="btn_white">Download CSV</Button>
                        </Grid>
                        <Grid className="text_right" item xs={4}>
                          <div className="list_number">1-50 of 45,257 charge session</div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>

                </TabPanel>

              


                <TabPanel className="tab_detail" {...props} value={value} index={3}>
                  <div className="table_scroll">
                    <div className="defult_table" style={{ width: '100%' }}>

                      <DataGrid autoHeight className="table"
                        rowHeight={59}
                        headerHeight={30}
                        rows={alertrow}
                        columns={alertcolumns}
                        pageSize={12}

                        disableSelectionOnClick />

                    </div>
                    <div className="table_footer">
                      <Grid container direction="row" alignItems="center" spacing={0}>
                        <Grid className="text_left" item xs={8}>
                          <div className="pagination">
                            <span><img src={Images.ic_first_page} alt="first page" /></span>
                            <span><img src={Images.ic_chevron_left} alt="back page" /></span>
                            <span>1</span>
                            <span><img src={Images.ic_chevron_right} alt="back page" /></span>
                          </div>
                          <Button className="btn_white">Clear new alerts</Button>
                          <Button className="btn_white ">Download CSV</Button>
                        </Grid>
                        <Grid className="text_right" item xs={4}>
                          <div className="list_number">1-50 of 45,257 alerts</div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>

                </TabPanel>

              </div>







            </div>
          </div>
        </main>

        {/*----Page Mid Section End----*/}
    </>
  );
}

export default DriverInfo;