import React from "react";
import Images from "assets/images";

export const defaultLatLong = { lat: 34.02467809739772, lng: -118.21411074835126 };

export const rendererOptions = { suppressMarkers: true };

export const googleMapLanguage = "en";
export const googleMapRegion = "US";
export const googleMapDefaultZoom = 13;
export const googleMapDefaultZoomRoute = 12;
export const latLongPrecision = 6;
export const availableEV = 'A';
export const notAvailableEV = 'N';
export const primaryEV = 'P';

export const AvailableEVStationMapMarker = ({lat,lng}: any) => <div >{<img src={Images.ic_edit_location_available} />}</div>;
export const NotAvailableEVStationMapMarker = ({lat,lng}: any) => <div >{<img src={Images.ic_edit_location_not_available} />}</div>;
export const PrimaryEVStationMapMarker = ({lat,lng}: any) => <div >{<img src={Images.ic_edit_location_primary_depot} />}</div>;