export const FETCH_FLEET_ACTION = 'FETCH_FLEET_ACTION';
export const FETCH_FLEET_DATA_ACTION = 'FETCH_FLEET_DATA_ACTION';



export const fetchFleetAction = (value: any) => {
    return {
        type: FETCH_FLEET_ACTION,
        payload: value
    }
};

export const fetchFleetDataAction = (value: any) => {
    return {
        type: FETCH_FLEET_DATA_ACTION,
        payload: value
    }
};
