import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'
import Images from '../../../../assets/images';
import { DataGrid } from '@material-ui/data-grid';
import ApiEndPoint from '../../../../services/apiUrl.json';
import { getMethod } from '../../../../services/api';
import { pageDetails } from '../../../../common/common';
import moment from 'moment';
import CommonPagination from '../../../../components/commonPagination/commonPagination'
import CommonDownloadCsv from '../../../../components/commonDownloadCsv/commonDownloadCsv';
import { dateFormats } from '../../../../common/commonDateFormats';

const EvseDepotAlerts = (props: any) => {
  const pageSize = pageDetails.pageSize;
  const [depotAlert, setDepotAlert] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(0);
  const [pageData, setPageData] = useState('');
  const [depotDownloadCsv, setDepotDownloadCsv] = useState();

  const fileHeader =[
    {label: 'TIME',key: 'alertTime'},
    {label: 'PORT',key: 'portInformation'},
    {label: 'ALERT',key: 'alert'}
    
  ];
 
  const fileName= 'DepotAlerts.csv'
  const alertcolumns = [
    {
      field: "e",
      headerName: "e",
      headerClassName: "empty",
      sortable: false,
      hasFocus: false,
      cellClassName: "empty",
      width: 15,
      maxWidth: 25,
      minWidth: 28,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <div className="empty">
            {cellValues.value}
          </div>
        );
      }
    },
    {
      field: "alertTime",
      headerName: "TIME",
      sortable: false,
      hasFocus: false,
      cellClassName: "bold_cell_text",
      width: 200,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <div className="alert_unread_mark">
            <span className="unread dnone"></span>
            {cellValues.value}
          </div>
        );
      }
      
    },
    {
      field: 'portInformation',
      headerName: 'PORT',
      sortable: false,
      width: 200,
      disableColumnMenu: true,
    },
    {
      field: 'alert',
      headerName: 'ALERT',
      sortable: false,
      minWidth: 200,
      flex: 0.2,
      disableColumnMenu: true
    },
    {
      field: "ee",
      headerName: "ee",
      headerClassName: "empty",
      sortable: false,
      hasFocus: false,
      cellClassName: "empty",
      width: 15,
      maxWidth: 25,
      minWidth: 28,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <div className="empty">
            {cellValues.value}
          </div>
        );
      }
    },
  ];

  useEffect(() => {
    getDepotAlert()
  }, [currentPage])
  useEffect(() => {
    if(depotAlert?.totalAlerts >0){
    getDepotDownloadCsv();
  }
  }, [depotAlert?.totalAlerts]);
  useEffect(() => {
    handlePageData()
  }, [depotAlert])

  const getDepotAlert = async () => {
    const page = currentPage;
    const size = pageSize;
    await getMethod(ApiEndPoint.depotInformation, props?.depotId + "/alerts" + "?page=" + page + "&size=" + size).then((res) => {
      setDepotAlert(res.data)
    }).catch((err) => {
      console.log("Error", err)
    })
  }

  const getDepotDownloadCsv = async () => {
    await getMethod(ApiEndPoint.depotInformation, props?.depotId + "/alerts" + "?page=" + 0 + "&size=" + depotAlert.totalAlerts).then((res) => {
      setDepotDownloadCsv(res.data);
      }).catch((err) => {
        console.log('Error', err);
      });
  };

  const alertDataSet = (value: any) => {
    let alertTableData = []
    if (value && value.alertsList) {
      alertTableData = value.alertsList.map((val: any) => ({ ...val, e: 'a', ee: 'aa', id: val.alertId,alertTime: moment(val.alertTime).format(dateFormats.dateTimeFormat),}))
    }
    return alertTableData
  }

  const handlePageData = () => {
    let totalPage = depotAlert && depotAlert.totalAlerts ? depotAlert.totalAlerts : 0;
    if (totalPage > 0) {
      let setPageValue = (currentPage >= 1 ? (pageSize * currentPage) + 1 : 1) + ' - ' + ((pageSize * (currentPage + 1)) < totalPage ? (pageSize * (currentPage + 1)) : totalPage) + ' of '
      setPageData(setPageValue)
    }
  }

  const handleCurrentPage = (setPage: any) => {
      setCurrentPage(setPage)    
  }

  return (
    <>
      <div className="table_scroll">
        <div className="defult_table" style={{ width: '100%' }}>
          <DataGrid autoHeight className="table"
            rowHeight={59}
            headerHeight={30}
            rows={alertDataSet(depotAlert)}
            columns={alertcolumns}
            pageSize={pageSize}
            disableSelectionOnClick />
        </div>

        <div className="table_footer">
          <Grid container direction="row" alignItems="center" spacing={0}>
            <Grid className="text_left" item xs={8}>
              {depotAlert && depotAlert.totalAlerts > pageSize ?
               <CommonPagination 
                  currentPage = {currentPage}
                  totalCount = {depotAlert?.totalAlerts}
                  callBackCurrentPage = {handleCurrentPage}
               /> : <></>}
              <Button className="btn_white">Clear New Alerts</Button>
              <CommonDownloadCsv {...props}
                datas={alertDataSet(depotDownloadCsv)}
                headers={fileHeader}
                filename={fileName}
                callBackDownloadCsv={getDepotDownloadCsv}/>
            </Grid>
            <Grid className="text_right" item xs={4}>
              <div className="list_number">{pageData} {depotAlert.totalAlerts ? (depotAlert.totalAlerts + ' Alerts') : ''}</div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
export default EvseDepotAlerts;