import React, {useEffect, useState} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import "react-image-gallery/styles/css/image-gallery.css";
import Images from 'assets/images';
import {getMethod} from "services/api";
import ApiEndPoint from "services/apiUrl.json";
import GoogleMapReact from "google-map-react";
import DestinationMapMarker from "common/destinationMapMarker";
import VehicleDetailMapMarker from "common/vehicleDetailMapMarker";
import '../vehicleDetail.scss';
import evStationData from 'data/evStations.json';
import destinationsStubData from 'data/destinations.json';
import locationButtonMap from "common/currentLocation";
import moment from 'moment';
import environment from "environment";
import {
    availableEV,
    AvailableEVStationMapMarker,
    defaultLatLong,
    googleMapDefaultZoomRoute,
    googleMapLanguage,
    googleMapRegion,
    notAvailableEV,
    NotAvailableEVStationMapMarker,
    primaryEV,
    PrimaryEVStationMapMarker,
    rendererOptions
} from "common/mapConstants";

const defaultTotalDurationDistance: { totalDuration: string; totalEnergy: string; totalDistance: string; distanceDurationList: any; waypoint_order: any } = {
    totalDistance: '0',
    totalDuration: '0',
    totalEnergy: '0',
    distanceDurationList: [],
    waypoint_order: []
};

let totalDurationDistance = defaultTotalDurationDistance;

function GoogleMapRoutesDestination(props: any){
    const { children, destinations, vehicleDetails, optimized, isAvailableEVMarkers,isNotAvailableEVMarkers,viewStops,isPrimaryEVMarkers, ...other } = props;
    const stationList = evStationData;

    let distance = 0;
    let duration = 0;

    const getMapOptions = (maps: any) => {
        return {
            streetViewControl: false,
            scaleControl: true,
            mapTypeControl: true,
            mapTypeId: maps.MapTypeId.ROADMAP,
            mapTypeControlOptions: {
                style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: maps.ControlPosition.TOP_RIGHT,
                mapTypeIds: [
                    maps.MapTypeId.ROADMAP,
                    maps.MapTypeId.SATELLITE,
                    maps.MapTypeId.HYBRID
                ]
            },

            zoomControl: true,
            clickableIcons: true
        };
    }

    const convertTotalDuration = (totalDuration: any) => {
        const hours = Math.floor(moment.duration(totalDuration,'minutes').asHours());
        const mins = moment.duration(totalDuration,'minutes').minutes();
        return (hours > 0 ? hours + ' hr ': '') + (mins > 0 ? mins + ' min ': '');
    }

    const totalDistanceDuration = (routes: any) => {
        let totalDistance = '0';
        let totalDuration = 0;
        const legs = routes.legs;
        let mi = 0;
        let ft = 0;
        for(let i=0; i<legs.length-1; i++) {
            const distArr = legs[i].distance.text.split(' ');
            if(distArr[1] === 'mi'){
                mi +=  Number(distArr[0]);
            }
            totalDuration += Number(legs[i].duration.text.split(' ')[0]);
        }

        totalDistance = (ft === 0)? ((String(mi.toFixed(1)).split('.')[1] !== '0')?mi.toFixed(1):mi.toFixed(0)) + ' miles' :  (mi + ' miles ');
        return {
            totalDistance: totalDistance,
            totalDuration: convertTotalDuration(totalDuration),
            totalEnergy: 'Est. ' + (mi/4).toFixed(1) + ' kWh',
            distanceDurationList: legs,
            waypoint_order: routes.waypoint_order
        }
    }

    const  apiIsLoaded = (map: any, maps: any, destinations: [{ address:{latitude:string,longitude:string} }]) => {
        const directionsService = new window.google.maps.DirectionsService();
        const directionsRenderer = new window.google.maps.DirectionsRenderer(rendererOptions);
        const infoWindow = new maps.InfoWindow();

        map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(locationButtonMap(infoWindow,map));

        directionsRenderer.setMap(map);
        const origin = { lat: vehicleDetails.latitude, lng: vehicleDetails.longitude };
        const destination : any = { lat: destinations[destinations.length-1].address.latitude, lng: destinations[destinations.length-1].address.longitude };
        let waypoints: any = [];

        destinations.map(destination => {
            waypoints.push({
                location: {
                    lat: destination.address.latitude,
                    lng: destination.address.longitude,
                },
                stopover: true,
            })
        });

        if(!viewStops){
            directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    waypoints: waypoints,
                    travelMode: google.maps.TravelMode.DRIVING,
                    optimizeWaypoints: optimized,
                },
                (result: any, status: string) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        directionsRenderer.setDirections(result);
                        const point = result.routes[ 0 ].legs[ 0 ];
                        distance = point.distance.text;
                        duration = point.duration.text;
                        totalDurationDistance =  totalDistanceDuration(result.routes[ 0 ]);
                        props.onChangeOfDistance(totalDurationDistance);
                        console.log('totalDurationDistance '+ totalDurationDistance);
                    } else {
                        console.error(`error fetching directions ${result}`);
                    }
                }
            );
        }

    };
    return  (
        (vehicleDetails) ? (<GoogleMapReact
            bootstrapURLKeys={{
                key: environment.googleMapKey,
                language: googleMapLanguage,
                region: googleMapRegion
            }}
            defaultCenter={{ lat: vehicleDetails.latitude, lng: vehicleDetails.longitude }}
            defaultZoom={googleMapDefaultZoomRoute}
            options={getMapOptions}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, destinations)}
        >
            {
                (vehicleDetails && vehicleDetails.vehicleId) ?
                    <VehicleDetailMapMarker {...props} key={vehicleDetails.id} lat={vehicleDetails.latitude}
                                            lng={vehicleDetails.longitude} text={vehicleDetails.vehicleId}
                                            tooltip={vehicleDetails.vehicleId} $hover={vehicleDetails.vehicleId}
                                            soc={vehicleDetails.soc}
                    /> :''
            }
            {
                stationList && stationList.length > 0 && stationList.map((value) => {
                    if(isAvailableEVMarkers.avState && value.status === availableEV){
                        return (
                            <AvailableEVStationMapMarker lat={value.latitude} lng={value.longitude} key={value.latitude} />
                        );
                    } else  if(isNotAvailableEVMarkers.avState && value.status === notAvailableEV){
                        return (
                            <NotAvailableEVStationMapMarker lat={value.latitude} lng={value.longitude} key={value.latitude}/>
                        );
                    } else  if(isPrimaryEVMarkers.avState && value.status === primaryEV){
                        return (
                            <PrimaryEVStationMapMarker lat={value.latitude} lng={value.longitude} key={value.latitude}/>
                        );
                    }
                })
            }
            {
                destinations && destinations.map((value: any) => {
                    return (
                        <DestinationMapMarker key={value.id} lat={value.address.latitude} lng={value.address.longitude}
                                              text={value.order} tooltip={value.name} $hover={value.name} />
                    );
                })

            }
        </GoogleMapReact>) : (<GoogleMapReact
            bootstrapURLKeys={{
                key: environment.googleMapKey,
                language: googleMapLanguage,
                region: googleMapRegion
            }}
            defaultCenter={defaultLatLong}
            defaultZoom={googleMapDefaultZoomRoute}
            yesIWantToUseGoogleMapApiInternals/>)
    )
}

GoogleMapRoutesDestination.propTypes = {
    children: PropTypes.node,
    destinations: PropTypes.any.isRequired,
    vehicleDetails: PropTypes.any.isRequired,
    optimized: PropTypes.any.isRequired,
    isAvailableEVMarkers: PropTypes.any.isRequired,
    isNotAvailableEVMarkers: PropTypes.any.isRequired,
    isPrimaryEVMarkers: PropTypes.any.isRequired,
    viewStops: PropTypes.any.isRequired,
    onChangeOfDistance: PropTypes.any
};

const VehicleRoutes = (props: any) => {
    const { children, currentVehicleId , ...other } = props;
    const [routes, setRoutes] = useState<any>();
    const [destinations, setDestinations] = useState<any>();
    const [vehicleDetails, setVehicleDetails] = useState();
    const [viewStops, setViewStops] = React.useState({isViewStops: true});
    const [selectedView, setSelectedView] = React.useState(1);
    const defaultSelect =1;
    const [selectedRoute, setSelectedRoute] = React.useState(defaultSelect);
    const [vehicleTotalDurationDistance, setVehicleTotalDurationDistance] = React.useState(totalDurationDistance);
    const isActive = true;
    const [center, setCenter] = useState<any>();
    const [vehicleId , setVehicleId] = useState(currentVehicleId);

    const [toggleDest, setToggleDest] = useState<any>();
    const [toggleRouteSelection, setToggleRouteSelection] = useState<any>();
    const [optimized, setOptimized] = React.useState({opState: false, viewStops: true});
    const [availableStMarkers, setAvailableStMarkers] = React.useState({avState: true});
    const [notAvailableStMarkers, setNotAvailableStMarkers] = React.useState({avState: true});
    const [primaryStMarkers, setPrimaryStMarkers] = React.useState({avState: true});
    const [isExpanded, setExpanded] = React.useState(isActive);
    const destinationsStub = destinationsStubData;
    const callToDestinationsApi = true;
    const [destinationsValue, setDestinationsValue] = useState<any>();


    React.useEffect(() => {
        setToggleDest(true);
        setToggleRouteSelection(true);
        setExpanded(isActive);
    }, [isActive, setExpanded]);

    React.useEffect(() => {
        setVehicleTotalDurationDistance(totalDurationDistance);
    }, [totalDurationDistance]);


    const handleRouteSelection = (event: any) => {
        setSelectedRoute(event.target.value);
        handleOptimizeRoutes({target:{value:1}});
    }

    const handleOptimizeRoutes = (event: any) => {
        setSelectedView(event.target.value);
        setVehicleTotalDurationDistance(defaultTotalDurationDistance);
        if(event.target.value === 1 ){
            setViewStops({isViewStops: true});
            setOptimized({opState: false, viewStops: true});
        } else {
            setViewStops({isViewStops: false});
            if(event.target.value === 2 ){
                setOptimized({opState: false, viewStops: false});
            }else if (event.target.value === 3){
                setOptimized({opState: true, viewStops: false});
            }
        }

        setCenter({ lat: destinationsValue.latitude, lng: destinationsValue.longitude });

    }

    const handleAvailableMarkers = (event: any) => {
        setAvailableStMarkers({avState: !availableStMarkers.avState});
    }

    const handleNotAvailableMarkers = (event: any) => {
        setNotAvailableStMarkers({avState: !notAvailableStMarkers.avState});
    }

    const handlePrimaryMarkers = (event: any) => {
        setPrimaryStMarkers({avState: !primaryStMarkers.avState});
    }


    const [isVisible, setIsVisible] = useState(true);

    const listenToScroll = () => {
        let heightToHideFrom = 50;
        const winScroll = document.body.scrollTop ||
            document.documentElement.scrollTop;
        if (winScroll > heightToHideFrom) {
            isVisible &&      // to limit setting state only the first time
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    };

    const collapseDestinationList = () => {
        setToggleDest(!toggleDest);
    }

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () =>
            window.removeEventListener("scroll", listenToScroll);
    }, [])

    useEffect(() => {
        getVehicleRoutes();
        getVehicleDetails();
    }, []);

    useEffect(()=>{
        if(routes && routes?.length > 0){
            getVehicleDestinations();
            if(destinationsValue){
                setCenter({ lat: destinationsValue.latitude, lng: destinationsValue.longitude });
            }

        }
    },[routes, selectedRoute]);

    const getVehicleRoutes = async () => {
        await getMethod(ApiEndPoint.vehicleService, vehicleId + "/routes").then((res) => {
            if(res && res.data && res.data.length > 0) {
                res.data.sort((routes1: any, routes2: any) => (routes1.id > routes2.id) ? 1 : -1)
                setSelectedRoute(res.data[0].id);
                setRoutes(res.data);
            }

            if(res.data.length === 0){
                setToggleDest(false);
                setToggleRouteSelection(false);
            }
        }).catch((err) => {
            console.log("Error", err)
        })
    }

    const getVehicleDestinations = async () => {
        if(callToDestinationsApi){
            await getMethod(ApiEndPoint.routesInformation, selectedRoute + "/destinations?sortBy=order").then((res) => {
                setDestinations(res.data);
                const dtVal = destinations && destinations?.length >= 1 && destinations[0] ? destinations[0].address : [];
                setDestinationsValue(dtVal);
            }).catch((err) => {
                console.log("Error", err)
            })
        } else {
            setDestinations(destinationsStub);
        }
    }

    const handleDurationChange = (totalDuration: any) => {
        setVehicleTotalDurationDistance(totalDuration);
        console.log(vehicleTotalDurationDistance);
    }

    const computeDistanceDuration = ( distanceObj: any) => {
        const distance = distanceObj.distance.text;
        const duration = distanceObj.duration.text;
        return duration+ ' (' + distance + ')';
    }

    const getVehicleDetails = async () => {
        await getMethod(ApiEndPoint.vehicleService, vehicleId).then((res) => {
            setVehicleDetails(res.data);
        }).catch((err) => {
            console.log("Error", err)
        })
    }


    return (
        <div className="information_section map">
            <div className="map_action_row">
                <Grid container  direction="row" justifyContent="space-between" alignItems="flex-start">
                    <Grid className="text_left" item xs={12} sm={12} md={3}>
                        <div className="select_location">
                            {(toggleRouteSelection) && <FormControl className="select_control">
                                <Select
                                    labelId="routes-label"
                                    id="routes-select"
                                    name="routes"
                                    onChange={handleRouteSelection} value={selectedRoute}
                                >
                                    {
                                        routes && routes?.length > 0 && routes?.map((value: { id: React.Key | readonly string[] | null | undefined; name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; stops: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }) => (
                                            // @ts-ignore
                                            // @ts-ignore
                                        <MenuItem className="optionlist_dest" key={value.id} value={value.id}>
                                            {value.name}  <span className="low_font"> ({value.stops} stops)</span>
                                        </MenuItem>))}
                                </Select>

                            </FormControl>   }

                        </div>

                    </Grid>

                    <Grid className="text_right" item xs={12} sm={12} md={9}>
                        <div className="view_stop">
                             {(toggleRouteSelection) &&  <FormControl className="select_control">
                                 <Select labelId="demo-simple-select-label" id="demo-simple-select" onChange={handleOptimizeRoutes} value={selectedView}>
                                     <MenuItem className="optionlist" value={1} id={'viewStops'}>
                                         View stops</MenuItem>
                                     <MenuItem className="optionlist" value={2} id={'routeSites'}>
                                         Route sites</MenuItem>
                                     <MenuItem className="optionlist" value={3} id={'optimizeRoutes'}>
                                         Optimize route</MenuItem>
                                 </Select>
                             </FormControl>}

                        </div>
                        <div className="map_legend">
                            <Checkbox
                                defaultChecked
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                onChange={handleAvailableMarkers}
                            />
                            <img src={Images.ic_edit_location_available} alt="Available" /> Available</div>
                        <div className="map_legend">
                            <Checkbox
                                defaultChecked
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                onChange={handleNotAvailableMarkers}
                            />
                            <img src={Images.ic_edit_location_not_available} alt="Available" /> Not Available</div>
                        <div className="map_legend">
                            <Checkbox
                                defaultChecked
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                onChange={handlePrimaryMarkers}
                            />
                            <img src={Images.ic_edit_location_primary_depot} alt="Available" />
                            Primary Depot</div>
                    </Grid>
                </Grid>
            </div>
            <div className="map_container destination_section">

                <div className={(toggleDest) ? 'destination_list_block': 'widthDestMain'} >
                    <div className="collaps_action" onClick={() => {collapseDestinationList()}}>
                        <img src={Images.ic_ic_arrow_drop_up} alt="Back" />
                    </div>
                    {(!toggleDest)
                    &&
                    <div className="collapsed_destinations">
                        <div className="collaps_action_dest" onClick={() => {collapseDestinationList()}}>
                            <img src={Images.ic_arrow_drop_down} alt="Back" />
                        </div>
                    </div>
                    }

                    <div className={(toggleDest)?"destination_head":'d-none'}>
                        <img src={Images.ic_directions} alt="Destination"/>
                        Destinations
                    </div>
                    <div className={(!viewStops.isViewStops && vehicleTotalDurationDistance.totalDistance !== '0' && toggleDest)?"destination_subhead":'d-none'}>
                        <div className="destination_time"> {vehicleTotalDurationDistance.totalDuration}</div>
                        <Grid container>
                            <Grid className="destination_dis" item xs={6} sm={6} md={6}>
                                {vehicleTotalDurationDistance.totalDistance}
                            </Grid>
                            <Grid className="destination_dis text_right" item xs={6} sm={6} md={6}>
                                {vehicleTotalDurationDistance.totalEnergy}
                            </Grid>
                        </Grid>
                    </div>

                    <div className={(toggleDest)?"destination_list":'d-none'}>
                        <List>
                            {
                                viewStops.isViewStops && destinations && destinations?.map((value: any, index: any) => (
                                <ListItem key={index}>
                                    <ListItemIcon className="des_numb_icon">
                                        <span>{value.order}</span>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <div className="title">{value.name}</div>
                                        <div className="address">
                                            {value.address.addressLine1 +
                                            ((value.address.addressLine2 !== undefined && value.address.addressLine2 !== '') ?
                                                " , " + value.address.addressLine2 : '')
                                            + " , " + value.address.city + "," + value.address.state + " "
                                            + value.address.postalCode
                                            }
                                        </div>
                                    </ListItemText>
                                </ListItem>
                            ))
                            }

                            {!viewStops.isViewStops && destinations && destinations?.length > 0 &&
                            vehicleTotalDurationDistance.totalDistance !== '0' &&
                            vehicleTotalDurationDistance.waypoint_order.length > 0 &&
                            vehicleTotalDurationDistance.waypoint_order.map((value: any, index:any) => (
                                <ListItem key={index}>
                                    <ListItemIcon className="des_numb_icon">
                                        <span>{destinations[value].order}</span>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <div className="title">{destinations[value].name}</div>
                                        <div className="address">
                                            {destinations[value].address.addressLine1 +
                                            ((destinations[value].address.addressLine2 !== undefined && destinations[value].address.addressLine2 !== '') ?
                                                " , " + destinations[value].address.addressLine2 : '')
                                            + " , " + destinations[value].address.city + "," + destinations[value].address.state + " "
                                            + destinations[value].address.postalCode
                                            }
                                        </div>
                                        <div className="dis"> {computeDistanceDuration(vehicleTotalDurationDistance.distanceDurationList[index]) } </div>
                                    </ListItemText>
                                </ListItem>
                            ))}

                        </List>
                    </div>

                </div>

                <div className="route_map" >
                    {(destinations && vehicleDetails)?(<div style={{ height: '100%', width: '100%' }}>
                        {(optimized.opState && !optimized.viewStops) && <GoogleMapRoutesDestination optimized={true} destinations={destinations} vehicleDetails={vehicleDetails} isAvailableEVMarkers={availableStMarkers} isNotAvailableEVMarkers={notAvailableStMarkers} isPrimaryEVMarkers={primaryStMarkers} viewStops={false} onChangeOfDistance={handleDurationChange}/>}
                        {(!optimized.opState && !optimized.viewStops) && <GoogleMapRoutesDestination optimized={false} destinations={destinations} vehicleDetails={vehicleDetails} isAvailableEVMarkers={availableStMarkers} isNotAvailableEVMarkers={notAvailableStMarkers} isPrimaryEVMarkers={primaryStMarkers} viewStops={false} onChangeOfDistance={handleDurationChange}/>}
                        {(!optimized.opState && optimized.viewStops) && <GoogleMapRoutesDestination optimized={false} destinations={destinations} vehicleDetails={vehicleDetails} isAvailableEVMarkers={availableStMarkers} isNotAvailableEVMarkers={notAvailableStMarkers} isPrimaryEVMarkers={primaryStMarkers} viewStops={true} onChangeOfDistance={handleDurationChange}/>}
                    </div>):
                        <GoogleMapReact
                            bootstrapURLKeys={{
                                key: environment.googleMapKey,
                                language: googleMapLanguage,
                                region: googleMapRegion
                            }}
                            defaultCenter={defaultLatLong}
                            defaultZoom={googleMapDefaultZoomRoute}
                            yesIWantToUseGoogleMapApiInternals>
                            {
                                evStationData && evStationData.map((value) => {
                                    if(availableStMarkers.avState && value.status === availableEV){
                                        return (
                                            <AvailableEVStationMapMarker lat={value.latitude} lng={value.longitude} key={value.latitude}/>
                                        );
                                    } else  if(availableStMarkers.avState && value.status === notAvailableEV){
                                        return (
                                            <NotAvailableEVStationMapMarker lat={value.latitude} lng={value.longitude} key={value.latitude}/>
                                        );
                                    } else  if(availableStMarkers.avState && value.status === primaryEV){
                                        return (
                                            <PrimaryEVStationMapMarker lat={value.latitude} lng={value.longitude} key={value.latitude}/>
                                        );
                                    }
                                })
                            }
                        </GoogleMapReact>
                    }
                </div >
            </div>
        </div>
    );
}
VehicleRoutes.propTypes = {
    children: PropTypes.node,
    currentVehicleId: PropTypes.any.isRequired,
}

export  default VehicleRoutes;