import './fleetConfigureSetting.scss';
import React from 'react';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Images from '../../assets/images';


const FleetConfigureSetting = () => {
  return (
    <>
      <List >
        <ListItem className="fleet_edit_item" button>
          <ListItemIcon>
            <img src={Images.IC_EVSE_BLUE} alt="Edit EVSE" />
          </ListItemIcon>
                            Edit EVSE
                        </ListItem>
        <ListItem className="fleet_edit_item" button>
          <ListItemIcon>
            <img src={Images.IC_AIRPORT_SHUTTLE_BLUE} alt="Edit Vehicle" />
          </ListItemIcon>
                            Edit Vehicle
                        </ListItem>
        <ListItem className="fleet_edit_item" button>
          <ListItemIcon>
            <img src={Images.IC_DRIVER} alt="Edit Driver" />
          </ListItemIcon>
                            Edit Driver
                        </ListItem>
        <ListItem className="fleet_edit_item" button>
          <ListItemIcon>
            <img src={Images.IC_RFID} alt="Edit RFID" />
          </ListItemIcon>
                            Edit RFID
                        </ListItem>
        <ListItem className="fleet_edit_item" button>
          <ListItemIcon>
            <img src={Images.IC_ROUTE} alt="Edit Route" />
          </ListItemIcon>
                            Edit Route
                        </ListItem>
        <ListItem className="fleet_edit_item" button>
          <ListItemIcon>
            <img src={Images.IC_UTILITIES} alt="Utility Setting" />
          </ListItemIcon>
                            Utility Setting
                        </ListItem>
        <ListItem className="create_fleet_btn" button>
          Create New Fleet
                        </ListItem>
      </List>
    </>
  )
}

export default FleetConfigureSetting;
