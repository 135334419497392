import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {getMethod} from "services/api";
import ApiEndPoint from "services/apiUrl.json";
import {pageDetails} from "common/common";
import {googleMapLanguage,googleMapRegion,rendererOptions} from "common/mapConstants";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Images from "assets/images";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {DataGrid} from "@material-ui/data-grid";
import moment from "moment";
import GoogleMapReact from "google-map-react";
import locationButtonMap from "common/currentLocation";
import  {getMapOptions} from "common/mapMethods";
import tripDestinations from "data/tripDestinations.json";
import environment from "environment";

const TripMarker: any = ({}) => <div >{<img src={Images.ic_edit_location_not_available} />}</div>;

function GoogleMapTripHistory(props:any){
    const { children, destinations, ...other } = props;

    const defaultTripLatLong = { lat: destinations[0].latitude, lng: destinations[0].longitude };

    const  apiIsLoaded = (map: any, maps: any, destinations: any[]) => {

        const directionsService = new window.google.maps.DirectionsService();
        const directionsRenderer = new window.google.maps.DirectionsRenderer(rendererOptions);
        const infoWindow = new maps.InfoWindow();

        map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(locationButtonMap(infoWindow,map));

        directionsRenderer.setMap(map);

        // Below consts are for temporary testing of google maps direction service
        const origin = { lat: destinations[0].latitude, lng: destinations[0].longitude };
        const destination = { lat: destinations[destinations.length-1].latitude, lng: destinations[destinations.length-1].longitude };
        let waypoints: any = [];

        destinations.map(destination => {
            waypoints.push({
                location: {
                    lat: destination.latitude,
                    lng: destination.longitude,
                },
                stopover: true,
            })
        });

        directionsService.route(
            {
                origin: origin,
                destination: destination,
                waypoints: waypoints,
                travelMode: google.maps.TravelMode.DRIVING,
                optimizeWaypoints: true,
            },
            (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    directionsRenderer.setDirections(result);
                } else {
                    console.error(`error fetching directions ${result}`);
                }
            }
        );

    };
    return  (
        (destinations && (destinations != null)) ? (<GoogleMapReact
            bootstrapURLKeys={{
                key: environment.googleMapKey,
                language: googleMapLanguage,
                region: googleMapRegion
            }}
            defaultCenter={defaultTripLatLong}
            defaultZoom={15}
            options={getMapOptions}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, destinations)}
        >
            {
                destinations && destinations.map((value: any) => {
                    return (
                        <TripMarker lat={value.latitude} lng={value.longitude} key={value.latitude}/> );
                })

            }
        </GoogleMapReact>) : (<GoogleMapReact
            bootstrapURLKeys={{
                key: environment.googleMapKey,
                language: googleMapLanguage,
                region: googleMapRegion
            }}
            defaultCenter={defaultTripLatLong}
            defaultZoom={12}
            yesIWantToUseGoogleMapApiInternals/>)
    )
}

GoogleMapTripHistory.propTypes = {
    children: PropTypes.node,
    destinations: PropTypes.any.isRequired
};


const VehicleTripHistory  = (props: any) => {
    const { children, currentVehicleId , ...other } = props;
    const [trips, setTrips] = useState<any>();
    const [tripDetails, setTripDetails] = React.useState<any>({});
    const [tripDetailsList, setTripDetailsList] = React.useState<any>({});
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState<any>('paper');
    const pageSize = pageDetails.pageSize;
    const [currentPage, setCurrentPage] = useState(0);
    const [tripHistoryLastPage, setTripHistoryLastPage] = useState(0);
    const [tripsPageData, setTripsPageData] = useState('');

    const vehicleTripColumns = [

        {
            field: "e",
            headerName: "e",
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            //flex:0.1,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <div className="empty">
                        {cellValues.value}
                    </div>
                );
            }
        },

        {
            field: 'startTime',
            headerName: 'START',
            sortable: false,
            flex: 0.1,
            minWidth: 150,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <>
                        {cellValues.value ? moment(cellValues.value).format("MMM D, YYYY; h:mmA") : '-'}
                    </>
                );
            }
        },
        {
            field: 'endTime',
            headerName: 'END',
            sortable: false,
            minWidth: 150,
            flex: 0.1,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <>
                        {cellValues.value ? moment(cellValues.value).format("MMM D, YYYY; h:mmA") : '-'}
                    </>
                );
            }
        },

        {
            field: 'driverName',
            headerName: 'DRIVER',
            sortable: false,
            flex: 0.1,
            minWidth: 100,
            disableColumnMenu: true
        },
        {
            field: 'driverId',
            headerName: 'DRIVER ID',
            sortable: false,
            //flex: 0.1,
            width:130,
            disableColumnMenu: true
        },
        {
            field: 'distance',
            headerName: 'DISTANCE (Mi)',
            sortable: false,
            flex: 0.1,
            minWidth: 120,
            disableColumnMenu: true
        },
        {
            field: 'odometer',
            headerName: 'ODOMETER',
            sortable: false,
            flex: 0.1,
            //width: 70,
            disableColumnMenu: true
        },
        {
            field: 'energy',
            headerName: 'ENERGY (kWh)',
            sortable: false,
            width: 120,
            //flex: 0.1,
            disableColumnMenu: true
        },
        {
            field: "ee",
            headerName: "ee",
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            //flex:0.1,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <div className="empty">
                        {cellValues.value}
                    </div>
                );
            }
        },

    ];

    const tripDetailcolumns = [

        {
            field: "e",
            headerName: "e",
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 20,
            //flex:0.1,
            minWidth: 20,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <div className="empty">
                        {cellValues.value}
                    </div>
                );
            }
        },

        {
            field: "timestamp",
            headerName: "TIME",
            sortable: false,
            hasFocus: false,
            width: 200,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <>
                        {cellValues.value ? moment(cellValues.value).format("MMM D, YYYY; h:mmA") : '-'}
                    </>
                );
            }
        },


        {
            field: 'distance',
            headerName: 'DISTANCE (mi)',
            sortable: false,
            minWidth: 110,
            flex:0.1,
            disableColumnMenu: true
        },

        {
            field: 'odometer',
            headerName: 'ODOMETER (mi)',
            sortable: false,
            minWidth: 100,
            flex:0.1,
            disableColumnMenu: true
        },

        {
            field: 'energy',
            headerName: 'ENERGY (kWh)',
            sortable: false,
            width: 120,
            //flex:0.1,
            disableColumnMenu: true
        },

        {
            field: "ee",
            headerName: "ee",
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 20,
            minWidth: 20,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <div className="empty">
                        {cellValues.value}
                    </div>
                );
            }
        },

    ];
    useEffect(() => {
        getVehicleTrips();
    }, []);

    useEffect(() => {
        handleTripHistoryPageData()
    }, [trips])

    const vehicleTripsData = (value: any) => {
        let tripsData = []
        if (value && value?.trips) {
            tripsData = value.trips.map((val: any) => ({ ...val, e: 'a', ee: 'aa', id: val.id }))
        }
        return tripsData
    }

    const vehicleTripDetailsList = (value: any) => {
        let tripsList = []
        if (value && value?.tripDetails) {
            tripsList = value.tripDetails.map((val: any,index: any) => ({ ...val, e: 'a', ee: 'aa', id: (index+1), latitude: Number(val.latitude.toFixed(6)), longitude: Number(val.longitude.toFixed(6)) }))
        }
        return tripsList
    }


    const getTripDetails = (value: any) => {
        let tripsDetail = [];
        if (trips && trips?.trips) {
            tripsDetail = trips.trips.filter((trip: any) => trip.id === value).map((val: any) => ({ ...val, e: 'a', ee: 'aa', id: val.id }))
        }
        return tripsDetail[0];
    }

    const handleTripDetailOpen = (scrollType: any, ev: any) => {
        setOpen(true);
        setScroll(scrollType);
        setTripDetails(getTripDetails(ev.row.id));
        getVehicleTripDetailsList(ev.row.id);
    };

    const getVehicleTripDetailsList = async (tripId: any) => {
        await getMethod(ApiEndPoint.vehicleService,   "/trips/"+tripId+"/details").then((res) => {
            setTripDetailsList(res.data);
            console.log(tripDetailsList);
        }).catch((err) => {
            console.log("Error", err)
        })
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleTripHistoryPageData = () => {
        let totalTripPage = (trips && trips?.totalTrips) ? trips?.totalTrips : 0;
        if (totalTripPage > 0) {
            let setTripPageValue = ((currentPage >= 1) ? (pageSize*currentPage) + 1 : 1) + ' - ' +
                ((pageSize*(currentPage+1)) < totalTripPage ? (pageSize*(currentPage+1)) : totalTripPage  ) + ' of '
            setTripsPageData(setTripPageValue);
        }
    }

    const handleMoveStart = () => {
        setCurrentPage(0)
    }

    const handleMoveLeft = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1)
        }
    }

    const handleTripHistoryMoveRight = () => {
        let totalPage = (trips && trips.trips) ? trips.trips : 0;
        if (totalPage > 0) {
            setTripHistoryLastPage(Math.ceil(totalPage / pageSize))
            if ((currentPage + 1) < Math.ceil(totalPage / pageSize)) {
                setCurrentPage(currentPage + 1)
            }
        }
    }

    const handleTripHistoryMoveEnd = () => {
        setCurrentPage(tripHistoryLastPage ? tripHistoryLastPage -1  : 0)
    }

    const getVehicleTrips = async () => {
        await getMethod(ApiEndPoint.vehicleService, currentVehicleId + "/trips?page=0&size="+pageDetails.pageSize).then((res) => {
            setTrips(res.data);
        }).catch((err) => {
            console.log("Error", err)
        })
    }

    return (
        <>
            <div data-testid="VehicleList" className="dialog_box">

                <Dialog className="dialog_container"
                        open={open}
                        onClose={handleClose}
                        scroll={scroll}
                        aria-labelledby="scroll-dialog-title"
                        aria-describedby="scroll-dialog-description"
                >
                    <div className="dialog_title">
                        <h4>Trip Detail</h4>
                        <IconButton className="closed_btn full_right" onClick={handleClose}>
                            <span className="material-icons">cancel</span>
                        </IconButton>

                    </div>
                    <div data-testid="vehicleDataTest" className="dialog_body">
                        <div className="history_charging_list trip_details">
                            <Grid container>
                                <Grid item xs={5}>
                                    {(tripDetails)?  <div className="h_charge_list">
                                        <div className="label">Start Trip Time</div>
                                        <div className="result">
                                            {tripDetails?.startTime ? moment(tripDetails?.startTime).format("MMM D, YYYY; h:mmA") : '-'}
                                        </div>
                                        <div className="label">End Trip Time</div>
                                        <div className="result">{tripDetails?.endTime ? moment(tripDetails?.endTime).format("MMM D, YYYY; h:mmA") : '-'}</div>
                                        <div className="label">Driver Name</div>
                                        <div className="result">{tripDetails?.driverName}</div>
                                        <div className="label">Driver ID</div>
                                        <div className="result">{tripDetails?.driverId}</div>
                                        <div className="label">Distance (mi)</div>
                                        <div className="result">{tripDetails?.distance}</div>
                                        <div className="label">Odometer (mi)</div>
                                        <div className="result">{tripDetails?.odometer}</div>
                                        <div className="label">Energy (kWh)</div>
                                        <div className="result">{tripDetails?.energy}</div>
                                        <div className="label">Start Trip Address</div>
                                        <div className="result">{tripDetails?.startAddress}</div>
                                        <div className="label">End Trip Address</div>
                                        <div className="result">{tripDetails?.endAddress}</div>
                                    </div> :''}

                                    <div className="table_footer mt-10">
                                        <Grid container direction="row" alignItems="center" spacing={0}>
                                            <Grid className="text_left" item xs={12}>
                                                <Button className="btn_white blue_bg ml_0">Download CSV</Button>
                                            </Grid>

                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item xs={7}>
                                    {
                                       <GoogleMapTripHistory destinations={(tripDetailsList && tripDetailsList.tripDetails && tripDetailsList.tripDetails.length > 0)?tripDetailsList.tripDetails:tripDestinations.tripDestinations}/>
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    </div>

                    <DialogContent dividers={scroll === 'paper'}>
                        <DialogContentText component="div"
                            id="scroll-dialog-description"
                        >
                            <div className="dialog_data_table">
                                <h5>Trip Details</h5>
                            </div>
                            <div className="table_scroll">
                                <div className="defult_table" style={{ width: '100%' }}>
                                    <DataGrid autoHeight className="table"
                                              rowHeight={59}
                                              headerHeight={30}
                                              rows={vehicleTripDetailsList(tripDetailsList)}
                                              columns={tripDetailcolumns}
                                              pageSize={pageDetails.pageSize}
                                              disableSelectionOnClick />
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>

            <div className="table_scroll">
                <div className="defult_table" style={{ width: '100%' }} >

                    <DataGrid autoHeight className="table"
                              rowHeight={59}
                              headerHeight={30}
                              rows={vehicleTripsData(trips)}
                              columns={vehicleTripColumns}
                              pageSize={12}
                              onRowClick= {(ev) => handleTripDetailOpen('paper', ev)}
                              disableSelectionOnClick />

                </div>
                <div className="table_footer">
                    <Grid container direction="row" alignItems="center" spacing={0}>
                        <Grid data-testId='vehicleTripTest' className="text_left" item xs={8}>
                            {trips && trips?.totalTrips > pageSize ?
                                <div className="pagination">
                                    {currentPage && currentPage >= 1 ?
                                        <>
                                            <span onClick={handleMoveStart}><img src={Images.ic_first_page} alt="first page" /></span>
                                            <span onClick={handleMoveLeft}><img src={Images.ic_chevron_left} alt="left page" /></span>
                                        </> : <></>
                                    }
                                    <span>{currentPage + 1}</span>
                                    {currentPage >= 0  && (currentPage + 1) < tripHistoryLastPage ?
                                        <>
                                            <span onClick={handleTripHistoryMoveRight}><img src={Images.ic_chevron_right} alt="right page" /></span>
                                            <span onClick={handleTripHistoryMoveEnd}><img src={Images.ic_last_page} alt="last page" /></span>
                                        </> : <></>
                                    }
                                </div>
                                :<></>}
                            <Button className="btn_white">Download CSV</Button>
                        </Grid>
                        <Grid className="text_right" item xs={4}>
                            <div className="list_number">{tripsPageData} { (trips && trips.totalTrips )? trips.totalTrips + ' trips' : ''}</div>
                        </Grid>
                    </Grid>
                </div>
            </div>
          </>
    );
}

VehicleTripHistory.propTypes = {
    children: PropTypes.node,
    currentVehicleId: PropTypes.any.isRequired,
}

export default VehicleTripHistory;