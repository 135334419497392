import 'pages/vehicleDetail/vehicleDetail.scss';

const locationButtonMap = (infoWindow: any,map: any) => {
        const locationButton = document.createElement("button");
        locationButton.textContent = "";
        locationButton.classList.add("custom-map-control-button");

        locationButton.addEventListener("click", () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const pos = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };

                        infoWindow.setPosition(pos);
                        infoWindow.setContent("Location found.");
                        infoWindow.open(map);
                        map.setCenter(pos);
                    },
                    () => {
                        console.log('error');
                    }
                );

            }
        });

        return locationButton;
}

export default locationButtonMap;