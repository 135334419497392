import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Images from '../../assets/images';
import moment from 'moment';
import {
    ComposedChart,
    Line,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Scatter,
    ResponsiveContainer,
    ReferenceLine,
    CartesianAxis,
} from 'recharts';

const DepotEnergyStatsCard = (props:any) => {

    const formatXAxis = (tickItem:any) => {
        return moment(tickItem).format('M/D');
    }

    return (
        <>
            <Card className="small_card" variant="outlined">
                <CardContent className="card_content">
                    <div className="section_title">
                        <Grid container>
                            <Grid item sm={6} md={12} lg={6}>
                                <h3>Energy Stats</h3>
                            </Grid>
                            <Grid className="energy_legend" item sm={6} md={12} lg={6}>
                                <div className="legend_list">
                                    <span>&nbsp; </span>Current
                                </div>
                                <div className="legend_list">
                                    <span className="average">&nbsp; </span>Average
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="section_content">
                        <ResponsiveContainer className="no_line" width={'99%'} height={100}>
                            <BarChart
                                data={props.depotEnergyStats}
                                margin={{
                                    top: 3,
                                    right: 3,
                                    bottom: 3,
                                    left: 2,
                                }}
                            >
                                <CartesianGrid vertical={false} stroke="#E5E5E5" strokeOpacity={1} fillOpacity={0.5} />
                                <XAxis axisLine={true} tickLine={false} dataKey="dayOfWeek" style={{ fontSize: '10px', color:'#000000' }} tickFormatter={formatXAxis}/>
                                <YAxis tickMargin={-2} dy={4} unit=' KW' axisLine={false} tickLine={false} tickCount={5} interval={0} style={{ fontSize: '10px', color:'#000000' }} />
                                <Bar barSize={10} dataKey="dowLoad" fill="#CCCCCC" radius={[10, 10, 0, 0]} />
                                <Bar barSize={10} dataKey="dowAvgLoad" fill="#EEEEEE" radius={[10, 10, 0, 0]}/>
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </CardContent>
            </Card>
        </>
    )
}

export default DepotEnergyStatsCard;
