import React from "react";

const DestinationMapMarker = ({ text, tooltip, $hover,lat,lng }: any) => {
    const handleClick = () => {
    };

    return (
        <div className={$hover ? "des_numb_icon_maps" : "des_numb_icon_maps"} onClick={handleClick}>
      <span title={tooltip}>
        {text}
      </span>
        </div>
    );
};

export default DestinationMapMarker;