import Images from '../assets/images';


export const vehImageList = {
    vehImgLength: 4,

    imageList : [
        {
            id: 1,
            speedoUrl: Images.speed_meter2
        },
        {
            id: 2,
            speedoUrl: Images.speed_meter3
        },
        {
            id: 3,
            speedoUrl: Images.speed_meter4
        },
        {
            id: 4,
            speedoUrl: Images.speed_meter5
        }
    ]
}