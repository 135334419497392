const initialState = {
    error: null,
    vehDetails : [],
    contactDetails : [],
    OrganisationDetails : {}
};

const currentVehicleId = '';
const currentVehicleDetailId = '';

export const fetchVehicleReducer = (state = initialState, action: any) => {
    switch(action.type) {
        case 'FETCH_VEHICLE_ACTION': {
            return {
                ...state,
                error: null,
                vehDetails: action.payload.value,
                contactDetails: action.payload.contactDetails,
                OrganisationDetails: action.payload.OrganisationDetails
            }
        }   
        default: 
            return state;
    }
}

export const fetchCurrentVehicleReducer = (state = currentVehicleId, action: any) => {
    switch(action.type) {
        case 'FETCH_CURRENT_VEHICLE_ID': {
            state = action.payload
            return state
        }   
        default: 
            return state;
    }
}

export const fetchCurrentVehicleDetailReducer = (state = currentVehicleDetailId, action: any) => {
    switch(action.type) {
        case 'FETCH_CURRENT_VEHICLE_DETAIL_ID': {
            state = action.payload
            return state
        }   
        default: 
            return state;
    }
}