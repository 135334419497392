import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import {dropDown} from 'common/common';
import DescriptionBox from 'common/descriptionBox';
import DropDownList from 'common/dropdownList';
import FormButton from 'common/formButton';

const dropDownName = "Zone";
const descName = "Description/Note";
const EditDepotStationInformation = (props:any) => { 
    const open = props?.open;
    const callBackMethod = props?.onClose; 
    const stationDetail = props?.details;
   
   

    return (
        <>
        <div className="dialog_box">
            <Dialog className="dialog_container edit_detail_section dialog_container_center" {...props}
             open={open}
             onClose={callBackMethod}>
                <div className="dialog_title">
                    <h4>Station Information</h4>
                    < FormButton 
                    cancel={callBackMethod}
                    />
                </div>
                <DialogContent dividers='paper' {...props}>
                    <DialogContentText component="div" id="scroll-dialog-description">
                        <div className="defult_form">
                            <div className="section_group">
                                <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={4}>
                                    <Grid item xs={6}>
                                        <div className="form_col">
                                            <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                                                <Grid item xs={12}>
                                                <label>Station name</label>
                                                <TextField 
                                                variant="outlined" 
                                                value={stationDetail?.serialNumber}
                                                />
                                                </Grid>
                                            </Grid>
                                        </div>
                                        < DropDownList
                                        label={dropDownName}
                                        data={dropDown.zones}
                                        />
                                    </Grid>
                                    </Grid>   
                                  </div>
                                  < DescriptionBox 
                                  description={stationDetail?.description}
                                  name={descName}
                                  />
                                </div>             
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>
  
        </>
    );
}

EditDepotStationInformation.propTypes = {
  callBackMethod: PropTypes.func,
  open: PropTypes.any
};

EditDepotStationInformation.defaultProps = {
  open: false,
}; 
export default EditDepotStationInformation;