import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Images from 'assets/images';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import './driverGroups.scss';
const drawerWidth = 200;




function TabPanel(props: any) {
  const { children, value, index, ...other } = props;



  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}






const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },

  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },


  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(8) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },





  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));




const DriverGroups = (props: any) => {

  const history = useHistory();
  const [opend, setOpend] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpend(true);
  };

  const handleDrawerClose = () => {
    setOpend(false);
  };




  const [isVisible, setIsVisible] = useState(true);




  const listenToScroll = () => {
    let heightToHideFrom = 50;
    const winScroll = document.body.scrollTop ||
      document.documentElement.scrollTop;
    if (winScroll > heightToHideFrom) {
      isVisible &&      // to limit setting state only the first time         
        setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () =>
      window.removeEventListener("scroll", listenToScroll);
  }, [])



  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const navigateToDriverDetails = () => {
       history.push('/driverDetail')
  }

  return (
      <>
        {/*----Page Mid Section Start----*/}
        <main className={classes.content}>
          <div className={"mid_section_inside, vehicle_list, shift_group_row"}>
          <Grid container spacing={2}>
                 {/*----Page Mid Left Section Start----*/}
                 <Grid item xs={12} sm={12} md={7} lg={8} className="mid_section_left" >
               

                
             
                   


                  <div className="depot_list">
                    <List >

                      {/*----EVSE Depot1 Start----*/}
                     
                        <ListItem>
                          <Card className="list_card" variant="outlined" >
                            <CardContent className="card_content" onClick={() => navigateToDriverDetails()}>
                              <div className="depot_details vehicle_detail">
                                <Grid container>
                                  <Grid item xs={12}>
                                    <div className="depot_information">
                                    <div className="depot_location_map no-border">
                                      <img src={Images.shift_driver_group} alt="shift_driver_group" />
                                    </div>
                                      <div className="depot_info">
                                        <div className="depot_name">Collect and Charge</div>
                                         <div className="port_details">
                                             <span className="alert">
                                            <span><img src={Images.IC_alert_12x14} alt="Depot Alert" /></span>
                                            3 Alerts</span>
                                            <span className="port">
                                            <span><img src={Images.ic_driver_group} alt="Driver Group" /></span>
                                            29 Total Drivers</span>
                                        </div>
                                      </div>
                                    </div>
                                  </Grid>
                                  
                                </Grid>

                                {/*----EVSE Depot Status----*/}
                                <div className="stat_list mt-10">
                                <div className="status driver_onduty">
                                <span className="status_text">On Duty</span>
                                <span className="status_number">15</span>
                                </div>
                                <div className="status driver_not_available">
                                <span className="status_text">Off Duty</span>
                                <span className="status_number">10</span>
                                </div>
                                <div className="status driver_suspended">
                                <span className="status_text">Suspended</span>
                                <span className="status_number">4</span>
                                </div>
                               
                                </div>
                              </div>
                            </CardContent>
                          </Card>

                         

                          
                        </ListItem>
                     
                        <ListItem>
                          <Card className="list_card" variant="outlined">
                            <CardContent className="card_content" onClick={() => navigateToDriverDetails()}>
                              <div className="depot_details vehicle_detail">
                                <Grid container>
                                  <Grid item xs={12}>
                                    <div className="depot_information">
                                    <div className="depot_location_map no-border">
                                      <img src={Images.shift_driver_group} alt="shift_driver_group" />
                                    </div>
                                      <div className="depot_info">
                                        <div className="depot_name">Maintenance</div>
                                         <div className="port_details">
                                             <span className="alert">
                                            <span><img src={Images.IC_alert_12x14} alt="Depot Alert" /></span>
                                            4 Alerts</span>
                                            <span className="port">
                                            <span><img src={Images.ic_driver_group} alt="Driver Group" /></span>
                                            27 Total Drivers</span>
                                        </div>
                                      </div>
                                    </div>
                                  </Grid>
                                  
                                </Grid>

                                {/*----EVSE Depot Status----*/}
                                <div className="stat_list mt-10">
                                <div className="status driver_onduty">
                                <span className="status_text">On Duty</span>
                                <span className="status_number">11</span>
                                </div>
                                <div className="status driver_not_available">
                                <span className="status_text">Off Duty</span>
                                <span className="status_number">14</span>
                                </div>
                                <div className="status driver_suspended">
                                <span className="status_text">Suspended</span>
                                <span className="status_number">2</span>
                                </div>
                               
                                </div>
                              </div>
                            </CardContent>
                          </Card>

                         

                          
                        </ListItem>

                        <ListItem>
                          <Card className="list_card" variant="outlined">
                            <CardContent className="card_content" onClick={() => navigateToDriverDetails()} >
                              <div className="depot_details vehicle_detail">
                                <Grid container>
                                  <Grid item xs={12}>
                                    <div className="depot_information">
                                    <div className="depot_location_map no-border">
                                      <img src={Images.shift_driver_group} alt="shift_driver_group" />
                                    </div>
                                      <div className="depot_info">
                                        <div className="depot_name">Security</div>
                                         <div className="port_details">
                                             <span className="alert">
                                            <span><img src={Images.IC_alert_12x14} alt="Depot Alert" /></span>
                                            2 Alerts</span>
                                            <span className="port">
                                            <span><img src={Images.ic_driver_group} alt="Driver Group" /></span>
                                            13 Total Drivers</span>
                                        </div>
                                      </div>
                                    </div>
                                  </Grid>
                                  
                                </Grid>

                                {/*----EVSE Depot Status----*/}
                                <div className="stat_list mt-10">
                                <div className="status driver_onduty">
                                <span className="status_text">On Duty</span>
                                <span className="status_number">5</span>
                                </div>
                                <div className="status driver_not_available">
                                <span className="status_text">Off Duty</span>
                                <span className="status_number">7</span>
                                </div>
                                <div className="status driver_suspended">
                                <span className="status_text">Suspended</span>
                                <span className="status_number">1</span>
                                </div>
                                
                                </div>
                              </div>
                            </CardContent>
                          </Card>

                         

                          
                        </ListItem>

                        <ListItem>
                          <Card className="list_card" variant="outlined">
                            <CardContent className="card_content" onClick={() => navigateToDriverDetails()}>
                              <div className="depot_details vehicle_detail">
                                <Grid container>
                                  <Grid item xs={12}>
                                    <div className="depot_information">
                                    <div className="depot_location_map no-border">
                                      <img src={Images.shift_driver_group} alt="shift_driver_group" />
                                    </div>
                                      <div className="depot_info">
                                        <div className="depot_name">Delivery</div>
                                         <div className="port_details">
                                             <span className="alert">
                                            <span><img src={Images.IC_alert_12x14} alt="Depot Alert" /></span>
                                            7 Alerts</span>
                                            <span className="port">
                                            <span><img src={Images.ic_driver_group} alt="Driver Group" /></span>
                                            47 Total Drivers</span>
                                        </div>
                                      </div>
                                    </div>
                                  </Grid>
                                  
                                </Grid>

                                {/*----EVSE Depot Status----*/}
                                <div className="stat_list mt-10">
                                <div className="status driver_onduty">
                                <span className="status_text">On Duty</span>
                                <span className="status_number">21</span>
                                </div>
                                <div className="status driver_not_available">
                                <span className="status_text">Off Duty</span>
                                <span className="status_number">22</span>
                                </div>
                                <div className="status driver_suspended">
                                <span className="status_text">Suspended</span>
                                <span className="status_number">4</span>
                                </div>
                             
                                </div>
                              </div>
                            </CardContent>
                          </Card>

                         

                          
                        </ListItem>

                    </List>

                    
                  </div>
                  
 
                






























                 


                 </Grid>    

                 <Grid item xs={12} sm={12} lg={4} md={5} className="mid_section_right vehicle_g_status" >


<Card className="small_card" variant="outlined">
                <CardContent className="card_content" >
                    <div className="section_title">
                        <Grid container>
                            <Grid item xs={12}>
                                <h3>Drivers Status</h3>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="section_content">
                        <div className="depot_bar">
                            <span className="onduty" style={{ width: "50%" }}>&nbsp;</span>
                            <span className="driver-unavailable" style={{ width: "40%" }}>&nbsp;</span>
                            <span className="suspended" style={{ width:"calc(10% - 3px)" }}>&nbsp;</span>
                            
                        </div>
                        <div className="depot_state">
                            <Grid container justifyContent="space-between" alignItems="flex-start">
                                <Grid className="state">
                                    <span className="">&nbsp;</span>
                                    <div className="state_text">
                                    TOTAL
                                    </div>
                                    <div className="state_number">
                                    126
                                    </div>
                                </Grid>
                                <Grid className="state">
                                    <span className="onduty">&nbsp;</span>
                                    <div className="state_text">
                                    ON DUTY
                                    </div>
                                    <div className="state_number">
                                    62
                                    </div>
                                </Grid>
                             
                                <Grid className="state">
                                    <span className="driver-unavailable">&nbsp;</span>
                                    <div className="state_text">
                                    OFF DUTY
                                    </div>
                                    <div className="state_number">
                                    53
                                    </div>
                                </Grid>
                                <Grid className="state">
                                    <span className="suspended">&nbsp;</span>
                                    <div className="state_text">
                                    SUSPENDED
                                    </div>
                                    <div className="state_number">
                                    11
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </CardContent>
            </Card>

            <Card className="small_card" variant="outlined">
                <CardContent className="card_content">
                    <div className="section_title">
                        <Grid container>
                            <Grid item xs={12}>
                                <h3>Recent Message</h3>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="recent_alert_list">
                    <List >
                      <ListItem> 
                       <span className="read"></span> 
                      <Grid justifyContent="space-between" container spacing={0}>
                        <Grid item md={12} xs={4} lg={4} sm={4} className="vehicle_number" >O’Conner, Josh</Grid>
                        <Grid item md={12} lg={8} sm={8} xs={8} className="time_detail text_right"  >Jan 3, 2022; 4:32 PM   </Grid>
                      </Grid>  
                      <div className="recent_alert_msg">
                      EV battery is below 10% battery
                      </div>
                      </ListItem>
                      <ListItem> 
                       <span className="off_duty"></span> 
                      <Grid justifyContent="space-between" container spacing={0}>
                        <Grid item md={12} xs={4} lg={4} sm={4} className="vehicle_number" >Li, Shannon</Grid>
                        <Grid item md={12} xs={8} lg={8} sm={8} className="time_detail text_right" >Dec 30, 2021; 2:18 PM </Grid>
                      </Grid>  
                      <div className="recent_alert_msg">
                      Unavailable for schedule shift
                      </div>
                      </ListItem>
                      <ListItem> 
                       <span className="suspended"></span> 
                      <Grid justifyContent="space-between" container spacing={0}>
                        <Grid className="vehicle_number" item md={12} xs={4} lg={4} sm={4}>Apple, Paul</Grid>
                        <Grid className="time_detail text_right" item md={12} xs={8} lg={8} sm={8}>Dec 29, 2021; 8:54 AM </Grid>
                      </Grid>  
                      <div className="recent_alert_msg">
                      Remove driver profile
                      </div>
                      </ListItem>
                      <ListItem> 
                       <span className="read"></span> 
                      <Grid justifyContent="space-between" container spacing={0}>
                        <Grid className="vehicle_number" item md={12} xs={4} lg={4} sm={4}>Kovarsky, Martin</Grid>
                        <Grid className="time_detail text_right" item md={12} xs={8} lg={8} sm={8}>Dec 28, 2021; 11:28 AM </Grid>
                      </Grid>  
                      <div className="recent_alert_msg">
                      License will expire in 30 days
                      </div>
                      </ListItem>
                      <ListItem> 
                       <span className="read"></span> 
                      <Grid justifyContent="space-between" container spacing={0}>
                        <Grid className="vehicle_number" item md={12} xs={4} lg={4} sm={4}>Lai, Ray</Grid>
                        <Grid className="time_detail text_right" item md={12} xs={8} lg={8} sm={8}>Dec 26, 2021; 4:38 PM </Grid>
                      </Grid>  
                      <div className="recent_alert_msg">
                      EV battery is below 20% battery
                      </div>
                      </ListItem>
                  
                   

                    </List>
                       
                    </div>
                </CardContent>
            </Card>
</Grid>


           </Grid>   
          </div>
        </main>

        {/*----Page Mid Section End----*/}
  </>
  );
}

export default DriverGroups;