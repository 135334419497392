import React, { useEffect, useState } from "react";
import Proptypes from "prop-types";
import GoogleMapReact from "google-map-react";
import Images from "../../assets/images";
import environment from "../../environment";
import { getMapOptions } from "common/mapMethods";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';

const AnyReactComponent = () => (
    <div>{<img src={Images.MAP_MARKER} />}</div>
);

const CommonAutoSearchGoogleMap = (props: any) => {
    const [value, setValue] = useState<any>(null);
    const [dragabble, setDragabble] = React.useState(true);
    const initialConfig = {
        mapApiLoaded: false,
        mapInstance: null,
        mapApi: null,
        geoCoder: null,
        places: [],
        address: "",
    };
    const [configSearch, setConfigSearch] = React.useState<any>(initialConfig);

    useEffect(() => {
    }, []);

    const defaultMapOptions = {
        fullscreenControl: props?.fullscreenControl
            ? props.fullscreenControl
            : false,
        scrollwheel: props?.scrollwheel ? props.scrollwheel : false,
        gestureHandling: props?.gestureHandling ? props?.gestureHandling : "none",
    };

    const onMarkerInteraction = (childKey: any, childProps: any, mouse: any) => {
        setDragabble(false);
        // props.setIntialValues({
        //     ...props.intialValues,
        //     depotDetailsData: { lat: mouse.lat, lon: mouse.lng },
        // });
        props.setAddressDetails({ ...props.addressDetails, lat: mouse.lat, lon: mouse.lng });
    };

    const apiHasLoaded = (map: any, maps: any) => {
        setConfigSearch({
            ...configSearch,
            mapApiLoaded: true,
            mapInstance: map,
            mapApi: maps,
        });
    };


    const matchAddress = (place: any) => {

        let data: any = [];
        data['street_number'] = '';
        data['route'] = '';
        for (var i = 0; i < place.address_components.length; i++) {
            if (place.address_components[i].types[0] == 'locality') {
                data['city'] = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'administrative_area_level_2') {
                data['district'] = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'administrative_area_level_1') {
                data['state'] = place.address_components[i].long_name;
                data['state_code'] = place.address_components[i].short_name;
            }
            if (place.address_components[i].types[0] == 'country') {
                data['country'] = place.address_components[i].long_name;
                data['country_code'] = place.address_components[i].short_name;
            }
            if (place.address_components[i].types[0] == 'postal_code') {
                data['zipCode'] = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'street_number') {
                data['street_number'] = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'route') {
                data['route'] = place.address_components[i].long_name;
            }
        }
        //setting props as it will not set in loop
        let formatedAddress = place.formatted_address;
        if (data['street_number'] != '' && data['route'] != '') {
            formatedAddress = data['street_number'] + data['route'];
        }
        console.log(place);
        let lat = place.geometry.viewport.Ab.g;
        let lon = place.geometry.viewport.Ra.h;
        props.setAddressDetails({ ...props.addressDetails, StreetAddress1: formatedAddress, City: data['city'], CountryCode: data['country_code'], country: data['country'], ZipCode: data['zipCode'], State: data['state'], lat: lat, lon: lon });
        return data;
    }

    const matchLatLng = (lat: any, lng: any) => {
        props.setAddressDetails({ ...props.addressDetails, lat: lat, lon: lng });
    }

    const sSelected = (e: any) => {
        geocodeByPlaceId(e.value.place_id)
            .then(results => { matchAddress(results[0]); })
            .catch(error => console.error(error));

    }

    const { places, mapApiLoaded, mapInstance, mapApi } = configSearch;

    return (
        <>
            {mapApiLoaded && (
                <div>
                    <GooglePlacesAutocomplete
                        apiKey={environment.googleMapKey}
                        selectProps={{
                            value,
                            onChange: sSelected,
                        }}
                    />
                </div>
            )}

            <GoogleMapReact
                bootstrapURLKeys={{ key: environment.googleMapKey, libraries: ['places', 'geometry'] }}
                center={{
                    lat: props.center.lat,
                    lng: props.center.lon,
                }}
                draggable={dragabble}
                onChildMouseDown={onMarkerInteraction}
                onChildMouseMove={onMarkerInteraction}
                zoom={props?.zoom}
                options={props.options ? getMapOptions : defaultMapOptions}
                onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
                yesIWantToUseGoogleMapApiInternals
            >
                <AnyReactComponent
                    {...props}
                    lat={props.addressDetails?.lat}
                    lng={props.addressDetails?.lon}
                />
            </GoogleMapReact>
        </>
    );
};

CommonAutoSearchGoogleMap.propTypes = {
    center: Proptypes.any,
    zoom: Proptypes.number,
    markers: Proptypes.any,
};

CommonAutoSearchGoogleMap.defaultProps = {
    center: {},
    zoom: 5,
    markers: [],
};

export default CommonAutoSearchGoogleMap;
