import React, { useState } from "react";
import ImageUploading from 'react-images-uploading';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Images from 'assets/images';
import Button from '@material-ui/core/Button';
import { Grid } from "@material-ui/core";

const fileTypes = ["JPG", "PNG", "GIF"];

function DragDropImages() {
    const [images, setImages] = React.useState([]);
    const maxNumber = 10;
    const bytes = 6291456;
    const imageType = ['jpeg', 'png']

    const [fileErr, setfileError] = useState<any>();

    const onChange = (imageList: any, addUpdateIndex: any) => {
        // data for submit
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
    };

    const onError = (errors: any, files: any) => {
        if(errors.maxFileSize) {
            setfileError({name: files[0].file.name, size: bytesToSize(files[0].file.size)} );
        }
    }

    const bytesToSize = (bytes: any) => {
        const decimals = 2
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        }


    return (
        <>
            <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                maxFileSize={bytes}
                acceptType={imageType}
                dataURLKey="data_url"
                onError={onError}
            >

                {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                    errors
                }) => (
                    // write your building UI
                    <>

                        <Card style={{ border: isDragging ? "1px dashed #000" : '1px dashed #25556E' }} onClick={onImageUpload} {...dragProps}>
                            <CardContent >
                                {errors && <div>
                                    {errors?.maxNumber && <div className="error"> <span>Maximum 10 photos allowed to upload</span> </div>}
                                    {errors?.acceptType && <div className="error"> <span>Your selected file type is not allow</span> </div>}
                                    {errors?.maxFileSize && <div className="error"> <span> File "{fileErr?.name}" ({fileErr?.size}) exceeds maximum allowed upload size of 6 MB</span> </div>}
                                    {errors?.resolution && <div className="error"> <span>Selected file is not match your desired resolution</span> </div>}
                                </div>}
                                {onError}

                                <div className="dragdrop">
                                    <img src={Images.up_image} alt="upimg" className="up-icon" />
                                    <label htmlFor="contained-button-file">
                                        <Button variant="contained" color="primary" component="span">
                                            Drag and drop or click here <cite>Maximum photo upload size 6MB . Accepted JPEG and PNG</cite>
                                        </Button>
                                    </label>


                                    {/* <div className="progresbar">
                                        
                                        <label>Port.jpeg<span>(4 MB)</span></label>
                                        <div className='range'><div className='upper-range'></div></div>
                                        <IconButton className="top_action_btn error-icn">
                                            <img src={Images.error_icon} alt="del" className="error-img" />
                                        </IconButton>
                                        <IconButton className="top_action_btn cancel">
                                            <img src={Images.cancel_icon} alt="del" className="cancel-img" />
                                        </IconButton>
                                        <IconButton className="top_action_btn cancel">
                                            <img onClick={onImageRemoveAll} src={Images.cancelred_icon} alt="del" className="cancelred-img" />
                                        </IconButton>
                                    </div> */}



                                </div>
                            </CardContent>
                        </Card>



                        <Grid container spacing={0}>
                            <Grid item xs={6}>
                                <div className="drag-text">Drag and drop thumbnail for ordering</div>
                            </Grid>

                            <Grid item xs={6}>
                                <div className="drag-text text-right">Maximum 10 photo can be upload</div>
                            </Grid>
                        </Grid>

                        <Grid container direction="row" spacing={3} alignItems="center">

                            {imageList
                            .sort((a: any, b: any) => a.file.name.localeCompare(b.file.name))
                            .map((image, index) => (
                                <Grid key={index} item>
                                    <div className="items">
                                        <img src={image.data_url} alt="del" className="" />
                                        <IconButton onClick={() => onImageRemove(index)} className="top_action_btn del">
                                            <img src={Images.delete_icon} alt="del" className="del-img" />
                                        </IconButton>

                                        <IconButton onClick={() => onImageUpdate(index)} className="top_action_btn drag">
                                            <img src={Images.drag_icon} alt="drag" />
                                        </IconButton>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </>
                )}

            </ImageUploading>


        </>

    );
}

export default DragDropImages;