import React from "react";
import {DataGrid} from "@material-ui/data-grid";
import Grid from "@material-ui/core/Grid";
import Images from "assets/images";
import Button from "@material-ui/core/Button";

const VehicleServiceHistory = () => {

    const serviceHistoryCol = [

        {
            field: "e",
            headerName: "e",
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <div className="empty">
                        {cellValues.value}
                    </div>
                );
            }
        },

        {
            field: "start",
            headerName: "START",
            sortable: false,
            hasFocus: false,
            minWidth: 150,
            flex: 0.1,
            disableColumnMenu: true,
        },
        {
            field: 'end',
            headerName: 'END',
            sortable: false,
            flex: 0.1,
            minWidth: 70,
            disableColumnMenu: true,
        },

        {
            field: 'odometer',
            headerName: 'ODOMETER',
            sortable: false,
            flex: 0.1,
            minWidth: 70,
            disableColumnMenu: true,
        },
        {
            field: 'description',
            headerName: 'DESCRIPTION',
            sortable: false,
            minWidth: 200,
            flex: 0.3,
            disableColumnMenu: true
        },


        {
            field: "ee",
            headerName: "ee",
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <div className="empty">
                        {cellValues.value}
                    </div>
                );
            }
        },

    ];

    const serviceHistoryRow = [
        { id: 1, e: 'a', start: 'Mar 14, 2021; 7:13AM', end: 'Dec 21, 2021; 2:52PM', odometer: '46,175', description: '7,500 mile routine maintenance.', ee: 'a' },
        { id: 2, e: 'a', start: 'Oct 24, 2021; 9:41AM', end: 'Oct 26, 2021; 7:13AM', odometer: '41,587', description: 'Window crack.', ee: 'a' },
        { id: 3, e: 'a', start: 'Sep 9, 2021; 10:34AM', end: 'Sep 9, 2021; 3:15PM', odometer: '36,185', description: 'Replaced windshield wipers', ee: 'a' },
        { id: 4, e: 'a', start: 'Jun 15, 2021; 5:58PM', end: 'Jun 21, 2021; 7:23AM', odometer: '31,581', description: 'Replace left mirror', ee: 'a' },
        { id: 5, e: 'a', start: 'Mar 14, 2021; 7:13AM', end: 'Mar 21, 2021; 11:39AM', odometer: '22,185', description: 'Flat tire. Replace front left tire', ee: 'a' },
        { id: 6, e: 'a', start: 'Feb 15, 2021; 1:58PM', end: 'Feb 15, 2021; 1:58PM', odometer: '15,581', description: 'Rear camera has static. Replace the camera with new camera. ', ee: 'a' },
        { id: 7, e: 'a', start: 'Dec 24, 2020; 9:41AM', end: 'Jan 2, 2021; 4:45PM', odometer: '14,487', description: '7,500 mile routine maintenance.', ee: 'a' },
        { id: 8, e: 'a', start: 'Aug 4, 2020; 1:13PM', end: 'Aug 9, 2020; 4:39PM', odometer: '13,175', description: 'Manufacture recall - replace steering wheel.', ee: 'a' },
    ];

    return (
        <div className="table_scroll">
            <div className="defult_table" style={{ width: '100%' }} >
                <DataGrid autoHeight className="table"
                          rowHeight={59}
                          headerHeight={30}
                          rows={serviceHistoryRow}
                          columns={serviceHistoryCol}
                          pageSize={12}
                          disableSelectionOnClick />
            </div>
            <div className="table_footer">
                <Grid container direction="row" alignItems="center" spacing={0}>
                    <Grid className="text_left" item xs={8}>
                        <div className="pagination">
                            <span><img src={Images.ic_first_page} alt="first page" /></span>
                            <span><img src={Images.ic_chevron_left} alt="back page" /></span>
                            <span>1</span>
                            <span><img src={Images.ic_chevron_right} alt="back page" /></span>

                        </div>
                        <Button className="btn_white">Download CSV</Button>
                    </Grid>
                    <Grid className="text_right" item xs={4}>
                        <div className="list_number">1-50 of 45,257 charge session</div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )

}

export default VehicleServiceHistory;