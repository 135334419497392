import React from 'react';

const StatusButton = (props:any) => {

    const classType = props && props.classType ? props.classType : '';

    return (
        <>
            <div className={`status ${classType}`}>
                <span className="status_text">{props.statusLabel}</span>
                <span className="status_number">{props.statusValue}</span>
            </div>
        </>
    )
}

export default StatusButton;
