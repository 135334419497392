import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import  Images from "assets/images";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import React from "react";

const VehicleAnalytics = () => {

    return(
        <div className="information_section map">
            <div className="vehicle_analytics">
                <Grid container spacing={3}>
                    <Grid item sm={12} md={4} lg={4}>
                        <Card variant="outlined" className="basic_card">
                            <CardContent>
                                <div className="title_row">
                                    <h4>24 Hour Status</h4>
                                    <div className="sub_heading">Average status last 24 hours</div>
                                </div>
                                <div className="card_mid">
                                    <img className="img_res" src={Images.hr_status} alt="hr_status"/>
                                </div>
                                <div className="card_legend">
                                    <Grid container>
                                        <Grid className="legend_item transit" item sm={4} md={6} lg={4}><img
                                            src={Images.ic_transit} alt=""/>Transit (33%)</Grid>
                                        <Grid className="legend_item parked" item sm={4} md={6} lg={4}><img
                                            src={Images.ic_parking} alt=""/>Parked (7%)</Grid>
                                        <Grid className="legend_item charging" item sm={4} md={6} lg={4}><img
                                            src={Images.ic_charging} alt=""/>Charging (23%)</Grid>
                                        <Grid className="legend_item in-service" item sm={4} md={6} lg={4}><img
                                            src={Images.ic_in_service} alt=""/>In Service (4%)</Grid>
                                        <Grid className="legend_item complete" item sm={4} md={6} lg={4}><img
                                            src={Images.ic_complete} alt=""/>Complete (20%)</Grid>
                                        <Grid className="legend_item connected" item sm={4} md={6} lg={4}><img
                                            src={Images.ic_connected} alt=""/>Connected (12%)</Grid>
                                        <Grid className="legend_item offline" item sm={4} md={6} lg={4}><img
                                            src={Images.ic_offline} alt=""/>Offline (1%)</Grid>
                                    </Grid>
                                </div>
                            </CardContent>
                        </Card>
                        <Card variant="outlined" className="basic_card">
                            <CardContent>
                                <div className="title_row">
                                    <h4>Charge percent</h4>
                                    <div className="sub_heading">Average 7 days vehicle charge</div>
                                </div>
                                <div className="card_mid text_center">
                                    <img src={Images.charge_status_vehicle} alt="charge_percent"/>
                                </div>

                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item sm={12} md={4} lg={4}>
                        <Card variant="outlined" className="basic_card">
                            <CardContent>
                                <div className="title_row">
                                    <h4>Vehicle efficiency </h4>
                                    <div className="sub_heading">30 day average: 248 Wh/mi</div>
                                </div>
                                <div className="card_mid">
                                    <div className="efficiency_bar">
                                        <List aria-label="main mailbox folders">
                                            <ListItem>
                                                <Grid container>
                                                    <Grid className="date" item lg={6} sm={6}>1/2/2022</Grid>
                                                    <Grid className="time" item lg={6} sm={6}>259 Wh/mi</Grid>
                                                    <Grid className="bar" item sm={12}><span
                                                        style={{left: "50%"}}>&nbsp;</span></Grid>
                                                </Grid>

                                            </ListItem>
                                            <ListItem>
                                                <Grid container>
                                                    <Grid className="date" item lg={6} sm={6}>1/1/2022</Grid>
                                                    <Grid className="time" item lg={6} sm={6}>252 Wh/mi</Grid>
                                                    <Grid className="bar" item sm={12}><span
                                                        style={{left: "45%"}}>&nbsp;</span></Grid>
                                                </Grid>

                                            </ListItem>
                                            <ListItem>
                                                <Grid container>
                                                    <Grid className="date" item lg={6} sm={6}>12/31/2021</Grid>
                                                    <Grid className="time" item lg={6} sm={6}>267 Wh/mi</Grid>
                                                    <Grid className="bar" item sm={12}><span
                                                        style={{left: "52%"}}>&nbsp;</span></Grid>
                                                </Grid>

                                            </ListItem>
                                            <ListItem>
                                                <Grid container>
                                                    <Grid className="date" item lg={6} sm={6}>12/30/2021</Grid>
                                                    <Grid className="time" item lg={6} sm={6}>291 Wh/mi</Grid>
                                                    <Grid className="bar" item sm={12}><span
                                                        style={{left: "60%"}}>&nbsp;</span></Grid>
                                                </Grid>

                                            </ListItem>
                                            <ListItem>
                                                <Grid container>
                                                    <Grid className="date" item lg={6} sm={6}>12/29/2021</Grid>
                                                    <Grid className="time" item lg={6} sm={6}>252 Wh/mi</Grid>
                                                    <Grid className="bar" item sm={12}><span
                                                        style={{left: "52%"}}>&nbsp;</span></Grid>
                                                </Grid>

                                            </ListItem>
                                        </List>

                                    </div>
                                </div>

                            </CardContent>
                        </Card>

                        <Card variant="outlined" className="basic_card">
                            <CardContent>
                                <div className="title_row">
                                    <h4>Energy</h4>
                                    <div className="sub_heading">12 week energy trend</div>
                                </div>
                                <div className="card_mid text_center">
                                    <img className="img_res" src={Images.energy_gr} alt="charge_percent"/>
                                </div>

                            </CardContent>
                        </Card>

                        <Card variant="outlined" className="basic_card">
                            <CardContent>
                                <div className="title_row">
                                    <h4>Create custom views</h4>
                                    <div className="sub_heading">Download vehicle data in a CSV format. Format customized
                                        data tables and create graphs in Excel.
                                    </div>
                                </div>
                                <div className="card_mid text_center">
                                    <img className="img_res" src={Images.custom_views} alt="charge_percent"/>
                                </div>
                                <div className="button_row">
                                    <Grid container direction="row" alignItems="center" spacing={0}>
                                        <Grid className="text_left" item xs={12}>
                                            <Button className="btn_white ml_0 blue_bg">Download CSV</Button>
                                        </Grid>

                                    </Grid>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item sm={12} md={4} lg={4}>
                        <Card variant="outlined" className="basic_card">
                            <CardContent>
                                <div className="title_row">
                                    <h4>5 Day Status</h4>
                                    <div className="sub_heading">Daily average status</div>
                                </div>
                                <div className="card_mid">
                                    <div className="efficiency_bar five_day_status">
                                        <List aria-label="main mailbox folders">
                                            <ListItem>
                                                <Grid container>
                                                    <Grid className="date" item lg={6} sm={12}>1/1/2022</Grid>
                                                    <Grid className="bar" item sm={12}>
                                                        <span className="transit" style={{width: "30%"}}>&nbsp;</span>
                                                        <span className="parked" style={{width: "10%"}}>&nbsp;</span>
                                                        <span className="charging" style={{width: "20%"}}>&nbsp;</span>
                                                        <span className="in-service" style={{width: "5%"}}>&nbsp;</span>
                                                        <span className="complete" style={{width: "20%"}}>&nbsp;</span>
                                                        <span className="connected" style={{width: "5%"}}>&nbsp;</span>
                                                        <span className="offline" style={{width: "calc(10% - 7px)"}}>&nbsp;</span>
                                                    </Grid>
                                                </Grid>

                                            </ListItem>
                                            <ListItem>
                                                <Grid container>
                                                    <Grid className="date" item lg={6} sm={12}>1/1/2022</Grid>
                                                    <Grid className="bar" item sm={12}>
                                                        <span className="transit" style={{width: "20%"}}>&nbsp;</span>
                                                        <span className="parked" style={{width: "10%"}}>&nbsp;</span>
                                                        <span className="charging" style={{width: "30%"}}>&nbsp;</span>
                                                        <span className="in-service" style={{width: "5%"}}>&nbsp;</span>
                                                        <span className="complete" style={{width: "20%"}}>&nbsp;</span>
                                                        <span className="connected" style={{width: "5%"}}>&nbsp;</span>
                                                        <span className="offline" style={{width: "calc(10% - 7px)"}}>&nbsp;</span>
                                                    </Grid>
                                                </Grid>

                                            </ListItem>
                                            <ListItem>
                                                <Grid container>
                                                    <Grid className="date" item lg={6} sm={12}>12/31/2021</Grid>
                                                    <Grid className="bar" item sm={12}>
                                                        <span className="transit" style={{width: "40%"}}>&nbsp;</span>
                                                        <span className="parked" style={{width: "10%"}}>&nbsp;</span>
                                                        <span className="charging" style={{width: "10%"}}>&nbsp;</span>
                                                        <span className="in-service" style={{width: "5%"}}>&nbsp;</span>
                                                        <span className="complete" style={{width: "20%"}}>&nbsp;</span>
                                                        <span className="connected" style={{width: "5%"}}>&nbsp;</span>
                                                        <span className="offline" style={{width: "calc(10% - 7px)"}}>&nbsp;</span>
                                                    </Grid>
                                                </Grid>

                                            </ListItem>
                                            <ListItem>
                                                <Grid container>
                                                    <Grid className="date" item lg={6} sm={12}>12/30/2021</Grid>
                                                    <Grid className="bar" item sm={12}>
                                                        <span className="transit" style={{width: "20%"}}>&nbsp;</span>
                                                        <span className="parked" style={{width: "20%"}}>&nbsp;</span>
                                                        <span className="charging" style={{width: "20%"}}>&nbsp;</span>
                                                        <span className="in-service" style={{width: "5%"}}>&nbsp;</span>
                                                        <span className="complete" style={{width: "20%"}}>&nbsp;</span>
                                                        <span className="connected" style={{width: "5%"}}>&nbsp;</span>
                                                        <span className="offline" style={{width: "calc(10% - 7px)"}}>&nbsp;</span>
                                                    </Grid>
                                                </Grid>

                                            </ListItem>
                                            <ListItem>
                                                <Grid container>
                                                    <Grid className="date" item lg={6} sm={12}>12/29/2021</Grid>
                                                    <Grid className="bar" item sm={12}>
                                                        <span className="transit" style={{width: "30%"}}>&nbsp;</span>
                                                        <span className="parked" style={{width: "5%"}}>&nbsp;</span>
                                                        <span className="charging" style={{width: "25%"}}>&nbsp;</span>
                                                        <span className="in-service" style={{width: "5%"}}>&nbsp;</span>
                                                        <span className="complete" style={{width: "20%"}}>&nbsp;</span>
                                                        <span className="connected" style={{width: "5%"}}>&nbsp;</span>
                                                        <span className="offline" style={{width: "calc(10% - 7px)"}}>&nbsp;</span>
                                                    </Grid>
                                                </Grid>

                                            </ListItem>
                                        </List>

                                    </div>
                                </div>
                                <div className="card_legend">
                                    <Grid container>
                                        <Grid className="legend_item transit" item sm={4} md={6} lg={4}><img
                                            src={Images.ic_transit} alt=""/>Transit</Grid>
                                        <Grid className="legend_item parked" item sm={4} md={6} lg={4}><img
                                            src={Images.ic_parking} alt=""/>Parked</Grid>
                                        <Grid className="legend_item charging" item sm={4} md={6} lg={4}><img
                                            src={Images.ic_charging} alt=""/>Charging</Grid>
                                        <Grid className="legend_item in-service" item sm={4} md={6} lg={4}><img
                                            src={Images.ic_in_service} alt=""/>In Service</Grid>
                                        <Grid className="legend_item complete" item sm={4} md={6} lg={4}><img
                                            src={Images.ic_complete} alt=""/>Complete</Grid>
                                        <Grid className="legend_item connected" item sm={4} md={6} lg={4}><img
                                            src={Images.ic_connected} alt=""/>Connected</Grid>
                                        <Grid className="legend_item offline" item sm={4} md={6} lg={4}><img
                                            src={Images.ic_offline} alt=""/>Offline</Grid>
                                    </Grid>
                                </div>

                            </CardContent>
                        </Card>

                        <Card variant="outlined" className="basic_card">
                            <CardContent>
                                <div className="title_row">
                                    <h4>Energy Consumption</h4>
                                    <div className="sub_heading">24 hour average kWh consumption</div>
                                </div>
                                <div className="card_mid text_center">
                                    <img className="img_res" src={Images.energy_consumption} alt="charge_percent"/>
                                </div>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

            </div>
        </div>
    )
}

export default VehicleAnalytics;