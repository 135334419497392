import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Images from 'assets/images';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const EvseSchedulePriority = (props: any) => {

  return (
    <>
      <div className="dialog_box">
        <Dialog className="dialog_container set_schedule set_fee_dialog "
          open={props?.openScheduleCall}
          onClose={props?.closeScheduleCal}
          scroll={props?.scrollScheduleCal}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <div className="dialog_title">
            <h4>Set Schedule Priority</h4>
            <div className="button_row mt-0 full_right">
              <Button className="btn_white text_btn_theme" onClick={props?.closeScheduleCal}>Cancel</Button>
              <Button className="btn_white blue_bg" >Apply Prioritization</Button>
            </div>
          </div>
          <DialogContent >
            <DialogContentText component="div"
              id="scroll-dialog-description">
              <div className="charging_fee">
                <div className="section_row">
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <label>Zone</label>
                      <FormControl className="select_control">
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" >
                          <MenuItem className="optionlist" value={1}>
                            Service</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <label>Template</label>
                      <FormControl className="select_control">
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" >
                          <MenuItem className="optionlist" value={1}>
                            Select priority template</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
                <div className="separator"></div>

                <div className="section_row">
                  <Grid container spacing={5}>
                    <Grid item xs={12}><h5>Standard schedule</h5></Grid>
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item xs={3}><label>Set demand limit</label></Grid>
                    <Grid item xs={5}>
                      <FormControl className="select_control">
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" >
                          <MenuItem className="optionlist" value={1}>
                            None</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item xs={3}><label>Set consumption</label></Grid>
                    <Grid item xs={5}>
                      <div className="set_consumption">
                        <Grid container >
                          <Grid item xs={6}>
                            <div className="priority_label"><span className="priority_1"></span>Priority 1</div>
                            <div className="priority_label"><span className="priority_2"></span>Priority 2</div>
                            <div className="priority_label"><span className="priority_3"></span>Priority 3</div>
                            <div className="priority_label"><span className="others"></span>Others</div>
                          </Grid>
                          <Grid item xs={6}>
                            <div className="add-remove_circle">
                              <a href=""><img src={Images.ic_remove_circle} alt="Remove" /></a>
                              <a href=""><img src={Images.ic_add_circle} alt="Add" /></a>
                            </div>
                            <div className="add-remove_circle">
                              <a href=""><img src={Images.ic_remove_circle} alt="Remove" /></a>
                              <a href=""><img src={Images.ic_add_circle} alt="Add" /></a>
                            </div>
                            <div className="add-remove_circle">
                              <a href=""><img src={Images.ic_remove_circle} alt="Remove" /></a>
                              <a href=""><img src={Images.ic_add_circle} alt="Add" /></a>
                            </div>
                            <div className="add-remove_circle">
                              <a href=""><img src={Images.ic_remove_circle} alt="Remove" /></a>
                              <a href=""><img src={Images.ic_add_circle} alt="Add" /></a>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                    <Grid className="text_center" item xs={4}>
                      <img src={Images.schedule_chart} alt="" />
                    </Grid>
                  </Grid>
                </div>
                <div className="separator"></div>
                <div className="section_row">
                  <Grid container spacing={5}>
                    <Grid item xs={12}><h5>Off-peak</h5></Grid>
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item xs={3}><label>Set demand limit</label></Grid>
                    <Grid item xs={5}>
                      <FormControl className="select_control">
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" >
                          <MenuItem className="optionlist" value={1}>
                            None</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item xs={3}><label>Set consumption</label></Grid>
                    <Grid item xs={5}>
                      <div className="set_consumption">
                        <Grid container >
                          <Grid item xs={6}>
                            <div className="priority_label"><span className="priority_1"></span>Priority 1</div>
                            <div className="priority_label"><span className="priority_2"></span>Priority 2</div>
                            <div className="priority_label"><span className="priority_3"></span>Priority 3</div>
                            <div className="priority_label"><span className="others"></span>Others</div>
                          </Grid>
                          <Grid item xs={6}>
                            <div className="add-remove_circle">
                              <a href=""><img src={Images.ic_remove_circle} alt="Remove" /></a>
                              <a href=""><img src={Images.ic_add_circle} alt="Add" /></a>
                            </div>
                            <div className="add-remove_circle">
                              <a href=""><img src={Images.ic_remove_circle} alt="Remove" /></a>
                              <a href=""><img src={Images.ic_add_circle} alt="Add" /></a>
                            </div>
                            <div className="add-remove_circle">
                              <a href=""><img src={Images.ic_remove_circle} alt="Remove" /></a>
                              <a href=""><img src={Images.ic_add_circle} alt="Add" /></a>
                            </div>
                            <div className="add-remove_circle">
                              <a href=""><img src={Images.ic_remove_circle} alt="Remove" /></a>
                              <a href=""><img src={Images.ic_add_circle} alt="Add" /></a>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                    <Grid className="text_center" item xs={4}>
                      <img src={Images.schedule_chart_off_peak} alt="" />
                    </Grid>
                  </Grid>
                </div>
                <div className="separator"></div>
                <div className="section_row">
                  <Grid container spacing={5}>
                    <Grid item xs={12}><h5>Peak</h5></Grid>
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item xs={3}><label>Set demand limit</label></Grid>
                    <Grid item xs={5}>
                      <FormControl className="select_control">
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" >
                          <MenuItem className="optionlist" value={1}>
                            None</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item xs={3}><label>Set consumption</label></Grid>
                    <Grid item xs={5}>
                      <div className="set_consumption">
                        <Grid container >
                          <Grid item xs={6}>
                            <div className="priority_label"><span className="priority_1"></span>Priority 1</div>
                            <div className="priority_label"><span className="priority_2"></span>Priority 2</div>
                            <div className="priority_label"><span className="priority_3"></span>Priority 3</div>
                            <div className="priority_label"><span className="others"></span>Others</div>
                          </Grid>
                          <Grid item xs={6}>
                            <div className="add-remove_circle">
                              <a href=""><img src={Images.ic_remove_circle} alt="Remove" /></a>
                              <a href=""><img src={Images.ic_add_circle} alt="Add" /></a>
                            </div>
                            <div className="add-remove_circle">
                              <a href=""><img src={Images.ic_remove_circle} alt="Remove" /></a>
                              <a href=""><img src={Images.ic_add_circle} alt="Add" /></a>
                            </div>
                            <div className="add-remove_circle">
                              <a href=""><img src={Images.ic_remove_circle} alt="Remove" /></a>
                              <a href=""><img src={Images.ic_add_circle} alt="Add" /></a>
                            </div>
                            <div className="add-remove_circle">
                              <a href=""><img src={Images.ic_remove_circle} alt="Remove" /></a>
                              <a href=""><img src={Images.ic_add_circle} alt="Add" /></a>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                    <Grid className="text_center" item xs={4}>
                      <img src={Images.schedule_chartpeak} alt="" />
                    </Grid>
                  </Grid>
                </div>
                <div className="separator"></div>
                <div className="section_row">
                  <Grid container spacing={5}>
                    <Grid item xs={12}><h5>Super-peak</h5></Grid>
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item xs={3}><label>Set demand limit</label></Grid>
                    <Grid item xs={5}>
                      <FormControl className="select_control">
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" >
                          <MenuItem className="optionlist" value={1}>
                            7:30 PM</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item xs={3}><label>Set consumption</label></Grid>
                    <Grid item xs={5}>
                      <div className="set_consumption">
                        <Grid container >
                          <Grid item xs={6}>
                            <div className="priority_label"><span className="priority_1"></span>Priority 1</div>
                            <div className="priority_label"><span className="priority_2"></span>Priority 2</div>
                            <div className="priority_label"><span className="priority_3"></span>Priority 3</div>
                            <div className="priority_label"><span className="others"></span>Others</div>
                          </Grid>
                          <Grid item xs={6}>
                            <div className="add-remove_circle">
                              <a href=""><img src={Images.ic_remove_circle} alt="Remove" /></a>
                              <a href=""><img src={Images.ic_add_circle} alt="Add" /></a>
                            </div>
                            <div className="add-remove_circle">
                              <a href=""><img src={Images.ic_remove_circle} alt="Remove" /></a>
                              <a href=""><img src={Images.ic_add_circle} alt="Add" /></a>
                            </div>
                            <div className="add-remove_circle">
                              <a href=""><img src={Images.ic_remove_circle} alt="Remove" /></a>
                              <a href=""><img src={Images.ic_add_circle} alt="Add" /></a>
                            </div>
                            <div className="add-remove_circle">
                              <a href=""><img src={Images.ic_remove_circle} alt="Remove" /></a>
                              <a href=""><img src={Images.ic_add_circle} alt="Add" /></a>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                    <Grid className="text_center" item xs={4}>
                      <img src={Images.schedule_chart_super_peak} alt="" />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

export default EvseSchedulePriority;