import {makeStyles} from "@material-ui/core/styles";

export const sidebarData = {
  drawerWidth: 200
}

export const pageDetails = {
    pageSize: 12
}

export const mapDetails = {
  depotLocationMapZoom: 11,
  viewSatellite: true
}

export const depotGoogleMap = { 
  zoom: 9
};

export const styleDetails = {
  styleWidth:{ width: '100%' }
};

export const dropDown: {[key:string]: any}={
  zones: [
    {id: 1, value: 1, zone:"Loading zone"},
    {id: 2, value: 2, zone:"Service zone"},
    {id: 3, value: 3, zone:"Maintenance zone"},
    {id: 4, value: 4, zone:"Parking zone"}
  ],
}

export const defaultVariable: {[key:string]: any} = { 

    freeChargingDetails : [
    {id: 1, value: 0, name: "O min"},
    {id: 2, value: 300, name: "5 min"},
    {id: 3, value: 600, name: "10 min"},
    {id: 4, value: 900, name: "15 min"},
    {id: 5, value: 1200, name: "20 min"},
    {id: 6, value: 1800, name: "3O min"},
    {id: 7, value: 2400, name: "4O min"},
    {id: 8, value: 3600, name: "1 hour"},
    {id: 9, value: 5400, name: "1 hour 3O min"},
    {id: 10, value: 7200, name: "2 hours"},
    {id: 11, value: 10800, name: "3 hours"},
    {id: 12, value: 14400, name: "4 hours"}
  ],
  
   billingIncrementDetails : [
    {id: 1, value: 300, name: "5 min"},
    {id: 2, value: 600, name: "10 min"},
    {id: 3, value: 900, name: "15 min"},
    {id: 4, value: 1800, name: "30 min"},
    {id: 5, value: 3600, name: "1 hour"}
  ],

  usageBillingIncrementDetails : [
    {id: 1, value: 300, name: "5 min"},
    {id: 2, value: 600, name: "10 min"},
    {id: 3, value: 900, name: "15 min"},
    {id: 4, value: 1800, name: "30 min"},
    {id: 5, value: 3600, name: "1 hour"}
  ],

  gracePeriodDetails : [
    {id: 1, value: 0, name: "0 min"},
    {id: 2, value: 300, name: "5 min"},
    {id: 3, value: 600, name: "10 min"},
    {id: 4, value: 900, name: "15 min"},
    {id: 5, value: 1200, name: "20 min"},
    {id: 6, value: 1800, name: "30 min"},
    {id: 7, value: 3600, name: "1 hour"}
  ],

  defaultUnit : 'kWh',  
  defaultUnitType : 'kW',  
  defaultCurrency: 'USD',
  defaultCurrencySymbol: "$",

  portStatusColor: {
    error: 'error', 
    faulted: 'error', 
    complete: 'complete', 
    connected: 'connected', 
    charging : 'charging' , 
    offline: 'offline', 
    available: 'available',
    parked: 'parked',
    transit: 'transit',
    inservice: 'in-service',
    reserved: 'reserved'},

 vehicleStatusColor: {
  error: 'error', 
  faulted: 'error', 
  complete: 'complete', 
  connected: 'connected', 
  charging : 'charging' , 
  offline: 'offline', 
  available: 'available',
  parked: 'parked',
  transit: 'transit',
  inservice: 'in-service',
  reserved: 'reserved'
},

driverStatusColor: {
  'on duty': 'driver_onduty', 
  'suspended': 'driver_suspended', 
  'off duty': 'offline'
}


   
}

export const commonRoutes = { 
  fleetDashboardRoute : '/',
  depotAllRoutes : ['/evseDepotList', '/evseDepotDetail', '/depotStationDetails'],
  vehicleAllRoutes : ['/vehicleList', '/fleetVehicle', '/vehicleDetail'],
  driverAllRoutes : ['/drivers', '/driverDetail', '/driverInfo'],
  messageAllRoutes : ['/messages'],
  depotListRoute: '/evseDepotList',
  depotDetailRoute: '/evseDepotDetail',
  depotStationDetailRoute: '/depotStationDetails',
  vehicleListRoute: '/vehicleList',
  fleetVehicleRoute: '/fleetVehicle',
  vehicleDetailRoute: '/vehicleDetail',
  driversRoute: '/drivers',
  driverDetailRoute: '/driverDetail',
  driverInfoRoute: '/driverInfo',
  messageRoute : '/messages'

}

export const useStyles = makeStyles((theme) => ({
    search: {
      position: 'relative',
      '&:hover': {
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(0),
        width: '100%',
        paddingRight: '10px',
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '313px',
      background: '#fff',
      borderRadius: '5px'

    },

  search1: {
      position: 'relative',
      '&:hover': {
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(0),
        width: '100%',
        paddingRight: '10px',
      },
      marginRight: theme.spacing(1),
      marginLeft: 0,
      width: '150px',
      background: '#fff',
      borderRadius: '5px'

    },
    searchIcon: {
      padding: '0px 10px',
      height: '100%',
      fontSize: '14px',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#999',
    },

    inputRoot: {
      color: '#999',
    },
    inputInput: {
      padding: theme.spacing(1.5, 1, 1.5, 0),
      paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
      transition: theme.transitions.create('width'),
      fontSize: '12px',
      width: '300px',
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(0),
        width: '100%',
      },
    },
    inputInput1: {
      padding: theme.spacing(1.5, 1, 1.5, 0),
      paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
      transition: theme.transitions.create('width'),
      fontSize: '12px',
      width: '106px',
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(0),
        width: '100%',
      },
    },

    root: {
        display: 'flex',
    },
    drawer: {
        width: sidebarData.drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: sidebarData.drawerWidth,
    },

    drawerOpen: {
        width: sidebarData.drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },


    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(8) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },

    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));


