import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Images from '../../../../assets/images';
import ApiEndPoint from '../../../../services/apiUrl.json';
import { getMethod } from '../../../../services/api';
import { pageDetails, defaultVariable } from '../../../../common/common';
import { DataGrid } from '@material-ui/data-grid';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch} from 'react-redux';
import { fetchCurrentVehicleDetailId }  from '../../../../redux/actions/vehicleList'
import { showProgressBar } from '../../../../common/commonMethod';
import CommonPagination from '../../../../components/commonPagination/commonPagination';
import { styleDetails } from '../../../../common/common';

const VehicleGroupOverview = (props:any) => {
    const currentVehicleId = useSelector((state:any) => { return state.currentVehicleId })
    const pageSize = pageDetails.pageSize;
    const [vehicleOverviewData, setVehicleOverviewData] = useState<any>({});
    const [currentPage, setCurrentPage] = useState(0);
    const [pageData, setPageData] = useState('');
    const stateHistory = useHistory();
    const dispatch = useDispatch();

    const vehicleHistorycolumns = [

            {
              field: "e",
              headerName: "e",
              headerClassName: "empty",
              sortable: false,
              hasFocus: false,
              cellClassName: "empty",
              width: 15,
              maxWidth: 25,
              minWidth: 28,
              disableColumnMenu: true,
              renderCell: (cellValues:any) => {
                return (
                  <div className="empty">
                    {cellValues.value}
                  </div>
                );
              }
            },
          
            {
              field: 'status',
              headerName: 'STATUS',
              headerClassName: "action_columns",
              sortable: false,
              flex: 0.1,
              minWidth: 100,
              disableColumnMenu: true,
              renderCell: (cellValues:any) => {
                return (
                  <div className={`status ${defaultVariable.vehicleStatusColor[cellValues?.value?.toLowerCase()] }`}><span className="status_text">{cellValues.value ? cellValues.value : '-' }</span></div>
                );
              }
            },
            {
              field: "vehicleId",
              headerName: "VEHICLE ID",
              sortable: false,
              hasFocus: false,
              className: "port",
              minWidth: 90,
              flex: 0.1,
              disableColumnMenu: true,
              renderCell: (cellValues:any) => {
                return (
                  <div className="port_icon">
                    {cellValues.value ? cellValues.value : '-'}
                  </div>
                );
              }
            },
            {
              field: 'license',
              headerName: 'LICENSE',
              sortable: false,
              minWidth: 70,
              flex: 0.1,
              disableColumnMenu: true,
              renderCell: (cellValues:any) => {
                return (
                  <>
                    {cellValues.value ? cellValues.value : '-'}
                  </>
                );
              }
            },
          
            {
              field: 'make',
              headerName: 'MAKE',
              sortable: false,
              width: 80,
              disableColumnMenu: true,
              renderCell: (cellValues:any) => {
                return (
                  <>
                    {cellValues.value ? cellValues.value : '-'}
                  </>
                );
              }
            },
            {
              field: 'model',
              headerName: 'MODEL',
              sortable: false,
              width: 70,
              disableColumnMenu: true,
              renderCell: (cellValues:any) => {
                return (
                  <>
                    {cellValues.value ? cellValues.value : '-'}
                  </>
                );
              }
            },
            {
              field: 'driverName',
              headerName: 'DRIVER',
              sortable: false,
              minWidth: 100,
              flex: 0.1,
              disableColumnMenu: true,
              renderCell: (cellValues:any) => {
                return (
                  <>
                    {cellValues.value ? cellValues.value : '-'}
                  </>
                );
              }
            },
          
            {
              field: 'estMiles',
              headerName: 'EST RANGE',
              sortable: false,
              width: 90,
              disableColumnMenu: true,
              renderCell: (cellValues:any) => {
                return (
                  <>
                    {cellValues.value ? cellValues.value : '-'}
                  </>
                );
              }
            },
          
            {
              field: 'soc',
              headerName: 'SOC',
              sortable: false,
              disableColumnMenu: true,
              flex: 0.2,
              minWidth: 150,
              renderCell: (cellValues:any) => {
                return (
                  <div className="soc_level">
                    <div className="text_class">{cellValues.value ? (cellValues.value + '%')  : '-' }</div>
                    <div className="stat_progress">
                      <div className={`bar ${showProgressBar(cellValues.value? cellValues.value : 0) }`} style={{ "width": cellValues.value > 0 ? (cellValues.value + "%") : 0 }}></div>
                    </div>
                  </div>
                );
              }
            },
            {
              field: 'stationId',
              headerName: 'STATION ID',
              sortable: false,
              minWidth: 90,
              flex: 0.1,
              disableColumnMenu: true,
              renderCell: (cellValues:any) => {
                return (
                  <>
                    {cellValues.value ? cellValues.value : '-'}
                  </>
                );
              }
            },
            {
              field: '',
              headerName: '',
              sortable: false,
              width: 60,
              disableColumnMenu: true,
              renderCell: (cellValues:any) => {
                return (
                  <div className="port_icon">
                    <img src={Images.ic_dots} alt="map" />
                    {cellValues.value}
                  </div>
                );
              }
            },
          
            {
              field: "ee",
              headerName: "ee",
              headerClassName: "empty",
              sortable: false,
              hasFocus: false,
              cellClassName: "empty",
              width: 15,
              maxWidth: 25,
              minWidth: 28,
              disableColumnMenu: true,
              renderCell: (cellValues:any) => {
                return (
                  <div className="empty">
                    {cellValues.value}
                  </div>
                );
              }
            },
          
          ];
          

    useEffect(() => {
        getVehicleOverviewDetails()
    }, [currentPage])

    useEffect(() => {
        handlePageData()
    }, [vehicleOverviewData])

    const getVehicleOverviewDetails = async () => {
        const page = currentPage;
        const size = pageSize;
        await getMethod(ApiEndPoint.vehicleGroups, currentVehicleId + "/overview" + "?page=" + page + "&size=" + size).then((res) => {
            setVehicleOverviewData(res.data)
        }).catch((err) => {
            console.log("Error", err)
        })
    }

    const vechicleOverviewDataSet = (value:any) => {
        let historyTableData = []
        if (value && value.vehicleDetails) {
            historyTableData = value.vehicleDetails.map((val:any) => ({ ...val, e: 'a', ee: 'aa', id: val.id }))
        }
        return historyTableData
    }

    const handlePageData = () => {
        let totalPage = vehicleOverviewData && vehicleOverviewData.totalVehicleCount ? vehicleOverviewData.totalVehicleCount : 0;
        if (totalPage > 0) {
            let setPageValue = (currentPage >= 1 ? (pageSize * currentPage) + 1 : 1) + ' - ' + ((pageSize * (currentPage + 1)) < totalPage ? (pageSize * (currentPage + 1)) : totalPage) + ' of '
            setPageData(setPageValue)
        }
    }

    const navigateToVehicleDetails = (e:any) => {
        dispatch(fetchCurrentVehicleDetailId(e?.id));
        stateHistory.push('/vehicleDetail',{id: e?.id})
    }

    const handleCurrentPage = (setPage:any) => {
      setCurrentPage(setPage)
    }

    return (
        <>
            <div className="table_scroll">
                <div className="defult_table" style={styleDetails.styleWidth} >
                    <DataGrid autoHeight className="table"
                        rowHeight={59}
                        headerHeight={30}
                        rows={vechicleOverviewDataSet(vehicleOverviewData)}
                        columns={vehicleHistorycolumns}
                        pageSize={pageSize}
                        onRowClick={(e) => navigateToVehicleDetails(e)}
                    />
                </div>
                <div className="table_footer">
                    <Grid container direction="row" alignItems="center" spacing={0}>
                        <Grid className="text_left" item xs={8}> 
                        {vehicleOverviewData && vehicleOverviewData.totalVehicleCount > pageSize ?
                          <CommonPagination
                            currentPage={currentPage}
                            totalCount={vehicleOverviewData?.totalVehicleCount}
                            callBackCurrentPage={handleCurrentPage}
                          /> : <></>}
                        </Grid>
                        <Grid className="text_right" item xs={4}>
                            <div className="list_number">{pageData} {vehicleOverviewData.totalVehicleCount ? (vehicleOverviewData.totalVehicleCount + ' Vehicles') : ''}</div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}
export default VehicleGroupOverview;