import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useStyles } from '../../../../common/common';
import Images from '../../../../assets/images';

const VehicleGroupRoutes = (props:any) => {
    const classes = useStyles();

    return (
        <>
            <div className="information_section map">
                <div className="vehicle_routes">
                    <div className="map_action_row">
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid className="text_left" item xs={8}>
                                <div className="vehicle_search">
                                    <div className={classes.search1}>
                                        <div className={classes.searchIcon}>
                                            <SearchIcon />
                                        </div>
                                        <InputBase
                                            placeholder="Search route name"
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputInput1,
                                            }}
                                            inputProps={{ 'aria-label': 'search' }}
                                        />
                                    </div>
                                </div>
                                <div className="select_alpha">
                                    <FormControl className="select_control">
                                        <Select labelId="demo-simple-select-label" id="demo-simple-select" >
                                            <MenuItem className="optionlist" value={1}>
                                                Alphabetical (A to Z)</MenuItem>
                                        </Select>
                                    </FormControl>

                                </div>
                                <div className="button_section">
                                    <Button className="btn_white">Create Route</Button>
                                </div>
                            </Grid>
                            <Grid className="show_routes" item xs={4}>
                                Show 8 routes
                            </Grid>
                        </Grid>
                    </div>
                    <div className="vehicle_route_list">
                        <Grid container spacing={3}>
                            <Grid className="route_list_item" item sm={6} md={4} lg={3}>
                                <Card variant="outlined" className="basic_card">
                                    <CardContent>
                                        <div className="route_map"> <img className="img_res" src={Images.route_map_one} alt="" /></div>
                                        <div className="route_details">
                                            <h4>Cleaning: Boyle Heights</h4>
                                            <Grid container>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_sites} alt="sites" /> 12 Sites</Grid>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_hours} alt="hours" /> 10.1 miles</Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_distance} alt="distance" /> 44m</Grid>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_energy} alt="energy" /> 12.1 kWh</Grid>
                                            </Grid>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid className="route_list_item" item sm={6} md={4} lg={3}>
                                <Card variant="outlined" className="basic_card">
                                    <CardContent>
                                        <div className="route_map"> <img className="img_res" src={Images.route_map_two} alt="" /></div>
                                        <div className="route_details">
                                            <h4>Cleaning: Crenshaw</h4>
                                            <Grid container>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_sites} alt="sites" /> 9 Sites</Grid>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_hours} alt="hours" /> 22.6 miles</Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_distance} alt="distance" /> 1h 10m</Grid>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_energy} alt="energy" /> 23.5 kWh</Grid>
                                            </Grid>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid className="route_list_item" item sm={6} md={4} lg={3}>
                                <Card variant="outlined" className="basic_card">
                                    <CardContent>
                                        <div className="route_map"> <img className="img_res" src={Images.route_map_three} alt="" /></div>
                                        <div className="route_details">
                                            <h4>Cleaning: DTLA</h4>
                                            <Grid container>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_sites} alt="sites" /> 14 Sites</Grid>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_hours} alt="hours" /> 12.3 miles</Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_distance} alt="distance" /> 58m</Grid>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_energy} alt="energy" /> 15.9 kWh</Grid>
                                            </Grid>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid className="route_list_item" item sm={6} md={4} lg={3}>
                                <Card variant="outlined" className="basic_card">
                                    <CardContent>
                                        <div className="route_map"> <img className="img_res" src={Images.route_map_four} alt="" /></div>
                                        <div className="route_details">
                                            <h4>Cleaning: Koreatown</h4>
                                            <Grid container>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_sites} alt="sites" /> 8 Sites</Grid>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_hours} alt="hours" /> 9.1 miles</Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_distance} alt="distance" /> 41m</Grid>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_energy} alt="energy" /> 10.7 kWh</Grid>
                                            </Grid>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid className="route_list_item" item sm={6} md={4} lg={3}>
                                <Card variant="outlined" className="basic_card">
                                    <CardContent>
                                        <div className="route_map"> <img className="img_res" src={Images.route_map_five} alt="" /></div>
                                        <div className="route_details">
                                            <h4>Cleaning: MacArthur Pa …</h4>
                                            <Grid container>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_sites} alt="sites" /> 9 Sites</Grid>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_hours} alt="hours" /> 11.2 miles</Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_distance} alt="distance" /> 41m</Grid>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_energy} alt="energy" /> 17.4 kWh</Grid>
                                            </Grid>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid className="route_list_item" item sm={6} md={4} lg={3}>
                                <Card variant="outlined" className="basic_card">
                                    <CardContent>
                                        <div className="route_map"> <img className="img_res" src={Images.route_map_six} alt="" /></div>
                                        <div className="route_details">
                                            <h4>Cleaning: Mid City</h4>
                                            <Grid container>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_sites} alt="sites" /> 14 Sites</Grid>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_hours} alt="hours" /> 14.8 miles</Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_distance} alt="distance" /> 50m</Grid>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_energy} alt="energy" /> 26.3 kWh</Grid>
                                            </Grid>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid className="route_list_item" item sm={6} md={4} lg={3}>
                                <Card variant="outlined" className="basic_card">
                                    <CardContent>
                                        <div className="route_map"> <img className="img_res" src={Images.route_map_seven} alt="" /></div>
                                        <div className="route_details">
                                            <h4>Cleaning: Pico Union</h4>
                                            <Grid container>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_sites} alt="sites" /> 15 Sites</Grid>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_hours} alt="hours" /> 18.9 miles</Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_distance} alt="distance" /> 59m</Grid>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_energy} alt="energy" /> 32.8 kWh</Grid>
                                            </Grid>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid className="route_list_item" item sm={6} md={4} lg={3}>
                                <Card variant="outlined" className="basic_card">
                                    <CardContent>
                                        <div className="route_map"> <img className="img_res" src={Images.route_map_eight} alt="" /></div>
                                        <div className="route_details">
                                            <h4>Cleaning: South LA</h4>
                                            <Grid container>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_sites} alt="sites" /> 7 Sites</Grid>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_hours} alt="hours" /> 39.2 miles</Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_distance} alt="distance" /> 1h 56m</Grid>
                                                <Grid className="r_d_item" item sm={6}><img src={Images.ic_energy} alt="energy" /> 29.4 kWh</Grid>
                                            </Grid>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                    </div>
                </div>
            </div>
        </>
    );
}
export default VehicleGroupRoutes;