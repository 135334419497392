import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'
import ApiEndPoint from '../../services/apiUrl.json';
import { getMethod } from '../../services/api';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import { DataGrid } from '@material-ui/data-grid';
import { defaultVariable } from '../../common/common'

import {
    ComposedChart,
    Line,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    ReferenceLine,
  } from 'recharts';

const CommonChargeSessionDetails = (props:any) => { 
    const [scroll, setScroll] = React.useState('paper');
    const [sessionPowerDetails, setSessionPowerDetails] = useState([]);
    const historyId = props?.historyId;
    const chargeSessionInfo= props?.sessionInfo;
    const open = props?.open;
    const callBackMethod = props?.setHandleClose; 

    const historysessioncolumns = [

        {
          field: "e",
          headerName: "e",
          headerClassName: "empty",
          sortable: false,
          hasFocus: false,
          cellClassName: "empty",
          width: 15,
          maxWidth: 20,
          minWidth: 20,
          disableColumnMenu: true,
          renderCell: (cellValues:any) => {
            return (
              <div className="empty">
                {cellValues.value}
              </div>
            );
          }
        },
      
        {
          field: "startTime",
          headerName: "START TIME",
          sortable: false,
          hasFocus: false,
          width: 150,
          disableColumnMenu: true,
          
        },
      
      
        {
          field: 'endTime',
          headerName: 'END TIME',
          sortable: false,
          flex: 0.2,
          disableColumnMenu: true,
          
        },
        {
          field: 'avgPower',
          headerName: 'AVG POWER',
          sortable: false,
          width: 110,
          disableColumnMenu: true,
          renderCell: (cellValues:any) => {
            return (
              <>
                {cellValues.value ? cellValues.value + ' ' + defaultVariable.defaultUnitType : cellValues.value}
                </>
            );
          }
        },
        {
          field: 'maxPeak',
          headerName: 'MAX PEAK',
          sortable: false,
          width: 110,
          disableColumnMenu: true,
          renderCell: (cellValues:any) => {
            return (
              <>
                {cellValues.value ? cellValues.value + ' ' + defaultVariable.defaultUnitType : cellValues.value}
                </>
            );
          }
        },
      
      
        {
          field: 'totalEnergy',
          headerName: 'TOTAL ENERGY',
          sortable: false,
          width: 120,
          disableColumnMenu: true,
          renderCell: (cellValues:any) => {
            return (
              <>
                {cellValues.value ? cellValues.value + ' ' + defaultVariable.defaultUnit : cellValues.value}
                </>
            );
          }
        },
      
        {
          field: 'kwhRate',
          headerName: 'kWh Rate',
          sortable: false,
          width: 90,
          disableColumnMenu: true,
          renderCell: (cellValues:any) => {
            return (
              <>
                {cellValues.value ? defaultVariable.defaultCurrencySymbol + cellValues.value : cellValues.value}
                </>
            );
          }
        },
      
        {
          field: "ee",
          headerName: "ee",
          headerClassName: "empty",
          sortable: false,
          hasFocus: false,
          cellClassName: "empty",
          width: 15,
          maxWidth: 20,
          minWidth: 20,
          disableColumnMenu: true,
          renderCell: (cellValues:any) => {
            return (
              <div className="empty">
                {cellValues.value}
              </div>
            );
          }
        },
      
      ];

    useEffect(() => {
        getChargeSession(historyId)
    }, [historyId])

    const getChargeSession = async (historyId:any) => {
        await getMethod(ApiEndPoint.depotInformation, historyId + "/charge-history-details").then((res) => {
          if(res && res.data.chargeHistoryDetails){
            let sessionTableData = res.data.chargeHistoryDetails.map((val:any, index:any) => ({ ...val, e: 'a', ee: 'aa', maxPeak:val.maxPeak, id: index }))
            setSessionPowerDetails(sessionTableData?  sessionTableData : [])
          }
        }).catch((err) => {
          console.log("Error", err)
        })
      }

    return (
        <>
            <div className="dialog_box">

                <Dialog {...props} className="dialog_container"
                    open={open}
                    onClose={callBackMethod}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <div className="dialog_title">
                        <h4>Charge Session Detail</h4>
                        <IconButton className="closed_btn full_right" onClick={callBackMethod}>
                            <span className="material-icons">cancel</span>
                        </IconButton>

                    </div>
                    <div className="dialog_body">
                        <div className="history_charging_list">
                            <Grid container>
                                <Grid item xs={5}>
                                    <div className="h_charge_list">
                                        <div className="label">Start Charge</div>
                                        <div className="result">{chargeSessionInfo.startChargeTime ? chargeSessionInfo.startChargeTime : '-'}</div>
                                        <div className="label">End Charge</div>
                                        <div className="result">{chargeSessionInfo.endChargeTime ? chargeSessionInfo.endChargeTime : '-'}</div>
                                        <div className="label">Port</div>
                                        <div className="result"><a>{chargeSessionInfo.portInformation ? chargeSessionInfo.portInformation : '-'}</a></div>
                                        <div className="label">Driver Name</div>
                                        <div className="result">{chargeSessionInfo.driverName ? chargeSessionInfo.driverName : '-'}</div>
                                        <div className="label">RFID</div>
                                        <div className="result"><a>{chargeSessionInfo.rfId ? chargeSessionInfo.rfId : '-'}</a></div>
                                        <div className="label">Vehicle ID</div>
                                        <div className="result"><a>{chargeSessionInfo.vehicleID ? chargeSessionInfo.vehicleID : '-'}</a></div>
                                        <div className="label">Energy (kWh)</div>
                                        <div className="result">{chargeSessionInfo.energyKWh ? chargeSessionInfo.energyKWh : '-'}</div>
                                    </div>
                                </Grid>
                                <Grid item xs={7}>{sessionPowerDetails.length >= 1 ?
                                    <ResponsiveContainer width={'99%'} height={150}>
                                        <ComposedChart
                                            data={sessionPowerDetails}
                                            margin={{
                                                top: 3,
                                                right: 3,
                                                bottom: 3,
                                                left: 2,
                                            }}
                                        >
                                            <CartesianGrid vertical={false} fill="#EEEEEE" stroke="#FFFFFF" strokeOpacity={1} fillOpacity={0.5} strokeWidth={2.5} />
                                            <XAxis hide />
                                            <YAxis tickMargin={-1} dy={4} width={42} unit=' KW' tickCount={4} interval={0} axisLine={false} tickLine={false} style={{ fontSize: '8px', color: '#4D4D4F' }} />
                                            <Area dataKey="totalEnergy" fill="#65A844" stroke="#65A844" strokeOpacity={0.5} fillOpacity={0.2} />
                                            <Line dataKey="avgPower" stroke="#25556E" dot={false} strokeDasharray="2 1" />
                                            <ReferenceLine x={0} stroke="#65A844" strokeOpacity={1} alwaysShow={true} strokeWidth={2.5} />
                                            <ReferenceLine x={sessionPowerDetails ? sessionPowerDetails.length - 1 : 0} stroke="#65A844" strokeOpacity={1} alwaysShow={true} strokeWidth={2.5} />
                                        </ComposedChart>
                                    </ResponsiveContainer> : ''}
                                </Grid>
                            </Grid>
                        </div>
                    </div>

                    <DialogContent dividers={scroll === 'paper'}>
                        <DialogContentText component="div"
                            id="scroll-dialog-description"

                        >
                            <div className="dialog_data_table">
                                <h5>Power Flow Details</h5>

                            </div>
                            <div className="table_scroll">
                                <div className="defult_table" style={{ width: '100%' }}>

                                    <DataGrid autoHeight className="table"
                                        rowHeight={59}
                                        headerHeight={30}
                                        rows={sessionPowerDetails}
                                        columns={historysessioncolumns}
                                        disableSelectionOnClick />

                                </div>
                                <div className="table_footer">
                                    <Grid container direction="row" alignItems="center" spacing={0}>
                                        <Grid className="text_left" item xs={12}>
                                            <Button className="btn_white blue_bg ml_0">Download CSV</Button>
                                        </Grid>

                                    </Grid>
                                </div>
                            </div>


                        </DialogContentText>
                    </DialogContent>

                </Dialog>
            </div>
                            
        </>
    );
}

CommonChargeSessionDetails.propTypes = {
  historyId: PropTypes.number,
  callBackMethod: PropTypes.func,
  chargeSessionInfo: PropTypes.any,
  open: PropTypes.any
};

CommonChargeSessionDetails.defaultProps = {
  chargeSessionInfo: {},
  open: false,
}; 
export default CommonChargeSessionDetails;