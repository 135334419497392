import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useHistory} from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { DataGrid } from '@material-ui/data-grid';
import IconButton from '@material-ui/core/IconButton';
import {alertcolumns, alertrow, columns, rows} from 'data/driverGroupDetail'
import Images from 'assets/images';

const drawerWidth = 200;
function TabPanel(props: any) {
  const { children, value, index, ...other } = props;



  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}






const useStyles = makeStyles((theme) => ({

  search: {
    position: 'relative',
     '&:hover': {
     // backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '145px',
    background:'#fff',
    borderRadius:'5px'
  },
  searchIcon: {
    padding: '0px 10px',
    height: '100%',
    //width:'14px',
    fontSize:'14px',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color:'#999',
  },
  
  inputRoot: {
    color: '#999',
  },
  inputInput: {
    padding: theme.spacing(1.5, 1, 1.5, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
    transition: theme.transitions.create('width'),
    fontSize:'12px',
    width: '100px',
   
  },


  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },

  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },


  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(8) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },





  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));




const DriverGroupDetail = (staticContext: any, props: any) => {

  const history = useHistory();
  const [opend, setOpend] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpend(true);
  };

  const handleDrawerClose = () => {
    setOpend(false);
  };




  const [isVisible, setIsVisible] = useState(true);




  const listenToScroll = () => {
    let heightToHideFrom = 50;
    const winScroll = document.body.scrollTop ||
      document.documentElement.scrollTop;
    if (winScroll > heightToHideFrom) {
      isVisible &&      // to limit setting state only the first time         
        setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () =>
      window.removeEventListener("scroll", listenToScroll);
  }, [])



  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const navigateToDriverInfo = () => {
    history.push('/driverInfo')
}

  return (

      <>
        {/*----Page Mid Section Start----*/}
        <main className={classes.content}>
          <div className={isVisible ? '' : 'scroll_effect'}>
            <div className="depot_details vehicle_detail">
              <div className="depot_detail_head position_fixed_head">

              <div className="depot_information">
                  {/*----EVSE Depot Map----*/}

                  <div className="depot_location_map no-border">
                  <img src={Images.shift_driver_group} alt="shift_driver_group" />
                  </div>

                  {/*----EVSE Depot Information----*/}
                  <div className="depot_info">
                    <div className="depot_name">Collect and Charge</div>

                   {isVisible
                      &&
                      <div className="port_details">
                        <span className="alert">
                            <img src={Images.IC_alert_12x14} alt="Depot Alert" />
                            3 Alerts
                        </span>
                        <span className="port">
                        <img src={Images.ic_driver_group} alt="Driver Group" />
                        29 Total Drivers
                        </span>
                        
                      
                      </div>
                    }
                  </div>
                </div>

                <div className="stat_list text_right">
                  <div className="status driver_onduty">
                    <span className="status_text">On Duty</span>
                    <span className="status_number">15</span>
                  </div>
                  <div className="status driver_not_available">
                    <span className="status_text">Off Duty</span>
                    <span className="status_number">10</span>
                  </div>
                  <div className="status driver_suspended">
                    <span className="status_text">Suspended</span>
                    <span className="status_number">4</span>
                  </div>
                 

                </div>
          
              </div>




              <div className="detail_page_tab">
                <AppBar className="defult_tab position_fixed_nav" position="static" color="default">
                <Tabs className="tab_nav"
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable force tabs example"
                  >
                   
                    <Tab className="tab_nav_item" label="Overview" {...a11yProps(0)} />
                    <Tab className="tab_nav_item" label="Information" {...a11yProps(1)} />
                    <Tab className="tab_nav_item" label={<Badge className="alert_badge" badgeContent={3} color="primary">
                      Alerts &nbsp;  &nbsp; &nbsp; 
                    </Badge>} {...a11yProps(2)} />

      
                  </Tabs>
                  
                </AppBar>
 
                <TabPanel className="tab_detail" {...props} value={value} index={0}>
                <div className="table_scroll">
                <div className="defult_table" style={{ width: '100%' }}>

<DataGrid autoHeight className="table"
  rowHeight={59}
  headerHeight={30}
  rows={rows}
  columns={columns}
  pageSize={12}
  onRowClick={() => navigateToDriverInfo()}  />

</div>
                    <div className="table_footer">
                      <Grid container direction="row" alignItems="center" spacing={0}>
                        <Grid className="text_left" item xs={8}>
                          <div className="pagination">
                            <span><img src={Images.ic_first_page} alt="first page" /></span>
                            <span><img src={Images.ic_chevron_left} alt="back page" /></span>
                            <span>1</span>
                            <span><img src={Images.ic_chevron_right} alt="back page" /></span>

                          </div>
                         
                        </Grid>
                        <Grid className="text_right" item xs={4}>
                          <div className="list_number">1-50 of 45,257 charge session</div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </TabPanel>

               


                <TabPanel className="" {...props} value={value} index={1}>
                <div className="information_section">
                    <Grid container spacing={2}>
                      <Grid className="mid_section_left" item xs={12} sm={12} md={8}>
                        <Card variant="outlined" className="basic_card">
                          <CardContent>
                            {/*----Title Start----*/}
                            <div className="title_row">
                              <h2>DRIVER GROUP</h2>
                              <div className="action_icon">
                                <IconButton className="top_action_btn">
                                  <img src={Images.ic_edit} alt="edit" />
                                </IconButton>
                              </div>
                              <div className="clearfix"></div>
                            </div>
                            {/*----Title End----*/}

                            {/*----Information Start----*/}
                            <div className="info_detail">
                              <Grid container >
                                <Grid className="" item xs={12} sm={6} md={6}>
                                  <div className="tb_info_section mt-13">
                                    <div className="label">GROUP NAME</div>
                                    <div className="result">Collect and charge</div>
                                  </div>
                                  <div className="tb_info_section">
                                    <div className="label">ORGANIZATION</div>
                                    <div className="result">Blink Mobility</div>
                                  </div>
                                  <div className="tb_info_section">
                                    <div className="label">DESCRIPTION</div>
                                    <div className="result">Collect and charge vehicle for service. Drop-off at EVSE locations after charge is complete.
                                    </div>
                                  </div>
                                 
                                </Grid>
                                <Grid className="" item xs={12} sm={6} md={6}>
                                  <div className="tb_info_section mt-13">
                                    <div className="label">PRIMARY CONTACT</div>
                                    <div className="result">
                                      <span className="label">Name</span>
                                      <span className="result">Kimberly Mirron</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">Tel</span>
                                      <span className="result">+1 (414) 515-4555</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">Mob</span>
                                      <span className="result">+1 (310) 555-5555</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">Fax</span>
                                      <span className="result">+1 (414) 555-6666</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">Email</span>
                                      <span className="result">kim.mirron@email.com</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">Note</span>
                                      <span className="result">Available Mon - Fri from 8am -
                                        6pm. Please call mobile after
                                        hours.</span>
                                    </div>
                                  </div>

                                
                                
                                </Grid>
                              </Grid>
                            </div>
                            {/*----Information End----*/}
                          </CardContent>
                        </Card>

                   


                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                      <Card variant="outlined" className="basic_card">
              <CardContent>
                <div className="title_row">
                  <h2>PERMISSIONS</h2>
                  <div className="action_icon">
                    <IconButton className="top_action_btn">
                      <img src={Images.ic_edit} alt="edit" />
                    </IconButton>
                  </div>
                  <div className="clearfix"></div>
                </div>
                <div className="info_detail">
                  <div className="zone_section  mt-13">
                    <div className="tb_zone_section">
                      <div className="label">FLEET VEHICLE</div>
                      <div className="result">Super Duty</div>
                      <div className="result">E-Transit</div>
                    </div>
                  </div>
                  <div className="zone_section">
                    <div className="tb_zone_section">
                      <div className="label">EVSE LOCATION</div>
                      <div className="result">San Diego Zoo</div>
                      <div className="result">San Diego Convention Center</div>
                      <div className="result">Airport</div>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
                      </Grid>
                    </Grid>
                  </div>

                </TabPanel>
              



                <TabPanel className="tab_detail" {...props} value={value} index={2}>
                  <div className="table_scroll">
                    <div className="defult_table" style={{ width: '100%' }}>

                      <DataGrid autoHeight className="table"
                        rowHeight={59}
                        headerHeight={30}
                        rows={alertrow}
                        columns={alertcolumns}
                        pageSize={12}

                        disableSelectionOnClick />

                    </div>
                    <div className="table_footer">
                      <Grid container direction="row" alignItems="center" spacing={0}>
                        <Grid className="text_left" item xs={8}>
                          <div className="pagination">
                            <span><img src={Images.ic_first_page} alt="first page" /></span>
                            <span><img src={Images.ic_chevron_left} alt="back page" /></span>
                            <span>1</span>
                            <span><img src={Images.ic_chevron_right} alt="back page" /></span>
                          </div>
                          <Button className="btn_white">Clear new alerts</Button>
                          <Button className="btn_white ">Download CSV</Button>
                        </Grid>
                        <Grid className="text_right" item xs={4}>
                          <div className="list_number">1-50 of 45,257 alerts</div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>

                </TabPanel>

              </div>







            </div>
          </div>
        </main>

        {/*----Page Mid Section End----*/}
    </>
  );
}

export default DriverGroupDetail;