export const dateFormats: any= {
    dateTimeFormat: "MMM D, YYYY, h:mmA",
    dateFormmat: 'YYYY-MM-DD',
    dayTypeFormat: 'd',
    timFormat: 'h:mm A',
    hourTypeFormat: 'hours',
    check24TimeFormat: '12:00 AM',
    set24TimeFormat: '11:59 PM',
    check12TimeFormat: "00:00",
    set12TimeFormat: "23:59",
    
  }