import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import DepotAlerts from './depotAlerts/depotAlerts';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Images from 'assets/images';
import { defaultVariable,useStyles } from 'common/common';
import { a11yProps } from 'common/commonMethod';
import { getMethod } from 'services/api';
import ApiEndPoint from 'services/apiUrl.json';
import moment from 'moment';
import { useSelector } from 'react-redux';
import DepotChargeHistory from './depotChargeHistory/depotChargeHistory';
import DepotAnalytics from './depotAnalytics/depotAnalytics';
import  DepotStationDetailsLocation from './depotStationDetailsLocation';
import EditDepotStationInformation from './editDepotStationDetails/editDepotStationDetails';
import TabPanel from "common/TabPanel";
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

const DepotStationDetails = (staticContext: any,props:any) => {
  const currentStationId = useSelector((state:any) => {return state.currentStationId;});
  const [stationDetails, setstationDetails] = useState<any>({});
  const [portDetail, setPortDetail] = useState<any>({});
  const [isVisible, setIsVisible] = useState(true);
  const [openStationDetail, setStationDetailOpen] = useState(false);

  useEffect(() => {
    getDepotStationData();
  }, []);

  const getDepotStationData = async () => {
    await getMethod(ApiEndPoint.evseStationInformation,currentStationId + '/station-details').then((res) => {
        setstationDetails(res.data);
        if (res && res?.data && res?.data?.ports?.length >= 1) {
          setPortDetail(res?.data?.ports[0]);
        }
      }).catch((err) => {
        console.log('Error', err);
      });
  };
  
  const listenToScroll = () => {
    let heightToHideFrom = 50;
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll > heightToHideFrom) {
      isVisible && // to limit setting state only the first time
        setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };
  const stationDetailopen = () => () => {
    setStationDetailOpen(true);
  };
  
  const stationDetailclose = () => {
    setStationDetailOpen(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event:any, newValue:any
    ) => {
    setValue(newValue);
  };

  const [openEnergyInfo, setEnergyInfoOpen] = useState(false);
  const [scrollEnergyInfo, setEnergyInfoScroll] = useState('paper');

    const energyInfoOpen = (scrollType: any) => () => {
        setEnergyInfoOpen(true);
        setEnergyInfoScroll(scrollType);
      };
      
      const energyInfoClose = () => {
        setEnergyInfoOpen(false);
      };
  
  return (
    <>
   
   <div className="dialog_box">
            <Dialog className="dialog_container edit_detail_section md dialog_container_center" {...props}
             open={openEnergyInfo}
             onClose={energyInfoClose}
               scroll={scrollEnergyInfo}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description">
                <div className="dialog_title">
                    <h4>Energy Information</h4>
                    <div className="button_row mt-0 full_right">
                        <Button className="btn_white text_btn_theme" onClick={energyInfoClose}>Cancel</Button>
                        <Button className="btn_white blue_bg">Save Changes</Button>
                    </div>  
                </div>
                <DialogContent dividers='paper' {...props}>
                    <DialogContentText component="div" id="scroll-dialog-description">
                        <div className="defult_form">
                            <div className="section_group">
                            <h5>* Required information</h5>
                                <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={4}>
                                    <Grid item xs={7}>
                                          <div className="form_col">
                                            <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                                              <Grid item xs={12}>
                                                <label>Maximum Amps *</label>
                                                <Select labelId="demo-simple-select-label"  variant="outlined" >
                                                  <MenuItem className="defult_select_option" value={1}>24 Amps</MenuItem>
                                                  <MenuItem className="defult_select_option" value={2}>26 Amps</MenuItem>
                                                  <MenuItem className="defult_select_option" value={3}>28 Amps</MenuItem>
                                              </Select>
                                              </Grid> 
                                            </Grid> 
                                          </div> 
                                      </Grid>
                                    </Grid>   
                                  </div>
                                </div>             
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>




      {/*----Page Mid Section Start----*/}
      <main className={classes.content}>
        <div className={isVisible ? 'mid_section_inside details_pages': 'scroll_effect'}>
          <div className="depot_details station_details">
            <div className="depot_detail_head position_fixed_head">
              <div className="depot_information">
                {/*----EVSE Depot Map----*/}

                <div className="depot_location_map">
                  <img src={Images.ic_port_charger} alt="Port" />
                </div>

                {/*----EVSE Depot Information----*/}
                <div className="depot_info">
                  <div className="depot_name">
                    <span>{stationDetails?.serialNumber ? stationDetails.serialNumber : ''}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="detail_page_tab">
              <AppBar className="defult_tab position_fixed_nav" position="static" color="default">
                <div className="tab_menu_select">
                  <FormControl className="tab_menu_select_control">
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={1}>
                      <MenuItem className="optionlist" value={1}>Station Status: Public</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <Tabs className="tab_nav"
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable force tabs example">
                  
                <Tab className="tab_nav_item" label="Station Detail" {...a11yProps(0)}/>
                <Tab className="tab_nav_item" label="Charge History" {...a11yProps(1)}/>
                <Tab className="tab_nav_item" label="Analytics" {...a11yProps(2)}/>
                <Tab className="tab_nav_item" label={<Badge className="alert_badge" badgeContent={3} color="primary">
                        Alerts &nbsp; &nbsp; &nbsp;
                </Badge> } {...a11yProps(3)} />
                </Tabs>
              
              </AppBar>

              <TabPanel value={value} index={0}>
                <div className="information_section">
                  <Grid container spacing={2}>
                    <Grid className="mid_section_left" item xs={12} sm={12} md={12} lg={8}>
                      <Card variant="outlined" className="basic_card">
                        <CardContent>
                          {/*----Title Start----*/}
                          <div className="title_row">
                          <h2>STATION INFORMATION</h2>
                            <div className="action_icon" onClick={stationDetailopen()}>
                              <IconButton className="top_action_btn" >
                                <img src={Images.ic_edit} alt="edit" />
                              </IconButton>
                            </div>
                            <div className="clearfix"></div>
                          </div>
                          {/*----Title End----*/}

                          {/*----Information Start----*/}
                          <div data-testid="stationInfo" className="info_detail">
                            <Grid container>
                              <Grid data-testid='stationDataTest' className="" item xs={12}>
                                <div className="tb_info_section mt-13">
                                  <div className="row">
                                    <span className="label">Station name</span>
                                    <span className="result">{stationDetails?.serialNumber ? stationDetails.serialNumber : '-'}</span>
                                  </div>
                                  <div className="row">
                                    <span className="label">Zone</span>
                                    <span className="result">{stationDetails?.zone ? stationDetails.zone : '-'}</span>
                                  </div>
                                  <div className="row">
                                    <span className="label">Description/Note</span>
                                    <span data-testid='depoDescriptionTest' className="result">{stationDetails?.description ? stationDetails.description : '-'}{' '}</span>
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          {/*----Information End----*/}
                        </CardContent>
                      </Card>

                      <Card variant="outlined" className="basic_card">
                        <CardContent>
                          {/*----Title Start----*/}
                          <div className="title_row">
                            <h2>ENERGY INFO</h2>
                            <div className="action_icon">
                              <IconButton className="top_action_btn" onClick={energyInfoOpen('paper')}>
                                <img src={Images.ic_edit} alt="edit" />
                              </IconButton>
                            </div>
                            <div className="clearfix"></div>
                          </div>
                          {/*----Title End----*/}
                          
                          <EditDepotStationInformation {...props}
                          open={openStationDetail}
                          onClose ={stationDetailclose}
                          details={stationDetails}
                          />
                          
                          {/*----Information Start----*/}
                          <div data-testid="portInfo" className="info_detail">
                            <Grid container>
                              <Grid className="" item xs={12}>
                                <div data-testid='energyInfoData' className="tb_info_section mt-13">
                                  <div className="row">
                                    <span className="label">Maximum Amps</span>
                                    <span className="result">{portDetail?.maximumAmps ? portDetail.maximumAmps + ' Amps' : '-'}</span>
                                  </div>
                                  <div className="row">
                                    <span className="label">Voltage</span>
                                    <span className="result">{portDetail?.voltage ? portDetail.voltage + ' Volts' : '-'}</span>
                                  </div>
                                  <div className="row">
                                    <span className="label">Maximum Power</span>
                                    <span className="result">{portDetail?.maximumPower ? portDetail.maximumPower + ' kW' : '-'}</span>
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          {/*----Information End----*/}
                        </CardContent>
                      </Card>

                      <Card variant="outlined" className="basic_card">
                        <CardContent>
                          < DepotStationDetailsLocation />
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4}>
                      {stationDetails && stationDetails?.ports?.map((portValue:any,index:any) => (
                          <Card variant="outlined"
                          className="basic_card port_card"
                          key={index}>
                            <CardContent>
                              {/*----Title Start----*/}
                              <div className="title_row">
                                <h2>{portValue?.portName? portValue.portName: '-'}{' '}</h2>
                                <div className="full_right">
                                  {/* <div class="status charging"><span class="status_text">{portValue?.status ? portValue.status : '-'}</span></div> */}
                                  <div className={`status ${ defaultVariable.portStatusColor[portValue?.status?.toLowerCase()]}`}>
                                    <span className="status_text">{portValue?.status ? portValue.status : '-'}</span>
                                  </div>
                                </div>
                                <div className="clearfix"></div>
                              </div>
                              {/*----Title End----*/}
                              {/*----Information Start----*/}
                              <div className="info_detail">
                                <div  className="port_details tb_info_section">
                                  <div className="row">
                                    <span className="label">Connection type</span>
                                    <span className="result">{portValue?.connectionType? portValue.connectionType: '-'}</span>
                                  </div>
                                  {portValue?.status?.toLowerCase() ==='connected' || portValue?.status?.toLowerCase() === 'charging' || portValue?.status?.toLowerCase() === 'complete' ? (
                                    <div className="row">
                                      <span className="label">Estimate distance</span>
                                      <span className="result">{portValue?.estMiles ? portValue.estMiles : '-'}</span>
                                    </div>) : (
                                    <div></div>
                                  )}
                                  {portValue?.status?.toLowerCase() === 'connected' || portValue?.status?.toLowerCase() === 'charging' || portValue?.status?.toLowerCase() === 'complete' ? (
                                    <div className="row">
                                      <span className="label">State of Charge</span>
                                      <span className="result">{portValue?.soc ? portValue.soc + '%' : '-'}{' '}</span>
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                  {portValue?.status?.toLowerCase() === 'charging' || portValue?.status?.toLowerCase() === 'complete' ? (
                                    <div className="row">
                                      <span className="label">Start charge time</span>
                                      <span className="result">{portValue?.chargingTime ? moment( portValue.chargingTime).format('h:mm A'): '-'}{' '}</span>
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                  {portValue?.status?.toLowerCase() === 'charging' || portValue?.status?.toLowerCase() === 'complete' ? (
                                    <div className="row">
                                      <span className="label">Charge time</span>
                                      <span className="result">{portValue?.chargeTime ? portValue.chargeTime: '-'}</span>
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                  {portValue?.status?.toLowerCase() ==='reserved' || portValue?.status?.toLowerCase() === 'charging' || portValue?.status?.toLowerCase() === 'complete' ? (
                                    <div className="row">
                                      <span className="label">Vehicle ID</span>
                                      <span className="result">{portValue?.vehicleId ? portValue.vehicleId : '-'}</span>
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                  {portValue?.status?.toLowerCase() === 'reserved' || portValue?.status?.toLowerCase() === 'charging' || portValue?.status?.toLowerCase() === 'complete' ? (
                                    <div className="row">
                                      <span className="label">RFID reserved the port</span>
                                      <span className="result">{portValue?.rfid ? portValue.rfid : '-'}</span>
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                  {portValue?.status?.toLowerCase() ==='reserved' || portValue?.status?.toLowerCase() === 'charging' || portValue?.status?.toLowerCase() ==='complete' ? (
                                    <div className="row">
                                      <span className="label">User reserved the port</span>
                                      <span className="result">{portValue?.driverName ? portValue.driverName : '-'}</span>
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}

                                  <div className="button_row">
                                    <Grid container direction="row" alignItems="center" spacing={0}>
                                      <Grid className="text_left" item xs={12}>
                                        {portValue?.status?.toLowerCase() ==='connected' ? (<Button className="btn_white blue_bg">Start Charge</Button>) : ('')}
                                        {portValue?.status?.toLowerCase() === 'reserved' ? (<Button className="btn_white blue_bg">Cancel Reservation</Button>) : ('')}
                                        {portValue?.status?.toLowerCase() === 'charging' ? (<Button className="btn_white blue_bg">Stop Charge</Button>) : ('')}
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                        ))}

                      <Card variant="outlined" className="basic_card">
                        <CardContent>
                          {/*----Title Start----*/}
                          <div className="title_row">
                            <h2>STATION SPECIFICATION</h2>
                            <div className="clearfix"></div>
                          </div>
                          {/*----Title End----*/}
                          {/*----Information Start----*/}
                          <div className="info_detail">
                            <div className="port_details zone_card mt-16 tb_info_section">
                              <div className="row">
                                <span className="label">Charger Type</span>
                                <span className="result">{stationDetails?.chargerType ? stationDetails.chargerType : '-'}</span>
                              </div>
                              <div className="row">
                                <span className="label">Serial Number</span>
                                <span className="result">{stationDetails?.serialNumber ? stationDetails.serialNumber: '-'}</span>
                              </div>
                              <div className="row">
                                <span className="label">GUID/CPID</span>
                                <span className="result">{stationDetails?.guid ? stationDetails.guid : '-'}</span>
                              </div>
                              <div className="row">
                                <span className="label">Make</span>
                                <span className="result">{stationDetails?.chargerMaker ? stationDetails.chargerMaker : '-'}</span>
                              </div>
                              <div className="row">
                                <span className="label">Model</span>
                                <span data-testid="modelInfo"  className="result">{stationDetails?.chargerModel ? stationDetails.chargerModel : '-'}</span>
                              </div>
                              <div className="row">
                                <span className="label">Revision</span>
                                <span className="result">{stationDetails?.revision ? stationDetails.revision : '-'}</span>
                              </div>
                              <div className="row">
                                <span className="label">MEID</span>
                                <span className="result">{stationDetails?.meid ? stationDetails.meid : '-'}</span>
                              </div>
                              <div className="row">
                                <span className="label">Uni Board No.</span>
                                <span className="result">{stationDetails?.uniBoardNumber ? stationDetails.uniBoardNumber : '-'}</span>
                              </div>
                              <div className="row">
                                <span className="label">Wifi MAC address</span>
                                <span className="result">{stationDetails?.wifiMacAddress ? stationDetails.wifiMacAddress : '-'}</span>
                              </div>
                              <div className="row">
                                <span className="label">Ethernet MAC</span>
                                <span className="result">{stationDetails?.ethernetMac ? stationDetails.ethernetMac : '-'}</span>
                              </div>
                              <div className="row">
                                <span className="label">IP address</span>
                                <span className="result">{stationDetails?.ipAddress ? stationDetails.ipAddress : '-'}</span>
                              </div>
                              <div className="row">
                                <span className="label">Software Profile</span>
                                <span className="result">{stationDetails?.softwareProfile ? stationDetails.softwareProfile : '-'}</span>
                              </div>
                              <div className="row">
                                <span className="label">Current Version</span>
                                <span className="result">{stationDetails?.currentVersion ? stationDetails.currentVersion : '-'}</span>
                              </div>
                              <div className="row">
                                <span className="label">Deploy Date</span>
                                <span className="result"> {stationDetails?.deployDate ? moment(stationDetails.deployDate).format('MMM D, YYYY; h:mmA') : '-'} </span>
                              </div>
                              <div className="row">
                                <span className="label">Last Update</span>
                                <span className="result">{stationDetails?.lastUpdate ? moment(stationDetails.lastUpdate).format('MMM D, YYYY; h:mmA') : '-'} </span>
                              </div>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </div>
              </TabPanel>
              <TabPanel  className="tab_detail" {...props} value={value} index={1}>
                <DepotChargeHistory />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <DepotAnalytics />
              </TabPanel>
              <TabPanel  className="tab_detail" {...props} value={value} index={3}>
                <DepotAlerts />
              </TabPanel>
            </div>
          </div>
        </div>
      </main>
      {/*----Page Mid Section End----*/}
    </>
  );
};

export default DepotStationDetails;