import React from "react";
import 'pages/vehicleDetail/vehicleDetail.scss';
import PropTypes from "prop-types";

const VehicleDetailMapMarker = ({ text,soc, tooltip, $hover }: any) => {
    const handleClick = () => {
        console.log(`You clicked on ${tooltip}  ${$hover}`);
    };

    return (
        <div className={"vehicle_icon_dynamic_maps" } onClick={handleClick}>

      <span title={tooltip}  >
          { <div className="stat_progress vehicleLocationSoc">
              <div className={`bar vehicleSocColor vehicleLocationHt`} style={{ "width": soc > 0 ? (soc + "%") : 0 }}/>
          </div>
          }

          {text}
      </span>
        </div>
    );
};

export default VehicleDetailMapMarker;

VehicleDetailMapMarker.propTypes = {
    children: PropTypes.node,
    lat: PropTypes.any,
    lng: PropTypes.any
}