const currentStationId = '';

export const fetchCurrentStationReducer = (state = currentStationId, action: any) => {
    switch(action.type) {
        case 'FETCH_CURRENT_STATION_ID': {
            state = action.payload
            return state
        }   
        default: 
            return state;
    }
}
