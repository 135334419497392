import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'
import CommonTableDetails from 'components/commonTableDetails/commonTableDetails'
import ApiEndPoint from 'services/apiUrl.json';
import { getMethod } from 'services/api';
import { pageDetails } from 'common/common';
import moment from 'moment';
import { useSelector } from 'react-redux'
import CommonPagination from 'components/commonPagination/commonPagination';
import { styleDetails } from 'common/common';
import CommonDownloadCsv from 'components/commonDownloadCsv/commonDownloadCsv';
import { dateFormats } from 'common/commonDateFormats'

const VehicleDetailAlerts = (props: any) => {
  const currentVehicleDetailId = useSelector((state: any) => { return state.currentVehicleDetailId })
  const pageSize = pageDetails.pageSize;
  const [vehicleAlert, setVehicleAlert] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(0);
  const [pageData, setPageData] = useState('');
  const [vehicleDetailDownloadCsv, setVehicleDetailDownloadCsv] = useState();
  
  const fileHeader =[
    {label: 'TIME',key: 'alertTime'},
    {label: 'ODOMETER',key: 'odometer'},
    {label: 'ALERT',key: 'alert'}
    
  ];
 
  const fileName= 'VehicleDetailAlerts.csv'

  const alertcolumns = [
    {
      field: "e",
      headerName: "e",
      headerClassName: "empty",
      sortable: false,
      hasFocus: false,
      cellClassName: "empty",
      width: 15,
      maxWidth: 25,
      minWidth: 28,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <div className="empty">
            {cellValues.value}
          </div>
        );
      }
    },

    {
      field: "alertTime",
      headerName: "TIME",
      sortable: false,
      hasFocus: false,
      cellClassName: "bold_cell_text",
      minWidth: 180,
      flex: 0.1,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <div className="alert_unread_mark">
            <span className="unread dnone"></span>
            {cellValues.value}
          </div>
        );
      }
      
    },
    {
      field: 'odometer',
      headerName: 'ODOMETER',
      sortable: false,
      width: 130,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <>
            {cellValues.value ? cellValues.value : '-'}
          </>
        );
      }
    },


    {
      field: 'alert',
      headerName: 'ALERT',
      sortable: false,
      minWidth: 200,
      flex: 0.3,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <>
            {cellValues.value ? cellValues.value : '-'}
          </>
        );
      }
    },


    {
      field: "ee",
      headerName: "ee",
      headerClassName: "empty",
      sortable: false,
      hasFocus: false,
      cellClassName: "empty",
      width: 15,
      maxWidth: 25,
      minWidth: 28,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <div className="empty">
            {cellValues.value}
          </div>
        );
      }
    },

  ];

  useEffect(() => {
    getVehicleDetailAlert()
  }, [currentPage])
  useEffect(() => {
    if(vehicleAlert?.totalAlerts >0){
    getVehicleDetailDownloadCsv();
  }
  }, [vehicleAlert?.totalAlerts]);
  useEffect(() => {
    handlePageData()
  }, [vehicleAlert])

  const getVehicleDetailAlert = async () => {
    const page = currentPage;
    const size = pageSize;
    await getMethod(ApiEndPoint.vehicleService, currentVehicleDetailId + "/alerts" + "?page=" + page + "&size=" + size).then((res) => {
      setVehicleAlert(res.data)
    }).catch((err) => {
      console.log("Error", err)
    })
  }

  const getVehicleDetailDownloadCsv = async () => {
    await getMethod(ApiEndPoint.vehicleService, currentVehicleDetailId + "/alerts" + "?page=" + 0 + "&size=" + vehicleAlert.totalAlerts).then((res) => {
      setVehicleDetailDownloadCsv(res.data);
      }).catch((err) => {
        console.log('Error', err);
      });
  };

  const alertDataSet = (value: any) => {
    let alertTableData = []
    if (value && value.alertsList) {
      alertTableData = value.alertsList.map((val: any) => ({ ...val, e: 'a', ee: 'aa', id: val.alertId,alertTime: moment(val.alertTime).format(dateFormats.dateTimeFormat),}))
    }
    return alertTableData
  }

  const handlePageData = () => {
    let totalPage = vehicleAlert && vehicleAlert.totalAlerts ? vehicleAlert.totalAlerts : 0;
    if (totalPage > 0) {
      let setPageValue = (currentPage >= 1 ? (pageSize * currentPage) + 1 : 1) + ' - ' + ((pageSize * (currentPage + 1)) < totalPage ? (pageSize * (currentPage + 1)) : totalPage) + ' of '
      setPageData(setPageValue)
    }
  }

  const handleCurrentPage = (setPage: any) => {
    setCurrentPage(setPage)    
  }

  return (
    <>
      <div className="table_scroll">
        <div className="defult_table" style={styleDetails.styleWidth} >
          <CommonTableDetails
            rowHeight={59}
            headerHeight={30}
            rowDetails={alertDataSet(vehicleAlert)}
            columnDetails={alertcolumns}
            pazeSizeDetails={pageSize}
            totalCount={vehicleAlert?.totalAlerts}
          />
        </div>
        <div className="table_footer">
          <Grid container direction="row" alignItems="center" spacing={0}>
            <Grid className="text_left" item xs={8}>
              {vehicleAlert && vehicleAlert.totalAlerts > pageSize ?
                <CommonPagination 
                  currentPage = {currentPage}
                  totalCount = {vehicleAlert?.totalAlerts}
                  callBackCurrentPage = {handleCurrentPage}
                /> : <></>}
              <Button className="btn_white">Clear New Alerts</Button>
              <CommonDownloadCsv {...props} 
                datas={alertDataSet(vehicleDetailDownloadCsv)}
                headers={fileHeader}
                filename={fileName}
                callBackDownloadCsv={getVehicleDetailDownloadCsv}/>
            </Grid>
            <Grid className="text_right" item xs={4}>
              <div className="list_number">{pageData} {vehicleAlert.totalAlerts ? (vehicleAlert.totalAlerts + ' Alerts') : ''}</div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
export default VehicleDetailAlerts;