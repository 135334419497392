export const FETCH_VEHICLE_ACTION = 'FETCH_VEHICLE_ACTION';
export const FETCH_CURRENT_VEHICLE_ID = 'FETCH_CURRENT_VEHICLE_ID';
export const FETCH_CURRENT_VEHICLE_DETAIL_ID = 'FETCH_CURRENT_VEHICLE_DETAIL_ID';


export const fetchVehicleDetails = (value: any) => {
    return {
        type: FETCH_VEHICLE_ACTION,
        payload: value
    }
};

export const fetchCurrentVehicleId = (value: any) => {
    return {
        type: FETCH_CURRENT_VEHICLE_ID,
        payload: value
    }
};

export const fetchCurrentVehicleDetailId = (value: any) => {
    return {
        type: FETCH_CURRENT_VEHICLE_DETAIL_ID,
        payload: value
    }
};

