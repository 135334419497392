import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
const ListHours = (props: any) => {
    const Days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const Items = Days.map((weekDay: any, idx: any) => {

        return <div className="form_col" key={idx}>
            <Grid className="single_line time_schedule" container justifyContent="flex-start" alignItems="center" spacing={0}>
                <Grid item xs={12}>
                    <label>{weekDay}</label>
                    <Select labelId="demo-simple-select-label" variant="outlined" >
                        {props.time && props.time.map((value: any) => (<MenuItem className="defult_select_option" key={value.id} value={value.id}>
                            {value.time}
                        </MenuItem>))}
                    </Select>
                    <Select labelId="demo-simple-select-label" variant="outlined" >
                        {props.time && props.time.map((value: any) => (<MenuItem className="defult_select_option" key={value.id} value={value.id}>
                            {value.time}
                        </MenuItem>))}
                    </Select>
                </Grid>
            </Grid>
        </div>

    });

    return (
        <>
            <div className="section_group">
                <h3>Hours</h3>
                <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={4}>
                    <Grid item xs={6}>
                        <div className="form_col">
                            <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}>
                                <Grid item xs={12}>
                                    <label>Timezone *</label>
                                    <Select labelId="demo-simple-select-label" variant="outlined" name="timezone">
                                        {props.timezones && props.timezones.map((value: any) => (<MenuItem className="defult_select_option" key={value.id} value={value.id}>
                                            {value.timezone}
                                        </MenuItem>))}
                                    </Select>
                                </Grid>
                            </Grid>
                        </div>
                        {Items}
                    </Grid>
                </Grid>
            </div>
        </>
    );

}

export default ListHours;
