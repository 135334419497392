import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import ApiEndPoint from 'services/apiUrl.json';
import { getMethod, putMethod, postMethod } from 'services/api';
import moment from 'moment';
import Images from 'assets/images';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import SetChargeFee from './setChargeFee';
import { dateFormats } from 'common/commonDateFormats'

const DragAndDropCalendar = withDragAndDrop(Calendar as any)


const ChargeFee = (props: any) => {
    const setLocaleFormat = 'en'
    moment.updateLocale(setLocaleFormat, {
        week: {
            dow: 1,
            doy: 1,
        },
    });
    const localizer = momentLocalizer(moment);
    const [eventOpen, setEventOpen] = useState(false);
    const [finalEventData, setFinalEventData] = useState([]);
    const [costSegmentList, setCostSegmentList] = useState([]);
    const [segmentData, setSegmentData] = useState<any>('');
    const [startTimeData, setStartTimeData] = useState('');
    const [endTimeData, setEndTimeData] = useState('');
    const [startDayData, setStartDayData] = useState('');
    const [eventType, setEventType] = useState('');
    const [calenderData, setCalenderData] = useState<any>({});
    const calendetFinalData = calenderData && calenderData.daySchedules ? calenderData.daySchedules : [];
    const dateFormmat = dateFormats.dateFormmat;
    const startType = dateFormats.dayTypeFormat;
    const timFormat =dateFormats.timFormat;
    const hourType = dateFormats.hourTypeFormat;
    const checkTime = dateFormats.check24TimeFormat;
    const setTime = dateFormats.set24TimeFormat;
    const checkWithTime = dateFormats.check12TimeFormat;
    const setWithTime = dateFormats.set12TimeFormat;

    const formats = {
        dayFormat: "dddd",
        timeGutterFormat: "h A",
        eventTimeRangeFormat: (range: any) =>
            `${moment(range.start).format(timFormat)} – ${moment(range.end).format(timFormat)}`,
    };


    useEffect(() => {
        getCalenderData()
        getPricingMetadata()
        getCostSegment()
        generateTimeList()
    }, [])

    useEffect(() => {
        startOfWeek(moment)
    }, [calenderData])

    const generateTimeList = () => {
        const locale = setLocaleFormat; // or whatever you want...
        const startHour = 0;
        const hours = [];
        moment.locale(locale);

        for (let hour = 0; hour < 24; hour++) {
            hours.push(
                moment({ hour })
                    .add(startHour, hourType)
                    .format(timFormat)
            );

            hours.push(
                moment({
                    hour,
                    minute: 15
                })
                    .add(startHour, hourType)
                    .format(timFormat)
            );

            hours.push(
                moment({
                    hour,
                    minute: 30
                })
                    .add(startHour, hourType)
                    .format(timFormat)
            );

            hours.push(
                moment({
                    hour,
                    minute: 45
                })
                    .add(startHour, hourType)
                    .format(timFormat)
            );
        }
        return hours

    }

    const getCostSegment = async () => {
        await getMethod(ApiEndPoint.depotInformation, "/cost-segments").then((res) => {
            let returnValue = res?.data.filter((item: any) => item.segment !== 'Standard');
            setCostSegmentList(returnValue)
        }).catch((err) => {
            console.log("Error", err)
        })
    }

    const resetCostSegmentData = (dayValue:any, sTime:any, eTime:any, segId:any, eType:any) => {
        let paramObj = {
            "day": dayValue,
            "startTime": moment(sTime, [timFormat]).format('HH:mm'),
            "endTime": moment(eTime, [timFormat]).format('HH:mm'),
            "costSegmentId": segId
        }
        let resetMethod = eType === 'new'
            ? postMethod(ApiEndPoint.depotInformation, props?.depotId + '/schedule', paramObj)
            : putMethod(ApiEndPoint.depotInformation, props?.depotId + '/schedule', paramObj)
        resetMethod.then((res) => {
            if (res?.status === 200) {
                getCalenderData();
                eventClosed();
            } else {
                setEventType('statusError')
            }
            console.log("success", res)
        }).catch((err) => {
            console.log("Error", err)
        })

    }

    const handlEventChange = (val: any, type: any) => {
        if (type === 'segment') {
            setSegmentData(val);
        }
        if (type === 'startTime') {
            let endTimeDate = endTimeData === checkTime ? moment(setTime, timFormat) : moment(endTimeData, timFormat)
            let errr = (moment(val, timFormat) >= endTimeDate === true) && eventType !== 'overlap' ? 'timeError' : eventType === 'timeError' ? '' : undefined
            if(errr !== undefined){
                setEventType(errr);
            }
            setStartTimeData(val);
        }
        if (type === 'endTime') {
            let endTimeDate = val === checkTime ? moment(setTime, timFormat) : moment(val, timFormat)
            let errr = (endTimeDate <= moment(startTimeData, timFormat) === true) && eventType !== 'overlap' ? 'timeError' : eventType === 'timeError' ? '' : undefined
            if(errr !== undefined){
                setEventType(errr);
            }
            setEndTimeData(val);
        }
    }

    const getCalenderData = async () => {
        await getMethod(ApiEndPoint.depotInformation, props?.depotId + "/schedule").then((res) => {
            setCalenderData(res.data)

        }).catch((err) => {
            console.log("Error", err)
        })
    }



    const startOfWeek = (moment: any) => {
        
        const eventData: any = []
        let currentDate = moment()
        let weekStart = currentDate.clone().startOf('week')
        let monday = weekStart.format(dateFormmat);
        let tuesday = moment(weekStart).add(1, startType).format(dateFormmat);
        let wednesday = moment(weekStart).add(2, startType).format(dateFormmat);
        let thursday = moment(weekStart).add(3, startType).format(dateFormmat);
        let friday = moment(weekStart).add(4, startType).format(dateFormmat);
        let saturday = moment(weekStart).add(5, startType).format(dateFormmat);
        let sunday = moment(weekStart).add(6, startType).format(dateFormmat);
        calendetFinalData.map((val: any) => {
            if (val.day === 'MONDAY') {
                val && val.schedule && val.schedule.map((schedueldata: any) => {
                    schedueldata?.costSegment?.toLowerCase() !== 'standard' && eventData.push({
                        title: '',
                        start: new Date(moment(monday + ' ' + schedueldata.startTime)),
                        end: new Date(moment(monday + ' ' + (schedueldata.endTime === checkWithTime ? setWithTime : schedueldata.endTime))),
                        costSegment: schedueldata.costSegment,
                        costSegmentId: schedueldata.costSegmentId,
                        startDay: val.day,

                    })
                })
            } else if (val.day === 'TUESDAY') {
                val && val.schedule && val.schedule.map((schedueldata: any) => {
                    schedueldata?.costSegment?.toLowerCase() !== 'standard' && eventData.push({
                        title: '',
                        start: new Date(moment(tuesday + ' ' + schedueldata.startTime)),
                        end: new Date(moment(tuesday + ' ' + (schedueldata.endTime === checkWithTime ? setWithTime : schedueldata.endTime))),
                        costSegment: schedueldata.costSegment,
                        costSegmentId: schedueldata.costSegmentId,
                        startDay: val.day,
                    })
                })
            }
            else if (val.day === 'WEDNESDAY') {
                val && val.schedule && val.schedule.map((schedueldata: any) => {
                    schedueldata?.costSegment?.toLowerCase() !== 'standard' && eventData.push({
                        title: '',
                        start: new Date(moment(wednesday + ' ' + schedueldata.startTime)),
                        end: new Date(moment(wednesday + ' ' + (schedueldata.endTime === checkWithTime ? setWithTime : schedueldata.endTime))),
                        costSegment: schedueldata.costSegment,
                        costSegmentId: schedueldata.costSegmentId,
                        startDay: val.day,
                    })
                })
            }
            else if (val.day === 'THURSDAY') {
                val && val.schedule && val.schedule.map((schedueldata: any) => {
                    schedueldata?.costSegment?.toLowerCase() !== 'standard' && eventData.push({
                        title: '',
                        start: new Date(moment(thursday + ' ' + schedueldata.startTime)),
                        end: new Date(moment(thursday + ' ' + (schedueldata.endTime === checkWithTime ? setWithTime : schedueldata.endTime))),
                        costSegment: schedueldata.costSegment,
                        costSegmentId: schedueldata.costSegmentId,
                        startDay: val.day,
                    })
                })
            }
            else if (val.day === 'FRIDAY') {
                val && val.schedule && val.schedule.map((schedueldata: any) => {
                    schedueldata?.costSegment?.toLowerCase() !== 'standard' && eventData.push({
                        title: '',
                        start: new Date(moment(friday + ' ' + schedueldata.startTime)),
                        end: new Date(moment(friday + ' ' + (schedueldata.endTime === checkWithTime ? setWithTime : schedueldata.endTime))),
                        costSegment: schedueldata.costSegment,
                        costSegmentId: schedueldata.costSegmentId,
                        startDay: val.day,
                    })
                })
            }
            else if (val.day === 'SATURDAY') {
                val && val.schedule && val.schedule.map((schedueldata: any) => {
                    schedueldata?.costSegment?.toLowerCase() !== 'standard' && eventData.push({
                        title: '',
                        start: new Date(moment(saturday + ' ' + schedueldata.startTime)),
                        end: new Date(moment(saturday + ' ' + (schedueldata.endTime === checkWithTime ? setWithTime : schedueldata.endTime))),
                        costSegment: schedueldata.costSegment,
                        costSegmentId: schedueldata.costSegmentId,
                        startDay: val.day,
                    })
                })
            }
            else if (val.day === 'SUNDAY') {
                val && val.schedule && val.schedule.map((schedueldata: any) => {
                    schedueldata?.costSegment?.toLowerCase() !== 'standard' && eventData.push({
                        title: '',
                        start: new Date(moment(sunday + ' ' + schedueldata.startTime)),
                        end: new Date(moment(sunday + ' ' + (schedueldata.endTime === checkWithTime ? setWithTime : schedueldata.endTime))),
                        costSegment: schedueldata.costSegment,
                        costSegmentId: schedueldata.costSegmentId,
                        startDay: val.day,
                    })
                })
            }
        })
        setFinalEventData(eventData)
    }


    const eventOverlapCheck = (event: any) => {
        let checkEventOverlap = finalEventData.filter((val: any) => (event?.start >= val?.start && event?.start <= val?.end) && (event?.end >= val?.start && event?.end <= val?.end))
        let checkEventMatched = finalEventData.filter((val: any) => (event?.start > val?.start && event?.start < val?.end) || (event?.end > val?.start && event?.end < val?.end) || ((event?.start < val?.start && event?.start < val?.end) && (event?.end > val?.start && event?.end > val?.end)))
        if (event?.costSegmentId) {
            handleSelectEvent(event, 'edit')
        } else {
            (checkEventOverlap?.length === 0 && checkEventMatched?.length === 0) ? handleSelectSlot(event, 'new') : handleSelectSlot(event, 'overlap')
        }

    }
    const handleSelectEvent = (event: any, type: any) => {
        setSegmentData(event?.costSegmentId);
        setStartTimeData(moment(event?.start).format(timFormat));
        setEndTimeData(moment(event?.end).format(timFormat) === setTime ? moment(event?.end).add(1, 'm').format(timFormat) : moment(event?.end).format(timFormat));
        setStartDayData(event?.startDay);
        setEventType(type);
        setEventOpen(true);
    }

    const handleSelectSlot = (event: any, type: any) => {
        setSegmentData(2);

        setStartTimeData(moment(event?.start).format(timFormat));
        setEndTimeData(moment(event?.end).format(timFormat) === setTime ? moment(event?.end).add(1, 'm').format(timFormat) : moment(event?.end).format(timFormat));
        setStartDayData(moment(event?.start).format('dddd').toUpperCase());
        setEventType(type);
        setEventOpen(true);
    }


    const eventClosed = () => {
        setEventType('');
        setSegmentData('');
        setStartTimeData('');
        setEndTimeData('');
        setStartDayData('');
        setEventOpen(false);
    };

    const eventStyleGetter = (event: any, start: any, end: any, isSelected: any) => {
        var style = {}
        switch (event.costSegment) {
            case 'Peak':
                return { style: { backgroundColor: 'rgba(255, 75, 156, 0.2)', color: '#666666', fontSize: '10px', fontWeight: '500', borderRadius: '5px', border: '1px solid #FF489C' } };
            case 'Off-Peak':
                return { style: { backgroundColor: 'rgba(0, 185, 255, 0.2)', color: '#666666', fontSize: '10px', fontWeight: '500', borderRadius: '5px', border: '1px solid #00B9FF' } };
            case 'Super-Peak':
                return { style: { backgroundColor: 'rgb(195, 101, 153, 0.2)', color: '#666666', fontSize: '10px', fontWeight: '500', borderRadius: '5px', border: '1px solid #C365FD' } };
            case 'Standard':
                return { style: { backgroundColor: 'rgb(175, 134, 34, 0.2)', color: '#666666', fontSize: '10px', fontWeight: '500', borderRadius: '5px', border: '1px solid #af8625' } };
            default:
                return { style: style };
        }
    }

    const [openSchedule, setScheduleOpen] = React.useState(false);
    const [scrollSchedule, setScheduleScroll] = React.useState('paper');
    const [priceMetadata, setPriceMetadata] = React.useState({});

    const getPricingMetadata = async () => {
        await getMethod(ApiEndPoint.evsePricing, 'metadata').then((res) => {
            setPriceMetadata(res.data)
        }).catch((err) => {
            console.log("Error", err)
        })
    }

    const scheduleOpen = (scrollType: any) => () => {
        setScheduleOpen(true);
        setScheduleScroll(scrollType);
    };
    const scheduleClose = () => {
        setScheduleOpen(false);
    };

    const resizeEvent = ({ event, start, end }:any) => {
        let filteredArray = finalEventData.filter((val: any) => (val !== event))
        let checkEventMatched = filteredArray.filter((val: any) => (start > val?.start && start < val?.end) || (end > val?.start && end < val?.end) || ((start < val?.start && start < val?.end) && (end > val?.start && end > val?.end)))
        if (checkEventMatched?.length === 0) {
            const nextEvents:any  = finalEventData.map( (existingEvent:any) => {
                return existingEvent.start ===  event.start && existingEvent.end === event.end ?{ ...existingEvent, start, end }
                : existingEvent
              })
            setFinalEventData(nextEvents);
            resetCostSegmentData(event?.startDay, start, end,  event?.costSegmentId , 'edit')
        }
    };

    return (
        <>
            <div className="dialog_box">
                <Dialog className="edit_event_dialog dialog_container  dialog_container_center" onClose={eventClosed} aria-labelledby="simple-dialog-title" open={eventOpen}>
                    <div className="dialog_title">
                        <h4>Edit schedule</h4>
                        <div className="button_row mt-0 full_right">
                            <Button className="btn_white text_btn_theme" onClick={eventClosed}>Cancel</Button>
                            {eventType === 'overlap' || eventType === 'timeError' ?
                                <Button className="btn_white blue_bg" disabled>Reset schedule</Button> :
                                <Button className="btn_white blue_bg" onClick={() =>resetCostSegmentData(startDayData, startTimeData, endTimeData,  segmentData , eventType)}>Reset schedule</Button>
                            }
                        </div>
                    </div>
                    <div className="edit_event_inside">
                        <h4>Update: <span>{startDayData}</span></h4>
                        <div className="edit_evt_row">
                            <div className="label">Schedule:</div>
                            <div className="result label_select">
                                <FormControl className="select_control ">
                                    <Select labelId="demo-simple-select-label" id="demo-simple-select1" value={segmentData} onChange={(e) => handlEventChange(e.target.value, 'segment')}>
                                        {costSegmentList && costSegmentList.map((value: any) => (
                                            <MenuItem className="optionlist" key={value.id} value={value.id}>
                                                <span className={`label_legend ${value?.segment ? value.segment.toLowerCase() : ''}`}></span>{value.segment}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className="edit_evt_row">
                            <div className="label">Start:</div>
                            <div className="result">
                                <FormControl className="select_control">
                                    <Select labelId="demo-simple-select-label" id="demo-simple-select2" value={startTimeData} onChange={(e) => handlEventChange(e.target.value, 'startTime')}>
                                        {generateTimeList().map((value, index) => (
                                            <MenuItem className="optionlist" key={index} value={value}>
                                                {value}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className="edit_evt_row">
                            <div className="label">End:</div>
                            <div className="result">
                                <FormControl className="select_control">
                                    <Select labelId="demo-simple-select-label" id="demo-simple-select3" value={endTimeData} onChange={(e) => handlEventChange(e.target.value, 'endTime')}>
                                        {generateTimeList().map((value, index) => (
                                            <MenuItem className="optionlist" key={index} value={value}>
                                                {value}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        {eventType === 'overlap' || eventType === 'statusError' ? <div className="error_msg">Warning!, Schedule overlapped with other event.</div> : eventType === 'timeError' ? <div className="error_msg">Warning!, start time should be less than end time.</div> : ''}
                    </div>
                </Dialog>
            </div>
            <div className="dialog_box">
                <Dialog className="dialog_container set_fee_dialog dialog_container_center" {...props}
                    open={openSchedule}
                    onClose={scheduleClose}
                    scroll={scrollSchedule}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <SetChargeFee
                        depotId={props?.depotId}
                        metadata={priceMetadata}
                        scheduleClose={scheduleClose}
                    />
                </Dialog>
            </div>
            <div className="information_section">
                <Grid container spacing={2}>
                    <Grid className="mid_section_left" item xs={12}>
                        <Card variant="outlined" className="basic_card cal_card_state">
                            <CardContent>
                                {/*----Title Start----*/}
                                <div className="title_row">
                                    <div className="charge_fee_btn">
                                        <div className="schedule_select">
                                            <FormControl className="select_control">
                                                <Select labelId="demo-simple-select-label" id="demo-simple-select" >
                                                    <MenuItem className="optionlist" value={1}>
                                                        Schedule A</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <Button className="outline_btn" onClick={scheduleOpen('paper')} variant="outlined"><img src={Images.ic_setfee} alt="Set Fee" />Set Fee</Button>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                                <div className="charge_fee_cal">
                                    <div className="cal_legend">
                                        <div className="legend">Standard</div>
                                        <div className="legend off_peak">Off-Peak</div>
                                        <div className="legend peak">Peak</div>
                                        <div className="legend super_peak">Super-Peak</div>
                                    </div>
                                    <DragAndDropCalendar
                                        selectable
                                        resizable
                                        localizer={localizer}
                                        formats={formats}
                                        events={finalEventData}
                                        startAccessor="start"
                                        endAccessor="end"
                                        onEventResize={resizeEvent}
                                        defaultView={Views.WEEK}
                                        views={['week']}
                                        eventPropGetter={(eventStyleGetter)}
                                        style={{}}
                                        step={15}
                                        timeslots={4}
                                        onSelectEvent={(event: any, target: any) => eventOverlapCheck(event)}
                                        onSelectSlot={(event: any) => eventOverlapCheck(event)}
                                        dayLayoutAlgorithm="no-overlap"
                                    />
                                </div>
                            </CardContent>
                        </Card>
                        <div className="time_zone_info">Time zone: <span>UTC -8</span></div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}
export default ChargeFee