import React, { useState, useEffect, useRef }  from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Images from 'assets/images';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import './messagesDetails.scss';
const useStyles = makeStyles((theme :any) => ({
  root: {
    display: 'flex',
    
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function ListItemLink(props:any) {
  return <ListItem button component="a" {...props} />;
}
const MessagesDetails = (props:any) => {
  const classes = useStyles();
  const [chatList, setChatList] = useState<any>([]);
  const [messageValue, setMessageValue] = useState<any>('');
  const messagesEndRef = useRef<any>(null)

  useEffect(() => {
    scrollToBottom()
  }, [messageValue])

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
  }

  const messageSend = () => {
    let allMessage = chatList;
    allMessage.push(messageValue);
    setMessageValue('')
    setChatList(allMessage); 
  }

  const handleChange = (e:any) => {
      setMessageValue(e?.target?.value)   
  };
  
  return (
    <>



 {/*----Messages Page Mid Left Section Start----*/}
<div className='msg_page'>
<main className={classes.content + "main_mid_section p-b-0"}>
          <div className={"mid_section_inside, messages"}>
          <Grid container spacing={1}>
                 {/*----Page Mid Left Section Start----*/}
                 <Grid className="mid_section_left driver_list_section" item xs={3} sm={3} md={3}>
                 <Card className="small_card" variant="outlined">
                    <CardContent className="card_content">
                      <div className='select_driver'>
                      <FormControl className="select_control">
                                  <Select labelId="demo-simple-select-label" id="demo-simple-select" >
                                    <MenuItem className="optionlist" value={1}>Select driver</MenuItem>
                                    <MenuItem className="optionlist" value={2}>Declan, Mckenna</MenuItem>
                                    <MenuItem className="optionlist" value={3}>Ronnan, Jerry</MenuItem>
                                    <MenuItem className="optionlist" value={4}>Li, Justin</MenuItem>
                                    <MenuItem className="optionlist" value={5}>Stuckey, Sarah</MenuItem>
                                    <MenuItem className="optionlist" value={6}>Star, Floyd</MenuItem>
                                    <MenuItem className="optionlist" value={7}>McCain, Sam</MenuItem>
                                    <MenuItem className="optionlist" value={8}>McLean, Sara</MenuItem>
                                    <MenuItem className="optionlist" value={9}>Parker, Melissa</MenuItem>
                                    <MenuItem className="optionlist" value={10}>O’Neal, Susan</MenuItem>
                                  </Select>
                               </FormControl>
                      </div>

                        <div className="driver_list">

                        <List component="nav">
                          <ListItem button className='online'>
                            <ListItemIcon className="user_img">
                            <img src={Images.blink_bot} alt="Blink Bot" />
                            <span></span>
                            </ListItemIcon>
                            <ListItemText>Blink Bot</ListItemText>
                          </ListItem>

                          <ListItem button className='offline'>
                            <ListItemIcon className="user_img">
                            <img src={Images.driver_pic_1} alt="Blink Bot" />
                            <span></span>
                            </ListItemIcon>
                            <ListItemText>Terrance, Tristen</ListItemText>
                          </ListItem>

                          <ListItem button className='online active'>
                            <ListItemIcon className="user_img">
                            <img src={Images.driver_pic_2} alt="Blink Bot" />
                            <span></span>
                            </ListItemIcon>
                            <ListItemText>Declan, Mckenna 
                            <IconButton className='open_new_win'>
                              <img src={Images.ic_open_in_new} alt="ic_open_in_new" />
                              </IconButton>
                              </ListItemText>
                          </ListItem>

                          <ListItem button className='online'>
                            <ListItemIcon className="user_img">
                            <img src={Images.driver_pic_3} alt="Blink Bot" />
                            <span></span>
                            </ListItemIcon>
                            <ListItemText>Ronnan, Jerry</ListItemText>
                          </ListItem>

                          <ListItem button className='offline'>
                            <ListItemIcon className="user_img">
                            <img src={Images.driver_all} alt="Blink Bot" />
                            <span></span>
                            </ListItemIcon>
                            <ListItemText>Message to all drivers</ListItemText>
                          </ListItem>

                          <ListItem button className='offline'>
                            <ListItemIcon className="user_img">
                            <img src={Images.driver_pic_4} alt="Blink Bot" />
                            <span></span>
                            </ListItemIcon>
                            <ListItemText>Li, Justin</ListItemText>
                          </ListItem>

                          <ListItem button className='offline'>
                            <ListItemIcon className="user_img">
                            <img src={Images.driver_pic_5} alt="Blink Bot" />
                            <span></span>
                            </ListItemIcon>
                            <ListItemText>Stuckey, Sarah</ListItemText>
                          </ListItem>

                          <ListItem button className='offline'>
                            <ListItemIcon className="user_img">
                            <img src={Images.driver_pic_6} alt="Blink Bot" />
                            <span></span>
                            </ListItemIcon>
                            <ListItemText>Star, Floyd</ListItemText>
                          </ListItem>

                          <ListItem button className='offline'>
                            <ListItemIcon className="user_img">
                            <img src={Images.driver_pic_7} alt="Blink Bot" />
                            <span></span>
                            </ListItemIcon>
                            <ListItemText>McCain, Sam</ListItemText>
                          </ListItem>

                          <ListItem button className='online'>
                            <ListItemIcon className="user_img">
                            <img src={Images.driver_pic_8} alt="Blink Bot" />
                            <span></span>
                            </ListItemIcon>
                            <ListItemText>McLean, Sara</ListItemText>
                          </ListItem>

                          <ListItem button className='offline'>
                            <ListItemIcon className="user_img">
                            <img src={Images.driver_pic_9} alt="Blink Bot" />
                            <span></span>
                            </ListItemIcon>
                            <ListItemText>Parker, Melissa</ListItemText>
                          </ListItem>

                          <ListItem button className='online'>
                            <ListItemIcon className="user_img">
                            <img src={Images.driver_pic_10} alt="Blink Bot" />
                            <span></span>
                            </ListItemIcon>
                            <ListItemText>O’Neal, Susan</ListItemText>
                          </ListItem>
                          <ListItem button className='offline'>
                            <ListItemIcon className="user_img">
                            <img src={Images.driver_pic_11} alt="Blink Bot" />
                            <span></span>
                            </ListItemIcon>
                            <ListItemText>Leh, Terrance</ListItemText>
                          </ListItem>
                         </List> 
                        </div>
                    </CardContent>
                  </Card>     

                </Grid>

                <Grid className="mid_section_right driver_list_section" item xs={9} sm={9} md={9}>
                 <Card className="small_card" variant="outlined">
                    <CardContent className="card_content"> 
                        <div className="driver_list msg_listing">

                        <List component="nav">
                          <ListItem className='online received'>
                            <ListItemIcon className="user_img">
                            <img src={Images.driver_pic_2} alt="Blink Bot" />
                            <span></span>
                            </ListItemIcon>
                            <ListItemText>
                              <div className='msg_text'>Behind schedule because of traffic. Should be done in an hr.</div>  
                              <div className='msg_time'>11/14/2021 10:22 AM</div>
                            </ListItemText>                         
                          </ListItem>

                          <ListItem className='online blink_bot'>
                            <ListItemIcon className="user_img">
                            <img src={Images.blink_bot} alt="Blink Bot" />
                            <span></span>
                            </ListItemIcon>
                            <ListItemText>
                              <div className='msg_text'>Vehicle VH1455 needs to be serviced. Please contact service manager.</div>  
                              <div className='msg_time'>11/21/2021 4:01 PM</div>
                            </ListItemText>                         
                          </ListItem>

                          <ListItem className='online send'>
                           
                            <ListItemText>
                              <div className='msg_text'>Where are you? Can’t see your</div>  
                              <div className='msg_time'>11/24/2021 11:54 AM</div>
                            </ListItemText>                         
                          </ListItem>

                          <ListItem className='online received'>
                            <ListItemIcon className="user_img">
                            <img src={Images.driver_pic_2} alt="Blink Bot" />
                            <span></span>
                            </ListItemIcon>
                            <ListItemText>
                              <div className='msg_text'>Around the corner by Petco on Wiltshire and Western. </div>  
                              <div className='msg_time'>11/29/2021 2:46 PM</div>
                            </ListItemText>                         
                          </ListItem>

                          <ListItem className='online send'>
                           
                           <ListItemText>
                             <div className='msg_text'>Ok</div>  
                             <div className='msg_time'>11/24/2021 11:54 AM</div>
                           </ListItemText>                         
                         </ListItem>

                          <ListItem className='online received'>
                            <ListItemIcon className="user_img">
                            <img src={Images.driver_pic_2} alt="Blink Bot" />
                            <span></span>
                            </ListItemIcon>
                            <ListItemText>
                              <div className='msg_text'>I got a flat. I can’t finish the route.</div>  
                              <div className='msg_time'>12/20/2021 11:37 AM</div>
                            </ListItemText>                         
                          </ListItem>

                          <ListItem className='online send'>
                           
                           <ListItemText>
                             <div className='msg_text'>Sending another van to finish your route.</div>  
                             <div className='msg_time'>12/20/2021 11:54 AM</div>
                           </ListItemText>                         
                         </ListItem>

                         <ListItem className='online send'>
                           
                           <ListItemText>
                             <div className='msg_text'>Maintenance coming</div>  
                             <div className='msg_time'>12/20/2021 12:06 PM</div>
                           </ListItemText>                         
                         </ListItem>

                          <ListItem className='online blink_bot'>
                            <ListItemIcon className="user_img">
                            <img src={Images.blink_bot} alt="Blink Bot" />
                            <span></span>
                            </ListItemIcon>
                            <ListItemText>
                              <div className='msg_text'>Vehicle VH1455 is low on energy. Please charge soon.</div>  
                              <div className='msg_time'>12/27/2021 1:36 PM</div>
                            </ListItemText>                         
                          </ListItem>

                          {chatList && chatList.map((value:any, index:any) => (
                            <ListItem className='online send' key={index}>
                           
                            <ListItemText>
                              <div className='msg_text'>{value}</div> 
                              <div className='msg_time'>{moment().format("MM/D/YYYY h:mm A")}</div>
                            </ListItemText>                         
                          </ListItem>
                           ))} 

                         </List> 
                         <div ref={messagesEndRef} />
                        </div>

                        <div className='send_msg_block'>
                        <InputBase placeholder="Type a new message" id="inputbase-message-value"
                                name="messageValue" value={messageValue} onChange={handleChange} onKeyPress={(e) => e.key === 'Enter' && messageSend()}/>
                        <Button className='send_msg_button' variant="contained" onClick={messageSend}>
                        <img src={Images.send_button} alt="Send MSG" />
                        </Button>
                        </div>
                    </CardContent>
                  </Card>     

                </Grid>
          </Grid>
          </div>
</main>                   
</div>

    </>
  )
}

export default MessagesDetails;
