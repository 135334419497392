import React, { useState, useEffect, useRef } from "react";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from "@material-ui/core/TextField";
import Divider from '@material-ui/core/Divider';
import ListHours from "./listHours";
import { ContactlessOutlined } from "@material-ui/icons";
import CommonAddressForm from "components/commonAddressForm/commonAddressForm";
import SimpleReactValidator from 'simple-react-validator';
import CommonContactForm from "../../../../components/commonContactForm/commonContactForm";

const EditOrganizationDetails = (props: any) => {
  const sortedHours = props.infoDetails.hoursOfOperations.sort(function(a: any, b: any){return a.id-b.id});
  const [intialValues, setIntialValues] = useState<any>({depotDetailsData:props.depotDetailsData,contactInfo:props.contactInfo,hoursOfOperations:sortedHours});
  const [timezones, setTimeZones] = useState<any>();
  const [time, setTime] = useState<any>();
  const [scroll, setScroll] = React.useState('paper');
  const [addressDetails, setAddressDetails] = useState<any>();
  const [fleetContact, setFleetContact] = useState<any>();
  const simpleValidator = useRef(new SimpleReactValidator())
  const [initialDetails, setInitialDetails] = useState<any>();
  const [, forceUpdate] = useState<any>();

  useEffect(() =>{
    getTimeZonedata()
    getTime()
    makeAddressData()
    makeContactData()
    initialData()
  },[])

  const makeAddressData = async () =>{
    let addressInfo =  {
      "StreetAddress1":props.depotDetailsData?.streetAddress1 ? props.depotDetailsData?.streetAddress1 : '',
      "StreetAddress2":props.depotDetailsData?.streetAddress2 ? props.depotDetailsData?.streetAddress2 : '',
      "City":props.depotDetailsData?.city ? props.depotDetailsData?.city : '',
      "State":props.depotDetailsData?.state ? props.depotDetailsData?.state : '',
      "lat": props.depotDetailsData?.lat ? props.depotDetailsData?.lat : '',
      "lon": props.depotDetailsData?.lon ? props.depotDetailsData?.lon : '',
      "ZipCode":props.depotDetailsData?.zipCode ? props.depotDetailsData?.zipCode : '',
      "CountryCode":props.depotDetailsData?.country ? props.depotDetailsData?.country : '',
      "description":props.depotDetailsData?.description ? props.depotDetailsData?.description : '',
      "notes":props.depotDetailsData?.notes ? props.depotDetailsData?.notes : '',
          } 
    setAddressDetails(addressInfo);
  }

  const makeContactData = async () =>{
    let contactInfo =  {
      "firstName":props.contactInfo?.firstName ? props.contactInfo?.firstName : '',
      "telePhoneNumber":props.contactInfo?.telePhoneNumber ? props.contactInfo?.telePhoneNumber : '',
      "mobileNumber":props.contactInfo?.mobileNumber ? props.contactInfo?.mobileNumber : '',
      "fax":props.contactInfo?.fax ? props.contactInfo?.fax : '',
      "email":props.contactInfo?.email ? props.contactInfo?.email : '',
      "note":props.contactInfo?.note ? props.contactInfo?.note : '',
    } 
  setFleetContact(contactInfo);
  }

  const initialData = async ()=>{
    let initialDta = {
      "name": props.depotDetailsData?.evsedepotName,
      "organizationName": props.depotDetailsData?.organizationName
    }
    setInitialDetails(initialDta);
  }

  const handleInitialChange = (e: any) => {
    let name = e.target.name;let value = e.target.value;
    setInitialDetails({ ...initialDetails, [name]:value});    
  };

  const getTime = async () => {
    let tempData = {
      "time": [{
        "id": 1,
        "time": "9:00 AM"
      }, {
        "id": 2,
        "time": "7:00 PM"
      }]
    }
    setTime(tempData['time']);
  }

  const getTimeZonedata = async () => {
    let tempData = {
      "timezones": [{
        "id": 1,
        "timezone": "MST"
      }, {
        "id": 2,
        "timezone": "IST"
      }, {
        "id": 3,
        "timezone": "PST"
      }, {
        "id": 4,
        "timezone": "CST"
      }],
      "totalZones": 4
    }
    setTimeZones(tempData['timezones']);
  }

const submitEditForm = () =>{
  if (simpleValidator.current.allValid()) {
    let data:any = [];
    data['basic'] = initialDetails;
    data['address'] = addressDetails;
    data['contact'] = fleetContact;
    //data will be submitted. 
    alert('You submitted the form and stuff!');
  } else {
    simpleValidator.current.showMessages();
    // rerender to show messages for the first time
    // you can use the autoForceUpdate option to do this automatically`
    forceUpdate(1);
  }
}
  
  return (
    <>
      <div className="dialog_title">
        <h4>Edit Organization Details</h4>
        <div className="button_row mt-0 full_right">
          <Button
            className="btn_white text_btn_theme"
            onClick={props?.organizationDetailclose}
          >
            Cancel
          </Button>
          <Button onClick={submitEditForm}  className="btn_white blue_bg">Save Changes</Button>
        </div>
      </div>
      

      <DialogContent dividers={scroll === 'paper'} {...props}>
        <DialogContentText id="scroll-dialog-description">
          <div className="defult_form">
            <div className="section_group">
                  <h5>* Required information</h5>
                  <Grid container spacing={3} className="block_label">
                    <Grid item xs={6}>
                       <div className="form_col">
                        <label>Name *</label>
                        <TextField
                      variant="outlined"
                      className="error"
                      value={initialDetails?.name}
                      name="name"                      
                      onChange={handleInitialChange}
                      onBlur={()=>simpleValidator.current.showMessageFor('name')}
                      inputProps={{ maxLength: 140 }}
                      />
                      <span className="error_msg">{simpleValidator.current.message('name', initialDetails?.name, 'required')}</span>
                       </div> 
                    </Grid>
                    <Grid item xs={6}>
                       <div className="form_col">
                        <label>Organization *</label>
                        <TextField
                      label=""
                      disabled
                      className="disable"
                      variant="outlined"
                      value={initialDetails?.organizationName}
                      name="organizationName"
                      onChange={handleInitialChange}
                      onBlur={()=>simpleValidator.current.showMessageFor('organizationName')}
                      />
                      <span className="error_msg">{simpleValidator.current.message('organizationName', initialDetails?.organizationName, 'required')}</span>
                       </div>  
                    </Grid>
                  </Grid>  
                  </div> 
            <Divider />
             <CommonAddressForm
             addressDetails = {addressDetails}
             setAddressDetails = {setAddressDetails}
             validator = {simpleValidator}
             renderMap={true}
             />
            <Divider />
            <CommonContactForm 
              contact = {fleetContact}
              setContact = {setFleetContact}
              validator = {simpleValidator}
              title = "Contact"
              />
            <Divider /> 
            <ListHours
            hoursOfOperations={intialValues.hoursOfOperations}
            timezones = {timezones}
            time={time}
            />
          </div>
        </DialogContentText>
      </DialogContent>
    </>
  );
};

export default EditOrganizationDetails;
