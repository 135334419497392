import React from "react";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import PropTypes from "prop-types";


const DescriptionBox= (props:any) => {
    return (
        <div className="section_group">
                                    <div className="form_col">
                                      <Grid className="single_line textarea" container justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                                        <Grid item xs={12}>
                                          <label>{props?.name}</label>
                                          <TextField multiline rows={4} 
                                          helperText="25 Characters (maximum 400 characters)" 
                                          value={props?.description} 
                                          variant="outlined" /> 
                                        </Grid> 
                                      </Grid> 
                                    </div>
                                  </div>
    );
}

export default DescriptionBox;
