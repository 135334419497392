import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import ApiEndPoint from 'services/apiUrl.json';
import { getMethod } from 'services/api';
import { pageDetails } from 'common/common';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { DataGrid } from '@material-ui/data-grid';
import CommonChargeSessionDetails from 'components/commonChargeSessionDetails/commonChargeSessionDetails';
import CommonPagination from 'components/commonPagination/commonPagination';
import CommonDownloadCsv from 'components/commonDownloadCsv/commonDownloadCsv';
import { styleDetails } from 'common/common';
import { dateFormats } from 'common/commonDateFormats';

const VehicleDetailsChargeHistory = (props: any) => {
    const currentVehicleDetailId = useSelector((state: any) => { return state.currentVehicleDetailId })
    const pageSize = pageDetails.pageSize;
    const [vehicleDetailsChargeHistory, setVehicleDetailsChargeHistory] = useState<any>({});
    const [currentPage, setCurrentPage] = useState(0);
    const [pageData, setPageData] = useState('');
    const [vehicleDetailsChargeSessionInfo, setVehicleDetailsChargeSessionInfo] = useState<any>({});
    const [open, setOpen] = React.useState(false);
    const [vehicleDetailDownloadCsv, setVehicleDetailDownloadCsv] = useState();
    const [scroll, setScroll] = React.useState('paper');

    const fileHeader =[
      {label: 'DRIVER',key: 'driverName'},
      {label: 'PORT',key: 'portInformation'},
      {label: 'START CHARGE',key: 'startChargeTime'},
      {label: 'END CHARGE',key: 'endChargeTime'},
      {label: 'TIME',key: 'time'},
      {label: 'ENERGY (kWh)',key: 'energyKWh'}
     
    ];
   
    const fileName= 'VehicleDetailChargeHistory.csv'

    const vehicleDetailsChargeHistorycolumns = [

        {
          field: "e",
          headerName: "e",
          headerClassName: "empty",
          sortable: false,
          hasFocus: false,
          cellClassName: "empty",
          width: 15,
          maxWidth: 25,
          minWidth: 28,
          disableColumnMenu: true,
          renderCell: (cellValues: any) => {
            return (
                <div className="empty">
                  {cellValues.value}
                </div>
            );
          }
        },
      
        {
          field: "driverName",
          headerName: "DRIVER",
          sortable: false,
          hasFocus: false,
          minWidth: 100,
          flex: 0.1,
          disableColumnMenu: true,
          renderCell: (cellValues: any) => {
            return (
                <>
                    {cellValues.value ? cellValues.value : '-'}
                </>
            );
        }
        },
      
      
        {
          field: 'portInformation',
          headerName: 'PORT',
          sortable: false,
          width: 120,
          disableColumnMenu: true,
          renderCell: (cellValues: any) => {
            return (
                <>
                    {cellValues.value ? cellValues.value : '-'}
                </>
            );
        }
        },
        {
          field: 'startChargeTime',
          headerName: 'START',
          sortable: false,
          minWidth: 160,
          flex: 0.1,
          disableColumnMenu: true,
          
        },
      
        {
          field: 'endChargeTime',
          headerName: 'END',
          sortable: false,
          minWidth:200,
          flex: 0.1,
          disableColumnMenu: true,
          
        },
        {
          field: 'time',
          headerName: 'TIME',
          sortable: false,
          width: 100,
          disableColumnMenu: true,
          renderCell: (cellValues: any) => {
            return (
                <>
                    {cellValues.value ? cellValues.value : '-'}
                </>
            );
        }
        },
        {
          field: 'energyKWh',
          headerName: 'ENERGY (kWh)',
          sortable: false,
          width: 130,
          disableColumnMenu: true,
          renderCell: (cellValues: any) => {
            return (
                <>
                    {cellValues.value ? cellValues.value : '-'}
                </>
            );
        }
        },
      
      
      
        {
          field: "ee",
          headerName: "ee",
          headerClassName: "empty",
          sortable: false,
          hasFocus: false,
          cellClassName: "empty",
          width: 15,
          maxWidth: 25,
          minWidth: 28,
          disableColumnMenu: true,
          renderCell: (cellValues: any) => {
            return (
                <div className="empty">
                  {cellValues.value}
                </div>
            );
          }
        },
      
      ];
      

    useEffect(() => {
        getVehicleDetailsChargeHistory()
    }, [currentPage])
    useEffect(() => {
      if(vehicleDetailsChargeHistory?.totalchargeHistory >0){
      getVehicleDetailDownloadCsv();
    }
    }, [vehicleDetailsChargeHistory?.totalchargeHistory]);
    useEffect(() => {
        handlePageData()
    }, [vehicleDetailsChargeHistory])

    const getVehicleDetailsChargeHistory = async () => {
        const page = currentPage;
        const size = pageSize;
        await getMethod(ApiEndPoint.vehicleService, currentVehicleDetailId + "/charge-history" + "?page=" + page + "&size=" + size).then((res) => {
            setVehicleDetailsChargeHistory(res.data)
        }).catch((err) => {
            console.log("Error", err)
        })
    }

    const getVehicleDetailDownloadCsv = async () => {
      await getMethod(ApiEndPoint.vehicleService, currentVehicleDetailId + "/charge-history" + "?page=" + 0 + "&size=" + vehicleDetailsChargeHistory.totalchargeHistory).then((res) => {
        setVehicleDetailDownloadCsv(res.data);
        }).catch((err) => {
          console.log('Error', err);
        });
    };

    const vechicleDetailsHistoryDataSet = (value: any) => {
        let historyTableData = []
        if (value && value.chargeHistoryList) {
            historyTableData = value.chargeHistoryList.map((val: any) => ({ ...val, e: 'a', ee: 'aa', id: val.historyId,startChargeTime: moment(val.startChargeTime).format(dateFormats.dateTimeFormat), endChargeTime: moment(val.endChargeTime).format(dateFormats.dateTimeFormat), }))
        }
        return historyTableData
    }

    const handlePageData = () => {
        let totalPage = vehicleDetailsChargeHistory && vehicleDetailsChargeHistory.totalchargeHistory ? vehicleDetailsChargeHistory.totalchargeHistory : 0;
        if (totalPage > 0) {
            let setPageValue = (currentPage >= 1 ? (pageSize * currentPage) + 1 : 1) + ' - ' + ((pageSize * (currentPage + 1)) < totalPage ? (pageSize * (currentPage + 1)) : totalPage) + ' of '
            setPageData(setPageValue)
        }
    }

    const handleClickOpen = (scrollType: any, chargeData: any) => () => {
        if(chargeData && chargeData.chargeHistoryList && chargeData.chargeHistoryList.length >= 1){
          setOpen(true);
        }
        setScroll(scrollType);
      };

    const handleClose = () => {
        setOpen(false);
      };

    const onVehicleDetailsChargeHistoryClick = (e: any) =>{
          if(e && e.row){
            setVehicleDetailsChargeSessionInfo(e.row);
          }     
        }

    const handleCurrentPage = (setPage: any) => {
        setCurrentPage(setPage)    
      }    

    return (
        <>
        <div className="table_scroll">
          <div className="defult_table" style={styleDetails.styleWidth} onClick={handleClickOpen('paper', vehicleDetailsChargeHistory)}>
            <DataGrid autoHeight className="table"
              rowHeight={59}
              headerHeight={30}
              rows={vechicleDetailsHistoryDataSet(vehicleDetailsChargeHistory)}
              columns={vehicleDetailsChargeHistorycolumns}
              pageSize={pageSize}
              onRowClick={(e) => onVehicleDetailsChargeHistoryClick(e)}
            />
          </div>
          {open &&
            <CommonChargeSessionDetails {...props}
              open={open}
              setHandleClose={handleClose}
              historyId={vehicleDetailsChargeSessionInfo.historyId}
              sessionInfo={vehicleDetailsChargeSessionInfo}
            />}


          <div className="table_footer">
            <Grid container direction="row" alignItems="center" spacing={0}>
              <Grid className="text_left" item xs={8}>    
              {vehicleDetailsChargeHistory && vehicleDetailsChargeHistory.totalchargeHistory > pageSize ?
                <CommonPagination 
                  currentPage = {currentPage}
                  totalCount = {vehicleDetailsChargeHistory?.totalchargeHistory}
                  callBackCurrentPage = {handleCurrentPage}
                /> : <></>}
                <CommonDownloadCsv {...props}
                datas={vechicleDetailsHistoryDataSet(vehicleDetailDownloadCsv)}
                headers={fileHeader}
                filename={fileName}
                callBackDownloadCsv={getVehicleDetailDownloadCsv}/>              </Grid>
              <Grid className="text_right" item xs={4}>
                <div className="list_number">{pageData} {vehicleDetailsChargeHistory.totalchargeHistory ? (vehicleDetailsChargeHistory.totalchargeHistory + ' Charge Session') : ''}</div>
              </Grid>
            </Grid>
          </div>
        </div>
      </>
    );
}
export default VehicleDetailsChargeHistory;