export const FETCH_DEPOT_ACTION = 'FETCH_DEPOT_ACTION';



export const fetchDepotDetails = (value: any) => {
    return {
        type: FETCH_DEPOT_ACTION,
        payload: value
    }
};
