import React from 'react';
import Proptypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import Images from '../../assets/images';
import environment from '../../environment';
import { getMapOptions } from 'common/mapMethods';

const AnyReactComponent = ({onMarkerClick} : { onMarkerClick: any}) => <div onClick={onMarkerClick}>{<img src={Images.MAP_MARKER} />}</div>;

const CommonGoogleMap = (props:any) => {

const defaultMapOptions = {
    fullscreenControl: props?.fullscreenControl ? props.fullscreenControl: false,
    scrollwheel: props?.scrollwheel ? props.scrollwheel: false,
    gestureHandling: props?.gestureHandling ? props?.gestureHandling: 'none'
  };    


    return (
        <>
            <GoogleMapReact 
                bootstrapURLKeys={{ key: environment.googleMapKey,libraries: ['places'] }}
                center={
                    {
                        lat: props?.center?.lat,
                        lng: props?.center?.lon
                    }
                }
                zoom={props?.zoom}
                options={props.options ? getMapOptions : defaultMapOptions }
            >
                {props?.markers && props.markers .map((value:any, index:any) => (<AnyReactComponent {...props}
                 lat={value.lat}
                 lng={value.lon}
                 key={index}   
                 onMarkerClick={() => props.markerClicked(value)}
                />))}  
            </GoogleMapReact>
        </>
    );
}

CommonGoogleMap.propTypes = {
    center: Proptypes.any,
    zoom: Proptypes.number,
    markers: Proptypes.any
};
  
CommonGoogleMap.defaultProps = {
    center: {},
    zoom: 5,
    markers: []
};

export default CommonGoogleMap;
