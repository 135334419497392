import React, { useState, useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Images from 'assets/images';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import StatusButton from 'components/statusButton/statusButton';
import {useHistory} from "react-router-dom";
import ApiEndPoint from 'services/apiUrl.json';
import { getMethod } from 'services/api';
import VehicleStatus from 'components/vehicleStatus/vehicleStatus';
import { useSelector, useDispatch} from 'react-redux';
import { fetchVehicleDetails, fetchCurrentVehicleId }  from 'redux/actions/vehicleList'
import moment from "moment";
import {vehImageList} from 'data/vehicleImageList'
import {useStyles} from 'common/common';
import {dateFormats} from 'common/commonDateFormats';
import './vehicleGroupList.scss';

const VehicleGroupList = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const [vehiclesList, setVehiclesList] = useState<any>({});
  const [vehicleSpinnerOff, setVehicleSpinnerOff] = useState<boolean>(true)
  const classes = useStyles();
  const currentFleetId = useSelector((state: any) => { return state.currentFleetId })
  const vehicleListValue = vehiclesList ? vehiclesList?.vehicleGroups : [];

  const getVehicleImage = (val: any) => {
      let vehImageLength = vehImageList.vehImgLength;
      let finalImage;
      let defaultImage = Images.speed_meter2;
      if(val >= vehImageLength){ 
        let fVal = val - (vehImageLength * Math.floor(val/vehImageLength)) 
        finalImage =  vehImageList.imageList.filter(value => value?.id === (fVal+1))[0]?.speedoUrl
      }else {
        finalImage =  vehImageList.imageList.filter(value => value?.id === (val+1))[0]?.speedoUrl
      }
      return finalImage ? finalImage : defaultImage;
  }

  useEffect(() => {
    getVehicleListData()
  }, [currentFleetId])

  const getVehicleListData = async () => {
    await getMethod(ApiEndPoint.fleetDepotList, currentFleetId + "/vehicle-groups").then((res) => {
      setVehicleSpinnerOff(false)
      setVehiclesList(res?.data)
    }).catch((err) => {
      setVehicleSpinnerOff(false)
      console.log("Error", err)
    })
  }

  const navigateToFleetVehicle = (value: any) => {
    let vehiclePayload = {
      value: value ? value : {},
      contactDetails :vehiclesList?.contacts ? vehiclesList.contacts : [], 
      OrganisationDetails :vehiclesList?.fleetDetails ? vehiclesList.fleetDetails : {},
    }
    dispatch(fetchVehicleDetails(vehiclePayload));
    dispatch(fetchCurrentVehicleId(value?.id));
    history.push('/fleetVehicle', { vehicleId: value?.id})
  }

  return (
    <>
        <main className={classes.content}>
        {vehicleSpinnerOff ?  <CircularProgress className="inner_progress" /> :
          <div className={"mid_section_inside, vehicle_list"}>
          <Grid container spacing={2}>
                 <Grid className="mid_section_left" item xs={12} sm={12} md={8}>
                  <div className="depot_list">
                    <List data-testid='vehicleGroupDataTest'>
                      {vehicleListValue && vehicleListValue.map((value: any, index: any) => (
                        <ListItem key={index}>
                          <Card className="list_card" variant="outlined" onClick={() => navigateToFleetVehicle(value)}>
                            <CardContent className="card_content" >
                              <div className="depot_details">
                                <Grid container>
                                  <Grid item xs={9} sm={10} md={9} lg={9}>
                                    <div className="depot_information">
                                      <div className="depot_location_map">
                                      <img className='img_res' src={value?.image} alt="Fleet Vehicle" />
                                      </div>
                                      <div className="depot_info">
                                          <div className="depot_name">{value?.name}</div>
                                         <div className="port_details">
                                             <span className="alert">
                                            <span><img src={Images.IC_alert_12x14} alt="Depot Alert" /></span>
                                            {value?.totalAlerts} Alerts</span>
                                            <span className="port">
                                            <span><img src={Images.IC_AIRPORT_SHUTTLE} alt="airport shuttle" /></span>
                                            {value?.totalVehicles} Vehicles</span>
                                        </div>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid className="depot_graph text_right" item xs={3} sm={2} md={3} lg={3}>
                                  <img src={getVehicleImage(index)} alt="Fleet Vehicle" />   
                                  </Grid>
                                </Grid>
                                <div className="stat_list">
                                  <StatusButton classType="parked" statusLabel="Parked" statusValue={value?.statusCount?.parked} />
                                  <StatusButton classType="complete" statusLabel="Complete" statusValue={value?.statusCount?.complete} />
                                  <StatusButton classType="charging" statusLabel="Charging" statusValue={value?.statusCount?.charging} />
                                  <StatusButton classType="connected" statusLabel="Connected" statusValue={value?.statusCount?.connected} />
                                  <StatusButton classType="transit" statusLabel="Transit" statusValue={value?.statusCount?.transit} />
                                  <StatusButton classType="in-service" statusLabel="In Service" statusValue={value?.statusCount?.inService} />
                                  <StatusButton classType="offline" statusLabel="Offline" statusValue={value?.statusCount?.offline} />
                                </div>
                              </div>
                            </CardContent>
                          </Card>  
                        </ListItem>
                      ))}
                    </List>
                  </div>
                 </Grid>    
                 <Grid className="mid_section_right vehicle_g_status" item xs={12} sm={12} md={4}>
                 <Card className="small_card" variant="outlined">
                <CardContent className="card_content">
                    <div className="section_title">
                        <Grid container>
                            <Grid item xs={12}>
                                <h3>Vehicle Status</h3>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="vehicle_state_list">
                    <List data-testid='statusDataTest' >
                     <VehicleStatus classType="parked" statusLabel="Parked" statusValue={vehiclesList?.totalStatus?.parked} />
                     <VehicleStatus classType="completed" statusLabel="Complete" statusValue={vehiclesList?.totalStatus?.complete} />
                     <VehicleStatus classType="charging" statusLabel="Charging" statusValue={vehiclesList?.totalStatus?.charging} />
                     <VehicleStatus classType="connected" statusLabel="Connected" statusValue={vehiclesList?.totalStatus?.connected} />
                     <VehicleStatus classType="transit" statusLabel="Transit" statusValue={vehiclesList?.totalStatus?.transit} />
                     <VehicleStatus classType="in-service" statusLabel="In Service" statusValue={vehiclesList?.totalStatus?.inService} />
                     <VehicleStatus classType="offline" statusLabel="Offline" statusValue={vehiclesList?.totalStatus?.offline} />
                    </List>     
                    </div>
                </CardContent>
            </Card>
            <Card className="small_card" variant="outlined">
                <CardContent className="card_content">
                    <div className="section_title">
                        <Grid container>
                            <Grid item xs={12}>
                                <h3>Recent Alerts</h3>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="recent_alert_list">
                    <List data-testid='alertDataTest'>
                      {vehiclesList && vehiclesList?.alerts?.length >= 1 && vehiclesList?.alerts.slice(0, 5).map((value: any, index: any) => (
                      <ListItem key={index}> 
                       <span className="unread"></span> 
                      <Grid justifyContent="space-between" container spacing={0}>
                      <Grid className="vehicle_number" item xs={4} >{value?.vehicleId}</Grid>
                        <Grid className="time_detail text_right" item xs={8} >{value?.alertTime ? moment(value?.alertTime).format(dateFormats.dateTimeFormat): ''} </Grid>
                      </Grid>  
                      <div className="recent_alert_msg">
                      {value?.alert}
                      </div>
                      </ListItem>
                      ))}                  
                    </List>
                    </div>
                </CardContent>
              </Card>
            </Grid>
           </Grid>   
          </div>
        }
        </main>
    </>
  );
}

export default VehicleGroupList