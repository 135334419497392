import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Checkbox from "@material-ui/core/Checkbox";
import Images from "../../../../assets/images";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import {getMethod} from "../../../../services/api";
import ApiEndPoint from "../../../../services/apiUrl.json";
import {
    pageDetails,sidebarData
} from "../../../../common/common";
import GoogleMapReact from "google-map-react";
import VehicleDetailMapMarker from "../../../../common/vehicleDetailMapMarker";
import PropTypes, { any } from "prop-types";
import evStationData from '../../../../data/evStations.json';
import vehicleDetailsList from '../../../../data/vehicleDetailsList.json';
import {distanceToMouse} from "../../../../common/commonMethod";
import {getMapOptions} from "../../../../common/mapMethods";
import environment from "../../../../environment";
import {defaultLatLong,
    googleMapLanguage,
    googleMapRegion,
    googleMapDefaultZoom,
    latLongPrecision, availableEV, notAvailableEV, primaryEV,AvailableEVStationMapMarker,PrimaryEVStationMapMarker,NotAvailableEVStationMapMarker} from "../../../../common/mapConstants";
import {DefaultRenderer, MarkerClusterer} from "@googlemaps/markerclusterer";
import '../vehicleGroup.scss';
import '../../../../index';


const useStylesForVehicleLocation = makeStyles((theme) => ({
    search: {
        position: 'relative',
        '&:hover': {
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: theme.spacing(0),
            width: '100%',
            paddingRight: '10px',
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '313px',
        background: '#fff',
        borderRadius: '5px'
    },

    search1: {
        position: 'relative',
        '&:hover': {
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: theme.spacing(0),
            width: '100%',
            paddingRight: '10px',
        },
        marginRight: theme.spacing(1),
        marginLeft: 0,
        width: '150px',
        background: '#fff',
        borderRadius: '5px'

    },
    searchIcon: {
        padding: '0px 10px',
        height: '100%',
        fontSize: '14px',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#999',
    },

    inputInput: {
        padding: theme.spacing(1.5, 1, 1.5, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
        transition: theme.transitions.create('width'),
        fontSize: '12px',
        width: '300px',
        [theme.breakpoints.down('md')]: {
            marginLeft: theme.spacing(0),
            width: '100%',
        },
    },
    inputInput1: {
        padding: theme.spacing(1.5, 1, 1.5, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
        transition: theme.transitions.create('width'),
        fontSize: '12px',
        width: '106px',
        [theme.breakpoints.down('md')]: {
            marginLeft: theme.spacing(0),
            width: '100%',
        },
    },

    inputRoot: {
        color: '#999',
    },
    root: {
        display: 'flex',
    },
    drawer: {
        width: sidebarData.drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: sidebarData.drawerWidth,
    },

    drawerOpen: {
        width: sidebarData.drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },


    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(8) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },

    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));
let vehicleListOnMap = vehicleDetailsList.vehicleDetails;
let vehicleListFromApi = [{}];

function GoogleMapVehiclesLocation(props:any){
    const { children,vehiclesList,vehicleToShow, isAvailableEVMarkers,isNotAvailableEVMarkers,isPrimaryEVMarkers, ...other } = props;
    const stationList = evStationData;
    vehicleListOnMap = vehiclesList;
    let vehicleLatLong :any;
    const [mapState, setMapState] = useState<any>({});
    const [markerCluster, setMarkerCluster] = useState<any>({});
    let mc :any;

    if(vehicleListOnMap.length > 0){
        vehicleLatLong = {
        latitude: Number((vehicleListOnMap[0].latitude).toFixed(latLongPrecision)),
        longitude: Number((vehicleListOnMap[0].longitude).toFixed(latLongPrecision))
    }
    } else {
        vehicleLatLong = defaultLatLong;
    }

    const defaultTripLatLong = { lat: vehicleLatLong.latitude, lng: vehicleLatLong.longitude };

    const clusterIconChargingTemp :any = {
        complete: 0,
        charging: 0,
        connected: 0,
        transit:  0,
        inservice: 0,
        offline: 0,
        parked: 0
    };

    let clusterChargingData :any = {
        parkedComplete: 0,
        chargingConnected: 0,
        inService: 0,
        transit: 0,
        offline: 0
    }

    let svgStr = '';

    const updateClusterChargingTotal = (index:any) => {
        clusterIconChargingTemp[index.toLowerCase()] += 1;
    }

    /*Below function gives dynamic percentage of number vehicles based on status (Parked/Complete/Inservice, etc..) to form cluster icon.*/
    const calculateClusterPercentage = (status:any) => {
        return (status/vehicleListOnMap.length) * 100;
    }

    const generateClusterCharging = () => {
        const clusterChargingDataTemp =
            [{attr: 'parkedComplete', val: calculateClusterPercentage(clusterIconChargingTemp.complete+ clusterIconChargingTemp.parked )},
                {attr: 'chargingConnected', val: calculateClusterPercentage(clusterIconChargingTemp.charging+ clusterIconChargingTemp.connected )},
                {attr: 'inService', val: calculateClusterPercentage(clusterIconChargingTemp.inservice )},
                {attr: 'transit', val: calculateClusterPercentage(clusterIconChargingTemp.transit)},
                {attr: 'offline', val: calculateClusterPercentage(clusterIconChargingTemp.offline)}
            ];

        const colorOfChargingData :{[key:string]: any} = {
            'parkedComplete':'#3B771E',
            'chargingConnected':'#fdbb1d',
            'inService':'#E96A24',
            'transit':'#074F74',
            'offline':'#dddddd'
        }

        clusterChargingDataTemp.sort((val1, val2) =>
            val1.val < val2.val ? 1:-1);
        clusterChargingData[clusterChargingDataTemp[0].attr] = 100;

        let tempSvgStr = '  <circle r="5" cx="10" cy="10" stroke="'+ colorOfChargingData[clusterChargingDataTemp[0].attr] +
            '" stroke-width="6" stroke-dasharray="calc('+ clusterChargingData[clusterChargingDataTemp[0].attr] +' * 31.42 / 100), 31.42" transform="rotate(-90) translate(-20)" />' ;

        let circleWithNoStrokeArr = '';

        clusterChargingDataTemp.map((value, index) => {
            if(index > 0) {
                clusterChargingData[value.attr] = clusterChargingData[clusterChargingDataTemp[index -1].attr] - clusterChargingDataTemp[index -1].val;
              if(value.val === 0) {
                  circleWithNoStrokeArr = '<circle r="5" cx="10" cy="10" stroke="'+colorOfChargingData[value.attr] +'" stroke-width="6" transform="rotate(-90) translate(-20)" />';
              } else {
                  tempSvgStr = tempSvgStr + '<circle r="5" cx="10" cy="10" stroke="'+ colorOfChargingData[value.attr] +
                      '" stroke-width="6" stroke-dasharray="calc('+ clusterChargingData[value.attr] +' * 31.42 / 100), 31.42" transform="rotate(-90) translate(-20)" /> ' ;
              }
            }
        })

        svgStr = '<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20">' +
            + circleWithNoStrokeArr + tempSvgStr
            + '  <circle r="5" cx="10" cy="10" stroke-width="10" fill="white" />' +
            '</svg>'

    }

    const createMarker = (value:any,map:any,maps:any) => {
        const marker = new maps.Marker({
            position: {
                lat: value.latitude,
                lng: value.longitude,
            },
            label: {
                text: String(value.vehicleId),
                color: "#25556E",
                fontSize: "11px"
            },
            map
        });

        const socWidth  = ((45 * value.soc)/100) + 'px'

        const svgSV = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="67.999" height="69" viewBox="0 0 67.999 69">\n' +
            '  <defs>\n' +
            '    <filter id="Union_13" x="0" y="0" width="67.999" height="69" filterUnits="userSpaceOnUse">\n' +
            '      <feOffset dy="3" input="SourceAlpha"/>\n' +
            '      <feGaussianBlur stdDeviation="3" result="blur"/>\n' +
            '      <feFlood flood-opacity="0.161"/>\n' +
            '      <feComposite operator="in" in2="blur"/>\n' +
            '      <feComposite in="SourceGraphic"/>\n' +
            '    </filter>\n' +
            '  </defs>\n' +
            '  <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_13)">\n' +
        '    <path id="Union_13-2" data-name="Union 13" d="M18,40H32L25,51ZM5,40a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0H45a5,5,0,0,1,5,5V35a5,5,0,0,1-5,5Z" transform="translate(9 6)" fill="#fff">\n' +
            '    </path>'+
            ' <rect fill="#EEEEEE" x="17%" y="20%" style="width: '+ '45px; height: 4px;"></rect>; '+
            ' <rect fill="#25556E" x="17%" y="20%" style="width: ' + socWidth +';' +' height: 4px;"></rect>; '+
            '  </g>\n' +
            '</svg>\n';

        const svg = window.btoa(svgSV);

        marker.setIcon({
            url: `data:image/svg+xml;base64,${svg}`
        });
        return marker;
    }

    const handleApiLoaded = (map:any, maps:any) => {
        const markers:any = [];

        const vehicleListToDisplay = (vehicleToShow) ? vehicleToShow.vehicleList:vehicleListOnMap;
        vehicleListToDisplay && vehicleListToDisplay.forEach((value:any) => {
            const marker = createMarker(value, map, maps);
            markers.push(
                marker
            );

            updateClusterChargingTotal(value.status);
        });

        const rc = new DefaultRenderer();

        rc.render = ({count,position},stats) =>{
            generateClusterCharging();
            const svg = window.btoa(svgStr);

            return new window.google.maps.Marker({
                position,
                icon: {
                    url: `data:image/svg+xml;base64,${svg}`,
                    scaledSize: new window.google.maps.Size(45, 45)
                },
                label: {
                    text: String(count),
                    color: "#000",
                    fontSize: "12px"
                },
                zIndex: 1000 + count,
            });
        }

        if(markerCluster && markerCluster.mc){
            if(!mc){
                mc = markerCluster.mc;
            }
            mc.clearMarkers();
            mc.setMap(mapState.map);
            mc.addMarkers(markers);
            mc.renderer = rc;
        } else {
            mc = new MarkerClusterer({ map,markers,renderer: rc });
        }

        setMapState({map,maps});
        setMarkerCluster({mc, markers});
    };

    const reloadClusters = () => {
        if(markerCluster && markerCluster.mc && markerCluster.mc.markers.length === 0){
            let markers = markerCluster.markers;
            let mc = markerCluster.mc;
            clearMarkers(markers, mc);
            handleApiLoaded(mapState.map, mapState.maps);
        }

    }

    const clearMarkers = (markers:any, mc:any) => {
        let i = 0,
            l = markers.length;
        for (i; i < l; i++) {
            markers[i].setMap(null)
        }
        markers = [];
        // Clears all clusters and markers from the clusterer.
        mc.clearMarkers();
        mc.setMap(null);
    }

    const handleApiLoadedForSearch = () => {
        if(vehicleToShow.vehicleList.length === 1){
            let markers = markerCluster.markers;
            let mc = markerCluster.mc;
            clearMarkers(markers, mc);
        }
    };

    return (
        (vehicleToShow.vehicleList && vehicleToShow.vehicleList.length > 0)?
            ((vehicleToShow.vehicleList.length !== 1)?<GoogleMapReact {...props}
            bootstrapURLKeys={{
                key: environment.googleMapKey,
                language: googleMapLanguage,
                region: googleMapRegion
            }}
            center={defaultTripLatLong}
            defaultZoom={googleMapDefaultZoom}
            options={getMapOptions}
            distanceToMouse={distanceToMouse}
            onGoogleApiLoaded={({ map, maps }) => {
                handleApiLoaded(map, maps);
            }}
        >
                        {reloadClusters()}
            {
                stationList && stationList.map((value) => {
                    if(isAvailableEVMarkers.avState && value.status === availableEV){
                        return (
                            <AvailableEVStationMapMarker lat={value.latitude} lng={value.longitude} />
                        );
                    }
                    else if(isNotAvailableEVMarkers.avState && value.status === notAvailableEV){
                        return (
                            <NotAvailableEVStationMapMarker lat={value.latitude} lng={value.longitude} />
                        );
                    } else  if(isPrimaryEVMarkers.avState && value.status === primaryEV){
                        return (
                            <PrimaryEVStationMapMarker lat={value.latitude} lng={value.longitude} />
                        );
                    }
                })
            }
        </GoogleMapReact>:
                    <GoogleMapReact
                        bootstrapURLKeys={{
                            key: environment.googleMapKey,
                            language: googleMapLanguage,
                            region: googleMapRegion
                        }}
                        center={defaultTripLatLong}
                        defaultZoom={googleMapDefaultZoom}
                        options={getMapOptions}
                    >
                        {
                            handleApiLoadedForSearch()
                        }
                        {
                            stationList && stationList.map((value) => {
                                if(isAvailableEVMarkers.avState && value.status === availableEV){
                                    return (
                                        <AvailableEVStationMapMarker lat={value.latitude} lng={value.longitude} />
                                    );
                                }
                                else if(isNotAvailableEVMarkers.avState && value.status === notAvailableEV){
                                    return (
                                        <NotAvailableEVStationMapMarker lat={value.latitude} lng={value.longitude} />
                                    );
                                } else  if(isPrimaryEVMarkers.avState && value.status === primaryEV){
                                    return (
                                        <PrimaryEVStationMapMarker lat={value.latitude} lng={value.longitude} />
                                    );
                                }
                            })
                        }
                        {
                            vehicleListOnMap && vehicleListOnMap.map((value) => {
                                return (
                                    <VehicleDetailMapMarker {...props} key={value.id} lat={value.latitude} lng={value.longitude} text={value.vehicleId} tooltip={value.vehicleId} soc={value.soc} />
                                )})
                        }
                    </GoogleMapReact>
            ):
            <GoogleMapReact {...props}
                bootstrapURLKeys={{
                    key: environment.googleMapKey,
                    language: googleMapLanguage,
                    region: googleMapRegion
                }}
                defaultCenter={defaultTripLatLong}
                defaultZoom={googleMapDefaultZoom}
                options={getMapOptions}
                distanceToMouse={distanceToMouse}
            >
                {
                    stationList && stationList.map((value) => {
                        if(isAvailableEVMarkers.avState && value.status === availableEV){
                            return (
                                <AvailableEVStationMapMarker lat={value.latitude} lng={value.longitude} />
                            );
                        } else  if(isNotAvailableEVMarkers.avState && value.status === notAvailableEV){
                            return (
                                <NotAvailableEVStationMapMarker lat={value.latitude} lng={value.longitude} />
                            );
                        } else  if(isPrimaryEVMarkers.avState && value.status === primaryEV){
                            return (
                                <PrimaryEVStationMapMarker lat={value.latitude} lng={value.longitude} />
                            );
                        }
                    })
                }
        </GoogleMapReact>
    )

}

GoogleMapVehiclesLocation.propTypes = {
    children: PropTypes.node,
    vehicleDetails: PropTypes.any.isRequired,
    isAvailableEVMarkers: PropTypes.any.isRequired,
    isNotAvailableEVMarkers: PropTypes.any.isRequired,
    isPrimaryEVMarkers: PropTypes.any.isRequired,
    vehicleToShow: PropTypes.any.isRequired
};

const VehicleLocation = (props:any) => {
    const classes = useStylesForVehicleLocation();
    const currentVehicleGroupId = useSelector((state:any) => { return state.currentVehicleId })
    const [vehicleOverviewDataList, setVehicleOverviewDataList] = useState({});
    const [vehicleOverviewDataForMap, setVehicleOverviewDataForMap] = useState<any>({});
    const [currentPage, setCurrentPage] = useState(0);
    const pageSize = pageDetails.pageSize;
    const [availableStMarkers, setAvailableStMarkers] = React.useState({avState: true});
    const [notAvailableStMarkers, setNotAvailableStMarkers] = React.useState({avState: true});
    const [primaryStMarkers, setPrimaryStMarkers] = React.useState({avState: true});
    const [vehicleDataForMapChanged, setVehicleDataForMapChanged] = React.useState<any>({changed: false, vehicleList: [], initialVehicleId:''});

    useEffect(() => {
        getVehicleOverviewDetailsList()
    }, [currentPage])

    const getVehicleOverviewDetailsList= async() => {
        console.log(currentVehicleGroupId);
        const page = currentPage;
        const size = pageSize;
        await getMethod(ApiEndPoint.vehicleGroups, currentVehicleGroupId + "/overview" + "?page=" + page + "&size=" + size).then((res) => {
            setVehicleOverviewDataList(res.data);
            vehicleListFromApi = [...res.data.vehicleDetails];
            setVehicleOverviewDataForMap(res.data);
            setVehicleDataForMapChanged({changed: false, vehicleList: res.data.vehicleDetails,initialVehicleId:''});
        }).catch((err) => {
            console.log("Error", err)
        })
    }

    const handleAvailableMarkers = (event:any) => {
        setAvailableStMarkers({avState: !availableStMarkers.avState});
    }

    const handleNotAvailableMarkers = (event:any) => {
        setNotAvailableStMarkers({avState: !notAvailableStMarkers.avState});
    }

    const handlePrimaryMarkers = (event:any) => {
        setPrimaryStMarkers({avState: !primaryStMarkers.avState});
    }

    const getVehicleDetails = (value:any) => {
        let vehicleDetail:any = [];
        if (vehicleListFromApi && vehicleListFromApi.length > 0) {
            vehicleDetail = vehicleListFromApi.filter((vehicle:any) => (
                vehicle?.vehicleId && vehicle?.vehicleId.toLowerCase().indexOf(value.toLowerCase()) > -1 || vehicle?.license && vehicle?.license.toLowerCase().indexOf(value.toLowerCase()) > -1  || (vehicle?.vehicleVIN && vehicle?.vehicleVIN.toLowerCase().indexOf(value.toLowerCase()) > -1 )) )
                .map((val:any) => ({ ...val, id: val.id }))
        }
        return vehicleDetail;
    }

    const handleSearch = (event:any) => {
        const key = event.target.value;
        const changedVal = !vehicleDataForMapChanged.changed;
        if(key === ''){
            const vehicleListData:any = vehicleOverviewDataList;
            vehicleListData.vehicleDetails = vehicleListFromApi;
            setVehicleOverviewDataForMap(vehicleListData);
            setVehicleDataForMapChanged({changed: changedVal, vehicleList: vehicleListFromApi,initialVehicleId:''});
        } else  if(key.length > 2){
            const searchedVehicle = getVehicleDetails(key);
            const vehiclesListToShowOnMap:any = vehicleOverviewDataForMap;
            vehiclesListToShowOnMap.vehicleDetails = searchedVehicle;
            setVehicleOverviewDataForMap(vehiclesListToShowOnMap);
            if(searchedVehicle.length > 0){
            setVehicleDataForMapChanged({changed: changedVal, vehicleList: searchedVehicle,initialVehicleId:searchedVehicle[0].vehicleId});
            } else{
                setVehicleDataForMapChanged({changed: changedVal, vehicleList: vehicleListFromApi,initialVehicleId:''});
            }
        }
    }

    return (
        <div className="information_section map">

            <div className="map_action_row">
                <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                    <Grid className="text_left" item xs={5} sm={3}>
                        <div className="vehicle_search">
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon />
                                </div>
                                <InputBase
                                    placeholder="Search vehicle ID, license number, or VIN number"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ 'aria-label': 'search' }}
                                    onKeyUpCapture={(e) => handleSearch(e)}
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid className="text_right" item xs={7} sm={9}>

                        <div className="map_legend">
                            <Checkbox
                                defaultChecked
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                onChange={handleAvailableMarkers}
                            />
                            <img src={Images.ic_edit_location_available} alt="Available" /> Available</div>
                        <div className="map_legend">
                            <Checkbox
                                defaultChecked
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                onChange={handleNotAvailableMarkers}
                            />
                            <img src={Images.ic_edit_location_not_available} alt="Available" /> Not Available</div>
                        <div className="map_legend">
                            <Checkbox
                                defaultChecked
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                onChange={handlePrimaryMarkers}
                            />
                            <img src={Images.ic_edit_location_primary_depot} alt="Available" />
                            Primary Depot</div>
                    </Grid>
                </Grid>
            </div>

            <div className="map_container">
                <div className="route_map">
                    <div style={{ height: '482px', width: '100%' }}>
                        {
                        (vehicleOverviewDataForMap && vehicleOverviewDataForMap.vehicleDetails) ?
                                <GoogleMapVehiclesLocation {...props} vehicleToShow={vehicleDataForMapChanged}
                                                           vehiclesList={vehicleOverviewDataForMap.vehicleDetails}
                                                           isAvailableEVMarkers={availableStMarkers} isNotAvailableEVMarkers={notAvailableStMarkers} isPrimaryEVMarkers={primaryStMarkers}
                                />:''
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}

export default VehicleLocation;