import React, { useState, useEffect } from 'react';
import PropTypes, { any } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { DataGrid } from '@material-ui/data-grid';
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash';
import ApiEndPoint from 'services/apiUrl.json';
import { getMethod } from 'services/api';
import moment from 'moment';
import Images from 'assets/images';
import StatusButton from 'components/statusButton/statusButton';
import GoogleMapReact from 'google-map-react';
import DepotInformation from './depotInformation/depotInformation';
import {pageDetails, defaultVariable} from 'common/common';
import ChargeFee from './chargeFee/chargeFee';
import './evseDepotDetail.scss';
import { showProgressBar } from 'common/commonMethod';
import { fetchCurrentStationId }  from 'redux/actions/stationList'
import EvseDepotAlerts from './evseDepotAlerts/evseDepotAlerts'
import EvseDepotChargeHistory from './evseDepotChargeHistory/evseDepotChargeHistory' 
import EvseSchedule from './evseSchedule/evseSchedule'
import {useHistory} from "react-router-dom";
import EvseDepotAnalytics from './evseDepotAnalytics/evseDepotAnalytics';
import CommonPagination from 'components/commonPagination/commonPagination';
import './evseDepotDetail.scss';
const AnyReactComponent = ({}) => <div >{<img src={Images.MAP_MARKER} />}</div>;

const columns = [
  {
    field: "e",
    headerName: "e",
    headerClassName: "empty",
    sortable: false,
    hasFocus: false,
    cellClassName: "empty",
    width: 15,
    maxWidth: 25,
    minWidth: 28,
    disableColumnMenu: true,
    renderCell: (cellValues: any) => {
      return (
        <div className="empty">
          {cellValues.value}
        </div>
      );
    }
  },
  {
    field: "port",
    headerName: "PORT",
    headerClassName: "action_columns",
    sortable: false,
    hasFocus: false,
    className: "port",
    minWidth: 120,
    flex: 0.1,
    disableColumnMenu: true,
    renderCell: (cellValues: any) => {
      return (
        <div className="port_icon">
          {cellValues.value ? cellValues.value : '-'}
        </div>
      );
    }
  },
  {
    field: 'status',
    headerName: 'STATUS',
    sortable: false,
    width: 120,
    disableColumnMenu: true,
    renderCell: (cellValues: any) => {
      return (
      <div className={`status ${defaultVariable.portStatusColor[cellValues?.value?.toLowerCase()] }`}><span className="status_text">{cellValues.value ? cellValues.value : '-' }</span></div>
      );
    }
  },
  {
    field: 'zone',
    headerName: 'ZONE',
    sortable: false,
    minWidth: 80,
    flex: 0.1,
    disableColumnMenu: true,
    renderCell: (cellValues: any) => {
      return (
        <>
          {cellValues.value ? cellValues.value : '-' }
          </>
      );
    }
  },
  {
    field: 'vehicle',
    headerName: 'VEH ID',
    sortable: false,
    flex: 0.1,
    minWidth: 100,
    disableColumnMenu: true,
    renderCell: (cellValues: any) => {
      return (
        <>
          {cellValues.value ? cellValues.value : '-' }
          </>
      );
    }
  },
  {
    field: 'rfId',
    headerName: 'RFID',
    sortable: false,
    width: 80,
    disableColumnMenu: true,
    renderCell: (cellValues: any) => {
      return (
        <>
          {cellValues.value ? cellValues.value : '-' }
          </>
      );
    }
  },
  {
    field: 'estMiles',
    headerName: 'EST (mi)',
    sortable: false,
    width: 90,
    disableColumnMenu: true,
    renderCell: (cellValues: any) => {
      return (
        <>
          {cellValues.value ? cellValues.value : '-' }
          </>
      );
    }    
  },
  {
    field: 'soc',
    headerName: 'SOC',
    sortable: false,
    disableColumnMenu: true,
    width: 180,
    renderCell: (cellValues: any) => {
      return (
        <div className="soc_level">
          <div className="text_class">{cellValues.value ? (cellValues.value + '%')  : '-' }</div>
          <div className="stat_progress">
            <div className={`bar ${showProgressBar(cellValues.value? cellValues.value : 0)}`} style={{ "width": cellValues.value > 0 ? (cellValues.value + "%") : 0 }}></div>
          </div>
        </div>
      );
    }
  },
  {
    field: 'startTime',
    headerName: 'START',
    sortable: false,
    minWidth: 70,
    flex: 0.1,
    disableColumnMenu: true,
    renderCell: (cellValues: any) => {
      return (
        <>
          {cellValues.value ? moment(cellValues.value).format("h:mm a") : '-'}
          </>
      );
    }
  },
  {
    field: 'time',
    headerName: 'TIME',
    sortable: false,
    width: 70,
    disableColumnMenu: true,
    renderCell: (cellValues: any) => {
      return (
        <>
          {cellValues.value ? cellValues.value: '-'}
          </>
      );
    }
  },
  {
    field: "ee",
    headerName: "ee",
    headerClassName: "empty",
    sortable: false,
    hasFocus: false,
    cellClassName: "empty",
    width: 15,
    maxWidth: 25,
    minWidth: 28,
    disableColumnMenu: true,
    renderCell: (cellValues: any) => {
      return (
        <div className="empty">
          {cellValues.value}
        </div>
      );
    }
  },
];

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const EvseDepotDetail = (props: any) => {
  const depotDetailsData = useSelector((state: any) => { return get(state.depotData, ['depotDetails'], []) })
  const currentDepotId = depotDetailsData?.evsedepotId
  const dispatch = useDispatch();
  const history = useHistory();
  const [overviewInfo, setOverviewInfo] = useState<any>({});
  const [overviewPageData, setOverviewPageData] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [tabChange, setTabChange] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [value, setValue] = React.useState(0);
  const pageSize = pageDetails.pageSize;
  const defaultProps = {
    center: {
      lat: 37.0902,
      lng: 95.7129
    },
    zoom: 10
  };
  const defaultMapOptions = {
    fullscreenControl: false,
    scrollwheel: false,
    gestureHandling: 'none'
  };

  useEffect(() => {
    getOverviewData()
  }, [currentPage])

  useEffect(() => {
    handleOverviewPageData()
  }, [overviewInfo])

  const getOverviewData = async () => {
    const page = currentPage;
    const size = pageSize;
    await getMethod(ApiEndPoint.depotInformation, currentDepotId + "/charge-port-details" + "?page=" + page + "&size=" + size).then((res) => {
      setOverviewInfo(res.data);
    }).catch((err) => {
      console.log("Error", err)
    })
  }
  

  const overviewDataSet = (value: any) => {
    let overviewTableData = []
    if (value && value.details) {
      overviewTableData = value.details.map((val: any) => ({ ...val, e: 'a', ee: 'aa', id: val.id, driverName: val.firstName ? (val.firstName + ' ' + val.lastName): '' }))
    }
    return overviewTableData
  }

  const handleOverviewPageData = () => {
    let totalPage = overviewInfo && overviewInfo.totalCount ? overviewInfo.totalCount : 0;
    if (totalPage > 0) {
      let setPageValue = (currentPage >= 1 ? (pageSize*currentPage) + 1 : 1) + ' - ' + ((pageSize*(currentPage+1)) < totalPage ? (pageSize*(currentPage+1)) : totalPage  ) + ' of '
      setOverviewPageData(setPageValue)
    }
  }

  const handleCurrentPage = (setPage: any) => {
    setCurrentPage(setPage)     
  }
  
  const listenToScroll = () => {
    let heightToHideFrom = 50;
    const winScroll = document.body.scrollTop ||
      document.documentElement.scrollTop;
    if (winScroll > heightToHideFrom) {
      isVisible &&      // to limit setting state only the first time         
        setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  const navigateToDepotStationDetails = (value: any) => {
      dispatch(fetchCurrentStationId(value?.chargerId));
      history.push('/depotStationDetails', { depotId: currentDepotId, stationId: value?.chargerId})
  }

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () =>
      window.removeEventListener("scroll", listenToScroll);
  }, [])

  const classes = useStyles();
  const handleChange = (event: any, newValue: any) => {
    if(tabChange !== newValue) {
        setCurrentPage(0);
        setTabChange(newValue);        
    }
    window.scrollTo(0, 0);
    setValue(newValue);
  }; 

  return (
    <>
        <main className={classes.content}>
          <div className={isVisible ? 'mid_section_inside details_pages' : 'mid_section_inside details_pages scroll_effect'}>
            <div className="depot_details">
              <div className="depot_detail_head position_fixed_head">
                <div className="depot_information">
                  <div className="depot_location_map">
                    <GoogleMapReact className="theme_radius" {...props}
                      bootstrapURLKeys={{ key: "AIzaSyCqFay6qqmew8KKgujKBlhSNzA7RAr4yqQ" }}
                      defaultCenter={
                        {
                          lat: depotDetailsData ? depotDetailsData.lat : defaultProps.center.lat,
                          lng: depotDetailsData ? depotDetailsData.lon : defaultProps.center.lng
                        }
                      }
                      defaultZoom={defaultProps.zoom}
                      options={defaultMapOptions}
                    >
                      <AnyReactComponent {...props}
                        lat={depotDetailsData.lat}
                        lng={depotDetailsData.lon}
                      />
                    </GoogleMapReact>
                  </div>
                  <div className="depot_info">
                    <div className="depot_name">{depotDetailsData.evsedepotName}</div>
                    <div className="depot_addr">{depotDetailsData.streetAddress1 ? depotDetailsData.streetAddress1 : ''} {depotDetailsData.streetAddress2 ? ', ' + depotDetailsData.streetAddress2 : ''}<br />{depotDetailsData.city + ', ' + depotDetailsData.state + ' ' + depotDetailsData.zipCode + ', ' + depotDetailsData.country}</div>
                    {isVisible
                      &&
                      <div className="port_details">
                        <span className="port">
                          <img src={Images.IC_PORT_12x14} alt="Depot Port" />
                          {depotDetailsData.evseports} Total Ports</span>
                      </div>
                    }
                  </div>
                </div>
                <div data-testid='statusCount' className="stat_list text_right">
                  <StatusButton classType="available" statusLabel="Available" statusValue={depotDetailsData.availablePorts} />
                  <StatusButton classType="connected" statusLabel="Connected" statusValue={depotDetailsData.connectedPorts} />
                  <StatusButton classType="charging" statusLabel="Charging" statusValue={depotDetailsData.chargingPorts} />
                  <StatusButton classType="complete" statusLabel="Complete" statusValue={depotDetailsData.completePorts} />
                  <StatusButton classType="error" statusLabel="Error" statusValue={depotDetailsData.errorPorts} />
                  <StatusButton classType="offline" statusLabel="Offline" statusValue={depotDetailsData.offlinePorts} />
                </div>
              </div>
              <div className="detail_page_tab">
                <AppBar className="defult_tab position_fixed_nav" position="static" color="default">
                  <Tabs className="tab_nav"
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="off"
                    aria-label="scrollable force tabs example"
                  >
                    <Tab className="tab_nav_item" label="Overview" {...a11yProps(0)} />
                    <Tab className="tab_nav_item" label="Information" {...a11yProps(1)} />
                    <Tab className="tab_nav_item" label="Analytics" {...a11yProps(2)} />
                    <Tab className="tab_nav_item" label="Charge History" {...a11yProps(3)} />
                    <Tab className="tab_nav_item" label="Schedule" {...a11yProps(4)} />
                    <Tab className="tab_nav_item" label="Charge Fee" {...a11yProps(5)} />
                    <Tab className="tab_nav_item" label={<Badge className="alert_badge" badgeContent={3} color="primary">
                      Alerts &nbsp;  &nbsp; &nbsp;
                    </Badge>} {...a11yProps(5)} />
                  </Tabs>
                </AppBar>
                <TabPanel className="tab_detail" {...props} value={value} index={0}>
                  <div className="table_scroll">
                    <div className="defult_table" style={{ width: '100%' }}>

                      <DataGrid autoHeight className="table" {...props}
                        rowHeight={59}
                        headerHeight={30}
                        rows={overviewDataSet(overviewInfo)}
                        columns={columns}
                        pageSize={pageSize}
                        onRowClick={(e) => navigateToDepotStationDetails(e?.row)} />

                    </div>
                    <div className="table_footer">
                      <Grid container direction="row" alignItems="center" spacing={0}>
                        <Grid className="text_left" item xs={8}>
                        {overviewInfo && overviewInfo.totalCount > pageSize ?
                          <CommonPagination 
                          currentPage = {currentPage}
                          totalCount = {overviewInfo?.totalCount}
                          callBackCurrentPage = {handleCurrentPage}
                       /> : <></>}
                        </Grid>
                        <Grid className="text_right" item xs={4}>
                          <div className="list_number">{overviewPageData} {overviewInfo.totalCount ? (overviewInfo.totalCount + ' Ports') : '' } </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <DepotInformation
                  depotDetailsData={depotDetailsData}
                  depotId={currentDepotId}
                  />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <EvseDepotAnalytics />
                </TabPanel>
                <TabPanel className="tab_detail" {...props} value={value} index={3}>
                  <EvseDepotChargeHistory 
                    depotId={currentDepotId} 
                  />
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <EvseSchedule 
                    depotId={currentDepotId} 
                  />
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <ChargeFee 
                    depotId={currentDepotId} 
                  />
                </TabPanel>
                <TabPanel className="tab_detail" {...props} value={value} index={6}>
                  <EvseDepotAlerts 
                    depotId={currentDepotId}
                  />
                </TabPanel>
              </div>
            </div>
          </div>
        </main>
    </>
  );
}
export default EvseDepotDetail