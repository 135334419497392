import React from 'react'
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const isAuthentic = true;
    return (
        <Route {...rest} render={props => (isAuthentic ? <Component {...props} /> : <Redirect to="/" />)} />
    )
}

export default PrivateRoute
