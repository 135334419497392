import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { useSelector, useDispatch } from 'react-redux'
import { commonRoutes} from '../../common/common';
import { fetchFleetAction }  from '../../redux/actions/fleetDashboard'
import Images from '../../assets/images';
import './header.scss';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  

  inputRoot: {
    color: 'inherit',
  },

  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const Header = () => {
  const dispatch = useDispatch();
  const fleetList = useSelector((state:any) => { return state?.fleetData ? state.fleetData : [] }) 
  const depotData = useSelector((state:any) => { return state?.depotData?.depotDetails ? state.depotData.depotDetails : {} }) 
  const vehicleData = useSelector((state:any) => { return state?.vehicleData?.vehDetails ? state.vehicleData.vehDetails : {} }) 
  const currentFleetId =  useSelector((state:any) => { return state.currentFleetId})
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentFleet, setCurrentFleet] = React.useState(0);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [currentFleetName , setCurrentFleetName] = React.useState(null);
  const [currentDepotName , setCurrentDepotName] = React.useState(null);
  const [currentVehicleName , setCurrentVehicleName] = React.useState(null);
  const [breadCrumbsName , setBreadCrumbsName] = React.useState<any>(null);
  const [breadCrumbsUrl , setbreadCrumbsUrl] = React.useState<any>(null);
  const [breadCrumbsLast , setBreadCrumbsLast] = React.useState<any>(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  useEffect(() => {
    setCurrentFleet(currentFleetId)
    setCurrentFleetName(fleetList?.filter((value:any) => value?.fleetId === currentFleetId)[0]?.fleetName)
}, [currentFleetId])

  useEffect(() => {
    setCurrentDepotName(depotData?.evsedepotName)
  }, [depotData])

  useEffect(() => {
    setCurrentVehicleName(vehicleData?.name)
  }, [vehicleData])

  useEffect(() => {
    handleBreadCrumbs()
  })

  const handleProfileMenuOpen = (event:any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event:any) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleFleetDropdown = (event:any) => {
    setCurrentFleet(event?.target?.value);
    if(event?.target?.value > 0){
      dispatch(fetchFleetAction(event?.target?.value));
      switch (event?.target?.value > 0) {
        case (history?.location?.pathname === commonRoutes.fleetDashboardRoute && event?.target?.value > 0):
          history.push(commonRoutes.depotListRoute)
          break;
        case (commonRoutes.depotAllRoutes.includes(history?.location?.pathname) && history?.location?.pathname !== commonRoutes.depotListRoute):
          history.push(commonRoutes.depotListRoute)
          break;
        case (commonRoutes.vehicleAllRoutes.includes(history?.location?.pathname) && history?.location?.pathname !== commonRoutes.vehicleListRoute):
          history.push(commonRoutes.vehicleListRoute)
          break;
        case (commonRoutes.driverAllRoutes.includes(history?.location?.pathname) && history?.location?.pathname !== commonRoutes.driversRoute) :
          history.push(commonRoutes.driversRoute)
          break;    
      }
    }else{
      history.push('/')
    }
  }

  const handleBreadCrumbs = () => {
    let bCrumbsName = history.location.pathname === commonRoutes.depotListRoute ? 'EVSE Depots' : 
        commonRoutes.depotAllRoutes.includes(history?.location?.pathname) ? 'Evse Depot Details' :        
        history.location.pathname === commonRoutes.vehicleListRoute ? 'Vehicles' : 
        commonRoutes.vehicleAllRoutes.includes(history?.location?.pathname) ? 'Vehicle Details' :      
        history.location.pathname === commonRoutes.driversRoute ? 'Drivers' : 
        commonRoutes.driverAllRoutes.includes(history?.location?.pathname) ? 'Driver Details' :      
        history.location.pathname === commonRoutes.messageRoute ? 'Messages' : null; 
    setBreadCrumbsLast(bCrumbsName);

    switch (bCrumbsName !== null) {
      case (bCrumbsName === 'Evse Depot Details'):
        setBreadCrumbsName(currentDepotName);
        setbreadCrumbsUrl(commonRoutes.depotListRoute);
      break;  
      case (bCrumbsName === 'Vehicle Details'):
        setBreadCrumbsName(currentVehicleName);
        setbreadCrumbsUrl(commonRoutes.vehicleListRoute);
      break; 
      case (bCrumbsName === 'Driver Details'):
        setBreadCrumbsName('Drivers');
        setbreadCrumbsUrl(commonRoutes.driversRoute);
      break; 
      default:
        setBreadCrumbsName(null);
        setbreadCrumbsUrl(null);
    }    
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Log Out</MenuItem>
     
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
<MenuItem> 
        <IconButton className="top_action_btn">
        <img src={Images.IC_HELP} alt="help_icon" />
        </IconButton>
        <p>Help</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton className="top_action_btn"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <img src={Images.IC_USER} alt="user_icon" />
        </IconButton>
        <p>User</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar className="top_header">
        <Toolbar className="header_inner">

       <div className="inside_page_head">
       <IconButton className="back-arrow" onClick={()=> history.goBack()}>
       <img src={Images.ic_back_arrow} alt="back" />
      </IconButton>
      <div className="header_breadcrum">
<b></b>
      <Breadcrumbs className="header_breadcrum_inside"  aria-label="breadcrumb">
      <Link color="inherit" href="/">
        <span className="material-icons">home</span>
        </Link>
        <Link color="inherit" href={breadCrumbsUrl}>
          <span>{currentFleetName}</span>
        </Link>
        {breadCrumbsName && 
        <Link color="inherit">
        <span>{breadCrumbsName}</span>
        </Link> }
         <Typography color="textPrimary"> <span>{breadCrumbsLast}</span></Typography>
      </Breadcrumbs>
      </div>
   
        </div>   
        <img className="dashborad_logo" src={Images.LOGO} alt="logo" />
       
          <div className={classes.grow} />
          <FormControl className="header_form_control">
      <Select labelId="fleet-simple-select-label" id="fleet-simple-select" value={currentFleet} onChange={handleFleetDropdown}>
      <MenuItem className="optionlist" value={0}>
                                  Select Fleet
                                </MenuItem>
      {fleetList && fleetList.map((value:any) => (<MenuItem className="optionlist" key={value.fleetId} value={value.fleetId}> 
                                {value.fleetName}
                                </MenuItem>))} 
        </Select>
      </FormControl>
          <div className="search_box">
            <div className="searchIcon">
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search"
              classes={{
                root: classes.inputRoot,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
         
          <div className={classes.sectionDesktop}>
            <IconButton className="top_action_btn">
            <img src={Images.IC_HELP} alt="help_icon" />
            </IconButton>
            <IconButton className="top_action_btn"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <img src={Images.IC_USER} alt="user_icon" />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}

export default Header;