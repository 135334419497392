const environment = {
    baseUrl: "http://44.224.237.201:8081",
    googleMapKey: "AIzaSyCqFay6qqmew8KKgujKBlhSNzA7RAr4yqQ"
};
if (process.env.REACT_APP_ENV === "development") {
    environment.baseUrl = "http://44.224.237.201:8081";
}
else if (process.env.REACT_APP_ENV === "staging") {
    environment.baseUrl = "http://44.234.144.172:8080";
}

else if (process.env.REACT_APP_ENV === "production") {
    environment.baseUrl = "http://44.234.144.172:8080";
}

export default environment

