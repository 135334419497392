const initialState = {
    error: null,
    depotDetails : []
};

export default function fetchDepotReducer(state = initialState, action: any) {
    switch(action.type) {
        case 'FETCH_DEPOT_ACTION': {
            return {
                ...state,
                error: null,
                depotDetails: action.payload
            }
        }   
        default: 
            return state;
    }
}