import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'
import CommonTableDetails from 'components/commonTableDetails/commonTableDetails'
import ApiEndPoint from 'services/apiUrl.json';
import { getMethod } from 'services/api';
import { pageDetails } from 'common/common';
import moment from 'moment';
import { useSelector } from 'react-redux';
import CommonPagination from 'components/commonPagination/commonPagination';
import { styleDetails } from 'common/common';
import CommonDownloadCsv from 'components/commonDownloadCsv/commonDownloadCsv';
import { dateFormats } from 'common/commonDateFormats';

const DepotAlerts = (props:any) => { 
  const currentStationId = useSelector((state:any) => { return state.currentStationId })
  const pageSize = pageDetails.pageSize;
  const [stationAlert, setStationAlert] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(0);
  const [pageData, setPageData] = useState('');
  const [depotStationDownloadCsv, setDepotStationDownloadCsv] = useState();

  const fileHeader =[
    {label: 'TIME',key: 'alertTime'},
    {label: 'PORT',key: 'portInformation'},
    {label: 'ALERT',key: 'alert'}
    
  ];
 
  const fileName= 'DepotStationAlerts.csv'

  const alertcolumns = [
    {
        field: "e",
        headerName: "e",
        headerClassName: "empty",
        sortable: false,
        hasFocus: false,
        cellClassName: "empty",
        width: 15,
        maxWidth: 25,
        minWidth: 28,
        disableColumnMenu: true,
        renderCell: (cellValues:any) => {
          return (
            <div className="empty">
              {cellValues.value}
            </div>
          );
        }
      },
    
      {
        field: "alertTime",
        headerName: "TIME",
        sortable: false,
        hasFocus: false,
        cellClassName: "bold_cell_text",
        minWidth: 180,
        flex: 0.1,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
          return (
            <div className="alert_unread_mark">
              <span className="unread dnone"></span>
              {cellValues.value}
            </div>
          );
        }
        
      },
  
      {
        field: 'portInformation',
        headerName: 'PORT',
        sortable: false,
        width: 120,
        disableColumnMenu: true,
        renderCell: (cellValues:any) => {
          return (
            <>
              {cellValues.value ? cellValues.value : '-'}
            </>
          );
        }
      },
      {
        field: 'alert',
        headerName: 'ALERT',
        sortable: false,
        minWidth: 200,
        flex: 0.3,
        disableColumnMenu: true,
        renderCell: (cellValues:any) => {
          return (
            <>
              {cellValues.value ? cellValues.value : '-'}
            </>
          );
        }
      },
    
    
      {
        field: "ee",
        headerName: "ee",
        headerClassName: "empty",
        sortable: false,
        hasFocus: false,
        cellClassName: "empty",
        width: 15,
        maxWidth: 25,
        minWidth: 28,
        disableColumnMenu: true,
        renderCell: (cellValues:any) => {
          return (
            <div className="empty">
              {cellValues.value}
            </div>
          );
        }
      },

  ];


  useEffect(() => {
    getStationAlert()
  }, [currentPage])
  useEffect(() => {
   if(stationAlert?.totalAlerts >0){
      getDepotStationDownloadCsv();
    }
   }, [stationAlert?.totalAlerts]);
  useEffect(() => {
    handlePageData()
  }, [stationAlert])

  const getStationAlert = async () => {
    await getMethod(ApiEndPoint.evseStationInformation, currentStationId + "/alerts" + "?page=" + currentPage + "&size=" + pageSize).then((res) => {
      setStationAlert(res.data)
      console.log("======",res.data)
    }).catch((err) => {
      console.log("Error", err)
    })
  }

  const getDepotStationDownloadCsv = async () => {
    await getMethod(ApiEndPoint.evseStationInformation, currentStationId + "/alerts" + "?page=" + 0 + "&size=" + stationAlert.totalAlerts).then((res) => {
      setDepotStationDownloadCsv(res.data);
      }).catch((err) => {
        console.log('Error', err);
      });
  };

  const alertDataSet = (value:any) => {
    let alertTableData = []
    if (value && value.alertsList) {
      alertTableData = value.alertsList.map((val:any) => ({ ...val, e: 'a', ee: 'aa', id: val.alertId,alertTime: moment(val.alertTime).format(dateFormats.dateTimeFormat),}))
    }
    return alertTableData
  }

  const handlePageData = () => {
    let totalPage = stationAlert && stationAlert.totalAlerts ? stationAlert.totalAlerts : 0;
    if (totalPage > 0) {
      let setPageValue = (currentPage >= 1 ? (pageSize * currentPage) + 1 : 1) + ' - ' + ((pageSize * (currentPage + 1)) < totalPage ? (pageSize * (currentPage + 1)) : totalPage) + ' of '
      setPageData(setPageValue)
    }
  }

  const handleCurrentPage = (setPage:any) => {
    setCurrentPage(setPage)
  }

  return (
    <>
      <div className="table_scroll">
        <div className="defult_table" style={styleDetails.styleWidth} >
          <CommonTableDetails
            rowHeight={59}
            headerHeight={30}
            rowDetails={alertDataSet(stationAlert)}
            columnDetails={alertcolumns}
            pazeSizeDetails={pageSize}
            totalCount={stationAlert?.totalAlerts}
          /> 
        </div>
        <div className="table_footer">
          <Grid container direction="row" alignItems="center" spacing={0}>
            <Grid className="text_left" item xs={8}>
              {stationAlert && stationAlert.totalAlerts > pageSize ?
                <CommonPagination
                currentPage={currentPage}
                totalCount={stationAlert?.totalAlerts}
                callBackCurrentPage={handleCurrentPage}
                />
                : <></>}
              <Button className="btn_white">Clear New Alerts</Button>
              <CommonDownloadCsv {...props}
                datas={alertDataSet(depotStationDownloadCsv)}
                headers={fileHeader}
                filename={fileName}
                callBackDownloadCsv={getDepotStationDownloadCsv}/>
                </Grid>
            <Grid className="text_right" item xs={4}>
              <div className="list_number">{pageData} {stationAlert.totalAlerts ? (stationAlert.totalAlerts + ' Alerts') : ''}</div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
export default DepotAlerts;	