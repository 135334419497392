import React from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Images from '../../assets/images';
import { fetchFleetAction }  from '../../redux/actions/fleetDashboard'
import { useSelector, useDispatch} from 'react-redux'
import { get } from 'lodash';
import './fleetCard.scss';



const FleetCard = (props:any) => {

  const history = useHistory();
  const dispatch = useDispatch();
  
  const navigateTo = () => {
    if(props?.redirectLink && props?.fleetId){
      dispatch(fetchFleetAction(props?.fleetId));
      history.push(props.redirectLink, { fleetId: props.fleetId })
    }
  }
  return (
    <>
      <Grid item xs={12} sm={6} md={4}>
        <Card className="fleet_card" onClick={navigateTo} >
          <CardActionArea>
            <CardContent className="fleet_card_inside">
              <Typography variant="h5" className="fleet_title">
                <img src={Images.IC_BUS} alt="Bus_Icon" />{props.fleetName}
              </Typography>
              <div className="detail_list">
                <List >
                  <ListItem>
                    <ListItemIcon>
                      <img src={Images.IC_EVSE_GRAY} alt="evse" />
                    </ListItemIcon>
                    {props.totalPorts} EVSE Ports
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <img src={Images.IC_AIRPORT_SHUTTLE} alt="airport shuttle" />
                    </ListItemIcon>
                    {props.totalVeciles} Vehicles
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <img src={Images.IC_ASSIGMENT_IND} alt="assignment_ind" />
                    </ListItemIcon>
                    {props.totalDrivers} Drivers
                  </ListItem>
                </List>
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </>
  )
}

export default FleetCard;
