import React from 'react';
import FleetDashboard from '../pages/fleetDashboard/fleetDashboard';
import EvseDepotList from '../pages/evseDepotList/evseDepotList';
import EvseDepotDetail from '../pages/evseDepotList/evseDepotDetail/evseDepotDetail';
import DepotStationDetails from '../pages/evseDepotList/depotStationDetails/depotStationDetails';
import VehicleGroup from '../pages/vehicleGroupList/vehicleGroup/vehicleGroup';
import VehicleGroupList from '../pages/vehicleGroupList/vehicleGroupList';
import VehicleDetail from '../pages/vehicleDetail/vehicleDetail';
import DriverGroupDetail from '../pages/driverGroupDetail/driverGroupDetail';
import MessagesDetails from '../pages/messagesDetails/messagesDetails';
import DriverGroups from '../pages/driverGroups/driverGroups';
import DriverInfo from '../pages/driverInfo/driverInfo';
import PrivateRoute from './privateRoute';
import SidebarMenu from '../components/sidebarMenu/sidebarMenu';
import Header from '../components/header/header';
import { makeStyles } from '@material-ui/core/styles';


export const RoutesWithoutSidebar = () => { return (
    <>
        <Header />
        <PrivateRoute exact path="/" component={FleetDashboard} />
    </>
)};


export const RoutesWithSidebar = () => { 
    const useStyles = makeStyles((theme) => ({
        root: {
          display: 'flex',
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        content: {
          flexGrow: 1,
          padding: theme.spacing(3),
        },
      }));
    
    const classes = useStyles();
    return (
    <>  
          <div className="inside_page">
        <div className={classes.root}>
        <SidebarMenu />
        <PrivateRoute path="/evseDepotList" component={EvseDepotList} />
        <PrivateRoute path="/evseDepotDetail" component={EvseDepotDetail} />
        <PrivateRoute path="/depotStationDetails" component={DepotStationDetails} />
        <PrivateRoute path="/fleetVehicle" component={VehicleGroup} />
        <PrivateRoute path="/vehicleDetail" component={VehicleDetail} />
        <PrivateRoute path="/vehicleList" component={VehicleGroupList} />
        <PrivateRoute path="/driverDetail" component={DriverGroupDetail} />
        <PrivateRoute path="/drivers" component={DriverGroups} />
        <PrivateRoute path="/driverInfo" component={DriverInfo} />
        <PrivateRoute path="/messages" component={MessagesDetails} />
        </div>
        </div>
    </>
)};

