import environment from "../environment"
import axios from "axios";

const getHeaders = () => {
    const headers = {
        accept: 'application/json',
    };
    return headers;
};

export const getMethod = async (url, param) => {
    let params = param ? param : '';
    try {
        let data = await axios({
            method: "GET",
            url: environment.baseUrl + url + params,
            headers: getHeaders(),
        })
        return data;
    } catch (error) {
        return error.response;
    }
};


export const putMethod = async (url, param, body) => {
    let params = param ? param : '';
    try {
        let data = await axios({
            method: "PUT",
            url: environment.baseUrl + url + params,
            data: body,
        });
        return data;
    } catch (error) {
        return error.response;
    }
};

export const postMethod = async (url, param, body) => {
    let params = param ? param : '';
    try {
        let data = await axios({
            method: "POST",
            url: environment.baseUrl + url + params,
            data: body,
        });
        return data;
    } catch (error) {
        return error.response;
    }
};
