import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import ApiEndPoint from 'services/apiUrl.json';
import { getMethod } from 'services/api';
import { pageDetails } from 'common/common';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { DataGrid } from '@material-ui/data-grid';
import CommonChargeSessionDetails from 'components/commonChargeSessionDetails/commonChargeSessionDetails'
import CommonDownloadCsv from 'components/commonDownloadCsv/commonDownloadCsv';
import CommonPagination from 'components/commonPagination/commonPagination';
import { styleDetails } from 'common/common';
import { dateFormats } from 'common/commonDateFormats';

const DepotChargeHistory = (props:any) => {
  const currentStationId = useSelector((state:any) => { return state.currentStationId;});
  const [depotStationChargeHistory, setDepotStationChargeHistory] = useState<any>();
  const pageSize = pageDetails.pageSize;
  const [currentPage, setCurrentPage] = useState(0);
  const [stationChargeSessionInfo, setStationChargeSessionInfo] = useState<any>({});
  const [open, setOpen] = React.useState(false);
  const [depotStationDownloadCsv, setDepotStationDownloadCsv] = useState();
  const [scroll, setScroll] = React.useState('paper');
  const [stationHistoryPageData, setStationHistoryPageData] = useState('');

  const fileHeader =[
    {label: 'START CHARGE',key: 'startChargeTime'},
    {label: 'END CHARGE',key: 'endChargeTime'},
    {label: 'PORT',key: 'portInformation'},
    {label: 'DRIVER NAME',key: 'driverName'},
    {label: 'RFID',key: 'rfId'},
    {label: 'VEHICLE ID',key: 'vehicleID'},
    {label: 'ENERGY (kWh)',key: 'energyKWh'}
  ];
 
  const fileName= 'DepotStationChargeHistory.csv'

  const historycolumns = [{
      field: 'e',
      headerName: 'e',
      headerClassName: 'empty',
      sortable: false,
      hasFocus: false,
      cellClassName: 'empty',
      width: 15,
      maxWidth: 25,
      minWidth: 28,
      disableColumnMenu: true,
      renderCell: (cellValues:any) => {
        return <div className="empty">{cellValues.value}</div>;
      },
    },

    {
      field: 'startChargeTime',
      headerName: 'START CHARGE',
      sortable: false,
      hasFocus: false,
      cellClassName: 'bold_cell_text',
      minWidth: 180,
      flex: 0.1,
      disableColumnMenu: true,
    },

    {
      field: 'endChargeTime',
      headerName: 'END CHARGE',
      sortable: false,
      cellClassName: 'bold_cell_text',
      flex: 0.1,
      minWidth: 180,
      disableColumnMenu: true,
    },
    {
      field: 'portInformation',
      headerName: 'PORT',
      sortable: false,
      width: 110,
      disableColumnMenu: true,
    },

    {
      field: 'driverName',
      headerName: 'DRIVER NAME',
      sortable: false,
      flex: 0.1,
      minWidth: 80,
      disableColumnMenu: true,
    },
    {
      field: 'rfId',
      headerName: 'RFID',
      sortable: false,
      width: 90,
      disableColumnMenu: true,
    },
    {
      field: 'vehicleID',
      headerName: 'VEHICLE ID',
      sortable: false,
      flex: 0.1,
      minWidth: 100,
      disableColumnMenu: true,
    },
    {
      field: 'energyKWh',
      headerName: 'ENERGY (kWh)',
      sortable: false,
      width: 110,
      disableColumnMenu: true,
    },

    {
      field: 'ee',
      headerName: 'ee',
      headerClassName: 'empty',
      sortable: false,
      hasFocus: false,
      cellClassName: 'empty',
      width: 15,
      maxWidth: 25,
      minWidth: 28,
      disableColumnMenu: true,
      renderCell: (cellValue:any) => {
        return <div className="empty">{cellValue.value}</div>;
      },
    },
  ];  

  const stationHandleClickOpen = (scrollType:any, chargeData:any) => () => {
    if (
      chargeData &&
      chargeData.chargeHistoryList &&
      chargeData.chargeHistoryList.length >= 1
    ) {
      setOpen(true);
    }
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    getDepotStationChargeHistoryData();
  }, [currentPage]);
  useEffect(() => {
    if(depotStationChargeHistory?.totalchargeHistory >0){
    getDepotStationDownloadCsv();
  }
  }, [depotStationChargeHistory?.totalchargeHistory]);
  useEffect(() => {
    handleDepotStationChargeHistoryPageData();
  }, [depotStationChargeHistory]);

  const getDepotStationChargeHistoryData = async () => {
    const page = currentPage;
    const size = pageSize;
    await getMethod(ApiEndPoint.evseStationInformation,currentStationId + '/charge-history' + '?page=' + page + '&size=' + size).then((res) => {
      setDepotStationChargeHistory(res.data);
      }).catch((err) => {
        console.log('Error', err);
      });
  };

  const getDepotStationDownloadCsv = async () => {
    await getMethod(ApiEndPoint.evseStationInformation,currentStationId + '/charge-history' + '?page=' + 0 + '&size=' + depotStationChargeHistory.totalchargeHistory).then((res) => {
      setDepotStationDownloadCsv(res.data);
      }).catch((err) => {
        console.log('Error', err);
      });
  };

  const onStationChargeHistoryClick = (e:any) =>{
    if (e && e.row){
        setStationChargeSessionInfo(e.row);
      }     
    }
  
  const depotStationChargeHistoryDataSet = (value:any) => {
    let chargeHistoryTableData = [];
    if (value && value.chargeHistoryList) {
      chargeHistoryTableData = value.chargeHistoryList.map((val:any) => ({ ...val,e: 'a',ee: 'aa',id: val.historyId,startChargeTime: moment(val.startChargeTime).format(dateFormats.dateTimeFormat), endChargeTime: moment(val.endChargeTime).format(dateFormats.dateTimeFormat),}));
    }
    return chargeHistoryTableData;
  };

  const handleDepotStationChargeHistoryPageData = () => {
    let totalStationPage = depotStationChargeHistory && depotStationChargeHistory.totalchargeHistory ? depotStationChargeHistory.totalchargeHistory : 0;
    if (totalStationPage > 0) {
      let setStationPageValue =
        (currentPage >= 1 ? pageSize * currentPage + 1 : 1) +
        ' - ' +
        (pageSize * (currentPage + 1) < totalStationPage
          ? pageSize * (currentPage + 1)
          : totalStationPage) +
        ' of ';
      setStationHistoryPageData(setStationPageValue);
    }
  };

  const handleCurrentPage = (setPage:any) => {
    setCurrentPage(setPage)
  }  

  return (
    <>
      <div className="table_scroll">
        <div  data-testid="historyDataTest" className="defult_table" style={styleDetails.styleWidth} onClick={stationHandleClickOpen('paper', depotStationChargeHistory)}>
          <DataGrid autoHeight className="table"
            rowHeight={59}
            headerHeight={30}
            rows={depotStationChargeHistoryDataSet(depotStationChargeHistory)}
            columns={historycolumns}
            pageSize={pageSize}
            onRowClick={(e) => onStationChargeHistoryClick(e)}
          />
        </div>
                {open &&
                <CommonChargeSessionDetails {...props}
                    open={open}
                    setHandleClose ={handleClose}
                    historyId={stationChargeSessionInfo.historyId}
                    sessionInfo={stationChargeSessionInfo}
                />}

        <div data-testid="footerDataTest" className="table_footer">
          <Grid container direction="row" alignItems="center" spacing={0}>
            <Grid className="text_left" item xs={8}>
              {depotStationChargeHistory &&
              depotStationChargeHistory.totalchargeHistory > pageSize ? (
                  <CommonPagination
                    currentPage={currentPage}
                    totalCount={depotStationChargeHistory?.totalchargeHistory}
                    callBackCurrentPage={handleCurrentPage}
                  />
              ) : (
                <></>
              )}
              <CommonDownloadCsv {...props}
                datas={depotStationChargeHistoryDataSet(depotStationDownloadCsv)}
                headers={fileHeader}
                filename={fileName}
                callBackDownloadCsv={getDepotStationDownloadCsv}/>
            </Grid>
            <Grid data-testid="dataTest" className="text_right" item xs={4}>
              <div className="list_number">
                {stationHistoryPageData}{' '}
                {depotStationChargeHistory && depotStationChargeHistory.totalchargeHistory ? depotStationChargeHistory.totalchargeHistory + ' charge session' : ''}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>{' '}
    </>
  );
};
export default DepotChargeHistory;