import React, { useState, useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import ListSubheader from '@material-ui/core/ListSubheader';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Images from 'assets/images';
import moment from 'moment';
import ImageGallery from 'react-image-gallery';
import ApiEndPoint from 'services/apiUrl.json';
import { getMethod } from 'services/api';
import "react-image-gallery/styles/css/image-gallery.css";
import EditOrganizationDetails from './editOrganizationDetails';
import DragDropImages from './drag-drop-images';

const DepotInformation = (props: any) => {

  const [openZone, setZoneOpen] = useState(false);
  const [scrollZone, setZoneScroll] = useState('paper');
  const [IsFormValid,setIsFormValid] = useState(true);

  const [openEditPhoto, setEditphotoOpen] = useState(false);
  const [scrollEditphoto, setEditphotoScroll] = useState('paper');

  const [openOrganizationDetail, setOrganizationDetailOpen] = useState(false);
  const [scrollOrganizationDetail, setOrganizationDetailScroll] = useState('paper');

  const [infoDetails, setInfoDetails] = useState<any>({});
  const [imageData, setImageData] = useState<any>({});
  const [zoneData, setZoneData] = useState<any>([]);
  const [permissionData, setPermissionData] = useState([]);
  const contactInfo = infoDetails && infoDetails?.contacts ? infoDetails.contacts[0] : [];
  const imageSlider = imageData && imageData.images ? imageData.images.map(({ url,thumbnailUrl, name }: any) => ({ original: url, thumbnail:thumbnailUrl ? thumbnailUrl : url, description: name })): [];

  const zoneOpen = (scrollType: any) => () => {
    setZoneOpen(true);
    setZoneScroll(scrollType);
  };
  
  const zoneClose = () => {
    setZoneOpen(false);
  };

  const editphotoOpen = (scrollType: any) => () => {
    setEditphotoOpen(true);
    setEditphotoScroll(scrollType);
  };
  
  const editphotoClose = () => {
    setEditphotoOpen(false);
  };
  
  const organizationDetailopen = (scrollType: any) => () => {
    setOrganizationDetailOpen(true);
    setOrganizationDetailScroll(scrollType);
  };
  
  const organizationDetailclose = () => {
    setOrganizationDetailOpen(false);
  };

  useEffect(() => {
    getDepotInformation()
    getImageData()
    getZoneData()
    getPermissionData()
  }, [])

  const getDepotInformation = async () => {
    await getMethod(ApiEndPoint.depotInformation, props?.depotId + "/information").then((res) => {
      setInfoDetails(res.data)
    }).catch((err) => {
      console.log("Error", err)
    })
  }

  const getImageData = async () => {
    await getMethod(ApiEndPoint.depotInformation, props?.depotId + "/images" ).then((res) => {
      setImageData(res.data)
    }).catch((err) => {
      console.log("Error", err)
    })
  }

  const getZoneData = async () => {
    await getMethod(ApiEndPoint.depotInformation, props?.depotId + "/zones" ).then((res) => {
      setZoneData(setApiZoneData(res.data))
    }).catch((err) => {
      console.log("Error", err)
    })
  }

  const setApiZoneData = (value: any) => {
    const zoneDetails: any= [];
    value && value.zones.map((val: any) => {
      val.zone_group.name && zoneDetails.some((el: any) => el.name === val.zone_group.name) ? 
        zoneDetails[0].zones.push(
          {id: val.id,
            name: val.name,
            desc: val.desc,
            images: val.images
            }) :
        zoneDetails.push({
          name: val.zone_group.name ? val.zone_group.name : '',
          desc: val.zone_group.desc ?  val.zone_group.desc : '',
          images: Object.keys(val.zone_group.image).length === 0 ? val.images[0] : val.zone_group.image ,
          zones: [{id: val.id,
            name: val.name,
            desc: val.desc,
            images: val.images}]})
    }) 
    return zoneDetails 
  }

  const getPermissionData = async () => {
    await getMethod(ApiEndPoint.depotInformation, props?.depotId + "/groups" ).then((res) => {
        setPermissionData(res?.data?.evseDepotGroups)
    }).catch((err) => {
      console.log("Error", err)
    })
  }

  return (
    <>
            <div className="dialog_box">
          <Dialog className="dialog_container dialog_container_center" {...props}
            open={openZone}
            onClose={zoneClose}
            scroll={scrollZone}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <div className="dialog_title">
              <h4>Station Zone</h4>
              <IconButton className="closed_btn full_right" onClick={zoneClose}>
                <span className="material-icons">cancel</span>
              </IconButton>
            </div>

            <DialogContent dividers='paper' {...props}>
              <DialogContentText component="div"
                id="scroll-dialog-description">
                <div className="station_zone">
                  <Grid container spacing={3}>
                    <Grid item xs={9}>
                      <div className="zone_map">
                        <img className="img_res" src={Images.ic_zone_map_del} alt="Zone Detail Map" />
                      </div>
                    </Grid>

                    <Grid item xs={3}>
                      <div className="zone_floor_info">
                        <List component="nav" className="z_floor_list">
                          <ListSubheader className="list_head">
                            <span className="circle">1</span> Loading
                          </ListSubheader>
                          <ListItem>
                            <ListItemIcon className="icon">
                              <img src={Images.IC_EVSE_BLUE} alt="Edit EVSE" />
                            </ListItemIcon>
                            <ListItemText primary="A - Port 1" />
                          </ListItem>

                          <ListItem>
                            <ListItemIcon className="icon">
                              <img src={Images.IC_EVSE_BLUE} alt="Edit EVSE" />
                            </ListItemIcon>
                            <ListItemText primary="A - Port 2" />
                          </ListItem>

                          <ListItem>
                            <ListItemIcon className="icon">
                              <img src={Images.IC_EVSE_BLUE} alt="Edit EVSE" />
                            </ListItemIcon>
                            <ListItemText primary="B - Port 1" />
                          </ListItem>

                          <ListItem>
                            <ListItemIcon className="icon">
                              <img src={Images.IC_EVSE_BLUE} alt="Edit EVSE" />
                            </ListItemIcon>
                            <ListItemText primary="B - Port 2" />
                          </ListItem>

                          <ListSubheader className="list_head">
                            <span className="circle">2</span> Service
                          </ListSubheader>
                          <ListItem>
                            <ListItemIcon className="icon">
                              <img src={Images.IC_EVSE_BLUE} alt="Edit EVSE" />
                            </ListItemIcon>
                            <ListItemText primary="C - Port 1" />
                          </ListItem>

                          <ListItem>
                            <ListItemIcon className="icon">
                              <img src={Images.IC_EVSE_BLUE} alt="Edit EVSE" />
                            </ListItemIcon>
                            <ListItemText primary="C - Port 2" />
                          </ListItem>

                          <ListItem>
                            <ListItemIcon className="icon">
                              <img src={Images.IC_EVSE_BLUE} alt="Edit EVSE" />
                            </ListItemIcon>
                            <ListItemText primary="C - Port 1" />
                          </ListItem>

                          <ListItem>
                            <ListItemIcon className="icon">
                              <img src={Images.IC_EVSE_BLUE} alt="Edit EVSE" />
                            </ListItemIcon>
                            <ListItemText primary="C - Port 2" />
                          </ListItem>

                          <ListItem>
                            <ListItemIcon className="icon">
                              <img src={Images.IC_EVSE_BLUE} alt="Edit EVSE" />
                            </ListItemIcon>
                            <ListItemText primary="C - Port 1" />
                          </ListItem>

                          <ListItem>
                            <ListItemIcon className="icon">
                              <img src={Images.IC_EVSE_BLUE} alt="Edit EVSE" />
                            </ListItemIcon>
                            <ListItemText primary="C - Port 2" />
                          </ListItem>

                        </List>
                      </div>
                    </Grid>
                  </Grid>

                  <div className="button_row">
                    <Grid container direction="row" alignItems="center" spacing={0}>
                      <Grid className="text_left" item xs={12}>
                        <Button className="text_btn" >First floor</Button>
                        <Button className="text_btn active">3rd floor</Button>
                      </Grid>

                    </Grid>
                  </div>
                </div>

              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>

        <div className="dialog_box">
          <Dialog className="dialog_container edit_detail_section dialog_container_center" {...props}
            open={openOrganizationDetail}
            onClose={organizationDetailclose}
            scroll={scrollOrganizationDetail}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <EditOrganizationDetails 
            organizationDetailclose={organizationDetailclose}
            IsFormValid={IsFormValid}
            setIsFormValid = {setIsFormValid}
            depotDetailsData={props.depotDetailsData}
            contactInfo={contactInfo}
            infoDetails={infoDetails}
            />
          </Dialog>
        </div>
        
        <div className="dialog_box">
          <Dialog className="dialog_container edit_photo_gallery  dialog_container_center" {...props}
            open={openEditPhoto}
            onClose={editphotoClose}
            scroll={scrollEditphoto}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <div className="dialog_title">
              <h4>Edit Photos</h4>
              <div className="button_row mt-0 full_right">                 
                    <Button className="btn_white text_btn_theme" onClick={editphotoClose}>Cancel</Button>
                 
                    <Button className="btn_white blue_bg">Save Changes</Button>  
                                 
                </div>            
            </div>

            <DialogContent  dividers='paper' {...props}>
              <DialogContentText
                id="scroll-dialog-description">
                  <div className="gallery_photo_upload">
                  <DragDropImages />
                  </div>
             
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>
        

      <div className="information_section">
        <Grid container spacing={2}>
          <Grid className="mid_section_left" item xs={12} sm={12} md={8}>
            <Card variant="outlined" className="basic_card">
              <CardContent>
                <div className="title_row">
                  <h2>ORGANIZATIONAL DETAILS</h2>
                  <div className="action_icon" onClick={organizationDetailopen('paper')}>
                    <IconButton className="top_action_btn">
                      <img src={Images.ic_edit} alt="edit" />
                    </IconButton>
                  </div>
                  <div className="clearfix"></div>
                </div>
                <div className="info_detail">
                  <Grid container >
                    <Grid className="" item xs={12} sm={6} md={6}>
                      <div className="tb_info_section mt-13">
                        <div className="label">NAME</div>
                        <div className="result">{props.depotDetailsData.evsedepotName}</div>
                      </div>
                      <div className="tb_info_section">
                        <div className="label">ORGANIZATION</div>
                        <div className="result">{props.depotDetailsData.organizationName}</div>
                      </div>
                      <div className="tb_info_section">
                        <div className="label">ADDRESS</div>
                        <div className="result">{props.depotDetailsData.streetAddress1 ? props.depotDetailsData.streetAddress1 : ''} {props.depotDetailsData.streetAddress2 ? ', ' + props.depotDetailsData.streetAddress2 : ''}<br />{props.depotDetailsData.city + ', ' + props.depotDetailsData.state + ' ' + props.depotDetailsData.zipCode} <br /> {props.depotDetailsData.country}
                          <br />
                        </div>
                      </div>
                      <div className="tb_info_section">
                        <div className="label">DESCRIPTION</div>
                        <div className="result">{props.depotDetailsData.description}
                        </div>
                      </div>
                      <div className="tb_info_section">
                        <div className="label">NOTES</div>
                        <div className="result">{props.depotDetailsData.notes}</div>
                      </div>
                    </Grid>
                    <Grid className="" item xs={12} sm={6} md={6}>
                      <div className="tb_info_section mt-13">
                        <div className="label">CONTACT</div>
                        <div className="result">
                          <span className="label">Name</span>
                          <span className="result">{contactInfo ? contactInfo.firstName : '' } {contactInfo ? contactInfo.lastName: '' } </span>
                        </div>
                        <div className="result">
                          <span className="label">Tel</span>
                          <span className="result">{contactInfo ? contactInfo.telePhoneNumber : ''} </span>
                        </div>
                        <div className="result">
                          <span className="label">Mob</span>
                          <span className="result">{contactInfo ? contactInfo.mobileNumber : ''}</span>
                        </div>
                        <div className="result">
                          <span className="label">Fax</span>
                          <span className="result">{contactInfo ? contactInfo.fax : ''}</span>
                        </div>
                        <div className="result">
                          <span className="label">Email</span>
                          <span className="result">{contactInfo ? contactInfo.email : ''}</span>
                        </div>
                        <div className="result">
                          <span className="label">Note</span>
                          <span className="result">{contactInfo ? contactInfo.note : ''}</span>
                        </div>
                      </div>
                      <div className="tb_info_section hours">
                        <div className="label">HOURS</div>
                        {infoDetails && infoDetails?.hoursOfOperations && infoDetails.hoursOfOperations.map((value: any, index: any) => (<div key={index} className="result">
                          <span className="label">{value.weekDay}</span>
                          <span className="result">{value.startTime ? moment(value.startTime, "h:mm:ss").format("hA") : null} {value.endTime ? '- ' + moment(value.endTime, "h:mm:ss").format("hA") : null}</span>
                        </div>))}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </CardContent>
            </Card>

            <Card variant="outlined" className="basic_card">
              <CardContent>
                <div className="title_row">
                  <h2>PHOTOS</h2>
                  <div className="action_icon">
                    <IconButton className="top_action_btn" onClick={editphotoOpen('paper')} >
                      <img src={Images.ic_edit} alt="edit" />
                    </IconButton>
                  </div>
                  <div className="clearfix"></div>
                  <div className="image_gallery">
                    <ImageGallery
                      items={imageSlider}
                      showPlayButton={false}
                      showBullets={true}
                    />
                  </div>
                </div>
                <div className="info_detail">
                </div>
              </CardContent>
            </Card>

          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Card variant="outlined" className="basic_card">
              <CardContent>
                <div className="title_row">
                  <h2>STATION ZONES</h2>
                  <div className="action_icon" onClick={zoneOpen('paper')}> 
                    <IconButton className="top_action_btn">
                      <img src={Images.ic_edit} alt="edit" />
                    </IconButton>
                  </div>
                  <div className="clearfix"></div>
                </div>
                <div className="info_detail">
                {zoneData && zoneData.map((value: any, index: any) => (
                  <div key={index} className="zone_section  mt-13">
                    <Grid container >
                      <Grid className="" item xs={12} sm={6} md={6}>

                        <div className="zone_detail">
                          <div className="zone_map">
                            <img className="img_res" src={value.images.url} alt="zone map" />
                          </div>
                        </div>
                      </Grid>
                      <Grid className="" item xs={12} sm={6} md={6}>
                        <div className="zone_floor_info">
                          <List component="nav" className="z_floor_list">
                            <ListSubheader className="list_head">
                              {value.name}
                            </ListSubheader>
                            {value && value.zones.map((val: any, index: any) => (
                            <ListItem key={index}>
                              <span className="circle">{val.id}</span>
                              <ListItemText primary={val.name} />
                            </ListItem>
                            ))}
                          </List>
                        </div>
                      </Grid>

                    </Grid>
                  </div>
                  ))}
                </div>
              </CardContent>
            </Card>

            <Card variant="outlined" className="basic_card">
              <CardContent>
                <div className="title_row">
                  <h2>PERMISSIONS</h2>
                  <div className="action_icon">
                    <IconButton className="top_action_btn">
                      <img src={Images.ic_edit} alt="edit" />
                    </IconButton>
                  </div>
                  <div className="clearfix"></div>
                </div>
                <div className="info_detail">
                {permissionData && permissionData.map((value: any, index: any) => (
                  <div key={index} className={`zone_section ${index === 0 ? 'mt-13': ''}`}>
                    <div className="tb_zone_section">
                     <div className="label">{value.grpType.toUpperCase()}</div>
                      {value && value.groups.map((grp: any, index: any) => (
                      <div key={index} className="result">{grp.name}</div>
                      ))}
                    </div>
                  </div>
                 ))}
                </div>
              </CardContent>
            </Card>

            <Card variant="outlined" className="basic_card">
              <CardContent>
                <div className="title_row">
                  <h2>AMENITIES</h2>
                  <div className="action_icon">
                    <IconButton className="top_action_btn">
                      <img src={Images.ic_edit} alt="edit" />
                    </IconButton>
                  </div>
                  <div className="clearfix"></div>
                </div>
                <div className="info_detail">
                  <div className="zone_section  mt-13">
                    <div className="tb_zone_section amenities">
                      <div className="result">
                        <span className="img_div"><img src={Images.ic_wifi} alt="wifi" /></span>
                                    Wifi</div>
                      <div className="result">
                        <span className="img_div"><img src={Images.ic_local_parking} alt="Parking" /></span>
                                    Parking</div>
                      <div className="result">
                        <span className="img_div"><img src={Images.ic_local_hotel} alt="Hotel" /></span>
                                    Hotel</div>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
export default DepotInformation