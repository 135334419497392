import React from "react";
import './depotStationDetails.scss';

const StationDetailMapMarker = ({ text, tooltip, $hover }:any) => {
    const handleClick = () => {
        console.log(`You clicked on ${tooltip}`);
    };

    return (
        <div className={$hover ? "station_icon_maps" : "station_icon_maps"} onClick={handleClick}>
      <span title={tooltip} >
        {text}
      </span>
        </div>
    );
};

export default StationDetailMapMarker;