import {defaultVariable} from '../common/common';
import Images from '../assets/images';
export const columns = [

  {
    field: "e",
    headerName: "e",
    headerClassName: "empty",
    sortable: false,
    hasFocus: false,
    cellClassName: "empty",
    width: 15,
    maxWidth: 25,
    minWidth: 28,
    disableColumnMenu: true,
    renderCell: (cellValues) => {
      return (
        <div className="empty">
          {cellValues.value}
        </div>
      );
    }
  },

  {
    field: 'Status',
    headerName: 'STATUS',
    sortable: false,
    flex: 0.1,
    minWidth: 100,
    disableColumnMenu: true,
    renderCell: (cellValues) => {
      return (
        
        <div className={`status ${defaultVariable.driverStatusColor[cellValues?.value?.toLowerCase()] }`}><span className="status_text">{cellValues?.value}</span></div>
      );
    }
  },

  
   
  {
    field: 'name',
    headerName: 'NAME',
    sortable: false,
    cellClassName: "bold_cell_text",
    width:160,
    disableColumnMenu: true
  },

    {
    field: "driverID",
    headerName: "DRIVER ID",
    sortable: false,
    hasFocus: false,
    cellClassName: "bold_cell_text",
    className: "port",
    minWidth: 100,
    flex: 0.1,
    disableColumnMenu: true,
   
  },

  {
    field: 'assignedVehicle',
    headerName: 'ASSIGNED VEHICLE',
    sortable: false,
    minWidth: 100,
    flex: 0.2,
    disableColumnMenu: true
  },

   {
    field: 'fleet',
    headerName: 'FLEET',
    sortable: false,
    minWidth: 100,
    flex: 0.2,
    disableColumnMenu: true
  },

  
  {
    field: '',
    headerName: '',
    sortable: false,
    width: 80,
    disableColumnMenu: true,
    renderCell: (cellValues) => {
      return (
        <div className="port_icon">
          <img src={Images.ic_dots} alt="map" />
          {cellValues.value}
        </div>
      );
    }
  },

  {
    field: "ee",
    headerName: "ee",
    headerClassName: "empty",
    sortable: false,
    hasFocus: false,
    cellClassName: "empty",
    width: 15,
    maxWidth: 25,
    minWidth: 28,
    disableColumnMenu: true,
    renderCell: (cellValues) => {
      return (
        <div className="empty">
          {cellValues.value}
        </div>
      );
    }
  },

];

export const alertcolumns = [

    {
      field: "e",
      headerName: "e",
      headerClassName: "empty",
      sortable: false,
      hasFocus: false,
      cellClassName: "empty",
      width: 15,
      maxWidth: 25,
      minWidth: 28,
      disableColumnMenu: true,
      renderCell: (cellValues) => {
        return (
          <div className="empty">
            {cellValues.value}
          </div>
        );
      }
    },
  
    {
      field: "time",
      headerName: "TIME",
      sortable: false,
      hasFocus: false,
      cellClassName: "bold_cell_text",
      width: 200,
      disableColumnMenu: true,
      renderCell: (cellValues) => {
        return (
          <div className="alert_unread_mark">
            <span className="unread dnone"></span>
            {cellValues.value}
          </div>
        );
      }
    },
  
  
    {
      field: 'driverId',
      headerName: 'DRIVER ID',
      sortable: false,
      flex: 0.1,
      minWidth: 70,
      disableColumnMenu: true,
    },
    {
      field: 'alert',
      headerName: 'ALERT',
      sortable: false,
      minWidth: 200,
      flex: 0.4,
      disableColumnMenu: true
    },
  
  
    {
      field: "ee",
      headerName: "ee",
      headerClassName: "empty",
      sortable: false,
      hasFocus: false,
      cellClassName: "empty",
      width: 15,
      maxWidth: 25,
      minWidth: 28,
      disableColumnMenu: true,
      renderCell: (cellValues) => {
        return (
          
          <div className="empty">
            {cellValues.value}
          </div>
        );
      }
    },
  
  ];

  export const alertrow = [
    { id: 1, e: 'a', time: 'Jan 3, 2022; 2:51 PM', driverId: 'DI8945', alert: 'EV battery is below 10% battery', ee: 'a' },
    { id: 2, e: 'a', time: 'Jan 2, 2022; 8:27 PM', driverId: 'DI8928', alert: 'Upload driver license', ee: 'a' },
    { id: 3, e: 'a', time: 'Jan 1, 2022; 9:53 PM', driverId: 'DI8418', alert: 'Unavailable for schedule shift', ee: 'a' },
    { id: 4, e: 'a', time: 'Dec 31, 2021; 7:34 PM', driverId: 'DI8946', alert: 'Submit a citation', ee: 'a' },
    { id: 5, e: 'a', time: 'Dec 30, 2021; 7:37 PM', driverId: 'DI8947', alert: 'License will expire in 30 days', ee: 'a' },
    { id: 6, e: 'a', time: 'Dec 29, 2021; 5:14 PM', driverId: 'DI8981', alert: 'EV battery is below 20% battery', ee: 'a' },
    { id: 7, e: 'a', time: 'Dec 28, 2021; 2:25 PM', driverId: 'DI8494', alert: 'License will expire in 30 days', ee: 'a' },
    { id: 8, e: 'a', time: 'Dec 27, 2021; 10:42 AM', driverId: 'DI8950', alert: 'Remove driver profile', ee: 'a' },
    { id: 9, e: 'a', time: 'Dec 20, 2021; 7:38 PM', driverId: 'DI8984', alert: 'Upload driver license', ee: 'a' },
    { id: 10, e: 'a', time: 'Dec 16, 2021; 7:16 AM', driverId: 'DI8952', alert: 'Update profile', ee: 'a' },
            
  ];

  export const rows = [
    { id: 1, e: 'a', Status: 'On Duty', name: 'McKenna Declan', driverID: 'DI8945', assignedVehicle: 'VEH1853, VEH4156, VEH1441', fleet: 'Ford 150 Lightning, Delivery', action: '', ee: 'a' },
    { id: 2, e: 'a', Status: 'Off Duty', name: 'Tristen Terrance', driverID: 'DI8951', assignedVehicle: 'VEH4156, VEH1441', fleet: 'Ford 150 Lightning, Delivery', action: '', ee: 'a' },
    { id: 3, e: 'a', Status: 'Suspended', name: 'Jerry Ronnan', driverID: 'DI4578', assignedVehicle: 'VEH1853', fleet: 'Super Duty', action: '', ee: 'a' },
    { id: 4, e: 'a', Status: 'On Duty', name: 'Justin Li', driverID: 'DI1855', assignedVehicle: 'VEH1853, VEH4156, VEH1441', fleet: 'E-Transit', action: '', ee: 'a' },
    { id: 5, e: 'a', Status: 'Suspended', name: 'Sarah Stuckey', driverID: 'DI8945', assignedVehicle: 'VEH1853', fleet: 'Super Duty, Delivery', action: '', ee: 'a' },
    { id: 6, e: 'a', Status: 'On Duty', name: 'Leah Starry', driverID: 'DI5571', assignedVehicle: 'VEH4156, VEH1441', fleet: 'E-Transit', action: '', ee: 'a' },
    { id: 7, e: 'a', Status: 'On Duty', name: 'Sam McCain', driverID: 'DI8581', assignedVehicle: 'VEH4156, VEH1441', fleet: 'E-Transit, Delivery', action: '', ee: 'a' },
    { id: 8, e: 'a', Status: 'Off Duty', name: 'Sara McLean', driverID: 'DI8945', assignedVehicle: 'VEH1853, VEH4156, VEH1441', fleet: 'Ford 150 Lightning, E-Transit', action: '', ee: 'a' },
    { id: 9, e: 'a', Status: 'On Duty', name: 'Melissa Parker', driverID: 'DI8557', assignedVehicle: 'VEH4156, VEH1441', fleet: 'Ford 150', action: '', ee: 'a' },
    { id: 10, e: 'a', Status: 'On Duty', name: 'Susan O’Neal', driverID: 'DI2545', assignedVehicle: 'VEH1853', fleet: 'Ford 150', action: '', ee: 'a' },
    { id: 11, e: 'a', Status: 'Off Duty', name: 'Terrance Leh', driverID: 'DI8925', assignedVehicle: 'VEH1853', fleet: 'Super Duty, Delivery', action: '', ee: 'a' },
   
    
   ];