export const FETCH_CURRENT_STATION_ID = 'FETCH_CURRENT_STATION_ID';


export const fetchCurrentStationId = (value: any) => {
    return {
        type: FETCH_CURRENT_STATION_ID,
        payload: value
    }
};

