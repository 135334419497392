import environment from './environment';
import { BrowserRouter, Switch } from "react-router-dom";
import {RoutesWithoutSidebar,RoutesWithSidebar } from './routes/routes';
import { Route } from 'react-router-dom';

import './App.scss';
function App() {
  console.log(environment.baseUrl)
  return (
    <div className="wrapper">
      <BrowserRouter>
        <Switch>     
        <Route exact path='/' component={RoutesWithoutSidebar} />   
        <Route path='/' component={RoutesWithSidebar} /> 
         </Switch>
      </BrowserRouter>
    </div >
  );
}

export default App;
