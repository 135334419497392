import React, { useState, useEffect } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import './commonTableDetails.scss';

const CommonTableDetails = (props:any) => {

    return (
        <>
            <DataGrid autoHeight className="table"
                rowHeight={props?.rowHeight}
                headerHeight={props?.headerHeight}
                rows={props?.rowDetails}
                columns={props?.columnDetails}
                pageSize={props?.pazeSizeDetails}
                disableSelectionOnClick />
        </>
    );
}
export default CommonTableDetails;