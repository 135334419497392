import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Images from '../../../../assets/images';
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import EditVehiclesDetails from './editVehicleDetails';

const VehicleGroupInformation = (props:any) => {
  const [openFleetPermission, setFleetPermissionOpen] = useState(false);
  const [scrollFleetPermission, setFleetPermissionScroll] = useState('paper');

  const [openChargePriority, setChargePriorityOpen] = useState(false);
  const [scrollChargePriority, setChargePriorityScroll] = useState('paper');

  const [openEditfleeDetail, setEditfleeDetailOpen] = useState(false);
  const [scrollEditfleeDetail, setEditfleeDetailScroll] = useState('paper');
  const [IsFormValid,setIsFormValid] = useState(true);  
 

  const vehicleData = useSelector((state:any) => { return state.vehicleData })

  const fleetPermissionopen = (scrollType: any) => () => {
    setFleetPermissionOpen(true);
    setFleetPermissionScroll(scrollType);
  };
  
  const fleetPermissionclose = () => {
    setFleetPermissionOpen(false);
  };

  const chargePriorityOpen = (scrollType: any) => () => {
    setChargePriorityOpen(true);
    setChargePriorityScroll(scrollType);
  };
  
  const chargePriorityclose = () => {
    setChargePriorityOpen(false);
  };

  const EditfleeDetailopen = (scrollType: any) => () => {
    setEditfleeDetailOpen(true);
    setEditfleeDetailScroll(scrollType);
  };
  
  const EditfleeDetailclose = () => {
    setEditfleeDetailOpen(false);
  };

    return (
        <>

<div className="dialog_box">
          <Dialog className="dialog_container edit_detail_section dialog_container_center" {...props}
            open={openEditfleeDetail}
            onClose={EditfleeDetailclose}
            scroll={scrollEditfleeDetail}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <EditVehiclesDetails
              EditfleeDetailclose={EditfleeDetailclose}
              IsFormValid={IsFormValid}
              setIsFormValid = {setIsFormValid}
              vehicleData={vehicleData}
            />
          </Dialog>
          </div>


<div className="dialog_box">
          <Dialog className="dialog_container sm edit_detail_section dialog_container_center" {...props}
            open={openChargePriority}
            onClose={chargePriorityclose}
            scroll={scrollChargePriority}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <div className="dialog_title">
              <h4>Edit Fleet Charge Priority</h4>
              <div className="button_row mt-0 full_right">                 
                    <Button className="btn_white text_btn_theme" onClick={chargePriorityclose}>Cancel</Button>
                    <Button className="btn_white blue_bg">Save Changes</Button>              
                </div>  
             
            </div>

            <DialogContent dividers='paper' {...props}>
              <DialogContentText component="div"
                id="scroll-dialog-description">
                <div className="defult_form">
                  <div className="section_group">
                  <Grid className="dobule_line mt-0" container justifyContent="flex-start" alignItems="flex-start" spacing={4}>
                    <Grid  item xs={9}>
                      <div className="form_col">
                      <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}>
                        <Grid item xs={12}>
                        <label>Fleet Charge Priority</label>
                        <Select labelId="demo-simple-select-label"  variant="outlined" >
                        <MenuItem className="defult_select_option" value={1}>Priority 1</MenuItem>
                        <MenuItem className="defult_select_option" value={2}>Priority 2</MenuItem>
                        <MenuItem className="defult_select_option" value={3}>Priority 3</MenuItem>
                      </Select> 
                        </Grid> 
                      </Grid> 
                      </div> 
                    
                     </Grid>
                                   </Grid>  
                  </div> 
                

                   </div>              
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>


<div className="dialog_box">
          <Dialog className="dialog_container sm edit_detail_section dialog_container_center" {...props}
            open={openFleetPermission}
            onClose={fleetPermissionclose}
            scroll={scrollFleetPermission}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <div className="dialog_title">
              <h4>Edit Fleet Permission</h4>
              <div className="button_row mt-0 full_right">                 
                    <Button className="btn_white text_btn_theme" onClick={fleetPermissionclose}>Cancel</Button>
                    <Button className="btn_white blue_bg">Save Changes</Button>              
                </div>  
             
            </div>

            <DialogContent dividers='paper' {...props}>
              <DialogContentText component="div"
                id="scroll-dialog-description">
                <div className="defult_form permission">
                  <div className="section_group">
                  <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={4}>
                    <Grid item xs={6}>
                    <FormLabel component="legend">Drivers</FormLabel>
                      <div className="form_col checkbox">
                        <FormControlLabel control={ 
                          <Checkbox name="checkedB" />
                        }
                        label="Maintenance"
                        />
                      </div>
                      <div className="form_col checkbox">
                        <FormControlLabel control={ 
                          <Checkbox name="checkedB" color="primary"/>
                        }
                        label="Operations"
                        />
                      </div>
                      <div className="form_col checkbox">
                        <FormControlLabel control={ 
                          <Checkbox name="checkedB" color="primary"/>
                        }
                        label="Security"
                        />
                      </div>
                      <div className="form_col checkbox">
                        <FormControlLabel control={ 
                          <Checkbox name="checkedB" color="primary"/>
                        }
                        label="Administrator"
                        />
                      </div>
                      <div className="form_col checkbox">
                        <FormControlLabel control={ 
                          <Checkbox name="checkedB" color="primary"/>
                        }
                        label="Service Operator"
                        />
                      </div>

                 </Grid>
                 <Grid item xs={6}>
                 <FormLabel component="legend">EVSE Location</FormLabel>
                      <div className="form_col checkbox">
                        <FormControlLabel control={ 
                          <Checkbox name="checkedB" color="primary"/>
                        }
                        label="Culver City"
                        />
                      </div>
                      <div className="form_col checkbox">
                        <FormControlLabel control={ 
                          <Checkbox name="checkedB" color="primary"/>
                        }
                        label="DTLA"
                        />
                      </div>
                      <div className="form_col checkbox">
                        <FormControlLabel control={ 
                          <Checkbox name="checkedB" color="primary"/>
                        }
                        label="Engelwood"
                        />
                      </div>
                      <div className="form_col checkbox">
                        <FormControlLabel control={ 
                          <Checkbox name="checkedB" color="primary"/>
                        }
                        label="LAX - Hawthorne"
                        />
                      </div>
                      <div className="form_col checkbox">
                        <FormControlLabel  control={ 
                          <Checkbox name="checkedB" color="primary"/>
                        }
                        label="Santa Monica"
                        />
                      </div>
                      <div className="form_col checkbox">
                        <FormControlLabel  control={ 
                          <Checkbox name="checkedB" color="primary"/>
                        }
                        label="Torrance"
                        />
                      </div>
                      <div className="form_col checkbox">
                        <FormControlLabel  control={ 
                          <Checkbox name="checkedB" color="primary"/>
                        }
                        label="Venice"
                        />
                      </div>
                      <div className="form_col checkbox">
                        <FormControlLabel control={ 
                          <Checkbox name="checkedB" color="primary"/>
                        }
                        label="West Hollywood"
                        />
                      </div>
                      <div className="form_col checkbox">
                        <FormControlLabel control={ 
                          <Checkbox name="checkedB" color="primary"/>
                        }
                        label="Westwood"
                        />
                      </div>

                 </Grid>
                  </Grid>   

                  </div>
               
                 
                 
                   
                  </div>             
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>

                  <div className="information_section">
                    <Grid container spacing={2}>
                      <Grid className="mid_section_left" item xs={12} sm={12} md={7} lg={8}>
                        <Card variant="outlined" className="basic_card">
                          <CardContent>
                            <div className="title_row">
                              <h2>FLEET DETAILS</h2>
                              <div className="action_icon" onClick={EditfleeDetailopen('paper')}>
                                <IconButton className="top_action_btn">
                                  <img src={Images.ic_edit} alt="edit" />
                                </IconButton>
                              </div>
                              <div className="clearfix"></div>
                            </div>
                            <div className="info_detail">
                              <Grid container >
                                <Grid className="" item xs={12} sm={6} md={6}>
                                  <div className="tb_info_section mt-13">
                                    <div className="label">FLEET NAME</div>
                                    <div className="result">{vehicleData.vehDetails.name ? vehicleData.vehDetails.name : '-'}</div>
                                  </div>
                                  <div className="tb_info_section">
                                    <div className="label">ORGANIZATION</div>
                                    <div className="result">{vehicleData.OrganisationDetails.organizationName ? vehicleData.OrganisationDetails.organizationName : '-'}</div>
                                  </div>
                                  <div className="tb_info_section">
                                    <div className="label">ADDRESS</div>
                                    <div className="result">{vehicleData.OrganisationDetails.evsedepotStreetAddress1 ? vehicleData.OrganisationDetails.evsedepotStreetAddress1 : '-'} {vehicleData.OrganisationDetails.evsedepotStreetAddress2 ? vehicleData.OrganisationDetails.evsedepotStreetAddress2 : '-'}<br />
                                    {vehicleData.OrganisationDetails.evsedepotCity ? vehicleData.OrganisationDetails.evsedepotCity : '-'} {vehicleData.OrganisationDetails.evsedepotState ? vehicleData.OrganisationDetails.evsedepotState : '-'} {vehicleData.OrganisationDetails.evsedepotZipCode ? vehicleData.OrganisationDetails.evsedepotZipCode : '-'}<br />
                                    {vehicleData.OrganisationDetails.evsedepotCountryCode ? vehicleData.OrganisationDetails.evsedepotCountryCode : '-'}<br />
                                    </div>
                                  </div>
                                  <div className="tb_info_section">
                                    <div className="label">DESCRIPTION</div>
                                    <div className="result">{vehicleData.OrganisationDetails.description ? vehicleData.OrganisationDetails.description : '-'}
                                    </div>
                                  </div>
                                  <div className="tb_info_section">
                                    <div className="label">NOTES</div>
                                    <div className="result">{vehicleData.OrganisationDetails.notes ? vehicleData.OrganisationDetails.notes : '-'}</div>
                                  </div>
                                </Grid>
                                <Grid className="" item xs={12} sm={6} md={6}>
                                  <div className="tb_info_section mt-13">
                                    <div className="label">FLEET CONTACT</div>
                                    <div className="result">
                                      <span className="label">Name</span>
                                      <span className="result">{vehicleData.contactDetails[0]?.firstName ? vehicleData.contactDetails[0].firstName : '-'} {vehicleData.contactDetails[0].lastName ? vehicleData.contactDetails[0].lastName : '-'}</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">Tel</span>
                                      <span className="result">{vehicleData.contactDetails[0]?.telePhoneNumber ? vehicleData.contactDetails[0].telePhoneNumber : '-'}</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">Mob</span>
                                      <span className="result">{vehicleData.contactDetails[0]?.mobileNumber ? vehicleData.contactDetails[0].mobileNumber : '-'}</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">Fax</span>
                                      <span className="result">{vehicleData.contactDetails[0]?.fax ? vehicleData.contactDetails[0].fax : '-'}</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">Email</span>
                                      <span className="result">{vehicleData.contactDetails[0]?.email ? vehicleData.contactDetails[0].email : '-'}</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">Note</span>
                                      <span className="result">{vehicleData.contactDetails[0]?.note ? vehicleData.contactDetails[0].note : '-'}</span>
                                    </div>
                                  </div>
                                  <div className="tb_info_section mt-13">
                                    <div className="label">SERVICE CONTACT</div>
                                    <div className="result">
                                      <span className="label">Name</span>
                                      <span className="result">{vehicleData.contactDetails[0]?.firstName ? vehicleData.contactDetails[0].firstName : '-'} {vehicleData.contactDetails[0].lastName ? vehicleData.contactDetails[0].lastName : '-'}</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">Tel</span>
                                      <span className="result">{vehicleData.contactDetails[0]?.telePhoneNumber ? vehicleData.contactDetails[0].telePhoneNumber : '-'}</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">Mob</span>
                                      <span className="result">{vehicleData.contactDetails[0]?.mobileNumber ? vehicleData.contactDetails[0].mobileNumber : '-'}</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">Fax</span>
                                      <span className="result">{vehicleData.contactDetails[0]?.fax ? vehicleData.contactDetails[0].fax : '-'}</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">Email</span>
                                      <span className="result">{vehicleData.contactDetails[0]?.email ? vehicleData.contactDetails[0].email : '-'}</span>
                                    </div>
                                    <div className="result">
                                      <span className="label">Note</span>
                                      <span className="result">{vehicleData.contactDetails[0]?.note ? vehicleData.contactDetails[0].note : '-'}</span>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={12} md={5} lg={4}>
                      <Card variant="outlined" className="basic_card">
              <CardContent>
                <div className="title_row">
                  <h2>CHARGE PRIORITY</h2>
                  <div className="action_icon" onClick={chargePriorityOpen('paper')}>
                    <IconButton className="top_action_btn">
                      <img src={Images.ic_edit} alt="edit" />
                    </IconButton>
                  </div>
                 
                  <div className="clearfix"></div>
                </div>
                <div className="info_detail">
                  <div className="port_details vehicle_info_card tb_info_section">                          
                  <div className="row mb-10 set_priority">
                    <span className="label ">Fleet Charge Priority</span>
                    <span className="result">
                    Priority 3

                  
                    </span>
                  </div>
                  </div>
                </div>
              </CardContent>
            </Card>

            <Card variant="outlined" className="basic_card ">
              <CardContent>
                <div className="title_row">
                  <h2>PERMISSIONS</h2>
                  <div className="action_icon" onClick={fleetPermissionopen('paper')}>
                    <IconButton className="top_action_btn">
                      <img src={Images.ic_edit} alt="edit" />
                    </IconButton>
                  </div>
                  <div className="clearfix"></div>
                </div>
                <div className="info_detail">
                  <div className="zone_section  mt-13">
                    <div className="tb_zone_section">
                      <div className="label">DRIVERS</div>
                      <div className="result">Maintenance</div>
                      <div className="result">Security</div>
                    </div>
                  </div>
                  <div className="zone_section">
                    <div className="tb_zone_section">
                      <div className="label">EVSE LOCATION</div>
                      <div className="result">Culver City</div>
                      <div className="result">LAX - Hawthorne</div>
                      <div className="result">Santa Monica</div>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
            </Grid>
          </Grid>
        </div>
        </>
    );
}
export default VehicleGroupInformation;